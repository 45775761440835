import { useState, useEffect } from "react";
import { getPremiumApiUrl } from "../config/api";

/**
 * Custom React hook to fetch market quotes with technical indicators from the backend API.
 *
 * @param {string} symbol - Stock symbol (e.g., 'AAPL')
 * @param {string} exchange - Exchange code (e.g., 'US')
 * @param {Array<string>} [indicators] - List of indicators (e.g., ['RSI', 'MACD'])
 * @param {string} [timeframe='d'] - Timeframe for data aggregation ('d', 'w', 'm'). Defaults to 'd'
 * @param {number} [period=100] - Number of periods to retrieve. Defaults to 100
 * @param {string} [fromDate] - Start date in 'YYYY-MM-DD' format (optional)
 * @param {string} [toDate] - End date in 'YYYY-MM-DD' format (optional)
 * @returns {{ data: Object | null, loading: boolean, error: string | null }}
 */
export const useMarketQuotesWithIndicators = (
  symbol,
  exchange,
  indicators = null,
  timeframe = "d",
  period = 100,
  fromDate,
  toDate,
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Do not fetch if no symbol or exchange is provided
    if (!symbol || !exchange) return;

    const fetchMarketQuotesWithIndicators = async () => {
      setLoading(true);
      setError(null);

      try {
        // Construct the query parameters
        const params = new URLSearchParams();

        // Add timeframe parameter
        if (timeframe) {
          params.append("timeframe", timeframe);
        }

        // Add period parameter
        if (period) {
          params.append("period", period);
        }

        // Add from date parameter
        if (fromDate) {
          params.append("from", fromDate);
        }

        // Add to date parameter
        if (toDate) {
          params.append("to", toDate);
        }

        // Add indicators parameter
        if (indicators && indicators.length > 0) {
          params.append("indicators", indicators.join(","));
        }

        // Combine symbol and exchange
        const symbolWithExchange = `${symbol}.${exchange}`;

        // Build the complete URL
        const url = `${getPremiumApiUrl()}/api/market-quotes/${encodeURIComponent(
          symbolWithExchange,
        )}/with-indicators?${params.toString()}`;

        // Fetch the data
        const response = await fetch(url);

        if (!response.ok) {
          // Extract error message from the response body
          let errorMessage = `Error ${response.status}: ${response.statusText}`;
          try {
            const errorData = await response.json();
            if (errorData.detail) {
              errorMessage = errorData.detail;
            }
          } catch (e) {
            // Unable to parse error message, keep default
          }
          throw new Error(errorMessage);
        }

        // Parse the response
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchMarketQuotesWithIndicators();
  }, [symbol, exchange, indicators, timeframe, period, fromDate, toDate]);

  return { data, loading, error };
};
