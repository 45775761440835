import React from "react";
import {
  Building2,
  Globe,
  Users,
  Calendar,
  MapPin,
  Phone as PhoneIcon,
  Globe2,
  UserCircle,
  Activity,
  Network,
  Briefcase as BriefcaseIcon,
} from "lucide-react";

const GeneralInfo = ({ data, darkMode, formatDate, MetricCard }) => {
  if (!data) return null;

  const listings = Object.values(data.Listings || {});
  const officers = Object.values(data.Officers || {}).slice(0, 5);

  // Utility functions for styling based on dark mode
  const getBackgroundStyle = () => {
    return darkMode
      ? "bg-slate-800 shadow-lg shadow-slate-900/20"
      : "bg-white shadow-lg shadow-slate-200/50";
  };

  const getTextColor = () => {
    return darkMode ? "text-slate-200" : "text-slate-700";
  };

  return (
    <div className="p-4 sm:p-2 space-y-6">
      {/* Enhanced Company Identifiers */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 sm:gap-6">
        <MetricCard
          title="Stock Symbol"
          value={data.Code}
          subtitle={`${data.Exchange} - ${data.Type}`}
          tooltip="Primary trading symbol and exchange"
          darkMode={darkMode}
        />
        <MetricCard
          title="Industry"
          value={data.Industry}
          subtitle={data.Sector}
          tooltip="Primary industry classification"
          darkMode={darkMode}
        />
        <MetricCard
          title="Employees"
          value={data.FullTimeEmployees?.toLocaleString()}
          subtitle="Full-time"
          tooltip="Total number of full-time employees"
          darkMode={darkMode}
        />
        <MetricCard
          title="Founded"
          value={formatDate(data.IPODate)}
          subtitle="IPO Date"
          tooltip="Company IPO date"
          darkMode={darkMode}
        />
      </div>

      {/* Industry Classification */}
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <Activity className="w-6 h-6 text-blue-500" />
              <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                Industry Classification
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {[
                { label: "GIC Sector", value: data.GicSector },
                { label: "GIC Group", value: data.GicGroup },
                { label: "GIC Industry", value: data.GicIndustry },
                { label: "GIC Sub-Industry", value: data.GicSubIndustry },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`rounded-lg p-4 ${
                    darkMode ? "bg-slate-700" : "bg-slate-50"
                  }`}
                >
                  <div
                    className={`text-sm font-medium ${
                      darkMode ? "text-slate-400" : "text-slate-600"
                    }`}
                  >
                    {item.label}
                  </div>
                  <div
                    className={`text-base font-semibold ${
                      darkMode ? "text-white" : "text-slate-900"
                    }`}
                  >
                    {item.value || "N/A"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Company Details */}
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-6">
            <div className="flex items-center space-x-3">
              <Building2 className="w-6 h-6 text-blue-500" />
              <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                Company Overview
              </h3>
            </div>
            {/* Description */}
            <div
              className={`text-sm ${
                darkMode ? "text-slate-300" : "text-slate-700"
              }`}
            >
              {data.Description}
            </div>

            {/* Market Classification */}
            <div>
              <h4
                className={`text-base font-semibold ${
                  darkMode ? "text-slate-200" : "text-slate-700"
                }`}
              >
                Market Classification
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                {[
                  {
                    label: "Market Category",
                    value: data.InternationalDomestic,
                  },
                  { label: "Home Category", value: data.HomeCategory },
                  {
                    label: "Status",
                    value: data.IsDelisted ? "Delisted" : "Active",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`rounded-lg p-4 ${
                      darkMode ? "bg-slate-700" : "bg-slate-50"
                    }`}
                  >
                    <div
                      className={`text-sm font-medium ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    >
                      {item.label}
                    </div>
                    <div
                      className={`text-base font-semibold ${
                        darkMode ? "text-white" : "text-slate-900"
                      }`}
                    >
                      {item.value || "N/A"}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Key Information Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Company Information */}
              <div>
                <h4
                  className={`text-base font-semibold ${
                    darkMode ? "text-slate-200" : "text-slate-700"
                  }`}
                >
                  Company Information
                </h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-start space-x-3">
                    <Calendar
                      className={`w-5 h-5 mt-1 ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    />
                    <div>
                      <div
                        className={`text-sm ${
                          darkMode ? "text-slate-300" : "text-slate-700"
                        }`}
                      >
                        Founded: {formatDate(data.IPODate)}
                      </div>
                      <div
                        className={`text-sm ${
                          darkMode ? "text-slate-300" : "text-slate-700"
                        }`}
                      >
                        Fiscal Year End: {data.FiscalYearEnd}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <Globe
                      className={`w-5 h-5 mt-1 ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    />
                    <div>
                      <div
                        className={`text-sm ${
                          darkMode ? "text-slate-300" : "text-slate-700"
                        }`}
                      >
                        {data.CountryName} ({data.CountryISO})
                      </div>
                      <div
                        className={`text-sm ${
                          darkMode ? "text-slate-300" : "text-slate-700"
                        }`}
                      >
                        Currency: {data.CurrencyCode} ({data.CurrencySymbol})
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Information */}
              <div>
                <h4
                  className={`text-base font-semibold ${
                    darkMode ? "text-slate-200" : "text-slate-700"
                  }`}
                >
                  Contact Information
                </h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-start space-x-3">
                    <MapPin
                      className={`w-5 h-5 mt-1 ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    />
                    <div
                      className={`text-sm ${
                        darkMode ? "text-slate-300" : "text-slate-700"
                      }`}
                    >
                      {data.AddressData?.Street}
                      <br />
                      {data.AddressData?.City}, {data.AddressData?.State}{" "}
                      {data.AddressData?.ZIP}
                      <br />
                      {data.AddressData?.Country}
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <PhoneIcon
                      className={`w-5 h-5 ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    />
                    <span
                      className={`text-sm ${
                        darkMode ? "text-slate-300" : "text-slate-700"
                      }`}
                    >
                      {data.Phone}
                    </span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Globe2
                      className={`w-5 h-5 ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    />
                    <a
                      href={data.WebURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`text-sm ${
                        darkMode ? "text-blue-400" : "text-blue-600"
                      } hover:underline`}
                    >
                      {data.WebURL?.replace("https://", "")?.replace(
                        "http://",
                        "",
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Officers */}
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-6">
            <div className="flex items-center space-x-3">
              <Users className="w-6 h-6 text-blue-500" />
              <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                Key Officers
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {officers.map((officer, index) => (
                <div
                  key={index}
                  className={`rounded-lg p-4 transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg ${
                    darkMode ? "bg-slate-700" : "bg-slate-50"
                  }`}
                >
                  <div className="flex items-start space-x-3">
                    <UserCircle
                      className={`w-6 h-6 ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    />
                    <div>
                      <div
                        className={`text-base font-semibold ${
                          darkMode ? "text-white" : "text-slate-900"
                        }`}
                      >
                        {officer.Name}
                      </div>
                      <div
                        className={`text-sm ${
                          darkMode ? "text-slate-300" : "text-slate-700"
                        }`}
                      >
                        {officer.Title}
                      </div>
                      {officer.YearBorn && officer.YearBorn !== "NA" && (
                        <div
                          className={`text-sm ${
                            darkMode ? "text-slate-400" : "text-slate-600"
                          }`}
                        >
                          Born: {officer.YearBorn}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Additional Listings */}
      {listings.length > 0 && (
        <div
          className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
            darkMode ? "border border-slate-700" : "border border-slate-100"
          }`}
        >
          <div className="p-4 sm:p-5">
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <Network className="w-6 h-6 text-blue-500" />
                <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                  Additional Listings
                </h3>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {listings.map((listing, index) => (
                  <div
                    key={index}
                    className={`rounded-lg p-4 transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg ${
                      darkMode ? "bg-slate-700" : "bg-slate-50"
                    }`}
                  >
                    <div
                      className={`text-sm font-medium ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    >
                      {listing.Exchange}
                    </div>
                    <div
                      className={`text-base font-semibold ${
                        darkMode ? "text-white" : "text-slate-900"
                      }`}
                    >
                      {listing.Code}
                    </div>
                    <div
                      className={`text-sm ${
                        darkMode ? "text-slate-400" : "text-slate-700"
                      }`}
                    >
                      {listing.Name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Company Identifiers */}
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-6">
            <div className="flex items-center space-x-3">
              <BriefcaseIcon className="w-6 h-6 text-blue-500" />
              <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                Company Identifiers
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {[
                { label: "ISIN", value: data.ISIN },
                { label: "CUSIP", value: data.CUSIP },
                { label: "CIK", value: data.CIK },
                { label: "LEI", value: data.LEI },
                { label: "Employer ID", value: data.EmployerIdNumber },
                { label: "OpenFIGI", value: data.OpenFigi },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`rounded-lg p-4 transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg ${
                    darkMode ? "bg-slate-700" : "bg-slate-50"
                  }`}
                >
                  <div
                    className={`text-sm font-medium ${
                      darkMode ? "text-slate-400" : "text-slate-600"
                    }`}
                  >
                    {item.label}
                  </div>
                  <div
                    className={`text-base font-semibold ${
                      darkMode ? "text-white" : "text-slate-900"
                    }`}
                  >
                    {item.value || "N/A"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
