import React from "react";

const TabButton = ({ label, isActive, onClick, icon: Icon, darkMode }) => (
  <button
    className={`px-2 py-1 text-xs font-semibold rounded-t-lg flex items-center transition-colors duration-200 ${
      isActive
        ? `${darkMode ? "bg-gray-800" : "bg-white"} ${darkMode ? "text-white" : "text-green-800"} shadow-lg`
        : `bg-green-50 text-green-600 hover:bg-white`
    }`}
    onClick={onClick}
  >
    <Icon className="mr-1" />
    <span className="hidden sm:inline">{label}</span>
  </button>
);

export default TabButton;
