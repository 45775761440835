import React from "react";
import { motion } from "framer-motion";

const CallControlButton = ({
  onClick,
  isEnabled,
  enabledIcon,
  disabledIcon,
  color,
}) => (
  <motion.button
    onClick={onClick}
    className={`w-12 h-12 rounded-full ${color} shadow-lg transition-all duration-300 ease-in-out flex items-center justify-center`}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {React.cloneElement(isEnabled ? enabledIcon : disabledIcon, {
      size: "1.5rem",
    })}
  </motion.button>
);

export default CallControlButton;
