import React, { useState } from "react";
import useAssetCandlestickData from "../../../../hooks/useAssetCandlestickData";
import { VictoryTheme } from "victory";
import LoadingSkeleton from "./LoadingSkeleton";
import ErrorDisplay from "../ErrorDisplay";
import CandlestickChart from "./CandlestickChart";
import PriceDisplay from "./PriceDisplay";
import CryptoTradeConditionsComponent from "./CryptoTradeConditionsComponent";
import UsQuoteTradeConditionsComponent from "./UsQuoteTradeConditionsComponent";
import ForexTradeConditionsComponent from "./ForexTradeConditionsComponent";
import UsStockTradeConditionsComponent from "./UsStockTradeConditionsComponent";
import CandlestickAssetSelectorForm from "./CandlestickAssetSelectorForm";
import { TrendingUp, TrendingDown } from "lucide-react";

function AssetCandlestickDataComponent({ darkMode }) {
  const [options, setOptions] = useState({
    market_type: "us",
    symbols: "AAPL",
    candleInterval: "60000",
  });

  const { candlestickData, fullData, loading, error } = useAssetCandlestickData(
    {
      market_type: options.market_type,
      symbols: options.symbols,
      candleInterval: options.candleInterval,
    },
  );

  const handleOptionsChange = (newOptions) => {
    setOptions(newOptions);
  };

  if (loading) {
    return (
      <div
        className={`w-full rounded-lg overflow-hidden ${
          darkMode ? "bg-gray-900" : "bg-white/90"
        }`}
      >
        <LoadingSkeleton darkMode={darkMode} />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={`w-full rounded-lg overflow-hidden ${
          darkMode ? "bg-gray-900" : "bg-white/90"
        }`}
      >
        <ErrorDisplay error={error} />
      </div>
    );
  }

  const theme = darkMode ? VictoryTheme.material : VictoryTheme.grayscale;
  const baseColor = darkMode ? "#FFFFFF" : "#000000";

  const latestData = fullData[fullData.length - 1];
  const isNegative = latestData?.dd < 0;

  const marketTypes = {
    us: {
      title: "US Stock Market",
      color: "#10B981",
      gradient: "from-emerald-500/20 to-emerald-500/5",
      ringColor: "ring-emerald-500/20",
    },
    crypto: {
      title: "Cryptocurrency Market",
      color: "#F59E0B",
      gradient: "from-amber-500/20 to-amber-500/5",
      ringColor: "ring-amber-500/20",
    },
    "us-quote": {
      title: "US Quote Market",
      color: "#3B82F6",
      gradient: "from-blue-500/20 to-blue-500/5",
      ringColor: "ring-blue-500/20",
    },
    forex: {
      title: "Foreign Exchange Market",
      color: "#8B5CF6",
      gradient: "from-violet-500/20 to-violet-500/5",
      ringColor: "ring-violet-500/20",
    },
  };

  const currentMarket = marketTypes[fullData[0]?.type] || {
    title: "Market Data",
    color: "#EC4899",
    gradient: "from-pink-500/20 to-pink-500/5",
    ringColor: "ring-pink-500/20",
  };

  function getAssetIcon(symbol, marketType) {
    let displayText = symbol?.substring(0, 3).toUpperCase();

    if (marketType === "forex") {
      displayText = `${symbol?.substring(0, 2)}/${symbol?.substring(3, 5)}`;
    } else if (marketType === "crypto") {
      displayText = symbol?.split("-")[0].substring(0, 3);
    } else if (marketType === "us" || marketType === "us-quote") {
      displayText = symbol?.substring(0, 2)?.toUpperCase();
    }

    return (
      <div className="relative group">
        <div
          className={`w-12 h-12 flex items-center justify-center rounded-lg bg-gradient-to-br ring-1 ${
            darkMode ? "ring-gray-700" : currentMarket.ringColor
          } ${darkMode ? "bg-gray-800" : currentMarket.gradient} transition-all duration-300 group-hover:scale-105`}
        >
          <span
            className="text-base font-bold"
            style={{ color: currentMarket.color }}
          >
            {displayText}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div
        className={`w-full rounded-lg shadow-lg overflow-hidden ${
          darkMode ? "bg-gray-900" : "bg-white/90"
        }`}
      >
        {/* Header */}
        <div
          className={`px-4 py-4 border-b ${
            darkMode ? "border-gray-800" : "border-gray-100/50"
          }`}
        >
          <div className="flex flex-col gap-4">
            <div className="flex items-start gap-3">
              {getAssetIcon(options.symbols, fullData[0]?.type)}
              <div className="flex-1 min-w-0">
                <div className="flex flex-col gap-2 mb-2">
                  <h2
                    className={`text-xl font-bold tracking-tight truncate ${
                      darkMode ? "text-gray-100" : "text-gray-900"
                    }`}
                  >
                    {options.symbols}
                  </h2>
                </div>
                <div className="flex items-center gap-2">
                  <PriceDisplay
                    latestData={latestData}
                    isNegative={isNegative}
                    marketType={fullData[0]?.type}
                  />
                  {isNegative ? (
                    <TrendingDown className="w-4 h-4 text-red-500" />
                  ) : (
                    <TrendingUp className="w-4 h-4 text-green-500" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-4 p-4">
          {/* Chart Controls */}
          <div
            className={`rounded-lg transition-all duration-300 ring-1 ${
              darkMode
                ? "bg-gray-800/50 hover:bg-gray-800 ring-gray-700"
                : "bg-gray-50 hover:bg-gray-100/70 ring-gray-200"
            }`}
          >
            <CandlestickAssetSelectorForm
              currentOptions={options}
              onOptionsChange={handleOptionsChange}
              darkMode={darkMode}
            />
          </div>

          {/* Chart */}
          <div
            className={`rounded-lg overflow-hidden transition-all duration-300 ring-1 ${
              darkMode
                ? "bg-gray-800/50 ring-gray-700"
                : "bg-gray-50 ring-gray-200"
            }`}
          >
            <CandlestickChart
              candlestickData={candlestickData}
              theme={theme}
              baseColor={baseColor}
              darkMode={darkMode}
            />
          </div>

          {/* Market Specific Components */}
          {fullData && fullData.length > 0 && (
            <div
              className={`rounded-lg transition-all duration-300 ring-1 ${
                darkMode
                  ? "bg-gray-800/50 hover:bg-gray-800 ring-gray-700"
                  : "bg-gray-50 hover:bg-gray-100/70 ring-gray-200"
              }`}
            >
              <div>
                {fullData[0]?.type === "us" && (
                  <UsStockTradeConditionsComponent
                    tradeData={fullData}
                    darkMode={darkMode}
                  />
                )}
                {fullData[0]?.type === "crypto" && (
                  <CryptoTradeConditionsComponent
                    tradeData={fullData}
                    darkMode={darkMode}
                  />
                )}
                {fullData[0]?.type === "us-quote" && (
                  <UsQuoteTradeConditionsComponent
                    tradeData={fullData}
                    darkMode={darkMode}
                  />
                )}
                {fullData[0]?.type === "forex" && (
                  <ForexTradeConditionsComponent
                    tradeData={fullData}
                    darkMode={darkMode}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssetCandlestickDataComponent;
