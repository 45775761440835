import React from "react";
import { Shield, Users, AlertTriangle, Check, X, Leaf } from "lucide-react";

const ESGScores = ({
  data,
  darkMode,
  formatNumber,
  formatDate,
  MetricCard,
}) => {
  if (!data) return null;

  const activities = Object.values(data.ActivitiesInvolvement || {}).sort(
    (a, b) => a.Activity.localeCompare(b.Activity),
  );

  const formatActivityName = (name) => {
    return name
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getScoreColorName = (score) => {
    if (score >= 80) return "green";
    if (score >= 60) return "blue";
    if (score >= 40) return "yellow";
    return "red";
  };

  const getScoreTextColor = (score) => {
    const color = getScoreColorName(score);
    return darkMode ? `text-${color}-400` : `text-${color}-600`;
  };

  const getScoreBackground = (score) => {
    const color = getScoreColorName(score);
    return darkMode ? `bg-${color}-400/10` : `bg-${color}-50`;
  };

  const getControversyColorName = (level) => {
    if (level >= 4) return "red";
    if (level >= 3) return "orange";
    if (level >= 2) return "yellow";
    return "green";
  };

  return (
    <div className="space-y-8">
      {/* ESG Scores Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Total ESG Score Card */}
        <div
          className={`rounded-xl overflow-hidden border transition-all duration-200 ${
            darkMode
              ? "bg-gray-800 border-gray-700 hover:shadow-black/20"
              : "bg-white border-gray-200 hover:shadow-gray-200/50"
          } hover:shadow-lg`}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h3
                className={`font-semibold ${
                  darkMode ? "text-gray-200" : "text-gray-700"
                }`}
              >
                Total ESG Score
              </h3>
              <Shield
                className={`w-6 h-6 ${getScoreTextColor(data.TotalEsg)}`}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-baseline gap-2">
                <span
                  className={`text-3xl font-bold ${getScoreTextColor(
                    data.TotalEsg,
                  )}`}
                >
                  {formatNumber(data.TotalEsg)}
                </span>
                <span
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  / 100
                </span>
              </div>
              <div
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {formatNumber(data.TotalEsgPercentile)}th percentile
              </div>
            </div>
          </div>
          <div className={`h-2 w-full ${getScoreBackground(data.TotalEsg)}`}>
            <div
              className={`h-full bg-${getScoreColorName(data.TotalEsg)}-600`}
              style={{ width: `${data.TotalEsg}%` }}
            />
          </div>
        </div>

        {/* Environmental Score Card */}
        <div
          className={`rounded-xl overflow-hidden border transition-all duration-200 ${
            darkMode
              ? "bg-gray-800 border-gray-700 hover:shadow-black/20"
              : "bg-white border-gray-200 hover:shadow-gray-200/50"
          } hover:shadow-lg`}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h3
                className={`font-semibold ${
                  darkMode ? "text-gray-200" : "text-gray-700"
                }`}
              >
                Environmental Score
              </h3>
              <Leaf
                className={`w-6 h-6 ${getScoreTextColor(
                  data.EnvironmentScore,
                )}`}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-baseline gap-2">
                <span
                  className={`text-3xl font-bold ${getScoreTextColor(
                    data.EnvironmentScore,
                  )}`}
                >
                  {formatNumber(data.EnvironmentScore)}
                </span>
                <span
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  / 100
                </span>
              </div>
              <div
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {formatNumber(data.EnvironmentScorePercentile)}th percentile
              </div>
            </div>
          </div>
          <div
            className={`h-2 w-full ${getScoreBackground(
              data.EnvironmentScore,
            )}`}
          >
            <div
              className={`h-full bg-${getScoreColorName(
                data.EnvironmentScore,
              )}-600`}
              style={{ width: `${data.EnvironmentScore}%` }}
            />
          </div>
        </div>

        {/* Social Score Card */}
        <div
          className={`rounded-xl overflow-hidden border transition-all duration-200 ${
            darkMode
              ? "bg-gray-800 border-gray-700 hover:shadow-black/20"
              : "bg-white border-gray-200 hover:shadow-gray-200/50"
          } hover:shadow-lg`}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h3
                className={`font-semibold ${
                  darkMode ? "text-gray-200" : "text-gray-700"
                }`}
              >
                Social Score
              </h3>
              <Users
                className={`w-6 h-6 ${getScoreTextColor(data.SocialScore)}`}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-baseline gap-2">
                <span
                  className={`text-3xl font-bold ${getScoreTextColor(
                    data.SocialScore,
                  )}`}
                >
                  {formatNumber(data.SocialScore)}
                </span>
                <span
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  / 100
                </span>
              </div>
              <div
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {formatNumber(data.SocialScorePercentile)}th percentile
              </div>
            </div>
          </div>
          <div className={`h-2 w-full ${getScoreBackground(data.SocialScore)}`}>
            <div
              className={`h-full bg-${getScoreColorName(data.SocialScore)}-600`}
              style={{ width: `${data.SocialScore}%` }}
            />
          </div>
        </div>

        {/* Controversy Level Card */}
        <div
          className={`rounded-xl overflow-hidden border transition-all duration-200 ${
            darkMode
              ? "bg-gray-800 border-gray-700 hover:shadow-black/20"
              : "bg-white border-gray-200 hover:shadow-gray-200/50"
          } hover:shadow-lg`}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h3
                className={`font-semibold ${
                  darkMode ? "text-gray-200" : "text-gray-700"
                }`}
              >
                Controversy Level
              </h3>
              <AlertTriangle
                className={`w-6 h-6 text-${getControversyColorName(
                  data.ControversyLevel,
                )}-500`}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-baseline gap-2">
                <span
                  className={`text-3xl font-bold text-${getControversyColorName(
                    data.ControversyLevel,
                  )}-500`}
                >
                  {data.ControversyLevel}
                </span>
                <span
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  / 5
                </span>
              </div>
              <div
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                Last updated: {formatDate(data.RatingDate)}
              </div>
            </div>
          </div>
          <div
            className={`h-2 w-full bg-${getControversyColorName(
              data.ControversyLevel,
            )}-100`}
          >
            <div
              className={`h-full bg-${getControversyColorName(
                data.ControversyLevel,
              )}-500`}
              style={{ width: `${(data.ControversyLevel / 5) * 100}%` }}
            />
          </div>
        </div>
      </div>

      {/* Activities Involvement Section */}
      <div
        className={`rounded-xl border transition-all duration-200 ${
          darkMode
            ? "bg-gray-800 border-gray-700 hover:shadow-black/20"
            : "bg-white border-gray-200 hover:shadow-gray-200/50"
        } hover:shadow-lg`}
      >
        <div
          className={`px-6 py-4 border-b ${
            darkMode ? "border-gray-700" : "border-gray-200"
          }`}
        >
          <h3
            className={`text-lg font-semibold ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Activities Involvement
          </h3>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {activities.map((activity, index) => {
              const involvementColor =
                activity.Involvement === "Yes" ? "red" : "green";
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between p-4 rounded-lg transition-all duration-200 ${
                    darkMode
                      ? `bg-gray-700 hover:bg-gray-600`
                      : `bg-gray-50 hover:bg-gray-100`
                  } border-l-4 border-${involvementColor}-500`}
                >
                  <span
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-200" : "text-gray-700"
                    }`}
                  >
                    {formatActivityName(activity.Activity)}
                  </span>
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      darkMode
                        ? `bg-${involvementColor}-500/20`
                        : `bg-${involvementColor}-50`
                    }`}
                  >
                    {activity.Involvement === "Yes" ? (
                      <Check
                        className={`w-5 h-5 text-${involvementColor}-500`}
                      />
                    ) : (
                      <X className={`w-5 h-5 text-${involvementColor}-500`} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESGScores;
