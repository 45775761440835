import React, { useState } from "react";
import {
  FaTimes,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaRedditAlien,
  FaLink,
} from "react-icons/fa";

const SocialShareDialog = ({ isOpen, onClose, url, title, darkMode }) => {
  const [copied, setCopied] = useState(false);
  const [shareError, setShareError] = useState("");

  if (!isOpen) return null;

  const shareOn = (platform, shareUrl) => {
    try {
      window.open(shareUrl, "_blank");
    } catch (error) {
      setShareError(
        `Unable to open ${platform} sharing. Please try again or use another method.`,
      );
    }
  };

  const shareOnFacebook = () =>
    shareOn(
      "Facebook",
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    );
  const shareOnTwitter = () =>
    shareOn(
      "Twitter",
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
    );
  const shareOnLinkedIn = () =>
    shareOn(
      "LinkedIn",
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
    );
  const shareOnReddit = () =>
    shareOn(
      "Reddit",
      `https://reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
    );

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => {
        setShareError("Unable to copy to clipboard. Please try again.");
      });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className={`rounded-lg p-6 w-full max-w-md ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"}`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Share this post</h2>
          <button
            onClick={onClose}
            className={`hover:text-gray-700 ${darkMode ? "text-gray-300 hover:text-gray-100" : "text-gray-500"}`}
          >
            <FaTimes size={24} />
          </button>
        </div>
        {shareError && (
          <div
            className={`mb-4 p-2 rounded ${darkMode ? "bg-red-800 text-white" : "bg-red-100 text-red-800"}`}
          >
            {shareError}
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <button
            onClick={shareOnFacebook}
            className="p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 flex items-center justify-center"
          >
            <FaFacebookF size={24} />
            <span className="ml-2">Facebook</span>
          </button>
          <button
            onClick={shareOnTwitter}
            className="p-2 bg-blue-400 text-white rounded-full hover:bg-blue-500 flex items-center justify-center"
          >
            <FaTwitter size={24} />
            <span className="ml-2">Twitter</span>
          </button>
          <button
            onClick={shareOnLinkedIn}
            className="p-2 bg-blue-800 text-white rounded-full hover:bg-blue-900 flex items-center justify-center"
          >
            <FaLinkedinIn size={24} />
            <span className="ml-2">LinkedIn</span>
          </button>
          <button
            onClick={shareOnReddit}
            className="p-2 bg-orange-500 text-white rounded-full hover:bg-orange-600 flex items-center justify-center"
          >
            <FaRedditAlien size={24} />
            <span className="ml-2">Reddit</span>
          </button>
        </div>
        <div
          className={`flex items-center rounded p-2 ${darkMode ? "bg-gray-700" : "bg-gray-100"}`}
        >
          <input
            type="text"
            value={url}
            readOnly
            className={`flex-grow bg-transparent outline-none ${darkMode ? "text-white" : "text-gray-900"}`}
          />
          <button
            onClick={copyToClipboard}
            className={`ml-2 p-1 rounded ${
              darkMode
                ? "bg-gray-600 hover:bg-gray-500"
                : "bg-gray-200 hover:bg-gray-300"
            }`}
          >
            {copied ? "Copied!" : <FaLink size={20} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SocialShareDialog;
