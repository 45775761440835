import React, { useState, useMemo } from "react";
import { useMarketQuotesWithIndicators } from "../../../../hooks/useMarketQuotesWithIndicators";
import { useSymbolSearch } from "../../../../hooks/useSymbolSearch";
import { exchanges } from "../../../../constants/EXCHANGES";
import ControlsSection from "./ControlsSection";
import ChartSection from "./charts/ChartSection";

const MarketQuotesCandlestick = ({ darkMode }) => {
  const [symbol, setSymbol] = useState("");
  const [selectedExchange, setSelectedExchange] = useState(exchanges[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isExchangeSelectOpen, setIsExchangeSelectOpen] = useState(false);
  const [isSymbolSelectOpen, setIsSymbolSelectOpen] = useState(false);

  const [period, setPeriod] = useState("d");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [chartType, setChartType] = useState("candlestick");
  const [zoomDomain, setZoomDomain] = useState();
  const [showGridlines, setShowGridlines] = useState(true);
  const [candleUpColor, setCandleUpColor] = useState("#22C55E");
  const [candleDownColor, setCandleDownColor] = useState("#EF4444");

  // Technical Indicators State
  const [showEMA, setShowEMA] = useState(false);
  const [showSMA, setShowSMA] = useState(false);
  const [showBollingerBands, setShowBollingerBands] = useState(false);
  const [showMACD, setShowMACD] = useState(false);
  const [showRSI, setShowRSI] = useState(false);

  // Use the custom hook for symbol search
  const {
    symbols,
    loading: loadingSymbols,
    setQuery,
  } = useSymbolSearch(selectedExchange.Code);

  // Memoize the indicators array
  const indicators = useMemo(() => {
    const ind = [];
    if (showEMA) ind.push("EMA");
    if (showSMA) ind.push("SMA");
    if (showBollingerBands) ind.push("BBANDS");
    if (showMACD) ind.push("MACD");
    if (showRSI) ind.push("RSI");
    return ind;
  }, [showEMA, showSMA, showBollingerBands, showMACD, showRSI]);

  // Use the custom hook for market quotes with indicators
  const {
    data: marketData,
    loading,
    error,
  } = useMarketQuotesWithIndicators(
    symbol,
    selectedExchange.Code,
    indicators,
    period,
    100, // You can adjust the number of periods as needed
    fromDate,
    toDate,
  );

  // Transform data as per Victory Charts requirements
  const transformData = (apiData) => {
    if (!apiData || !Array.isArray(apiData)) return [];

    return apiData
      .map((entry) => ({
        date: new Date(entry.date),
        open: +entry.open,
        high: +entry.high,
        low: +entry.low,
        close: +entry.close,
        volume: +entry.volume || 0,
      }))
      .sort((a, b) => a.date - b.date); // Ensure data is sorted by date
  };

  const exportData = () => {
    const data = transformData(marketData?.price_data);
    if (data.length === 0) return;

    const csvRows = [
      ["Date", "Open", "High", "Low", "Close"],
      ...data.map((row) => [
        row.date.toISOString().split("T")[0],
        row.open,
        row.high,
        row.low,
        row.close,
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvRows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${symbol}_${selectedExchange.Code}_data.csv`,
    );
    document.body.appendChild(link); // Required for FF

    link.click();
    document.body.removeChild(link);
  };

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
    setIsExchangeSelectOpen(false);
    setSymbol("");
    setSearchTerm("");
    setQuery("");
  };

  const handleSymbolSelect = (symbolCode) => {
    setSymbol(symbolCode);
    setIsSymbolSelectOpen(false);
    setSearchTerm("");
    setQuery("");
  };

  return (
    <div
      className={`w-full max-w-6xl mx-auto p-6 ${
        darkMode ? "bg-gray-900 text-white" : "bg-white text-gray-900"
      } rounded-xl shadow-lg`}
    >
      {/* Controls Section */}
      <ControlsSection
        darkMode={darkMode}
        symbol={symbol}
        setSymbol={setSymbol}
        selectedExchange={selectedExchange}
        setSelectedExchange={setSelectedExchange}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isExchangeSelectOpen={isExchangeSelectOpen}
        setIsExchangeSelectOpen={setIsExchangeSelectOpen}
        isSymbolSelectOpen={isSymbolSelectOpen}
        setIsSymbolSelectOpen={setIsSymbolSelectOpen}
        period={period}
        setPeriod={setPeriod}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        chartType={chartType}
        setChartType={setChartType}
        showGridlines={showGridlines}
        setShowGridlines={setShowGridlines}
        candleUpColor={candleUpColor}
        setCandleUpColor={setCandleUpColor}
        candleDownColor={candleDownColor}
        setCandleDownColor={setCandleDownColor}
        setZoomDomain={setZoomDomain}
        exportData={exportData}
        loadingSymbols={loadingSymbols}
        symbols={symbols}
        exchanges={exchanges}
        handleExchangeSelect={handleExchangeSelect}
        handleSymbolSelect={handleSymbolSelect}
        setQuery={setQuery}
        // Technical Indicators Props
        showEMA={showEMA}
        setShowEMA={setShowEMA}
        showSMA={showSMA}
        setShowSMA={setShowSMA}
        showBollingerBands={showBollingerBands}
        setShowBollingerBands={setShowBollingerBands}
        showMACD={showMACD}
        setShowMACD={setShowMACD}
        showRSI={showRSI}
        setShowRSI={setShowRSI}
      />

      {/* Chart Section */}
      <ChartSection
        darkMode={darkMode}
        loading={loading}
        error={error}
        marketData={marketData}
        chartType={chartType}
        zoomDomain={zoomDomain}
        setZoomDomain={setZoomDomain}
        candleUpColor={candleUpColor}
        candleDownColor={candleDownColor}
        showGridlines={showGridlines}
        transformData={transformData}
        // Technical Indicators Props
        showEMA={showEMA}
        showSMA={showSMA}
        showBollingerBands={showBollingerBands}
        showMACD={showMACD}
        showRSI={showRSI}
      />

      <div
        className={`text-xs text-right mt-4 ${
          darkMode ? "text-gray-400" : "text-gray-500"
        }`}
      >
        Last updated: {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default MarketQuotesCandlestick;
