import React from "react";
import {
  TrendingUp,
  DollarSign,
  BarChart2,
  PieChart,
  ArrowUpRight,
  ArrowDownRight,
  Target,
  LineChart,
  BookOpen,
} from "lucide-react";

const HighlightsInfo = ({
  data,
  darkMode,
  formatNumber,
  formatCurrency,
  formatPercent,
  MetricCard,
}) => {
  if (!data) return null;

  const getTrendIcon = (value) => {
    if (value > 0) {
      return (
        <div className="flex items-center gap-1 text-green-500 bg-green-500/10 px-2 py-1 rounded-full">
          <ArrowUpRight size={16} />
          <span className="text-xs font-medium">
            +{formatPercent(Math.abs(value))}
          </span>
        </div>
      );
    }
    if (value < 0) {
      return (
        <div className="flex items-center gap-1 text-red-500 bg-red-500/10 px-2 py-1 rounded-full">
          <ArrowDownRight size={16} />
          <span className="text-xs font-medium">
            -{formatPercent(Math.abs(value))}
          </span>
        </div>
      );
    }
    return null;
  };

  const CardWrapper = ({ children, className = "" }) => (
    <div
      className={`p-6 rounded-xl border transition-all duration-200
      ${
        darkMode
          ? "bg-gray-800 border-gray-700 hover:shadow-lg hover:shadow-black/10"
          : "bg-white border-gray-200 hover:shadow-lg hover:shadow-gray-200/50"
      }
      ${className}`}
    >
      {children}
    </div>
  );

  const MetricBox = ({ title, value, subtitle, icon: Icon, trend }) => (
    <div
      className={`p-4 rounded-xl transition-all duration-200
      ${
        darkMode
          ? "bg-gray-700/50 hover:bg-gray-700"
          : "bg-gray-50 hover:bg-gray-100"
      }`}
    >
      <div className="flex items-start justify-between mb-3">
        <div
          className={`text-sm font-medium ${darkMode ? "text-gray-400" : "text-gray-600"}`}
        >
          {title}
        </div>
        {Icon && (
          <div
            className={`p-2 rounded-lg ${darkMode ? "bg-gray-600/50" : "bg-gray-200/50"}`}
          >
            <Icon
              size={16}
              className={darkMode ? "text-gray-300" : "text-gray-600"}
            />
          </div>
        )}
      </div>
      <div className="space-y-1">
        <div
          className={`text-2xl font-bold tracking-tight
          ${darkMode ? "text-white" : "text-gray-900"}`}
        >
          {value}
        </div>
        {subtitle && (
          <div
            className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            {subtitle}
          </div>
        )}
      </div>
      {trend && <div className="mt-3">{trend}</div>}
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Market & Valuation Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <MetricCard
          title="Market Cap"
          value={formatCurrency(data.MarketCapitalization)}
          subtitle={`${formatCurrency(data.MarketCapitalizationMln)} M`}
          tooltip="Total market value of all outstanding shares"
          darkMode={darkMode}
        />
        <MetricCard
          title="Stock Price Target"
          value={formatCurrency(data.WallStreetTargetPrice)}
          tooltip="Wall Street analysts' consensus price target"
          darkMode={darkMode}
        />
        <MetricCard
          title="EBITDA"
          value={formatCurrency(data.EBITDA)}
          tooltip="Earnings Before Interest, Taxes, Depreciation, and Amortization"
          darkMode={darkMode}
        />
      </div>

      {/* Per Share Metrics */}
      <CardWrapper>
        <div className="flex items-center justify-between mb-6">
          <h3
            className={`text-lg font-semibold flex items-center gap-2
            ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            <DollarSign
              className={`w-5 h-5 ${darkMode ? "text-green-400" : "text-green-500"}`}
            />
            Per Share Metrics
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <MetricBox
            title="Earnings Per Share"
            value={formatCurrency(data.EarningsShare)}
            icon={LineChart}
          />
          <MetricBox
            title="Book Value"
            value={formatCurrency(data.BookValue)}
            icon={BookOpen}
          />
          <MetricBox
            title="Revenue Per Share"
            value={formatCurrency(data.RevenuePerShareTTM)}
            icon={BarChart2}
          />
          <MetricBox
            title="Dividend Per Share"
            value={formatCurrency(data.DividendShare)}
            subtitle={`${formatPercent(data.DividendYield)} yield`}
            icon={PieChart}
          />
        </div>
      </CardWrapper>

      {/* Growth & Forward Estimates */}
      <CardWrapper>
        <div className="flex items-center justify-between mb-6">
          <h3
            className={`text-lg font-semibold flex items-center gap-2
            ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            <TrendingUp
              className={`w-5 h-5 ${darkMode ? "text-green-400" : "text-green-500"}`}
            />
            Growth & Estimates
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <MetricBox
            title="Revenue Growth (YoY)"
            value={formatPercent(data.QuarterlyRevenueGrowthYOY)}
            trend={getTrendIcon(data.QuarterlyRevenueGrowthYOY)}
          />
          <MetricBox
            title="Earnings Growth (YoY)"
            value={formatPercent(data.QuarterlyEarningsGrowthYOY)}
            trend={getTrendIcon(data.QuarterlyEarningsGrowthYOY)}
          />
          <MetricBox
            title="PEG Ratio"
            value={formatNumber(data.PEGRatio)}
            icon={Target}
          />
        </div>
      </CardWrapper>

      {/* Margins & Returns */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <MetricCard
          title="Operating Margin"
          value={formatPercent(data.OperatingMarginTTM)}
          tooltip="Operating income as a percentage of revenue (TTM)"
          darkMode={darkMode}
        />
        <MetricCard
          title="Profit Margin"
          value={formatPercent(data.ProfitMargin)}
          tooltip="Net income as a percentage of revenue"
          darkMode={darkMode}
        />
        <MetricCard
          title="Gross Profit"
          value={formatCurrency(data.GrossProfitTTM)}
          tooltip="Revenue minus cost of goods sold (TTM)"
          darkMode={darkMode}
        />
      </div>

      {/* Returns */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <MetricCard
          title="Return on Equity"
          value={formatPercent(data.ReturnOnEquityTTM)}
          tooltip="Net income as a percentage of shareholders' equity (TTM)"
          darkMode={darkMode}
        />
        <MetricCard
          title="Return on Assets"
          value={formatPercent(data.ReturnOnAssetsTTM)}
          tooltip="Net income as a percentage of total assets (TTM)"
          darkMode={darkMode}
        />
      </div>

      {/* EPS Estimates */}
      <CardWrapper>
        <div className="flex items-center justify-between mb-6">
          <h3
            className={`text-lg font-semibold flex items-center gap-2
            ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            <Target
              className={`w-5 h-5 ${darkMode ? "text-green-400" : "text-green-500"}`}
            />
            EPS Estimates
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <MetricBox
            title="Current Quarter"
            value={formatCurrency(data.EPSEstimateCurrentQuarter)}
          />
          <MetricBox
            title="Next Quarter"
            value={formatCurrency(data.EPSEstimateNextQuarter)}
          />
          <MetricBox
            title="Current Year"
            value={formatCurrency(data.EPSEstimateCurrentYear)}
          />
          <MetricBox
            title="Next Year"
            value={formatCurrency(data.EPSEstimateNextYear)}
          />
        </div>
      </CardWrapper>
    </div>
  );
};

export default HighlightsInfo;
