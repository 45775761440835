import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import io from "socket.io-client";
import { getApiUrl } from "../config/api";

const UserSubscriptionContext = createContext(null);

export const UserSubscriptionProvider = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const socketRef = useRef(null);

  const resetSubscriptions = useCallback(() => {
    setSubscriptions([]);
    setCurrentSubscription(null);
  }, []);

  const fetchUserSubscriptions = useCallback(() => {
    if (!socketRef.current) return;
    setLoading(true);
    setError(null);
    try {
      socketRef.current.emit("fetch all subscriptions");
    } catch (err) {
      setError("Failed to fetch user subscriptions");
      console.error("Error fetching user subscriptions:", err);
      setLoading(false);
    }
  }, []);

  const fetchCurrentUserSubscription = useCallback(() => {
    if (!socketRef.current) return;
    setLoading(true);
    setError(null);
    try {
      socketRef.current.emit("fetch subscription");
    } catch (err) {
      setError("Failed to fetch current user subscription");
      console.error("Error fetching current user subscription:", err);
      setLoading(false);
    }
  }, []);

  const initializeSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    socketRef.current = io(`${getApiUrl()}/subscriptions`, {
      auth: { token },
    });

    socketRef.current.emit("join subscriptions");

    socketRef.current.on("subscription updated", (updatedSubscription) => {
      setCurrentSubscription(updatedSubscription);
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((sub) =>
          sub.id === updatedSubscription.id ? updatedSubscription : sub,
        ),
      );
      setLoading(false);
    });

    socketRef.current.on(
      "subscription created",
      ({ userSubscription, clientSecret }) => {
        setSubscriptions((prevSubscriptions) => [
          ...prevSubscriptions,
          userSubscription,
        ]);
        setCurrentSubscription(userSubscription);
        setLoading(false);
      },
    );

    socketRef.current.on("subscription cancelled", (cancelledSubscription) => {
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((sub) =>
          sub.id === cancelledSubscription.id ? cancelledSubscription : sub,
        ),
      );
      setCurrentSubscription((prevSub) =>
        prevSub?.id === cancelledSubscription.id
          ? cancelledSubscription
          : prevSub,
      );
      setLoading(false);
    });

    socketRef.current.on("all subscriptions", (subscriptionsData) => {
      setSubscriptions(subscriptionsData);
      setLoading(false);
    });

    socketRef.current.on("current subscription", (subscriptionData) => {
      setCurrentSubscription(subscriptionData);
      setLoading(false);
    });

    socketRef.current.on("error", (err) => {
      setError(err.message || "An error occurred");
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const handleUserLoggedIn = () => {
      resetSubscriptions();
      initializeSocket();
      fetchUserSubscriptions();
      fetchCurrentUserSubscription();
    };

    const handleUserLoggedOut = () => {
      // Disconnect socket
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      // Reset subscription state
      resetSubscriptions();
    };

    window.addEventListener("userLoggedIn", handleUserLoggedIn);
    window.addEventListener("userLoggedOut", handleUserLoggedOut);

    // Initialize socket if user is already logged in
    if (localStorage.getItem("token")) {
      initializeSocket();
      fetchUserSubscriptions();
      fetchCurrentUserSubscription();
    }

    return () => {
      window.removeEventListener("userLoggedIn", handleUserLoggedIn);
      window.removeEventListener("userLoggedOut", handleUserLoggedOut);
      // Disconnect socket on component unmount
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [
    initializeSocket,
    fetchUserSubscriptions,
    fetchCurrentUserSubscription,
    resetSubscriptions,
  ]);

  const createSubscription = useCallback(
    (paymentMethodId, priceId, planType, couponId) => {
      if (!socketRef.current) return;
      setLoading(true);
      setError(null);
      try {
        socketRef.current.emit("create subscription", {
          paymentMethodId,
          priceId,
          planType,
          couponId,
        });
      } catch (err) {
        setError("Failed to create subscription");
        console.error("Error creating subscription:", err);
        setLoading(false);
      }
    },
    [],
  );

  const cancelSubscription = useCallback((subscriptionId) => {
    if (!socketRef.current) return;
    setLoading(true);
    setError(null);
    try {
      socketRef.current.emit("cancel subscription", { subscriptionId });
    } catch (err) {
      setError("Failed to cancel subscription");
      console.error("Error cancelling subscription:", err);
      setLoading(false);
    }
  }, []);

  const value = {
    subscriptions,
    currentSubscription,
    loading,
    error,
    fetchUserSubscriptions,
    createSubscription,
    cancelSubscription,
    resetSubscriptions,
    fetchCurrentUserSubscription,
  };

  return (
    <UserSubscriptionContext.Provider value={value}>
      {children}
    </UserSubscriptionContext.Provider>
  );
};

export const useUserSubscription = () => {
  const context = useContext(UserSubscriptionContext);
  if (!context) {
    throw new Error(
      "useUserSubscription must be used within a UserSubscriptionProvider",
    );
  }
  return context;
};
