import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaUser, FaEnvelope, FaLock, FaUserPlus } from "react-icons/fa";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { darkMode } = useTheme();
  const { register } = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await register(username, email, password);
    } catch (err) {
      setError(err.message || "An error occurred. Please try again.");
    }
  };

  const inputClasses = `w-full px-4 py-3 rounded-lg border ${
    darkMode
      ? "bg-gray-800 border-gray-700 text-white placeholder-gray-500"
      : "bg-white border-gray-300 text-gray-900 placeholder-gray-400"
  } focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300`;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`w-full max-w-md mx-auto p-6 sm:p-8 rounded-xl shadow-2xl `}
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className={`text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center ${
          darkMode ? "text-green-400" : "text-green-600"
        }`}
      >
        Register
      </motion.h2>

      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`p-3 sm:p-4 mb-4 sm:mb-6 rounded-lg text-sm sm:text-base ${
            darkMode ? "bg-red-900/50 text-red-200" : "bg-red-100 text-red-700"
          }`}
        >
          <p className="text-center">{error}</p>
        </motion.div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
        <div>
          <label
            htmlFor="username"
            className={`block mb-1 sm:mb-2 font-medium text-sm sm:text-base ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Username
          </label>
          <div className="relative">
            <FaUser
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${
                darkMode ? "text-gray-500" : "text-gray-400"
              }`}
            />
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className={`${inputClasses} pl-10 text-sm sm:text-base`}
              placeholder="Enter your username"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="email"
            className={`block mb-1 sm:mb-2 font-medium text-sm sm:text-base ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Email
          </label>
          <div className="relative">
            <FaEnvelope
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${
                darkMode ? "text-gray-500" : "text-gray-400"
              }`}
            />
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`${inputClasses} pl-10 text-sm sm:text-base`}
              placeholder="Enter your email"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className={`block mb-1 sm:mb-2 font-medium text-sm sm:text-base ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Password
          </label>
          <div className="relative">
            <FaLock
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${
                darkMode ? "text-gray-500" : "text-gray-400"
              }`}
            />
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={`${inputClasses} pl-10 text-sm sm:text-base`}
              placeholder="Enter your password"
            />
          </div>
        </div>

        <motion.button
          type="submit"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          className={`w-full ${
            darkMode
              ? "bg-green-600 hover:bg-green-700"
              : "bg-green-500 hover:bg-green-600"
          } text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center shadow-lg text-sm sm:text-base`}
        >
          <FaUserPlus className="mr-2" />
          Register
        </motion.button>
      </form>
      <p
        className={`mt-4 sm:mt-6 text-center text-sm sm:text-base ${
          darkMode ? "text-gray-400" : "text-gray-600"
        }`}
      >
        Already have an account?{" "}
        <Link
          to="/forum/login"
          className={`font-medium ${
            darkMode
              ? "text-green-400 hover:text-green-300"
              : "text-green-600 hover:text-green-700"
          }`}
        >
          Login here
        </Link>
      </p>
    </motion.div>
  );
};

export default Register;
