import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  FaStar,
  FaRocket,
  FaUserCircle,
  FaShieldAlt,
  FaCode,
  FaGem,
} from "react-icons/fa";

function FeatureSection({ id }) {
  const { darkMode } = useTheme();

  const concepts = [
    {
      icon: FaStar,
      title: "Wealth Building Principles",
      description:
        "Explore fundamental wealth-building concepts. Learn about various investment strategies, the power of compound interest, and how to create multiple income streams. Understand the importance of financial planning and disciplined saving in achieving long-term financial growth.",
    },
    {
      icon: FaRocket,
      title: "Success Mindset",
      description:
        "Discover the power of a success-oriented mindset. Explore how your beliefs and attitudes about money can impact your financial decisions. Learn techniques for setting ambitious yet achievable goals, and develop the mental resilience needed to overcome financial challenges.",
    },
    {
      icon: FaUserCircle,
      title: "Personal Development",
      description:
        "Understand the connection between personal growth and financial success. Explore concepts like emotional intelligence, time management, and continuous learning. Discover how developing these soft skills can indirectly contribute to your financial well-being.",
    },
    {
      icon: FaShieldAlt,
      title: "Risk Management",
      description:
        "Learn about the importance of protecting your financial assets. Explore concepts like diversification, emergency funds, and insurance. Understand how to assess and mitigate various financial risks to create a more secure financial future.",
    },
    {
      icon: FaCode,
      title: "Financial Literacy",
      description:
        "Improve your understanding of key financial concepts. Learn how to read and interpret financial statements, understand market trends, and make informed financial decisions. Explore the basics of budgeting, debt management, and financial planning.",
    },
    {
      icon: FaGem,
      title: "Identifying Opportunities",
      description:
        "Develop skills to recognize potential financial opportunities. Learn how to analyze market trends, identify undervalued assets, and spot emerging industries. Understand the principle of calculated risk-taking in pursuing financial growth.",
    },
  ];

  return (
    <section
      id={id}
      className={`pt-24 pb-16 ${darkMode ? "bg-gray-800" : "bg-gradient-to-br from-green-50 to-blue-100"}`}
    >
      <div className="container px-5 mx-auto">
        <h2 className="text-3xl font-extrabold text-center mb-12">
          Key Concepts in Financial Growth and Personal Development
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {concepts.map((concept, index) => (
            <div
              key={index}
              className={`p-6 rounded-lg ${darkMode ? "bg-gray-700" : "bg-white"} shadow-lg transition-all duration-300 hover:shadow-xl`}
            >
              <div className="flex items-center mb-4">
                <div
                  className={`w-12 h-12 mr-4 rounded-full flex items-center justify-center ${darkMode ? "bg-gray-600" : "bg-green-100"}`}
                >
                  <concept.icon
                    className={`w-6 h-6 ${darkMode ? "text-green-400" : "text-green-600"}`}
                  />
                </div>
                <h3 className="text-lg font-semibold">{concept.title}</h3>
              </div>
              <p
                className={`text-sm ${darkMode ? "text-gray-300" : "text-gray-600"}`}
              >
                {concept.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
