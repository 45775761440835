import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useMediaQuery } from "react-responsive";
import { useLocation, Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTelegram,
  FaDiscord,
  FaTiktok,
} from "react-icons/fa";
import QuantumMoneyCodeLogoText from "../menu/QuantumMoneyCodeLogoText";

const socialLinks = [
  {
    icon: FaFacebook,
    url: "https://www.facebook.com/profile.php?id=61564916514349",
  },
  { icon: FaTwitter, url: "https://x.com/QuantMoneyCode" },
  { icon: FaInstagram, url: "https://instagram.com" },
  { icon: FaYoutube, url: "https://youtube.com" },
  { icon: FaTelegram, url: "https://t.me/+kRLtJee1VvRmODMx" },
  { icon: FaDiscord, url: "https://discord.com" },
  { icon: FaTiktok, url: "https://tiktok.com" },
];

const Footer = () => {
  const { darkMode } = useTheme();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 641px) and (max-width: 1024px)",
  });
  const location = useLocation();

  const isForumPage = location.pathname.includes("/forum");

  const qProps = {
    fontFamily: "Quantum",
    fontWeight: "300",
    fontSize: isSmallScreen ? "2rem" : isMediumScreen ? "2.5rem" : "4rem",
    style: {
      color: "gold",
      textShadow: "0 0 10px rgba(255, 223, 0, 0.9)",
    },
  };

  const suffixFontSize = isSmallScreen
    ? "1rem"
    : isMediumScreen
      ? "1.5rem"
      : "2rem";

  const textColorClass = darkMode ? "text-gray-400" : "text-gray-600";
  const linkHoverClass = darkMode
    ? "hover:text-green-400"
    : "hover:text-green-600";

  const SocialLink = ({ Icon, url }) => (
    <a
      href={url}
      className={`${textColorClass} ${linkHoverClass} transition-colors duration-300`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon size={24} />
    </a>
  );

  const FooterLink = ({ to, children }) => (
    <Link
      to={to}
      className={`text-sm ${textColorClass} ${linkHoverClass} transition-colors duration-300`}
    >
      {children}
    </Link>
  );

  return (
    <footer
      className={`py-16 ${darkMode ? "bg-gray-900" : "bg-gray-100"} ${
        isForumPage ? "md:ml-72 lg:ml-72" : ""
      }`}
    >
      <div className="container mx-auto px-5 max-w-4xl">
        <div className="flex flex-col items-center mb-12">
          <QuantumMoneyCodeLogoText
            qProps={qProps}
            suffixFontSize={suffixFontSize}
          />
          <p className={`text-center ${textColorClass} max-w-md mt-4`}>
            Unlock your financial potential and transform your future with our
            groundbreaking program.
          </p>
        </div>

        <div className="flex justify-center space-x-6 mb-12">
          {socialLinks.map(({ icon, url }, index) => (
            <SocialLink key={index} Icon={icon} url={url} />
          ))}
        </div>

        <div className="border-t border-gray-700 pt-8 mb-8">
          <p className={`text-center mb-4 text-sm ${textColorClass}`}>
            ClickBank is the retailer of this product. CLICKBANK® is a
            registered trademark of Click Sales, Inc., a Delaware corporation
            located at 1444 S. Entertainment Ave., Suite 410 Boise, ID 83709,
            USA and used by permission. ClickBank's role as retailer does not
            constitute an endorsement, approval, or review of this product or
            any claim, statement, or opinion used in promotion of this product.
          </p>
          <p className={`text-center mb-6 text-sm ${textColorClass}`}>
            For Product Support, please contact the seller: support@example.com
            <br />
            For Order Support, please contact ClickBank{" "}
            <a
              href="https://www.clickbank.com/support/"
              className={`underline ${linkHoverClass}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              HERE
            </a>{" "}
            or 1-800-390-6035
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center">
          <p
            className={`text-sm ${darkMode ? "text-gray-500" : "text-gray-400"} mb-4 md:mb-0`}
          >
            © 2024 Quantum Money Code. All Rights Reserved.
          </p>
          <div className="flex space-x-4">
            <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
            <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
