import { useState, useCallback } from "react";
import { getPremiumApiUrl } from "../config/api";

const useFinancialNews = () => {
  const [newsData, setNewsData] = useState([]);
  const [sentimentData, setSentimentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const baseUrl = `${getPremiumApiUrl()}/api`;

  const fetchNewsBySymbol = useCallback(
    async (symbol, limit = 50, offset = 0) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${baseUrl}/news/symbol/${symbol}?limit=${limit}&offset=${offset}`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setNewsData(data);
        return data;
      } catch (err) {
        setError(err.message);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [baseUrl],
  );

  const fetchNewsByTag = useCallback(
    async (tag, limit = 50, offset = 0) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${baseUrl}/news/tag/${tag}?limit=${limit}&offset=${offset}`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setNewsData(data);
        return data;
      } catch (err) {
        setError(err.message);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [baseUrl],
  );

  const fetchSentiment = useCallback(
    async (symbols, fromDate = null, toDate = null) => {
      setLoading(true);
      setError(null);
      try {
        let url = `${baseUrl}/news/sentiment?symbols=${symbols.join(",")}`;

        if (fromDate) {
          url += `&from_date=${fromDate}`;
        }
        if (toDate) {
          url += `&to_date=${toDate}`;
        }

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setSentimentData(data);
        return data;
      } catch (err) {
        setError(err.message);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [baseUrl],
  );

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      return null;
    }
    return date.toISOString().split("T")[0];
  };

  return {
    newsData,
    sentimentData,
    loading,
    error,
    fetchNewsBySymbol,
    fetchNewsByTag,
    fetchSentiment,
    formatDate,
  };
};

export default useFinancialNews;
