import { useState, useEffect } from "react";

const useRelativeTime = (date) => {
  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    const getRelativeTimeString = (date) => {
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);
      const diffInWeeks = Math.floor(diffInDays / 7);
      const diffInMonths = Math.floor(diffInDays / 30);

      const formatTime = (value, unit) =>
        `${value} ${unit}${value !== 1 ? "s" : ""} ago`;

      switch (true) {
        case diffInMonths > 0:
          return formatTime(diffInMonths, "month");
        case diffInWeeks > 0:
          return formatTime(diffInWeeks, "week");
        case diffInDays > 0:
          return formatTime(diffInDays, "day");
        case diffInHours > 0:
          return formatTime(diffInHours, "hour");
        case diffInMinutes > 0:
          return formatTime(diffInMinutes, "minute");
        default:
          return "Just now";
      }
    };

    setRelativeTime(getRelativeTimeString(date));

    const timer = setInterval(() => {
      setRelativeTime(getRelativeTimeString(date));
    }, 60000);

    return () => clearInterval(timer);
  }, [date]);

  return relativeTime;
};

export default useRelativeTime;
