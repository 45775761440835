import React from "react";
import { motion } from "framer-motion";
import { AlertTriangle } from "lucide-react";

const ErrorMessage = ({ darkMode, error }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className={`flex flex-col items-center justify-center p-12 rounded-xl ${
      darkMode ? "bg-gray-800/50" : "bg-white/50"
    } shadow-lg backdrop-blur-sm`}
  >
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
    >
      <AlertTriangle
        size={64}
        className={`mb-6 ${darkMode ? "text-red-400" : "text-red-600"}`}
      />
    </motion.div>
    <motion.h3
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.4, duration: 0.5 }}
      className={`text-2xl font-bold mb-4 ${darkMode ? "text-red-400" : "text-red-600"}`}
    >
      Oops! Something went wrong
    </motion.h3>
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.6, duration: 0.5 }}
      className={`text-lg text-center max-w-md ${darkMode ? "text-red-300" : "text-red-600"}`}
    >
      {error ||
        "An unexpected quantum fluctuation occurred. Please try again later or contact our support team if the problem persists."}
    </motion.p>
  </motion.div>
);

export default ErrorMessage;
