import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/navigation/Header";
import Footer from "./components/common/Footer";
import Landing from "./components/landing/Landing";
import { ThemeProvider, useTheme } from "./contexts/ThemeContext";
import { AuthProvider, useAuthContext } from "./contexts/AuthContext";
import { ProfileProvider } from "./contexts/ProfileContext";
import { AlertsProvider } from "./contexts/AlertsContext";
import About from "./components/common/About";
import NotFound from "./components/common/NotFound";
import Affiliates from "./components/affiliate/Affiliates";
import ChangingTitle from "./components/common/ChangingTitle";
import Forum from "./components/common/Forum";
import { UserSubscriptionProvider } from "./contexts/UserSubscriptionContext";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import TermsOfService from "./components/common/TermsOfService";

function AppContent() {
  const { darkMode } = useTheme();
  const location = useLocation();
  const { isAuthenticated, user, logout } = useAuthContext();

  const isForumRoute = location.pathname.startsWith("/forum");

  return (
    <div
      className={`${darkMode ? "bg-gray-900 text-white" : "bg-gradient-to-br from-green-50 to-blue-100 text-gray-800"} min-h-screen`}
    >
      <ChangingTitle />
      {!isForumRoute && (
        <Header
          isAuthenticated={isAuthenticated}
          onLogout={logout}
          user={user}
        />
      )}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/forum/*" element={<Forum />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AlertsProvider>
          <ProfileProvider>
            <UserSubscriptionProvider>
              <AuthProvider>
                <AppContent />
              </AuthProvider>
            </UserSubscriptionProvider>
          </ProfileProvider>
        </AlertsProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
