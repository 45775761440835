import React, { useState } from "react";
import {
  Users,
  ArrowUpRight,
  ArrowDownRight,
  Link as LinkIcon,
  ArrowUpDown,
  DollarSign,
  UserCircle,
  Calendar,
  ExternalLink,
} from "lucide-react";

const InsiderTransactions = ({
  data,
  darkMode,
  formatNumber,
  formatCurrency,
  formatDate,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  const transactions = Object.values(data || {});

  // Calculate aggregated stats
  const stats = transactions.reduce(
    (acc, trans) => {
      const amount = trans.transactionAmount * trans.transactionPrice;
      if (trans.transactionCode === "S") {
        acc.totalSales += amount;
        acc.salesCount++;
      } else if (trans.transactionCode === "P") {
        acc.totalPurchases += amount;
        acc.purchasesCount++;
      }
      return acc;
    },
    {
      totalSales: 0,
      totalPurchases: 0,
      salesCount: 0,
      purchasesCount: 0,
    },
  );

  const sortedTransactions = [...transactions].sort((a, b) => {
    if (sortConfig.key === "date") {
      return sortConfig.direction === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    }

    if (sortConfig.key === "value") {
      const aValue = a.transactionAmount * a.transactionPrice;
      const bValue = b.transactionAmount * b.transactionPrice;
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
    }

    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    return sortConfig.direction === "asc"
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });

  const handleSort = (key) => {
    setSortConfig((prevSort) => ({
      key,
      direction:
        prevSort.key === key && prevSort.direction === "desc" ? "asc" : "desc",
    }));
  };

  const SortHeader = ({ label, sortKey }) => (
    <th
      className={`py-3 px-4 text-left cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 transition-colors ${
        darkMode ? "text-gray-400" : "text-gray-600"
      }`}
      onClick={() => handleSort(sortKey)}
    >
      <div className="flex items-center gap-1">
        {label}
        <ArrowUpDown
          size={14}
          className={sortConfig.key === sortKey ? "opacity-100" : "opacity-50"}
        />
      </div>
    </th>
  );

  // Mobile transaction card component
  const TransactionCard = ({ transaction }) => (
    <div
      className={`p-4 border-b last:border-b-0 ${
        darkMode ? "border-gray-700" : "border-gray-200"
      }`}
    >
      <div className="flex justify-between items-start mb-3">
        <div className="space-y-1">
          <div
            className={`text-sm font-medium ${
              darkMode ? "text-gray-300" : "text-gray-900"
            }`}
          >
            {transaction.ownerName}
          </div>
          <div
            className={`text-xs ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            {formatDate(transaction.date)}
          </div>
        </div>
        <div
          className={`inline-flex items-center px-2 py-1 rounded-full text-xs ${
            transaction.transactionCode === "S"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          }`}
        >
          {transaction.transactionCode === "S" ? (
            <>
              <ArrowDownRight size={14} className="mr-1" />
              Sale
            </>
          ) : (
            <>
              <ArrowUpRight size={14} className="mr-1" />
              Purchase
            </>
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 text-sm">
        <div>
          <div
            className={`text-xs ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Shares
          </div>
          <div className={darkMode ? "text-white" : "text-gray-900"}>
            {formatNumber(transaction.transactionAmount)}
          </div>
        </div>
        <div>
          <div
            className={`text-xs ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Price
          </div>
          <div className={darkMode ? "text-white" : "text-gray-900"}>
            {formatCurrency(transaction.transactionPrice)}
          </div>
        </div>
        <div>
          <div
            className={`text-xs ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Value
          </div>
          <div className={darkMode ? "text-white" : "text-gray-900"}>
            {formatCurrency(
              transaction.transactionAmount * transaction.transactionPrice,
            )}
          </div>
        </div>
        <div>
          <div
            className={`text-xs ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Filing
          </div>
          <div>
            {transaction.secLink ? (
              <a
                href={transaction.secLink}
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center gap-1 ${
                  darkMode
                    ? "text-blue-400 hover:text-blue-300"
                    : "text-blue-600 hover:text-blue-700"
                }`}
              >
                <ExternalLink size={14} />
                Form 4
              </a>
            ) : (
              <span
                className={`text-sm ${darkMode ? "text-gray-500" : "text-gray-400"}`}
              >
                --
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4">
        <div
          className={`p-3 md:p-4 rounded-lg border ${
            darkMode
              ? "bg-gray-800 border-gray-700"
              : "bg-white border-gray-200"
          }`}
        >
          <div
            className={`text-xs md:text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Total Sales
          </div>
          <div
            className={`text-base md:text-xl font-semibold mt-1 ${
              darkMode ? "text-red-400" : "text-red-600"
            }`}
          >
            {formatCurrency(stats.totalSales)}
          </div>
          <div
            className={`text-xs md:text-sm mt-1 ${darkMode ? "text-gray-500" : "text-gray-500"}`}
          >
            {stats.salesCount} transactions
          </div>
        </div>

        <div
          className={`p-3 md:p-4 rounded-lg border ${
            darkMode
              ? "bg-gray-800 border-gray-700"
              : "bg-white border-gray-200"
          }`}
        >
          <div
            className={`text-xs md:text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Total Purchases
          </div>
          <div
            className={`text-base md:text-xl font-semibold mt-1 ${
              darkMode ? "text-green-400" : "text-green-600"
            }`}
          >
            {formatCurrency(stats.totalPurchases)}
          </div>
          <div
            className={`text-xs md:text-sm mt-1 ${darkMode ? "text-gray-500" : "text-gray-500"}`}
          >
            {stats.purchasesCount} transactions
          </div>
        </div>

        <div
          className={`p-3 md:p-4 rounded-lg border ${
            darkMode
              ? "bg-gray-800 border-gray-700"
              : "bg-white border-gray-200"
          }`}
        >
          <div
            className={`text-xs md:text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Net Activity
          </div>
          <div
            className={`text-base md:text-xl font-semibold mt-1 ${
              stats.totalPurchases > stats.totalSales
                ? darkMode
                  ? "text-green-400"
                  : "text-green-600"
                : darkMode
                  ? "text-red-400"
                  : "text-red-600"
            }`}
          >
            {formatCurrency(stats.totalPurchases - stats.totalSales)}
          </div>
          <div
            className={`text-xs md:text-sm mt-1 ${darkMode ? "text-gray-500" : "text-gray-500"}`}
          >
            {stats.purchasesCount + stats.salesCount} total
          </div>
        </div>

        <div
          className={`p-3 md:p-4 rounded-lg border ${
            darkMode
              ? "bg-gray-800 border-gray-700"
              : "bg-white border-gray-200"
          }`}
        >
          <div
            className={`text-xs md:text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            Average Transaction
          </div>
          <div
            className={`text-base md:text-xl font-semibold mt-1 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            {formatCurrency(
              (stats.totalPurchases + stats.totalSales) /
                (stats.purchasesCount + stats.salesCount) || 0,
            )}
          </div>
          <div
            className={`text-xs md:text-sm mt-1 ${darkMode ? "text-gray-500" : "text-gray-500"}`}
          >
            Last 90 days
          </div>
        </div>
      </div>

      {/* Transactions Section */}
      <div
        className={`rounded-lg border ${
          darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"
        }`}
      >
        <div className="p-4 border-b">
          <h3
            className={`text-lg font-semibold ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Recent Transactions
          </h3>
        </div>

        {/* Desktop Table View */}
        <div className="hidden sm:block overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr
                className={`border-b ${
                  darkMode ? "border-gray-700" : "border-gray-200"
                }`}
              >
                <SortHeader label="Date" sortKey="date" />
                <SortHeader label="Insider" sortKey="ownerName" />
                <th
                  className={`py-3 px-4 text-left ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Type
                </th>
                <SortHeader label="Shares" sortKey="transactionAmount" />
                <SortHeader label="Price" sortKey="transactionPrice" />
                <SortHeader label="Value" sortKey="value" />
                <th
                  className={`py-3 px-4 text-left ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Filing
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactions.map((transaction, index) => (
                <tr
                  key={index}
                  className={`border-b last:border-b-0 hover:bg-opacity-10 hover:bg-gray-500 transition-colors ${
                    darkMode ? "border-gray-700" : "border-gray-200"
                  }`}
                >
                  <td
                    className={`py-3 px-4 whitespace-nowrap ${
                      darkMode ? "text-gray-300" : "text-gray-900"
                    }`}
                  >
                    {formatDate(transaction.date)}
                  </td>
                  <td
                    className={`py-3 px-4 ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {transaction.ownerName}
                  </td>
                  <td className="py-3 px-4">
                    <div
                      className={`inline-flex items-center px-2 py-1 rounded-full text-xs ${
                        transaction.transactionCode === "S"
                          ? "bg-red-100 text-red-700"
                          : "bg-green-100 text-green-700"
                      }`}
                    >
                      {transaction.transactionCode === "S" ? (
                        <>
                          <ArrowDownRight size={14} className="mr-1" />
                          Sale
                        </>
                      ) : (
                        <>
                          <ArrowUpRight size={14} className="mr-1" />
                          Purchase
                        </>
                      )}
                    </div>
                  </td>
                  <td
                    className={`py-3 px-4 ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {formatNumber(transaction.transactionAmount)}
                  </td>
                  <td
                    className={`py-3 px-4 ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {formatCurrency(transaction.transactionPrice)}
                  </td>
                  <td
                    className={`py-3 px-4 ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {formatCurrency(
                      transaction.transactionAmount *
                        transaction.transactionPrice,
                    )}
                  </td>
                  <td className="py-3 px-4">
                    {transaction.secLink ? (
                      <a
                        href={transaction.secLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center gap-1 ${
                          darkMode
                            ? "text-blue-400 hover:text-blue-300"
                            : "text-blue-600 hover:text-blue-700"
                        }`}
                      >
                        <ExternalLink size={14} />
                        Form 4
                      </a>
                    ) : (
                      <span
                        className={`text-sm ${darkMode ? "text-gray-500" : "text-gray-400"}`}
                      >
                        --
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        <div className="sm:hidden">
          {sortedTransactions.map((transaction, index) => (
            <TransactionCard key={index} transaction={transaction} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InsiderTransactions;
