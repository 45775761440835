import React, { useState } from "react";

const MessageSettings = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);

  const handleToggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled);
    // You may want to save this setting to the server or local storage
  };

  return (
    <div>
      <h2>Message Settings</h2>
      <div>
        <label>
          <input
            type="checkbox"
            checked={notificationsEnabled}
            onChange={handleToggleNotifications}
          />
          Enable Notifications
        </label>
      </div>
      {/* Add more settings options here as needed */}
    </div>
  );
};

export default MessageSettings;
