import React from "react";
import { Loader } from "lucide-react";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import { useToggleFollower } from "../../../hooks/useToggleFollower";

const FollowerToggleButton = ({ followerId, darkMode }) => {
  const { isFollower, isLoading, toggleFollower } =
    useToggleFollower(followerId);

  const baseClasses =
    "px-4 py-2 rounded-full text-sm font-semibold transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center justify-center shadow-md hover:shadow-lg";
  const loadingClasses = "opacity-75 cursor-not-allowed";

  const getButtonClasses = () => {
    if (isLoading)
      return `${baseClasses} ${loadingClasses} bg-gray-300 text-gray-700`;
    return darkMode
      ? `${baseClasses} bg-gray-700 text-white hover:bg-red-600`
      : `${baseClasses} bg-gray-200 text-gray-800 hover:bg-red-100 hover:text-red-600`;
  };

  return (
    <button
      onClick={toggleFollower}
      disabled={isLoading}
      className={getButtonClasses()}
    >
      {isLoading ? (
        <>
          <Loader className="animate-spin mr-2" size={18} />
          Loading
        </>
      ) : isFollower ? (
        <>
          <RiUserFollowLine className="mr-2" size={18} />
          Remove Follower
        </>
      ) : (
        <>
          <RiUserUnfollowLine className="mr-2" size={18} />
          Allow Follower
        </>
      )}
    </button>
  );
};

export default FollowerToggleButton;
