import React from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import AuthRequiredAlert from "./AuthRequiredAlert";
import { useTheme } from "../../contexts/ThemeContext";

const AuthenticationGuard = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const { darkMode } = useTheme();

  if (isAuthenticated) {
    return children;
  } else {
    return (
      <AuthRequiredAlert
        darkMode={darkMode}
        title="Authentication Required"
        message="You must be logged in to access this content."
        buttonText="Login"
      />
    );
  }
};

export default AuthenticationGuard;
