import React from "react";
import {
  ArrowUp,
  ArrowDown,
  Minus,
  Info,
  LineChart,
  Percent,
  BarChart2,
  Calculator,
} from "lucide-react";

export const IncomeRatios = ({ data, previousData, darkMode }) => {
  const formatPercentage = (value) => `${value.toFixed(2)}%`;
  const formatCurrency = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const calculateChange = (current, previous) => {
    if (!previous) return null;
    return ((current - previous) / Math.abs(previous)) * 100;
  };

  const ratioGroups = [
    {
      title: "Profitability Ratios",
      icon: Percent,
      ratios: [
        {
          name: "Gross Margin",
          value: data.grossMargin,
          previousValue: previousData?.grossMargin,
          description:
            "Measures the percentage of revenue retained after cost of goods sold",
        },
        {
          name: "Operating Margin",
          value: data.operatingMargin,
          previousValue: previousData?.operatingMargin,
          description:
            "Shows operating profit as a percentage of total revenue",
        },
        {
          name: "Net Margin",
          value: data.netMargin,
          previousValue: previousData?.netMargin,
          description: "Indicates net profit as a percentage of total revenue",
        },
        {
          name: "EBITDA Margin",
          value: data.ebitdaMargin,
          previousValue: previousData?.ebitdaMargin,
          description:
            "Earnings before interest, taxes, depreciation, and amortization margin",
        },
      ],
    },
    {
      title: "Operational Efficiency",
      icon: BarChart2,
      ratios: [
        {
          name: "Operating Expense Ratio",
          value: data.operatingExpenseRatio,
          previousValue: previousData?.operatingExpenseRatio,
          description: "Operating expenses as a percentage of revenue",
        },
        {
          name: "SG&A to Revenue",
          value: data.sgaToRevenue,
          previousValue: previousData?.sgaToRevenue,
          description:
            "Selling, general, and administrative expenses relative to revenue",
        },
        {
          name: "Cost of Revenue Ratio",
          value: data.costOfRevenueRatio,
          previousValue: previousData?.costOfRevenueRatio,
          description: "Cost of revenue as a percentage of total revenue",
        },
      ],
    },
    {
      title: "Other Financial Metrics",
      icon: Calculator,
      ratios: [
        {
          name: "Interest Coverage",
          value: data.interestCoverage,
          previousValue: previousData?.interestCoverage,
          description: "Operating income divided by interest expense",
          isMultiple: true,
        },
        {
          name: "Effective Tax Rate",
          value: data.effectiveTaxRate,
          previousValue: previousData?.effectiveTaxRate,
          description: "Income tax expense as a percentage of pre-tax income",
        },
        {
          name: "Revenue per Employee",
          value: data.revenuePerEmployee,
          previousValue: previousData?.revenuePerEmployee,
          description: "Total revenue divided by number of employees",
          isCurrency: true,
        },
      ],
    },
  ];

  const RatioCard = ({ ratio }) => {
    const change = calculateChange(ratio.value, ratio.previousValue);
    const formattedValue = ratio.isCurrency
      ? formatCurrency(ratio.value)
      : ratio.isMultiple
        ? `${ratio.value.toFixed(2)}x`
        : formatPercentage(ratio.value);

    return (
      <div
        className={`flex items-center justify-between py-3 px-2
        group cursor-default transition-colors duration-200
        ${darkMode ? "hover:bg-gray-700/30" : "hover:bg-gray-100/80"}`}
      >
        <div className="flex items-center gap-2">
          <span
            className={`text-sm font-medium
            ${darkMode ? "text-gray-300" : "text-gray-600"}`}
          >
            {ratio.name}
          </span>
          <div className="relative group/tooltip">
            <Info
              className={`w-3.5 h-3.5 
              ${darkMode ? "text-gray-500 hover:text-gray-400" : "text-gray-400 hover:text-gray-500"} 
              transition-colors duration-200`}
            />
            <div
              className={`absolute left-1/2 -translate-x-1/2 bottom-full mb-2 
              hidden group-hover/tooltip:block w-64 p-3 text-xs rounded-lg shadow-lg
              backdrop-blur-sm z-10 border
              ${
                darkMode
                  ? "bg-gray-800/95 text-gray-200 border-gray-700"
                  : "bg-white text-gray-600 border-gray-200"
              }`}
            >
              {ratio.description}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <span
            className={`text-base font-semibold
            ${darkMode ? "text-gray-100" : "text-gray-900"}
            ${ratio.isMultiple || ratio.isCurrency ? "tabular-nums" : ""}`}
          >
            {formattedValue}
          </span>
          {change !== null && (
            <div
              className={`flex items-center gap-1 text-xs min-w-[60px] justify-center
              font-medium px-2 py-0.5 rounded 
              ${
                change > 0
                  ? "text-emerald-400 bg-emerald-400/10"
                  : change < 0
                    ? "text-red-400 bg-red-400/10"
                    : darkMode
                      ? "text-gray-400 bg-gray-600/40"
                      : "text-gray-500 bg-gray-200/50"
              }`}
            >
              {change > 0 ? (
                <ArrowUp className="w-3 h-3" />
              ) : change < 0 ? (
                <ArrowDown className="w-3 h-3" />
              ) : (
                <Minus className="w-3 h-3" />
              )}
              <span>{Math.abs(change).toFixed(1)}%</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`w-full transition-all duration-200 ease-in-out 
      ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      <div className="max-w-7xl mx-auto">
        <div
          className={`rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm
          ${darkMode ? "bg-gray-800/50" : "bg-white"}`}
        >
          {/* Header Section */}
          <div
            className={`px-6 py-5 border-b
            ${darkMode ? "border-gray-700/50" : "border-gray-100"}`}
          >
            <div className="flex items-center gap-3">
              <LineChart
                className={`w-5 h-5
                ${darkMode ? "text-gray-400" : "text-gray-500"}`}
              />
              <div>
                <h2
                  className={`text-xl font-semibold tracking-tight
                  ${darkMode ? "text-white" : "text-gray-900"}`}
                >
                  Financial Ratios & Metrics
                </h2>
                <p
                  className={`mt-1 text-sm font-medium
                  ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                >
                  Key performance indicators and financial health metrics
                </p>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="p-4 sm:p-6">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
              {ratioGroups.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className={`rounded-xl transition-colors
                    ${darkMode ? "bg-gray-800/30" : "bg-gray-50/80"}`}
                >
                  <div
                    className="flex items-center gap-2.5 p-4 border-b
                    ${darkMode ? 'border-gray-700/50' : 'border-gray-200/50'}"
                  >
                    <group.icon
                      className={`w-4 h-4 
                      ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                    />
                    <h3
                      className={`text-sm font-semibold
                      ${darkMode ? "text-white" : "text-gray-900"}`}
                    >
                      {group.title}
                    </h3>
                  </div>
                  <div>
                    {group.ratios.map((ratio, index) => (
                      <RatioCard key={index} ratio={ratio} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeRatios;
