import React, { useState, useEffect } from "react";
import { useSymbolSearch } from "../../../../hooks/useSymbolSearch";
import { exchanges } from "../../../../constants/EXCHANGES";
import { Info, ChevronDown, Check, Search } from "lucide-react";

const marketColors = {
  US: {
    color: "#10B981",
    gradient: "from-green-400 to-green-600",
    ring: "ring-emerald-500",
    icon: "🇺🇸",
  },
  CC: {
    color: "#F59E0B",
    gradient: "from-amber-400 to-amber-600",
    ring: "ring-amber-500",
    icon: "₿",
  },
  FOREX: {
    color: "#8B5CF6",
    gradient: "from-blue-400 to-blue-600",
    ring: "ring-violet-500",
    icon: "¥",
  },
};

const CandlestickAssetSelectorForm = ({
  currentOptions,
  onOptionsChange,
  darkMode,
}) => {
  const [formOptions, setFormOptions] = useState(currentOptions);
  const [isSymbolSelectOpen, setIsSymbolSelectOpen] = useState(false);
  const [isExchangeSelectOpen, setIsExchangeSelectOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const SUPPORTED_EXCHANGE_CODES = exchanges.filter((exchange) =>
    ["US", "CC", "FOREX"].includes(exchange.Code),
  );

  const [selectedExchange, setSelectedExchange] = useState(
    SUPPORTED_EXCHANGE_CODES.find((ex) => ex.Code === formOptions.exchange) ||
      SUPPORTED_EXCHANGE_CODES[0],
  );

  const { symbols, loading, error, setQuery } = useSymbolSearch(
    selectedExchange.Code,
    selectedExchange.Code !== "CC" && selectedExchange.Code !== "FOREX"
      ? "common_stock"
      : undefined,
  );

  const intervals = [
    { value: "60000", label: "1m", description: "Short-term trading" },
    { value: "300000", label: "5m", description: "Intraday analysis" },
    { value: "900000", label: "15m", description: "Active trading" },
    { value: "3600000", label: "1h", description: "Swing trading" },
    { value: "86400000", label: "1d", description: "Long-term trends" },
  ];

  const marketStyle = marketColors[selectedExchange.Code] || marketColors.US;

  const isFormValid = formOptions.symbols && formOptions.interval;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      onOptionsChange(formOptions);
    }
  };

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
    setFormOptions((prev) => ({
      ...prev,
      exchange: exchange.Code,
      market_type:
        exchange.Code === "US"
          ? prev.market_type
          : mapExchangeCodeToMarketType(exchange.Code),
      symbols: "",
    }));
    setIsExchangeSelectOpen(false);
    setSearchTerm("");
    setQuery("");
  };

  const handleSymbolSelect = (symbolCode) => {
    setFormOptions((prev) => ({
      ...prev,
      symbols: symbolCode,
    }));
    setIsSymbolSelectOpen(false);
    setSearchTerm(symbolCode);
    setQuery("");
  };

  const mapExchangeCodeToMarketType = (exchangeCode) => {
    if (exchangeCode === "US") return "us";
    if (exchangeCode === "CC") return "crypto";
    if (exchangeCode === "FOREX") return "forex";
    return "";
  };

  useEffect(() => {
    setQuery(searchTerm);
  }, [searchTerm, setQuery]);

  return (
    <div
      className={`w-full h-full flex flex-col ${darkMode ? "bg-slate-900" : "bg-white"} rounded-3xl border ${darkMode ? "border-slate-800" : "border-slate-200"}`}
    >
      <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
        <div className="h-full flex flex-col mx-auto px-4 pb-6">
          <div className="space-y-4 py-4">
            {/* Exchange Selector */}
            <div className="relative">
              <label
                className={`block text-sm font-medium mb-2 ${darkMode ? "text-slate-300" : "text-slate-600"}`}
              >
                Exchange
              </label>
              <button
                type="button"
                onClick={() => setIsExchangeSelectOpen(!isExchangeSelectOpen)}
                className={`w-full flex items-center justify-between px-4 py-3 rounded-xl border transition-colors ${
                  darkMode
                    ? "bg-slate-800 border-slate-700 hover:bg-slate-700 text-white"
                    : "bg-white border-slate-200 hover:bg-slate-50 text-slate-900"
                }`}
              >
                <div className="flex items-center gap-3">
                  <div
                    className={`w-10 h-10 flex items-center justify-center rounded-lg bg-gradient-to-br ring-1 ${marketStyle.gradient} ${marketStyle.ring}`}
                  >
                    <span className="text-lg text-white">
                      {marketColors[selectedExchange.Code]?.icon}
                    </span>
                  </div>
                  <span className="font-medium">{selectedExchange.Name}</span>
                </div>
                <ChevronDown
                  className={`w-5 h-5 ${darkMode ? "text-slate-400" : "text-slate-500"}`}
                />
              </button>

              {isExchangeSelectOpen && (
                <div
                  className={`absolute z-50 mt-2 w-full rounded-xl border shadow-lg ${
                    darkMode
                      ? "bg-slate-800 border-slate-700"
                      : "bg-white border-slate-200"
                  }`}
                >
                  {SUPPORTED_EXCHANGE_CODES.map((exchange) => {
                    const style = marketColors[exchange.Code];
                    return (
                      <button
                        key={exchange.Code}
                        type="button"
                        onClick={() => handleExchangeSelect(exchange)}
                        className={`w-full flex items-center gap-3 px-4 py-3 transition-colors ${
                          darkMode
                            ? "hover:bg-slate-700 text-white"
                            : "hover:bg-slate-50 text-slate-900"
                        }`}
                      >
                        <div
                          className={`w-10 h-10 flex items-center justify-center rounded-lg bg-gradient-to-br ring-1 ${style.gradient} ${style.ring}`}
                        >
                          <span className="text-lg text-white">
                            {marketColors[exchange.Code]?.icon}
                          </span>
                        </div>
                        <span className="font-medium">{exchange.Name}</span>
                        {selectedExchange.Code === exchange.Code && (
                          <Check className="w-5 h-5 ml-auto text-green-500" />
                        )}
                      </button>
                    );
                  })}
                </div>
              )}
            </div>

            {/* Symbol Search */}
            <div className="relative">
              <label
                className={`block text-sm font-medium mb-2 ${darkMode ? "text-slate-300" : "text-slate-600"}`}
              >
                Symbol
              </label>
              <div className="relative">
                <Search
                  className={`absolute left-4 top-3.5 w-5 h-5 ${darkMode ? "text-slate-400" : "text-slate-500"}`}
                />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsSymbolSelectOpen(e.target.value.length > 0);
                  }}
                  placeholder="Search symbols..."
                  className={`w-full pl-12 pr-4 py-3 rounded-xl border transition-colors ${
                    darkMode
                      ? "bg-slate-800 border-slate-700 text-white placeholder-slate-400"
                      : "bg-white border-slate-200 text-slate-900 placeholder-slate-500"
                  }`}
                />
              </div>

              {isSymbolSelectOpen && (
                <div
                  className={`absolute z-50 mt-2 w-full rounded-xl border shadow-lg max-h-64 overflow-y-auto ${
                    darkMode
                      ? "bg-slate-800 border-slate-700"
                      : "bg-white border-slate-200"
                  }`}
                >
                  {loading && (
                    <div
                      className={`px-4 py-3 ${darkMode ? "text-slate-300" : "text-slate-700"}`}
                    >
                      Loading...
                    </div>
                  )}
                  {!loading && symbols.length === 0 && (
                    <div
                      className={`px-4 py-3 ${darkMode ? "text-slate-300" : "text-slate-700"}`}
                    >
                      No symbols found
                    </div>
                  )}
                  {!loading &&
                    symbols.map((symbol) => (
                      <button
                        key={symbol.code}
                        type="button"
                        onClick={() => handleSymbolSelect(symbol.Code)}
                        className={`w-full text-left px-4 py-3 transition-colors ${
                          darkMode
                            ? "hover:bg-slate-700 text-white"
                            : "hover:bg-slate-50 text-slate-900"
                        }`}
                      >
                        <div className="font-medium">{symbol.Name}</div>
                        <div
                          className={
                            darkMode ? "text-slate-400" : "text-slate-500"
                          }
                        >
                          {symbol.Code}
                        </div>
                      </button>
                    ))}
                </div>
              )}
            </div>

            {/* US Quote Option */}
            {selectedExchange.Code === "US" && (
              <div
                className={`p-4 rounded-xl border ${
                  darkMode
                    ? "bg-slate-800 border-slate-700"
                    : "bg-slate-50 border-slate-200"
                }`}
              >
                <label className="flex items-center gap-3 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formOptions.market_type === "us-quote"}
                    onChange={(e) =>
                      setFormOptions((prev) => ({
                        ...prev,
                        market_type: e.target.checked ? "us-quote" : "us",
                      }))
                    }
                    className="w-4 h-4 rounded border-slate-300 text-green-500 focus:ring-green-500"
                  />
                  <span
                    className={`text-sm ${darkMode ? "text-slate-300" : "text-slate-600"}`}
                  >
                    Use delayed US quote data
                  </span>
                </label>
              </div>
            )}

            {/* Interval Selection */}
            <div className="space-y-2">
              <label
                className={`block text-sm font-medium ${darkMode ? "text-slate-300" : "text-slate-600"}`}
              >
                Interval
              </label>
              <div className="grid grid-cols-5 gap-2">
                {intervals.map((interval) => (
                  <button
                    key={interval.value}
                    type="button"
                    onClick={() =>
                      setFormOptions((prev) => ({
                        ...prev,
                        interval: interval.value,
                      }))
                    }
                    className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                      formOptions.interval === interval.value
                        ? "bg-green-500 text-white"
                        : darkMode
                          ? "bg-slate-800 text-slate-300 hover:bg-slate-700"
                          : "bg-slate-100 text-slate-600 hover:bg-slate-200"
                    }`}
                  >
                    {interval.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Footer Section */}
          <div
            className={`sticky bottom-0 left-0 right-0 pt-4 border-t ${darkMode ? "border-slate-700" : "border-slate-200"}`}
          >
            <div className="space-y-4">
              <button
                type="submit"
                disabled={!isFormValid}
                className={`w-full py-3 rounded-xl font-medium transition-colors ${
                  isFormValid
                    ? "bg-green-500 hover:bg-green-600 text-white"
                    : "bg-slate-300 cursor-not-allowed text-slate-500"
                }`}
              >
                Submit
              </button>

              <div
                className={`flex items-center gap-2 px-4 py-3 rounded-xl ${
                  darkMode
                    ? "bg-slate-800 text-slate-300 ring-1 ring-slate-700"
                    : "bg-slate-50 text-slate-600 ring-1 ring-slate-200"
                }`}
              >
                <Info className="w-4 h-4 flex-shrink-0" />
                <span className="text-sm font-medium">
                  Trading is available 24/7 for crypto markets
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Backdrop */}
      {(isExchangeSelectOpen || isSymbolSelectOpen) && (
        <div
          className="fixed inset-0 bg-black/50 z-40 hidden sm:block"
          onClick={() => {
            setIsExchangeSelectOpen(false);
            setIsSymbolSelectOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default CandlestickAssetSelectorForm;
