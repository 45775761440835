import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useUserSubscription } from "../../contexts/UserSubscriptionContext";
import { Check, ChevronRight } from "lucide-react";

const SubscriptionSuccess = ({ darkMode }) => {
  const navigate = useNavigate();
  const { loading, currentSubscription, error } = useUserSubscription();

  if (loading || !currentSubscription) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex justify-center items-center h-screen"
      >
        <motion.div
          animate={{
            rotate: 360,
            transition: { duration: 1, repeat: Infinity, ease: "linear" },
          }}
          className={`w-12 h-12 border-t-2 border-b-2 rounded-full ${
            darkMode ? "border-white" : "border-gray-800"
          }`}
        />
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={`text-center p-8 ${darkMode ? "text-white" : "text-gray-800"}`}
      >
        Error: {error}
      </motion.div>
    );
  }

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const getPlanName = () => {
    switch (currentSubscription?.planType?.toLowerCase()) {
      case "quantum":
        return "Quantum";
      case "business":
        return "Business";
      case "pro":
        return "Pro";
      default:
        return "Premium";
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className={`max-w-3xl mx-auto mt-12 p-8 rounded-3xl shadow-2xl ${
        darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
      }`}
    >
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            delay: 0.2,
            type: "spring",
            stiffness: 500,
            damping: 30,
          }}
          className={`inline-flex p-4 rounded-full ${
            darkMode ? "bg-green-600" : "bg-green-100"
          }`}
        >
          <Check
            size={32}
            className={darkMode ? "text-white" : "text-green-600"}
          />
        </motion.div>
        <motion.h2
          variants={itemVariants}
          className={`mt-6 text-4xl font-extrabold ${
            darkMode ? "text-green-400" : "text-green-600"
          }`}
        >
          Welcome to Quantum Money Code {getPlanName()} Plan!
        </motion.h2>
        <motion.p variants={itemVariants} className="mt-2 text-xl">
          Your subscription has been successfully activated.
        </motion.p>
      </div>

      <motion.div variants={itemVariants} className="mt-8 space-y-6">
        <p className="text-lg leading-relaxed">
          You now have access to all the amazing features of our {getPlanName()}{" "}
          Plan. Get ready to revolutionize your financial strategies with
          cutting-edge quantum algorithms and advanced market analysis tools.
        </p>
        <motion.div
          variants={itemVariants}
          className={`p-6 rounded-xl ${
            darkMode ? "bg-gray-700" : "bg-green-50"
          }`}
        >
          <h3 className="font-semibold text-xl mb-4">What's Next?</h3>
          <ul className="space-y-3">
            {[
              "Explore our quantum-powered trading algorithms",
              "Access exclusive market insights and predictions",
              "Join our community of elite traders and investors",
              "Attend exclusive webinars and training sessions",
            ].map((item, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center space-x-2"
              >
                <ChevronRight
                  size={20}
                  className={darkMode ? "text-green-400" : "text-green-600"}
                />
                <span>{item}</span>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </motion.div>

      <motion.div variants={itemVariants} className="mt-10 flex justify-center">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate("/forum/premium-features")}
          className={`py-3 px-8 rounded-full font-semibold text-white transition-all duration-200 flex items-center space-x-2 ${
            darkMode
              ? "bg-green-600 hover:bg-green-700"
              : "bg-green-500 hover:bg-green-600"
          }`}
        >
          <span>Go to Dashboard</span>
          <ChevronRight size={20} />
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default SubscriptionSuccess;
