import { useEffect } from "react";
import { scroller } from "react-scroll";

const useScrollToSection = (location) => {
  useEffect(() => {
    if (location.pathname === "/" && location.hash) {
      const sectionId = location.hash.slice(1);
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -96,
      });
    }
  }, [location]);
};

export default useScrollToSection;
