import React from "react";
import { BarChart } from "lucide-react";

const TopHoldingsTable = ({ data, darkMode }) => {
  const holdings = data.ETF_Data?.Top_10_Holdings || {};

  const holdingsArray = Object.entries(holdings)
    .map(([key, value]) => ({
      name: value?.Name || key || "N/A",
      assets: value?.["Assets_%"] || 0,
      change: (Math.random() * 10 - 5).toFixed(2),
    }))
    .sort((a, b) => b.assets - a.assets);

  const formatPercent = (value) => {
    return Number(value).toFixed(2) + "%";
  };

  const getPercentageBarWidth = (value) => {
    const maxValue = Math.max(...holdingsArray.map((h) => h.assets));
    return `${(value / maxValue) * 100}%`;
  };

  return (
    <div
      className={`w-full rounded-xl border shadow-sm transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Modern Header */}
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-4">
          <div
            className={`p-3 rounded-xl ${
              darkMode
                ? "bg-violet-500/10 text-violet-400"
                : "bg-violet-50 text-violet-500"
            }`}
          >
            <BarChart className="w-6 h-6" />
          </div>
          <div>
            <h3
              className={`text-xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Top Holdings
            </h3>
            <p
              className={`mt-1 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Major positions by allocation percentage
            </p>
          </div>
        </div>
      </div>

      {/* Responsive Layout */}
      <div className="overflow-hidden">
        {holdingsArray.length > 0 ? (
          <div className="relative">
            {/* For larger screens, display the table */}
            <div className="hidden sm:block">
              <table className="w-full">
                <thead>
                  <tr
                    className={`text-left ${
                      darkMode ? "bg-gray-800/40" : "bg-gray-50"
                    }`}
                  >
                    <th className="px-6 py-4 text-sm font-medium tracking-wider text-gray-500 dark:text-gray-400">
                      Company
                    </th>
                    <th className="px-6 py-4 text-sm font-medium tracking-wider text-gray-500 dark:text-gray-400">
                      Allocation
                    </th>
                    <th className="px-6 py-4 text-sm font-medium tracking-wider text-gray-500 dark:text-gray-400">
                      Distribution
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-800">
                  {holdingsArray.map((holding, index) => (
                    <tr
                      key={index}
                      className={`group hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors duration-150`}
                    >
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <div className="ml-2">
                            <div
                              className={`font-medium ${
                                darkMode ? "text-white" : "text-gray-900"
                              }`}
                            >
                              {holding.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center space-x-2">
                          <span
                            className={`font-semibold ${
                              darkMode ? "text-white" : "text-gray-900"
                            }`}
                          >
                            {formatPercent(holding.assets)}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="w-full h-4 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
                          <div
                            className={`h-full rounded-full bg-violet-500/20 dark:bg-violet-500/30 transition-all duration-500 relative group-hover:bg-violet-500/30 dark:group-hover:bg-violet-500/40`}
                            style={{
                              width: getPercentageBarWidth(holding.assets),
                            }}
                          ></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* For mobile screens, display cards */}
            <div className="sm:hidden">
              {holdingsArray.map((holding, index) => (
                <div
                  key={index}
                  className={`p-4 border-b last:border-b-0 ${
                    darkMode
                      ? "border-gray-800 bg-gray-900"
                      : "border-gray-200 bg-white"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <div
                        className={`font-medium ${
                          darkMode ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {holding.name}
                      </div>
                      <div
                        className={`mt-1 text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        Allocation: {formatPercent(holding.assets)}
                      </div>
                    </div>
                    <div className="w-20 h-4 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
                      <div
                        className={`h-full rounded-full bg-violet-500/20 dark:bg-violet-500/30`}
                        style={{
                          width: getPercentageBarWidth(holding.assets),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-6 text-center">
            <p className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}>
              No holdings data available.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopHoldingsTable;
