import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { CandlestickChart, LineChart, Landmark } from "lucide-react";
import AssetCandlestickDataComponent from "./liveData/AssetCandlestickDataComponent";
import FundamentalsDisplay from "./fundamentals/fundamental-display/FundamentalsDisplay";
import MarketQuotesCandlestick from "./market-quotes/MarketQuotesCandlestick";

const PremiumFeaturesLayout = ({ darkMode }) => {
  const getLinkStyles = (isActive) => {
    const baseStyles =
      "flex items-center justify-center gap-1.5 px-3 py-2 rounded-md transition-colors duration-200 text-sm md:text-base";
    const activeStyles = darkMode
      ? "bg-slate-700 text-white"
      : "bg-slate-200 text-slate-900";
    const inactiveStyles = darkMode
      ? "text-slate-300 hover:bg-slate-800"
      : "text-slate-600 hover:bg-slate-100";

    return `${baseStyles} ${isActive ? activeStyles : inactiveStyles}`;
  };

  return (
    <div
      className={`${darkMode ? "bg-slate-900 text-white" : "bg-white text-slate-900"}`}
    >
      {/* Navigation Bar - Made sticky and more compact */}
      <nav className="sticky top-0 z-10 border-b border-slate-200 dark:border-slate-700 bg-inherit">
        <div className="flex justify-end px-2">
          <NavLink
            to="candlestick"
            className={({ isActive }) => getLinkStyles(isActive)}
          >
            <CandlestickChart className="w-4 h-4" />
            <span className="hidden sm:inline">Realtime Data</span>
            <span className="inline sm:hidden">Real-time</span>
          </NavLink>
          <NavLink
            to="fundamentals"
            className={({ isActive }) => getLinkStyles(isActive)}
          >
            <Landmark className="w-4 h-4" />
            <span className="hidden sm:inline">Fundamental Analysis</span>
            <span className="inline sm:hidden">Analysis</span>
          </NavLink>
          <NavLink
            to="market-quotes"
            className={({ isActive }) => getLinkStyles(isActive)}
          >
            <LineChart className="w-4 h-4" />
            <span className="hidden sm:inline">Market Data</span>
            <span className="inline sm:hidden">Bar Chart</span>
          </NavLink>
        </div>
      </nav>

      {/* Content Area - Simplified container */}
      <div className={`${darkMode ? "bg-slate-800" : "bg-white"}`}>
        <Routes>
          <Route
            path="market-quotes"
            element={<MarketQuotesCandlestick darkMode={darkMode} />}
          />
          <Route
            path="fundamentals"
            element={<FundamentalsDisplay darkMode={darkMode} />}
          />
          <Route
            path="candlestick"
            element={<AssetCandlestickDataComponent darkMode={darkMode} />}
          />
          <Route
            path="*"
            element={<AssetCandlestickDataComponent darkMode={darkMode} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default PremiumFeaturesLayout;
