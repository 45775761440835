const TOPIC_MAPPING = {
  allPosts: "All Posts",
  quantumMoneyCode: "Quantum Money Code",
  manifestation: "Manifestation",
  meditationAndMindfulness: "Meditation & Mindfulness",
  successStories: "Success Stories",
  quantumNewsAndDevelopments: "Quantum News & Developments",
  visualizationTechniques: "Visualization Techniques",
  personalGrowth: "Personal Growth",
  wealthAndAbundance: "Wealth & Abundance",
  energyAndVibration: "Energy & Vibration",
  inspirationAndMotivation: "Inspiration & Motivation",
  healthAndWellness: "Health & Wellness",
  bookRecommendations: "Book Recommendations",
  popCulture: "Pop Culture",
  memes: "Memes",
  seekingAdvice: "Seeking Advice",
  justForFun: "Just for Fun",
  overcomingBlocks: "Overcoming Blocks",
};

export default TOPIC_MAPPING;
