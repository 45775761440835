import React from "react";
import { Search } from "lucide-react";

const SymbolSearch = ({
  darkMode,
  searchTerm,
  setSearchTerm,
  isSymbolSelectOpen,
  setIsSymbolSelectOpen,
  loadingSymbols,
  symbols,
  handleSymbolSelect,
  closeAllDropdowns,
}) => {
  return (
    <div className="space-y-2 relative">
      <label
        className={`text-sm font-medium ${
          darkMode ? "text-gray-300" : "text-gray-700"
        }`}
      >
        Symbol
      </label>
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toUpperCase())}
          onFocus={() => setIsSymbolSelectOpen(true)}
          placeholder="Search symbol..."
          className={`w-full p-4 pl-12 rounded-xl shadow-sm transition-all duration-200 ${
            darkMode
              ? "bg-gray-800/50 text-white placeholder-gray-400 focus:ring-2 focus:ring-emerald-500"
              : "bg-white text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-emerald-500"
          }`}
        />
        <Search className="absolute left-4 top-4 w-5 h-5 text-gray-400" />
        {isSymbolSelectOpen && (
          <div
            className={`absolute mt-2 w-full max-h-64 overflow-y-auto rounded-xl shadow-xl z-50 ${
              darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
            }`}
          >
            {loadingSymbols ? (
              <div className="p-4">Loading...</div>
            ) : symbols.length > 0 ? (
              symbols.map((symbolItem) => (
                <button
                  key={symbolItem.Code}
                  onClick={() => {
                    handleSymbolSelect(symbolItem.Code);
                    setSearchTerm(symbolItem.Code);
                    closeAllDropdowns();
                  }}
                  className={`w-full p-4 text-left transition-colors duration-200 ${
                    darkMode ? "hover:bg-gray-700/70" : "hover:bg-blue-50/70"
                  }`}
                >
                  <div className="font-medium">{symbolItem.Code}</div>
                  <div className={darkMode ? "text-gray-400" : "text-gray-500"}>
                    {symbolItem.Name}
                  </div>
                </button>
              ))
            ) : (
              <div className="p-4">No symbols found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SymbolSearch;
