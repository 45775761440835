import React from "react";
import { ArrowUp, ArrowDown, Minus } from "lucide-react";

export const IncomeDetailedBreakdown = ({
  currentData,
  previousData,
  darkMode,
}) => {
  const formatCurrency = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const formatPercentage = (value) => `${value.toFixed(2)}%`;

  const calculateChange = (current, previous) => {
    if (!previous) return null;
    return ((current - previous) / Math.abs(previous)) * 100;
  };

  const sections = [
    {
      title: "Revenue & Gross Profit",
      metrics: [
        {
          name: "Total Revenue",
          current: currentData.totalRevenue,
          previous: previousData?.totalRevenue,
          isCurrency: true,
        },
        {
          name: "Cost of Revenue",
          current: currentData.costOfRevenue,
          previous: previousData?.costOfRevenue,
          isCurrency: true,
          isNegative: true,
        },
        {
          name: "Gross Profit",
          current: currentData.grossProfit,
          previous: previousData?.grossProfit,
          isCurrency: true,
          isHighlight: true,
        },
        {
          name: "Gross Margin",
          current: currentData.grossMargin,
          previous: previousData?.grossMargin,
          isPercentage: true,
        },
      ],
    },
    {
      title: "Operating Expenses",
      metrics: [
        {
          name: "SG&A Expenses",
          current: currentData.sellingGeneralAdministrative,
          previous: previousData?.sellingGeneralAdministrative,
          isCurrency: true,
          isNegative: true,
        },
        {
          name: "Depreciation & Amortization",
          current: currentData.depreciationAndAmortization,
          previous: previousData?.depreciationAndAmortization,
          isCurrency: true,
          isNegative: true,
        },
        {
          name: "Other Operating Expenses",
          current: currentData.otherOperatingExpenses,
          previous: previousData?.otherOperatingExpenses,
          isCurrency: true,
          isNegative: true,
        },
        {
          name: "Total Operating Expenses",
          current: currentData.totalOperatingExpenses,
          previous: previousData?.totalOperatingExpenses,
          isCurrency: true,
          isNegative: true,
          isHighlight: true,
        },
      ],
    },
    {
      title: "Income Metrics",
      metrics: [
        {
          name: "Operating Income",
          current: currentData.operatingIncome,
          previous: previousData?.operatingIncome,
          isCurrency: true,
        },
        {
          name: "Interest Expense",
          current: currentData.interestExpense,
          previous: previousData?.interestExpense,
          isCurrency: true,
          isNegative: true,
        },
        {
          name: "Income Before Tax",
          current: currentData.incomeBeforeTax,
          previous: previousData?.incomeBeforeTax,
          isCurrency: true,
        },
        {
          name: "Income Tax Expense",
          current: currentData.incomeTaxExpense,
          previous: previousData?.incomeTaxExpense,
          isCurrency: true,
          isNegative: true,
        },
        {
          name: "Net Income",
          current: currentData.netIncome,
          previous: previousData?.netIncome,
          isCurrency: true,
          isHighlight: true,
        },
      ],
    },
    {
      title: "Additional Metrics",
      metrics: [
        {
          name: "EBIT",
          current: currentData.ebit,
          previous: previousData?.ebit,
          isCurrency: true,
        },
        {
          name: "EBITDA",
          current: currentData.ebitda,
          previous: previousData?.ebitda,
          isCurrency: true,
        },
        {
          name: "Operating Margin",
          current: currentData.operatingMargin,
          previous: previousData?.operatingMargin,
          isPercentage: true,
        },
        {
          name: "Net Margin",
          current: currentData.netMargin,
          previous: previousData?.netMargin,
          isPercentage: true,
        },
        {
          name: "Effective Tax Rate",
          current: currentData.effectiveTaxRate,
          previous: previousData?.effectiveTaxRate,
          isPercentage: true,
        },
      ],
    },
  ];

  const MetricRow = ({ metric, showBorder = true }) => {
    const change = calculateChange(metric.current, metric.previous);
    const formattedValue = metric.isPercentage
      ? formatPercentage(metric.current)
      : formatCurrency(metric.current);

    return (
      <div
        className={`flex justify-between items-center p-3 rounded-lg transition-all duration-200 
        ${showBorder ? "border border-transparent" : ""} 
        ${
          metric.isHighlight
            ? darkMode
              ? "bg-gray-700/40 hover:bg-gray-700/60"
              : "bg-gray-50 hover:bg-gray-100/80"
            : darkMode
              ? "hover:bg-gray-700/40"
              : "hover:bg-gray-50/80"
        }`}
      >
        <span
          className={`text-sm font-medium tracking-tight
          ${darkMode ? "text-gray-300" : "text-gray-600"}`}
        >
          {metric.name}
        </span>
        <div className="flex items-center gap-4">
          <span
            className={`font-semibold ${
              metric.isHighlight
                ? darkMode
                  ? "text-white"
                  : "text-gray-900"
                : darkMode
                  ? "text-gray-200"
                  : "text-gray-700"
            }`}
          >
            {formattedValue}
          </span>
          {change !== null && (
            <div
              className={`flex items-center gap-1.5 text-xs font-medium rounded-full px-2 py-0.5
              ${
                change > 0
                  ? "text-green-500 bg-green-500/10"
                  : change < 0
                    ? "text-red-500 bg-red-500/10"
                    : darkMode
                      ? "text-gray-400 bg-gray-600/40"
                      : "text-gray-500 bg-gray-200/50"
              }`}
            >
              {change > 0 ? (
                <ArrowUp className="w-3.5 h-3.5" />
              ) : change < 0 ? (
                <ArrowDown className="w-3.5 h-3.5" />
              ) : (
                <Minus className="w-3.5 h-3.5" />
              )}
              <span>{Math.abs(change).toFixed(1)}%</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`w-full transition-all duration-200 ease-in-out ${
        darkMode ? "bg-gray-900" : "bg-white"
      }`}
    >
      <div className="max-w-7xl mx-auto">
        <div
          className={`rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm ${
            darkMode ? "bg-gray-800/50" : "bg-white"
          }`}
        >
          {/* Header Section */}
          <div
            className={`px-6 py-5 border-b ${
              darkMode ? "border-gray-700/50" : "border-gray-100"
            }`}
          >
            <h2
              className={`text-xl font-semibold tracking-tight ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Detailed Income Statement Breakdown
            </h2>
            <p
              className={`mt-1 text-sm font-medium ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Comprehensive analysis of all income statement components
            </p>
          </div>

          {/* Main Content */}
          <div className="p-4 sm:p-6">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 sm:gap-6">
              {sections.map((section, sectionIndex) => (
                <div
                  key={sectionIndex}
                  className={`rounded-xl p-4 sm:p-5 transition-colors ${
                    darkMode ? "bg-gray-800/50" : "bg-gray-50/80"
                  }`}
                >
                  <h3
                    className={`text-base font-semibold mb-4 px-3 ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {section.title}
                  </h3>
                  <div className="space-y-2">
                    {section.metrics.map((metric, index) => (
                      <MetricRow key={index} metric={metric} />
                    ))}
                  </div>

                  {/* Section Summary */}
                  {section.metrics.some((m) => m.isHighlight) && (
                    <div
                      className={`mt-4 pt-4 border-t ${
                        darkMode ? "border-gray-700/50" : "border-gray-200/70"
                      }`}
                    >
                      {section.metrics
                        .filter((m) => m.isHighlight)
                        .map((metric, idx) => (
                          <MetricRow
                            key={`summary-${idx}`}
                            metric={{
                              ...metric,
                              name: `Total ${metric.name}`,
                            }}
                            showBorder={false}
                          />
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Period Information */}
            <div
              className={`mt-6 p-4 sm:p-5 rounded-xl transition-colors ${
                darkMode ? "bg-gray-800/50" : "bg-gray-50/80"
              }`}
            >
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
                  <span
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Current Period
                  </span>
                  <span
                    className={`text-sm font-semibold ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {new Date(currentData.date).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>
                {previousData && (
                  <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
                    <span
                      className={`text-sm font-medium ${
                        darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    >
                      Previous Period
                    </span>
                    <span
                      className={`text-sm font-semibold ${
                        darkMode ? "text-white" : "text-gray-900"
                      }`}
                    >
                      {new Date(previousData.date).toLocaleDateString(
                        undefined,
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        },
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeDetailedBreakdown;
