import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  FaPlay,
  FaRocket,
  FaInfoCircle,
  FaHandshake,
  FaUsers,
} from "react-icons/fa";
import NavButton from "../menu/NavButton";

function HeroSection({ id }) {
  const { darkMode } = useTheme();

  return (
    <section
      id={id}
      className={`min-h-screen flex items-center justify-center ${
        darkMode
          ? "bg-gray-900 text-white"
          : "bg-gradient-to-br from-green-50 to-blue-100 text-gray-800"
      }`}
    >
      <div className="container mx-auto px-4 py-16 mt-10 flex flex-col lg:flex-row items-center lg:justify-between">
        <div className="lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold mb-6 lg:mb-8 leading-tight">
            Unlock the Secret to <br className="hidden lg:inline-block" />
            <span
              className={`${darkMode ? "text-green-400" : "text-green-600"}`}
            >
              Transform Your Financial Future
            </span>
          </h1>
          <p className="mb-6 lg:mb-8 text-base sm:text-lg lg:text-xl leading-relaxed max-w-xl lg:max-w-2xl">
            Discover the powerful and mysterious techniques behind the Quantum
            Money Code. This groundbreaking program delves deep into the science
            of quantum energy, revealing how to align your thoughts and
            intentions with the frequency of abundance. You'll learn to unlock
            the hidden forces of the universe, transforming your financial
            reality and attracting wealth and prosperity like never before.
          </p>
          <div className="flex flex-col sm:flex-row justify-center lg:justify-start items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <NavButton
              to="cta-section"
              className="text-center"
              icon={FaRocket}
              isScrollNavigation={true}
            >
              Start Now
            </NavButton>
            <NavButton className="text-center" to="/about" icon={FaInfoCircle}>
              Learn More
            </NavButton>
            <NavButton
              to="/affiliates"
              icon={FaHandshake}
              className="text-yellow-600 hover:text-yellow-600 border-yellow-600 text-center"
              iconProps={{
                className: `${
                  darkMode ? "text-white" : "text-yellow-600"
                } text-2xl mr-2`,
              }}
              underlineStyleClass="bg-yellow-600"
            >
              Affiliates
            </NavButton>
            <NavButton
              to="/forum/login"
              icon={FaUsers}
              className="text-green-600 hover:text-green-600 border-green-600 text-center"
              iconProps={{
                className: `${
                  darkMode ? "text-white" : "text-green-600"
                } text-2xl mr-2`,
              }}
              underlineStyleClass="bg-green-400"
            >
              Join the Community
            </NavButton>
          </div>
        </div>
        <div className="lg:w-1/2 mt-10 lg:mt-0 w-full lg:ml-10">
          <div className="relative w-full h-0 pb-[56.25%] rounded-2xl shadow-2xl overflow-hidden">
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/k3BKwS4efxk?si=FXhczQ_u644tU7Op"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
              <div className="text-white text-center">
                <FaPlay className="mx-auto text-4xl mb-2 animate-bounce" />
                <p className="font-semibold">Watch the Video</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
