import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import useCreateEditDeletePost from "../../../hooks/useCreateEditDeletePost";
import { useTheme } from "../../../contexts/ThemeContext";
import CreateEditPostForm from "./CreateEditPostForm";
import CreateEditPostImageUpload from "./CreateEditPostImageUpload";
import CreateEditPostActionButtons from "./CreateEditPostActionButtons";

const hasVideoContent = (content) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const tiktokRegex =
    /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/|v\/|)(\d+)/;
  return youtubeRegex.test(content) || tiktokRegex.test(content);
};

const CreateEditPost = ({ user }) => {
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const { createEditPost, error, isLoading, postData, isEditMode, fetchPost } =
    useCreateEditDeletePost(id);
  const [images, setImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(true);

  useEffect(() => {
    if (id) {
      fetchPost(id);
    }
  }, [id, fetchPost]);

  useEffect(() => {
    if (isEditMode && postData) {
      const hasVideo = hasVideoContent(postData.content);
      setShowImageUploader(!hasVideo);
      if (postData.imageUrls && !hasVideo) {
        setImages(
          postData.imageUrls.map((url) => ({ preview: url, isExisting: true })),
        );
        setRemovedImages([]);
      } else {
        setImages([]);
        setRemovedImages(postData.imageUrls || []);
      }
    }
  }, [isEditMode, postData]);

  const handleSubmit = useCallback(
    async (formFields) => {
      const formData = new FormData();

      Object.keys(formFields).forEach((key) => {
        formData.append(key, formFields[key]);
      });

      images.forEach((image) => {
        if (image instanceof File) {
          formData.append("images", image);
        } else if (image.isExisting) {
          formData.append("existingImages", image.preview);
        }
      });

      removedImages.forEach((removedImage) => {
        formData.append("removedImages", removedImage);
      });

      const success = await createEditPost(formData);
      if (success) {
        navigate("/forum");
      }
    },
    [images, removedImages, createEditPost, navigate],
  );

  const handleImagesChange = useCallback((newImages, newRemovedImages) => {
    setImages(newImages);
    setRemovedImages((prevRemovedImages) => [
      ...prevRemovedImages,
      ...newRemovedImages,
    ]);
  }, []);

  const handleContentChange = useCallback((content) => {
    const hasVideo = hasVideoContent(content);
    setShowImageUploader(!hasVideo);
    if (hasVideo) {
      setImages((prevImages) => {
        const newRemovedImages = prevImages
          .filter((img) => img.isExisting)
          .map((img) => img.preview);
        setRemovedImages((prev) => [...prev, ...newRemovedImages]);
        return [];
      });
    }
  }, []);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const memoizedCreateEditPostForm = useMemo(
    () => (
      <CreateEditPostForm
        onSubmit={handleSubmit}
        darkMode={darkMode}
        initialData={postData}
        onContentChange={handleContentChange}
      >
        {(formState, handleChange, isFormValid) => (
          <>
            {showImageUploader && (
              <CreateEditPostImageUpload
                darkMode={darkMode}
                onImagesChange={handleImagesChange}
                initialImages={images}
              />
            )}
            <CreateEditPostActionButtons
              darkMode={darkMode}
              isLoading={isLoading}
              isFormValid={isFormValid}
              onCancel={handleCancel}
              isEditMode={isEditMode}
            />
          </>
        )}
      </CreateEditPostForm>
    ),
    [
      handleSubmit,
      darkMode,
      postData,
      handleContentChange,
      showImageUploader,
      handleImagesChange,
      images,
      isLoading,
      handleCancel,
      isEditMode,
    ],
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-2xl mx-auto p-4 sm:p-6"
    >
      <div className={`rounded-lg overflow-hidden shadow-lg`}>
        <div className="p-5">
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className={`mb-6 p-4 rounded-lg flex items-center ${
                  darkMode
                    ? "bg-red-900 text-red-200"
                    : "bg-red-100 text-red-700"
                }`}
              >
                <span>{error}</span>
              </motion.div>
            )}
          </AnimatePresence>

          {memoizedCreateEditPostForm}
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(CreateEditPost);
