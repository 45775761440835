import React from "react";
import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";

const LoadingMessage = ({ darkMode }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className={`flex flex-col items-center justify-center p-12 rounded-xl ${
      darkMode ? "bg-gray-800/50" : "bg-white/50"
    } shadow-lg backdrop-blur-sm`}
  >
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
    >
      <motion.div
        animate={{
          scale: [1, 1.2, 1],
          rotate: [0, 360],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.5, 1],
          repeat: Infinity,
        }}
      >
        <Loader2
          size={64}
          className={`mb-6 ${darkMode ? "text-green-400" : "text-green-600"}`}
        />
      </motion.div>
    </motion.div>
    <motion.h3
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, y: [0, -5, 0] }}
      transition={{
        delay: 0.4,
        duration: 2,
        y: {
          repeat: Infinity,
          duration: 2,
          ease: "easeInOut",
        },
      }}
      className={`text-2xl font-bold mb-4 ${darkMode ? "text-green-400" : "text-green-600"}`}
    >
      Loading conversation...
    </motion.h3>
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.6, duration: 0.5 }}
      className={`text-lg text-center max-w-md ${darkMode ? "text-green-300" : "text-green-600"}`}
    >
      <motion.span
        animate={{ opacity: [1, 0.5, 1] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        Please wait while we fetch the latest quantum insights
      </motion.span>{" "}
      <motion.span
        animate={{ opacity: [0.5, 1, 0.5] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        and mind-bending discussions.
      </motion.span>
    </motion.p>
  </motion.div>
);

export default LoadingMessage;
