import React, { useState, useEffect } from "react";

const VIDEO_TYPES = {
  YOUTUBE: "youtube",
  TIKTOK: "tiktok",
};

const PATTERNS = {
  [VIDEO_TYPES.YOUTUBE]:
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  [VIDEO_TYPES.TIKTOK]:
    /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/|v\/|)(\d+)/,
};

const extractVideoId = (url, type) => {
  const match = url.match(PATTERNS[type]);
  return match ? match[1] : null;
};

const VideoPreview = ({ type, videoId }) => {
  const embedUrls = {
    [VIDEO_TYPES.YOUTUBE]: `https://www.youtube.com/embed/${videoId}`,
    [VIDEO_TYPES.TIKTOK]: `https://www.tiktok.com/embed/v2/${videoId}`,
  };

  return (
    <div className="video-preview mt-4 max-w-3xl mx-auto">
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          className="w-full h-full"
          src={embedUrls[type]}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`${type.charAt(0).toUpperCase() + type.slice(1)} Video`}
        />
      </div>
    </div>
  );
};

const VideoEmbed = ({ content }) => {
  const [videoType, setVideoType] = useState(null);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const youtubeId = extractVideoId(content, VIDEO_TYPES.YOUTUBE);
    const tiktokId = extractVideoId(content, VIDEO_TYPES.TIKTOK);

    if (youtubeId) {
      setVideoType(VIDEO_TYPES.YOUTUBE);
      setVideoId(youtubeId);
    } else if (tiktokId) {
      setVideoType(VIDEO_TYPES.TIKTOK);
      setVideoId(tiktokId);
    } else {
      setVideoType(null);
      setVideoId(null);
    }
  }, [content]);

  if (videoType && videoId) {
    return <VideoPreview type={videoType} videoId={videoId} />;
  }

  return null;
};

export default VideoEmbed;
