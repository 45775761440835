import React from "react";
import {
  CalendarDays,
  DollarSign,
  Percent,
  ArrowDownUp,
  CalendarClock,
  History,
  BarChart3,
  Clock,
} from "lucide-react";

const SplitsDividends = ({
  data,
  darkMode,
  formatNumber,
  formatPercent,
  formatDate,
}) => {
  if (!data) return null;

  const dividendHistory = Object.values(data.NumberDividendsByYear || {}).sort(
    (a, b) => b.Year - a.Year,
  );

  // Utility functions for styling
  const getBackgroundStyle = () => {
    return darkMode
      ? "bg-slate-800 shadow-lg shadow-slate-900/20"
      : "bg-white shadow-lg shadow-slate-200/50";
  };

  const getTextColor = () => {
    return darkMode ? "text-slate-200" : "text-slate-700";
  };

  const MetricCard = ({ title, value, subtitle, icon: Icon }) => (
    <div
      className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
        darkMode ? "border border-slate-700" : "border border-slate-100"
      }`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <Icon className="w-6 h-6 text-blue-500" />
            <h3
              className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
            >
              {title}
            </h3>
          </div>
          <div className="space-y-2">
            <p
              className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              {value}
            </p>
            {subtitle && (
              <p
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const TimelineYear = ({ year, count }) => (
    <div
      className={`rounded-xl ${
        darkMode ? "bg-slate-700" : "bg-slate-50"
      } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
    >
      <div className="p-4 sm:p-5">
        <div className="flex items-center justify-between">
          <div
            className={`text-base font-semibold ${
              darkMode ? "text-white" : "text-slate-900"
            }`}
          >
            {year}
          </div>
          <div
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              darkMode
                ? "bg-slate-700 text-slate-300"
                : "bg-slate-100 text-slate-700"
            }`}
          >
            {count} payment{count !== 1 ? "s" : ""}
          </div>
        </div>
        <div className="mt-4 flex items-center gap-2">
          {Array.from({ length: count }).map((_, index) => (
            <div
              key={index}
              className={`w-4 h-4 rounded-full ${
                darkMode ? "bg-blue-500" : "bg-blue-600"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );

  const SectionWrapper = ({ title, icon: Icon, children }) => (
    <div
      className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:shadow-xl ${
        darkMode ? "border border-slate-700" : "border border-slate-100"
      }`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-6">
          <div className="flex items-center space-x-3">
            <Icon className="w-6 h-6 text-blue-500" />
            <h3 className={`text-lg font-semibold ${getTextColor()}`}>
              {title}
            </h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );

  const DateBox = ({ title, date, description, icon: Icon }) => (
    <div
      className={`rounded-xl ${
        darkMode ? "bg-slate-700" : "bg-slate-50"
      } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-4">
          <div
            className={`text-sm font-medium ${
              darkMode ? "text-slate-400" : "text-slate-600"
            }`}
          >
            {title}
          </div>
          <div className="flex items-center space-x-3">
            <div
              className={`p-2 rounded-lg ${
                darkMode ? "bg-slate-700" : "bg-slate-100"
              }`}
            >
              <Icon className="w-6 h-6 text-blue-500" />
            </div>
            <span
              className={`text-xl font-bold tracking-tight ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              {formatDate(date)}
            </span>
          </div>
          <div
            className={`text-sm font-medium ${
              darkMode ? "text-slate-400" : "text-slate-600"
            }`}
          >
            {description}
          </div>
        </div>
      </div>
    </div>
  );

  const StatBox = ({ title, value }) => (
    <div
      className={`rounded-xl ${
        darkMode ? "bg-slate-700" : "bg-slate-50"
      } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-2">
          <div
            className={`text-sm font-medium ${
              darkMode ? "text-slate-400" : "text-slate-600"
            }`}
          >
            {title}
          </div>
          <div
            className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
              darkMode ? "text-white" : "text-slate-900"
            }`}
          >
            {value}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Metrics Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        <MetricCard
          title="Forward Annual Rate"
          value={`$${formatNumber(data.ForwardAnnualDividendRate)}`}
          subtitle="Per Share"
          icon={DollarSign}
        />
        <MetricCard
          title="Dividend Yield"
          value={formatPercent(data.ForwardAnnualDividendYield)}
          subtitle="Forward"
          icon={Percent}
        />
        <MetricCard
          title="Payout Ratio"
          value={formatPercent(data.PayoutRatio)}
          subtitle="Of Earnings"
          icon={BarChart3}
        />
        <MetricCard
          title="Last Split"
          value={data.LastSplitFactor}
          subtitle={formatDate(data.LastSplitDate)}
          icon={ArrowDownUp}
        />
      </div>

      {/* Next Dividend Section */}
      <SectionWrapper title="Next Dividend" icon={CalendarClock}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <DateBox
            title="Ex-Dividend Date"
            date={data.ExDividendDate}
            description="Last day to buy shares to receive the dividend"
            icon={CalendarDays}
          />
          <DateBox
            title="Payment Date"
            date={data.DividendDate}
            description="Date when dividend will be paid to shareholders"
            icon={Clock}
          />
        </div>
      </SectionWrapper>

      {/* Dividend History Section */}
      <SectionWrapper title="Dividend History" icon={History}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {dividendHistory.slice(0, 9).map((yearData) => (
            <TimelineYear
              key={yearData.Year}
              year={yearData.Year}
              count={yearData.Count}
            />
          ))}
        </div>

        {dividendHistory.length > 9 && (
          <div
            className={`mt-4 px-4 py-3 rounded-lg text-sm font-medium inline-block ${
              darkMode
                ? "bg-slate-700 text-slate-300"
                : "bg-slate-100 text-slate-600"
            }`}
          >
            + {dividendHistory.length - 9} more years of dividend history
          </div>
        )}

        <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <StatBox
            title="Average Annual Payments"
            value={formatNumber(
              dividendHistory.reduce((sum, year) => sum + year.Count, 0) /
                dividendHistory.length,
            )}
          />
          <StatBox title="Years of History" value={dividendHistory.length} />
          <StatBox
            title="Total Payments"
            value={dividendHistory.reduce((sum, year) => sum + year.Count, 0)}
          />
        </div>
      </SectionWrapper>
    </div>
  );
};

export default SplitsDividends;
