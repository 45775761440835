import { useState } from "react";

export const tradeConditions = {
  0: {
    name: "Regular Sale",
    description:
      "A trade made without stated conditions, settled on the third business day after the transaction date.",
    icon: "CheckCircle",
  },
  1: {
    name: "Acquisition",
    description:
      "A transaction made on the Exchange as a result of an Exchange acquisition.",
    icon: "ShoppingCart",
  },
  2: {
    name: "Average Price Trade",
    description:
      "A trade where the reported price is based on an average of the prices for all or part of the trading day's transactions in a security.",
    icon: "TrendingUp",
  },
  3: {
    name: "Automatic Execution",
    description:
      "A NYSE trade executed automatically without potential price improvement.",
    icon: "FastForward",
  },
  4: {
    name: "Bunched Trade",
    description:
      "Multiple odd-lot or round-lot orders for the same security combined and executed at once, with client agreement.",
    icon: "Layers",
  },
  5: {
    name: "Bunched Sold Trade",
    description: "A bunched trade that isn't reported until after the fact.",
    icon: "Clock",
  },
  7: {
    name: "Cash Sale",
    description:
      "A trade where securities must be delivered and payment made on the same day.",
    icon: "DollarSign",
  },
  8: {
    name: "Closing Prints",
    description:
      "The last qualifying trade paid for a security by a Participant during the trading day.",
    icon: "LogOut",
  },
  9: {
    name: "Cross Trade",
    description: "A trade resulting from a Market Center crossing session.",
    icon: "Shuffle",
  },
  10: {
    name: "Derivatively Priced",
    description:
      "A trade-through transaction where the price was not based on the quoted price at execution time, and material terms were not determinable when committed.",
    icon: "Link",
  },
  11: {
    name: "Distribution",
    description:
      "Large blocks of a security sold into the market in smaller blocks over time to lower its price.",
    icon: "Share2",
  },
  12: {
    name: "Form T",
    description:
      "An extended hours trade executed outside of regular primary market hours.",
    icon: "Sunrise",
  },
  13: {
    name: "Extended Trading Hours (Sold Out of Sequence)",
    description:
      "A trade occurring outside regular market hours, reported out of sequence and at a time other than the actual transaction time.",
    icon: "Moon",
  },
  14: {
    name: "Intermarket Sweep",
    description:
      "The execution of an Intermarket Sweep Order, a type of trade-through transaction.",
    icon: "ArrowRightCircle",
  },
  15: {
    name: "Market Center Official Close",
    description:
      "The 'Official' closing value as determined by a Market Center.",
    icon: "LogOut",
  },
  16: {
    name: "Market Center Official Open",
    description:
      "The 'Official' opening value as determined by a Market Center.",
    icon: "LogIn",
  },
  17: {
    name: "Market Center Opening Trade",
    description: "A single-priced opening transaction by the Market Center.",
    icon: "PlayCircle",
  },
  18: {
    name: "Market Center Reopening Trade",
    description: "A single-priced reopening transaction by the Market Center.",
    icon: "RotateCw",
  },
  19: {
    name: "Market Center Closing Trade",
    description:
      "A single-priced closing transaction executed by the Market Center.",
    icon: "StopCircle",
  },
  20: {
    name: "Next Day",
    description:
      "A transaction where securities must be delivered on the first business day after the trade date.",
    icon: "Calendar",
  },
  21: {
    name: "Price Variation Trade",
    description:
      "A regular market session trade with a price significantly different from the current consolidated or primary market value.",
    icon: "Activity",
  },
  22: {
    name: "Prior Reference Price",
    description:
      "A trade based on a price from a previous point in time, executed at the same time as the previous reference price.",
    icon: "Rewind",
  },
  23: {
    name: "Rule 155 Trade (AMEX)",
    description:
      "A Seller's Option transaction giving the seller the option to deliver the security within a specified time period.",
    icon: "Shield",
  },
  24: {
    name: "Rule 127 NYSE",
    description:
      "A trade executed outside of the current quote, meeting specific conditions related to market capitalization or volume.",
    icon: "Globe",
  },
  25: {
    name: "Opening Prints",
    description:
      "The first drawings of a symbol's candlestick charts on a trading day.",
    icon: "BarChart",
  },
  27: {
    name: "Stopped Stock (Regular Trade)",
    description: "A type of Seller's Option transaction.",
    icon: "StopCircle",
  },
  28: {
    name: "Re-Opening Prints",
    description:
      "Transactions reported by the Market Center as a result of a single-priced re-opening event.",
    icon: "RotateCw",
  },
  29: {
    name: "Seller",
    description:
      "A Seller's Option transaction giving the seller the option to deliver the security within a specified time period.",
    icon: "UserMinus",
  },
  30: {
    name: "Sold Last",
    description:
      "Used when a trade prints in sequence but is reported late, or when Amex prints in accordance with specific rules.",
    icon: "Clock",
  },
  33: {
    name: "Sold (out of Sequence)",
    description:
      "Used when a trade is reported out of sequence and at a time other than the actual transaction time.",
    icon: "Shuffle",
  },
  34: {
    name: "Split Trade",
    description:
      "A two-market execution where a specialist or Market Maker agrees to execute a portion of an order at a price realized in another market.",
    icon: "DivideCircle",
  },
  35: {
    name: "Stock Option Trade",
    description:
      "Typically the stock portion of a delta neutral option trade executed by an option market maker.",
    icon: "Sliders",
  },
  36: {
    name: "Yellow Flag Regular Trade",
    description:
      "Identifies regular trades being reported during specific events as out of the ordinary.",
    icon: "Flag",
  },
  37: {
    name: "Odd Lot Trade",
    description:
      "A trade executed in increments smaller than the defined round lot size by a market centre.",
    icon: "MinusCircle",
  },
  38: {
    name: "Corrected Consolidated Close",
    description:
      "A transaction executed by the Listing Market to determine the official Consolidated Last Price.",
    icon: "Edit",
  },
  40: {
    name: "Held",
    description:
      "Trades received from a non-primary participant during a primary market regulatory halt, held and disseminated after market close.",
    icon: "PauseCircle",
  },
  41: {
    name: "Trade Thru Exempt",
    description:
      "A trade exempt from the Trade Through rule, which can prevent a broker from meeting their 'best execution' obligation in certain situations.",
    icon: "ArrowUpRight",
  },
  46: {
    name: "Contingent Trade",
    description:
      "Indicates a change to the previous indication or a new indication, often used in relation to delayed openings or trading halts.",
    icon: "Link",
  },
  52: {
    name: "Contingent Trade",
    description:
      "Identifies a transaction whose completion is contingent on the occurrence of a specific event.",
    icon: "Link",
  },
  53: {
    name: "Qualified Contingent Trade (QCT)",
    description:
      "A trade where the execution of one component is contingent on the execution of all other components at or near the same time.",
    icon: "Layers",
  },
  59: {
    name: "Placeholder for 611 exempt",
    description:
      "A placeholder for trades exempt under SEC Regulation NMS, Rule 611, often involving multi-component trades or related securities.",
    icon: "File",
  },
  60: {
    name: "SSR in Effect",
    description:
      "Indicates that the Short Sale Restriction is in effect for a stock that has dropped more than 10% intraday.",
    icon: "TrendingDown",
  },
};

export const useTradeConditionData = (initialData) => {
  const [tradeData, setTradeData] = useState(initialData || []);
  const [filterText, setFilterText] = useState("");

  const updateTradeData = (newData) => {
    setTradeData(newData);
  };

  const updateFilterText = (text) => {
    setFilterText(text);
  };

  return {
    tradeData,
    updateTradeData,
    tradeConditions,
    filterText,
    updateFilterText,
  };
};
