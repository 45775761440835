import React from "react";
import {
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryBar,
  VictoryStack,
  VictoryLegend,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from "victory";
import { BarChart3, Info, TrendingUp } from "lucide-react";
import { formatCurrency } from "./FormatHelpers";

const AssetCompositionChart = ({ data = [], darkMode }) => {
  const baseTheme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        grid: {
          stroke: darkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)",
        },
        tickLabels: {
          fill: darkMode ? "#fff" : "#000",
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
  };

  const metrics = [
    { key: "cash", name: "Cash", color: "#10B981", icon: "💰" },
    {
      key: "netReceivables",
      name: "Receivables",
      color: "#3B82F6",
      icon: "📊",
    },
    { key: "inventory", name: "Inventory", color: "#F59E0B", icon: "📦" },
    {
      key: "propertyPlantEquipment",
      name: "PP&E",
      color: "#8B5CF6",
      icon: "🏭",
    },
    {
      key: "longTermInvestments",
      name: "Investments",
      color: "#EF4444",
      icon: "📈",
    },
  ];

  // Ensure all data points have values (default to 0 if missing)
  const processedData = (data || []).map((d) => {
    const processed = { ...d };
    metrics.forEach((metric) => {
      processed[metric.key] = Number(d[metric.key]) || 0;
    });
    return processed;
  });

  const calculatePercentage = (value, stackData) => {
    if (!stackData) return "0.0";
    const total = metrics.reduce((sum, metric) => {
      const metricValue = Number(stackData[metric.key]) || 0;
      return sum + metricValue;
    }, 0);
    return total > 0 ? ((value / total) * 100).toFixed(1) : "0.0";
  };

  const getLatestValues = () => {
    if (!processedData.length) return {};
    return processedData[processedData.length - 1];
  };

  return (
    <div
      className={`w-full rounded-2xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl ${
        darkMode
          ? "bg-gray-900 border border-gray-800"
          : "bg-white border border-gray-100"
      }`}
    >
      {/* Enhanced Header */}
      <div
        className={`border-b ${darkMode ? "border-gray-800" : "border-gray-100"}`}
      >
        <div className="p-2 space-y-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="flex items-start sm:items-center gap-4">
              <div
                className={`p-3 rounded-xl ${
                  darkMode
                    ? "bg-blue-500/10 text-blue-400"
                    : "bg-blue-50 text-blue-500"
                }`}
              >
                <BarChart3 className="w-6 h-6" />
              </div>
              <div>
                <h3
                  className={`text-xl font-bold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Asset Composition
                </h3>
                <p
                  className={`mt-1 text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Comprehensive breakdown of assets over time
                </p>
              </div>
            </div>

            <div
              className={`flex items-center gap-2 px-4 py-2.5 rounded-xl text-sm font-medium transition-colors duration-200 ${
                darkMode
                  ? "bg-gray-800 text-gray-300 hover:bg-gray-700"
                  : "bg-gray-50 text-gray-600 hover:bg-gray-100"
              }`}
            >
              <Info className="w-4 h-4" />
              <span>Interactive Chart</span>
            </div>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="p-6">
        <div className="h-96 md:h-[500px] lg:h-[600px]">
          <VictoryChart
            theme={baseTheme}
            padding={{ top: 50, right: 50, bottom: 50, left: 70 }}
            scale={{ x: "time" }}
            domainPadding={{ x: 0 }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => {
                  if (!datum || typeof datum.y === "undefined") return "";

                  const value = formatCurrency(datum.y);
                  const date = datum.x
                    ? new Date(datum.x).toLocaleDateString()
                    : "Unknown Date";
                  const percentage = calculatePercentage(datum.y, datum._stack);

                  return [
                    datum.metricName || "Unknown",
                    `Value: ${value}`,
                    `Date: ${date}`,
                    `% of Total Assets: ${percentage}%`,
                  ].join("\n");
                }}
                labelComponent={
                  <VictoryTooltip
                    style={{
                      fontSize: 12,
                      fill: darkMode ? "white" : "black",
                    }}
                    flyoutStyle={{
                      fill: darkMode ? "rgba(17,24,39,0.95)" : "white",
                      stroke: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                      strokeWidth: 1,
                      filter: "drop-shadow(0 4px 6px rgb(0 0 0 / 0.1))",
                    }}
                    cornerRadius={8}
                    flyoutPadding={{ top: 12, bottom: 12, left: 16, right: 16 }}
                  />
                }
              />
            }
          >
            <VictoryLegend
              x={50}
              y={0}
              orientation="horizontal"
              gutter={20}
              itemsPerRow={3}
              style={{
                labels: {
                  fontSize: 12,
                  fill: darkMode ? "#fff" : "#000",
                },
                data: { opacity: 0.8 },
              }}
              data={metrics.map((metric) => ({
                name: metric.name,
                symbol: { fill: metric.color },
              }))}
            />
            <VictoryAxis
              tickFormat={(date) => {
                if (!date) return "";
                return new Date(date).getFullYear();
              }}
              tickCount={4}
              style={{
                tickLabels: {
                  fontSize: 12,
                  padding: 8,
                },
                grid: { stroke: "none" },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={formatCurrency}
              style={{
                tickLabels: {
                  fontSize: 12,
                  padding: 8,
                },
                grid: { strokeWidth: 0.5, strokeDasharray: "4, 4" },
              }}
            />
            <VictoryStack>
              {metrics.map((metric) => (
                <VictoryBar
                  key={metric.key}
                  data={processedData.map((d) => ({
                    x: new Date(d.date),
                    y: Number(d[metric.key]) || 0,
                    metricName: metric.name,
                    metricKey: metric.key,
                    _stack: d,
                  }))}
                  style={{
                    data: {
                      fill: metric.color,
                    },
                  }}
                  barWidth={({ scale }) => {
                    const range = scale.x.range();
                    return Math.max(
                      1,
                      (range[1] - range[0]) / processedData.length - 1,
                    );
                  }}
                />
              ))}
            </VictoryStack>
          </VictoryChart>
        </div>

        {/* Enhanced Legend Summary */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-8">
          {metrics.map((metric) => {
            const latestValues = getLatestValues();
            const currentValue = Number(latestValues[metric.key]) || 0;
            const percentage = calculatePercentage(currentValue, latestValues);

            return (
              <div
                key={metric.key}
                className={`relative flex flex-col p-4 rounded-xl transition-all duration-300 hover:scale-102 hover:shadow-md ${
                  darkMode ? "bg-gray-800/50" : "bg-gray-50/50"
                }`}
                style={{
                  borderLeft: `4px solid ${metric.color}`,
                }}
              >
                <div className="flex items-center justify-between mb-2">
                  <span
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    {metric.icon} {metric.name}
                  </span>
                  <span
                    className={`text-xs px-2 py-1 rounded-full ${
                      darkMode
                        ? "bg-gray-700 text-gray-300"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {percentage}%
                  </span>
                </div>
                <span
                  className={`text-lg font-bold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                  style={{ color: metric.color }}
                >
                  {formatCurrency(currentValue)}
                </span>
                <div
                  className={`absolute bottom-0 left-0 h-1 transition-all duration-300`}
                  style={{
                    width: `${percentage}%`,
                    backgroundColor: metric.color,
                    opacity: 0.3,
                    borderRadius: "0 4px 0 0",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AssetCompositionChart;
