import { useState, useEffect } from "react";
import { getPremiumApiUrl } from "../config/api";

export function useFundamentals(symbol, exchangeCode) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFundamentals = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${getPremiumApiUrl()}/api/fundamentals/${symbol}.${exchangeCode}`,
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(new Error(err.message || "An error occurred"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (symbol && exchangeCode) {
      fetchFundamentals();
    }
  }, [symbol, exchangeCode]);

  return { data, isLoading, error };
}
