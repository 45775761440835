import React, { useState } from "react";
import { ThumbsUp, MessageSquare, Share2 } from "lucide-react";
import useSharePost from "../../hooks/useSharePost";
import SocialShareDialog from "./post-item/SocialShareDialog";

const PostActions = ({
  post,
  handleLike,
  darkMode,
  likeCount,
  commentCount,
}) => {
  const { sharePost, shareCount, loading } = useSharePost(post.shareCount);
  const [isSocialShareDialogOpen, setIsSocialShareDialogOpen] = useState(false);

  const handleShareClick = async () => {
    const shareUrl = `${window.location.origin}/post/${post.id}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: post.title,
          text: post.content.substring(0, 100) + "...",
          url: shareUrl,
        });
        await sharePost(post.id);
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      setIsSocialShareDialogOpen(true);
    }
  };

  const handleCloseSocialShareDialog = async () => {
    setIsSocialShareDialogOpen(false);
    await sharePost(post.id);
  };

  const ActionButton = ({ onClick, active, icon: Icon, count, label }) => (
    <button
      onClick={onClick}
      className={`flex items-center justify-center px-2 py-1 rounded-md transition-all duration-300 ease-in-out ${
        darkMode
          ? active
            ? "text-green-400 hover:text-green-300"
            : "text-gray-200 hover:text-gray-100"
          : active
            ? "text-green-600 hover:text-green-500"
            : "text-gray-700 hover:text-gray-600"
      } hover:scale-110`}
    >
      <Icon
        size={18}
        className={`mr-1 ${active ? "fill-current" : ""} transition-transform duration-300 ease-in-out group-hover:scale-110`}
      />
      <span className="text-sm font-semibold">
        {count > 0 ? `${count} ${count === 1 ? label : label + "s"}` : label}
      </span>
    </button>
  );

  return (
    <>
      <div className="flex justify-between items-center mb-6 px-2 py-3">
        <ActionButton
          onClick={handleLike}
          active={likeCount > 0}
          icon={ThumbsUp}
          count={likeCount}
          label="Like"
        />
        <ActionButton
          onClick={() => {}}
          active={false}
          icon={MessageSquare}
          count={commentCount}
          label="Comment"
        />
        <ActionButton
          onClick={handleShareClick}
          active={false}
          icon={Share2}
          count={shareCount}
          label="Share"
        />
      </div>

      <SocialShareDialog
        isOpen={isSocialShareDialogOpen}
        onClose={handleCloseSocialShareDialog}
        url={`${window.location.origin}/post/${post.id}`}
        title={post.title}
        darkMode={darkMode}
      />
    </>
  );
};

export default PostActions;
