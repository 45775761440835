import React from "react";
import { motion } from "framer-motion";
import { BarChart3, Info } from "lucide-react";

const LoadingSkeleton = ({ darkMode = false }) => {
  const shimmerVariants = {
    initial: {
      backgroundPosition: "-500px 0",
    },
    animate: {
      backgroundPosition: "500px 0",
      transition: {
        repeat: Infinity,
        duration: 1.5,
        ease: "linear",
      },
    },
  };

  const baseShimmerStyle = {
    background: darkMode
      ? "linear-gradient(90deg, #1F2937 0%, #374151 50%, #1F2937 100%)"
      : "linear-gradient(90deg, #f3f4f6 0%, #e5e7eb 50%, #f3f4f6 100%)",
    backgroundSize: "1000px 100%",
  };

  return (
    <div
      className={`w-full rounded-xl overflow-hidden ${darkMode ? "bg-gray-800" : "bg-white"}`}
    >
      {/* Header */}
      <div className="border-b border-gray-200 border-opacity-10">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 gap-4">
          <div className="flex items-start sm:items-center gap-3 w-full sm:w-auto">
            <div
              className={`p-2 rounded-lg ${darkMode ? "bg-blue-500/10" : "bg-blue-50"}`}
            >
              <BarChart3
                className={`w-5 h-5 ${darkMode ? "text-blue-400" : "text-blue-500"}`}
              />
            </div>
            <div className="space-y-2 flex-grow">
              <motion.div
                variants={shimmerVariants}
                initial="initial"
                animate="animate"
                style={baseShimmerStyle}
                className="h-6 w-40 rounded"
              />
              <motion.div
                variants={shimmerVariants}
                initial="initial"
                animate="animate"
                style={baseShimmerStyle}
                className="h-4 w-56 rounded"
              />
            </div>
          </div>

          {/* Info badge */}
          <div
            className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm ${darkMode ? "bg-gray-700/50" : "bg-gray-50"} self-start sm:self-center`}
          >
            <Info className="w-4 h-4" />
            <span>Hover for details</span>
          </div>
        </div>
      </div>

      {/* Chart Area */}
      <div className="p-2 sm:p-2 space-y-6">
        <div className="space-y-4">
          {/* Legend placeholders */}
          <div className="flex flex-wrap gap-4 justify-start">
            {[1, 2, 3].map((i) => (
              <motion.div
                key={i}
                variants={shimmerVariants}
                initial="initial"
                animate="animate"
                style={baseShimmerStyle}
                className="h-5 w-24 rounded"
              />
            ))}
          </div>

          {/* Chart placeholder */}
          <motion.div
            variants={shimmerVariants}
            initial="initial"
            animate="animate"
            style={baseShimmerStyle}
            className="h-[300px] sm:h-[400px] md:h-[600px] w-full rounded"
          />
        </div>

        {/* Bottom stats grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
          {[1, 2, 3, 4, 5].map((i) => (
            <div
              key={i}
              className={`p-4 rounded-xl ${
                darkMode ? "bg-gray-700/30" : "bg-gray-50"
              }`}
            >
              <motion.div
                variants={shimmerVariants}
                initial="initial"
                animate="animate"
                style={baseShimmerStyle}
                className="h-4 w-20 rounded mb-2"
              />
              <motion.div
                variants={shimmerVariants}
                initial="initial"
                animate="animate"
                style={baseShimmerStyle}
                className="h-6 w-32 rounded"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
