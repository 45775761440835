import React from "react";
import {
  ArrowUpRight,
  ArrowDownRight,
  Clock,
  Scale,
  BarChart2,
  TrendingUp,
  Info,
  Building,
  ArrowLeftRight,
} from "lucide-react";

const UsQuoteTradeConditionsComponent = ({ tradeData, darkMode }) => {
  if (!tradeData || tradeData.length === 0) return null;

  const latestQuote = tradeData[tradeData.length - 1];
  const previousQuote = tradeData[tradeData.length - 2];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  };

  const calculateSpread = () => {
    const spread = latestQuote?.ap - latestQuote?.bp;
    const spreadPercentage = (spread / latestQuote?.ap) * 100;
    return {
      absolute: formatNumber(spread),
      percentage: formatNumber(spreadPercentage),
    };
  };

  const { absolute: spreadAbsolute, percentage: spreadPercentage } =
    calculateSpread();
  const midPrice = (latestQuote?.ap + latestQuote?.bp) / 2;
  const totalVolume = latestQuote?.as + latestQuote?.bs;
  const askPercentage = (latestQuote?.as / totalVolume) * 100;
  const bidPercentage = (latestQuote?.bs / totalVolume) * 100;

  return (
    <div
      className={`w-full rounded-lg shadow-md overflow-hidden ${darkMode ? "bg-gray-900" : "bg-white"}`}
    >
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              className={`p-2 rounded-full ${darkMode ? "bg-blue-600" : "bg-blue-100"}`}
            >
              <Building
                className={`w-6 h-6 ${darkMode ? "text-white" : "text-blue-600"}`}
              />
            </div>
            <div>
              <h2
                className={`text-2xl font-semibold ${darkMode ? "text-white" : "text-gray-800"}`}
              >
                Market Depth
              </h2>
              <div className="flex items-center gap-2 mt-1">
                <span
                  className={`text-base ${darkMode ? "text-gray-300" : "text-gray-600"}`}
                >
                  {latestQuote?.s}
                </span>
                <span
                  className={`px-2 py-0.5 rounded-full text-sm font-medium ${darkMode ? "bg-gray-700 text-gray-300" : "bg-gray-100 text-gray-600"}`}
                >
                  Level II Data
                </span>
              </div>
            </div>
          </div>
          <div
            className={`flex items-center gap-2 mt-4 sm:mt-0 px-3 py-2 rounded-lg text-sm ${darkMode ? "bg-gray-800 text-gray-300" : "bg-gray-100 text-gray-600"}`}
          >
            <Clock className="w-5 h-5" />
            <span>Updated: {formatTimestamp(latestQuote?.t)}</span>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-6 space-y-8">
        {/* Order Book */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Ask Side */}
          <div
            className={`p-6 rounded-lg ${darkMode ? "bg-red-900" : "bg-red-50"} border-l-4 border-red-500`}
          >
            <div className="flex justify-between items-start mb-6">
              <div>
                <span
                  className={`text-base font-medium ${darkMode ? "text-gray-300" : "text-gray-600"}`}
                >
                  Ask Price
                </span>
                <div
                  className={`text-3xl font-bold mt-1 ${darkMode ? "text-red-400" : "text-red-600"}`}
                >
                  ${formatNumber(latestQuote?.ap)}
                </div>
              </div>
              <div
                className={`flex items-center gap-2 px-3 py-1.5 rounded-full text-sm ${darkMode ? "bg-red-800 text-red-300" : "bg-red-100 text-red-600"}`}
              >
                <ArrowUpRight className="w-5 h-5" />
                <span>{formatNumber(askPercentage)}%</span>
              </div>
            </div>
            <div>
              <div
                className={`text-base ${darkMode ? "text-gray-300" : "text-gray-600"}`}
              >
                Volume: {latestQuote?.as?.toLocaleString()}
              </div>
              <div
                className={`mt-2 h-3 w-full rounded-full overflow-hidden ${darkMode ? "bg-red-800" : "bg-red-200"}`}
              >
                <div
                  className={`h-full ${darkMode ? "bg-red-500" : "bg-red-600"}`}
                  style={{ width: `${askPercentage}%` }}
                />
              </div>
            </div>
          </div>

          {/* Bid Side */}
          <div
            className={`p-6 rounded-lg ${darkMode ? "bg-green-900" : "bg-green-50"} border-l-4 border-green-500`}
          >
            <div className="flex justify-between items-start mb-6">
              <div>
                <span
                  className={`text-base font-medium ${darkMode ? "text-gray-300" : "text-gray-600"}`}
                >
                  Bid Price
                </span>
                <div
                  className={`text-3xl font-bold mt-1 ${darkMode ? "text-green-400" : "text-green-600"}`}
                >
                  ${formatNumber(latestQuote?.bp)}
                </div>
              </div>
              <div
                className={`flex items-center gap-2 px-3 py-1.5 rounded-full text-sm ${darkMode ? "bg-green-800 text-green-300" : "bg-green-100 text-green-600"}`}
              >
                <ArrowDownRight className="w-5 h-5" />
                <span>{formatNumber(bidPercentage)}%</span>
              </div>
            </div>
            <div>
              <div
                className={`text-base ${darkMode ? "text-gray-300" : "text-gray-600"}`}
              >
                Volume: {latestQuote?.bs?.toLocaleString()}
              </div>
              <div
                className={`mt-2 h-3 w-full rounded-full overflow-hidden ${darkMode ? "bg-green-800" : "bg-green-200"}`}
              >
                <div
                  className={`h-full ${darkMode ? "bg-green-500" : "bg-green-600"}`}
                  style={{ width: `${bidPercentage}%` }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Market Statistics */}
        <div
          className={`p-6 rounded-lg ${darkMode ? "bg-gray-800" : "bg-gray-100"}`}
        >
          <h3
            className={`text-xl font-semibold mb-6 ${darkMode ? "text-white" : "text-gray-800"}`}
          >
            Market Statistics
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            {[
              {
                label: "Spread",
                value: `$${spreadAbsolute}`,
                subValue: `${spreadPercentage}%`,
                icon: Scale,
                color: "text-blue-500",
                bgColor: darkMode ? "bg-blue-800" : "bg-blue-100",
              },
              {
                label: "Mid Price",
                value: `$${formatNumber(midPrice)}`,
                icon: ArrowLeftRight,
                color: "text-purple-500",
                bgColor: darkMode ? "bg-purple-800" : "bg-purple-100",
              },
              {
                label: "Total Volume",
                value: totalVolume.toLocaleString(),
                icon: BarChart2,
                color: "text-indigo-500",
                bgColor: darkMode ? "bg-indigo-800" : "bg-indigo-100",
              },
              {
                label: "Market Status",
                value: "Active",
                icon: TrendingUp,
                color: "text-green-500",
                bgColor: darkMode ? "bg-green-800" : "bg-green-100",
              },
            ].map((stat, index) => (
              <div
                key={index}
                className={`flex items-center p-4 rounded-lg ${stat.bgColor}`}
              >
                <div className={`p-2 rounded-full ${stat.color} bg-opacity-20`}>
                  <stat.icon className={`w-6 h-6 ${stat.color}`} />
                </div>
                <div className="ml-4">
                  <p
                    className={`text-base font-medium ${darkMode ? "text-gray-300" : "text-gray-600"}`}
                  >
                    {stat.label}
                  </p>
                  <p
                    className={`text-lg font-semibold ${darkMode ? "text-white" : "text-gray-800"}`}
                  >
                    {stat.value}
                  </p>
                  {stat.subValue && (
                    <p
                      className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                    >
                      {stat.subValue}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Depth Visualization */}
        <div
          className={`p-6 rounded-lg ${darkMode ? "bg-gray-800" : "bg-gray-100"}`}
        >
          <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6">
            <h3
              className={`text-xl font-semibold ${darkMode ? "text-white" : "text-gray-800"}`}
            >
              Market Depth Visualization
            </h3>
            <span
              className={`text-base ${darkMode ? "text-gray-300" : "text-gray-600"}`}
            >
              Total Volume: {totalVolume.toLocaleString()}
            </span>
          </div>
          <div className="relative h-40">
            <div className="absolute inset-0 flex">
              {/* Bid Side */}
              <div
                className={`flex-1 relative ${darkMode ? "bg-green-700" : "bg-green-200"}`}
                style={{ width: `${bidPercentage}%` }}
              >
                <div className="absolute inset-0 flex flex-col justify-center items-center">
                  <p
                    className={`text-lg font-semibold ${darkMode ? "text-green-300" : "text-green-700"}`}
                  >
                    Bid Volume
                  </p>
                  <p
                    className={`text-base ${darkMode ? "text-green-200" : "text-green-600"}`}
                  >
                    {latestQuote?.bs?.toLocaleString()}
                  </p>
                </div>
              </div>
              {/* Ask Side */}
              <div
                className={`flex-1 relative ${darkMode ? "bg-red-700" : "bg-red-200"}`}
                style={{ width: `${askPercentage}%` }}
              >
                <div className="absolute inset-0 flex flex-col justify-center items-center">
                  <p
                    className={`text-lg font-semibold ${darkMode ? "text-red-300" : "text-red-700"}`}
                  >
                    Ask Volume
                  </p>
                  <p
                    className={`text-base ${darkMode ? "text-red-200" : "text-red-600"}`}
                  >
                    {latestQuote?.as?.toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Information Footer */}
        <div
          className={`flex items-start gap-3 p-5 rounded-lg ${darkMode ? "bg-gray-800" : "bg-gray-100"}`}
        >
          <Info
            className={`w-6 h-6 mt-1 ${darkMode ? "text-gray-300" : "text-gray-600"}`}
          />
          <p
            className={`text-base ${darkMode ? "text-gray-300" : "text-gray-600"}`}
          >
            Market depth data displays the volume of orders to buy or sell at
            different price levels. The spread represents the difference between
            the best ask and bid prices.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UsQuoteTradeConditionsComponent;
