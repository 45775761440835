import React from "react";
import { Send } from "lucide-react";

const CommentForm = ({
  commentContent,
  setCommentContent,
  handleComment,
  darkMode,
}) => (
  <form onSubmit={handleComment} className="mb-6 space-y-4">
    <div className="relative">
      <textarea
        value={commentContent}
        onChange={(e) => setCommentContent(e.target.value)}
        placeholder="Write a comment..."
        className={`w-full p-4 rounded-lg border shadow-sm transition-all duration-300 ease-in-out ${
          darkMode
            ? "bg-gray-800 border-gray-700 text-white placeholder-gray-400"
            : "bg-white border-gray-200 text-gray-900 placeholder-gray-500"
        } focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent`}
        rows="3"
      ></textarea>
      <div className="absolute bottom-3 right-3">
        <button
          type="submit"
          className={`p-2 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            darkMode
              ? "bg-green-600 hover:bg-green-700 focus:ring-offset-gray-800"
              : "bg-green-500 hover:bg-green-600 focus:ring-offset-white"
          } text-white shadow-md`}
        >
          <Send size={20} />
        </button>
      </div>
    </div>
  </form>
);

export default CommentForm;
