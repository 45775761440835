import React from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const ProfileUpdateMessage = ({ message, isError }) => (
  <div
    className={`
      fixed bottom-0 left-0 right-0
      px-4 py-3
      flex items-center justify-center
      transform transition-all duration-300 ease-in-out
      ${message ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"}
      ${isError ? "bg-red-500 text-white" : "bg-green-500 text-white"}
      text-sm font-medium
    `}
  >
    {isError ? (
      <FaExclamationCircle className="flex-shrink-0 mr-2" />
    ) : (
      <FaCheckCircle className="flex-shrink-0 mr-2" />
    )}
    <span>{message}</span>
  </div>
);

export default ProfileUpdateMessage;
