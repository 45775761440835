export const plans = [
  {
    name: "PRO",
    price: "$10",
    description:
      "Ideal for individuals committed to advancing their financial knowledge.",
    features: [
      "All START features",
      "Advanced financial planning tools",
      "Personalized investment strategies",
      "Monthly webinars with financial experts",
      "Priority customer support",
      "Access to premium articles and guides",
      "Early access to new features",
    ],
    ads: "Limited ads, mostly non-intrusive",
    cta: "Upgrade to Pro",
    popular: true,
    image: "https://source.unsplash.com/800x600/?finance,investment",
  },
  {
    name: "BUSINESS",
    price: "$50",
    description:
      "Tailored for small to medium businesses and financial professionals seeking advanced tools.",
    features: [
      "All PRO features",
      "Team collaboration tools",
      "Advanced business financial forecasting",
      "Quarterly one-on-one consultation",
      "API access for custom integrations",
      "Advanced business analytics",
      "Customizable financial reports",
    ],
    ads: "Very few, carefully selected ads",
    cta: "Choose Business",
    image: "https://source.unsplash.com/800x600/?business,finance",
  },
  {
    name: "QUANTUM",
    price: "$100",
    description:
      "Our most comprehensive package for serious financial enthusiasts and large enterprises.",
    features: [
      "All BUSINESS features",
      "Exclusive in-depth market analysis reports",
      "Access to our network of top financial advisors",
      "Custom financial software development",
      "Dedicated account manager",
      "24/7 premium support",
      "Invitations to exclusive events and seminars",
    ],
    ads: "Ad-free experience",
    cta: "Get Special Access",
    image: "https://source.unsplash.com/800x600/?finance,enterprise",
  },
];

export const planDetails = {
  pro: {
    name: "PRO",
    price: "$10",
    priceId: "price_1Q9X4SCBp9O8UqlnGtg2fdgw",
    description:
      "Elevate your financial acumen with our comprehensive PRO Plan, meticulously designed for individuals committed to taking their financial literacy and management skills to unprecedented heights. Unlock a treasure trove of advanced planning tools that will empower you to make informed decisions about your financial future. Gain access to personalized investment strategies tailored to your unique financial situation and goals, helping you navigate the complex world of investments with confidence. Immerse yourself in exclusive monthly webinars led by renowned industry experts, offering deep dives into cutting-edge financial topics and trends. Expand your knowledge base with our extensive collection of in-depth articles and expert analyses, covering a wide range of advanced financial concepts and strategies. Enjoy the peace of mind that comes with priority customer support, ensuring that your questions and concerns are addressed promptly and efficiently by our team of financial specialists.",
    features: [
      "All features included in the START plan, providing a solid foundation for your financial journey",
      "Suite of sophisticated financial planning tools to help you create and optimize your long-term financial strategy",
      "Customized investment strategies developed by our team of expert financial advisors, tailored to your risk tolerance and financial goals",
      "Exclusive access to monthly webinars featuring insights from industry-leading financial experts and thought leaders",
      "Priority customer support with faster response times and access to our team of financial specialists",
      "Unlimited access to our premium library of articles, guides, and in-depth analyses covering advanced financial topics",
      "Early access to newly developed features and tools, allowing you to stay at the cutting edge of personal finance management",
      "Enhanced data visualization tools to help you better understand and analyze your financial data",
      "Automated bill tracking and reminder system to help you stay on top of your financial obligations",
    ],
    reviews: [
      {
        text: "Upgrading to the PRO plan was a game-changer for my investment strategy. The personalized investment advice has helped me optimize my portfolio in ways I never thought possible. The monthly expert webinars alone are worth the subscription price – they've provided invaluable insights that have significantly impacted my financial decisions.",
        rating: 5,
      },
      {
        text: "I cannot overstate the value I've received from the PRO plan. The advanced planning tools have revolutionized how I approach my finances, and the expert webinars have expanded my financial knowledge exponentially. The priority customer support is the cherry on top – always responsive and incredibly helpful. This plan is worth every penny and then some!",
        rating: 5,
      },
      {
        text: "As someone who recently upgraded from the START plan, I can confidently say that the PRO plan is a significant leap forward. The depth of information available in the premium articles is impressive, and the personalized investment strategies have given me a new level of confidence in my financial decisions. The early access to new features is also a nice touch – it's exciting to be at the forefront of personal finance technology.",
        rating: 5,
      },
      {
        text: "The advanced tools offered in the PRO plan have been a revelation. From intricate budgeting capabilities to detailed investment analysis, these tools have provided me with insights I never knew I needed. The data visualization features, in particular, have helped me grasp complex financial concepts with ease. A worthwhile investment for anyone serious about taking control of their finances.",
        rating: 5,
      },
      {
        text: "I've been consistently impressed with the level of support provided in the PRO plan. The customer service team is not only responsive but also knowledgeable and patient, always going the extra mile to ensure my questions are thoroughly answered. This level of support, combined with the wealth of advanced features, makes the PRO plan an exceptional value proposition.",
        rating: 5,
      },
    ],
    ads: "Limited number of carefully curated, non-intrusive ads to maintain a premium user experience",
    color: "Green",
    commitment:
      "Flexible subscription options with monthly or annual billing to suit your preferences",
    support:
      "Priority email support with guaranteed response times and access to financial specialists",
    upgrades:
      "Exclusive discounted upgrade path to our comprehensive BUSINESS plan for growing needs",
    cta: "Upgrade to Pro and Accelerate Your Financial Growth",
    popular: true,
    icon: "PieChart",
  },
  business: {
    name: "BUSINESS",
    price: "$50",
    priceId: "price_1Q9XjICBp9O8UqlnohHiTcr0",
    description:
      "Empower your business with our comprehensive BUSINESS Plan, meticulously tailored for small to medium enterprises and financial professionals seeking advanced financial management tools. This robust package is designed to elevate your business's financial operations to new heights of efficiency and insight. Collaborate seamlessly with your team using our sophisticated forecasting tools, enabling you to make data-driven decisions with confidence. Benefit from quarterly one-on-one consultations with our expert financial advisors, providing personalized guidance to navigate your unique business challenges. Unlock the full potential of our platform with API access, allowing for custom integrations that align perfectly with your existing systems and workflows. Enhance productivity and streamline operations with our advanced analytics suite, offering deep insights into your business's financial health and performance. Create customizable reports that speak directly to your stakeholders' needs, ensuring that you're always prepared to make informed strategic decisions.",
    features: [
      "All features included in the PRO plan, providing a robust foundation for your business's financial management",
      "Advanced team collaboration tools to streamline financial planning and decision-making processes across your organization",
      "State-of-the-art business financial forecasting capabilities, leveraging AI and machine learning for accurate projections",
      "Quarterly one-on-one consultation sessions with experienced financial advisors, offering tailored advice for your business",
      "Full API access for seamless integration with your existing software ecosystem and the ability to build custom solutions",
      "Comprehensive business analytics dashboard, providing real-time insights into your company's financial performance",
      "Fully customizable financial report generator, allowing you to create professional-grade reports for stakeholders",
      "Multi-user access with role-based permissions to ensure data security and streamline team workflows",
      "Dedicated data storage and enhanced security measures to protect your sensitive financial information",
      "Access to industry-specific financial benchmarks and KPIs to gauge your business's performance",
    ],
    reviews: [
      {
        text: "The BUSINESS plan has been nothing short of transformative for our company's financial planning and management. The advanced forecasting tools have significantly improved our budgeting accuracy, while the team collaboration features have streamlined our decision-making process. The quarterly consultations provide invaluable insights tailored to our industry. For any SMB serious about financial management, this plan is a game-changer.",
        rating: 5,
      },
      {
        text: "As a tech-focused company, the API access offered in the BUSINESS plan was a crucial factor in our decision. It has allowed us to seamlessly integrate the platform with our existing systems, creating a unified financial management ecosystem. The customizable reports have also been a hit with our board members, providing them with clear, actionable insights. The value this plan offers is exceptional.",
        rating: 5,
      },
      {
        text: "We've experienced remarkable growth since implementing the BUSINESS plan. The advanced analytics have uncovered opportunities and efficiencies we hadn't previously identified, directly impacting our bottom line. The ability to customize reports has saved our finance team countless hours and improved our communication with investors. For businesses looking to scale, this plan provides all the tools you need.",
        rating: 5,
      },
      {
        text: "The team collaboration features in the BUSINESS plan have revolutionized how our departments work together on financial matters. We're now able to make faster, more informed decisions with everyone on the same page. The role-based access controls ensure data security while promoting transparency. It's clear this plan was designed with the complex needs of growing businesses in mind.",
        rating: 5,
      },
      {
        text: "I can't overstate how valuable the forecasting tools in the BUSINESS plan have been for our company. The accuracy of the projections, combined with the ability to run multiple scenarios, has elevated our strategic planning process. The quarterly consultations provide expert guidance on interpreting and acting on these forecasts. This plan has quickly become indispensable to our operations.",
        rating: 5,
      },
    ],
    ads: "Minimal, carefully selected ads that are relevant to business financial services",
    color: "Blue",
    commitment:
      "Flexible annual subscription with the option for quarterly billing to manage cash flow",
    support:
      "Dedicated account manager for personalized assistance and strategic advice",
    upgrades:
      "Seamless transition to custom enterprise solutions as your business grows",
    cta: "Elevate Your Business Finances",
    icon: "Briefcase",
  },
  quantum: {
    name: "QUANTUM",
    price: "$100",
    priceId: "price_1Q9Y80CBp9O8Uqlnia1vT98J",
    description:
      "Experience the pinnacle of financial management with our exclusive Quantum Plan, meticulously crafted for discerning financial enthusiasts and large enterprises demanding the utmost in customization and support. This premier offering grants you unparalleled access to cutting-edge financial tools and expert insights, positioning you at the forefront of financial innovation. Delve into exclusive, in-depth market analyses that provide a comprehensive view of global financial trends and opportunities. Connect directly with our elite network of top financial advisors, gaining personalized guidance from industry leaders to inform your most critical financial decisions. Benefit from bespoke software development tailored precisely to your unique requirements, ensuring that your financial management tools align perfectly with your specific needs and workflows. Enjoy the dedicated support of a seasoned account manager who understands the intricacies of your financial landscape, providing proactive assistance and strategic recommendations. With round-the-clock premium support, rest assured that expert help is always at your fingertips, no matter the complexity of your query or the hour of your need.",
    features: [
      "All features included in the BUSINESS plan, providing a comprehensive foundation for advanced financial management",
      "Exclusive access to proprietary in-depth market analysis reports, offering unparalleled insights into global financial trends",
      "Direct connection to our curated network of elite financial advisors for personalized consultation and strategy sessions",
      "Custom financial software development services tailored to your specific needs and integrated seamlessly with your existing systems",
      "A dedicated, experienced account manager offering proactive support and strategic financial guidance",
      "24/7 premium support with guaranteed response times, ensuring your critical questions are answered promptly at any hour",
      "VIP invitations to exclusive financial seminars, conferences, and networking events featuring industry leaders and innovators",
      "Advanced AI-driven predictive analytics for superior forecasting and risk assessment",
      "Unlimited cloud storage for your financial data with the highest level of encryption and security measures",
      "Priority beta access to new features and tools, allowing you to shape the future of our platform",
    ],
    reviews: [
      {
        text: "The level of customization and support provided by the QUANTUM plan is truly unparalleled in the financial services industry. The dedicated account manager has become an invaluable extension of our team, consistently providing insights that have materially impacted our financial strategy. The custom software development has allowed us to create a financial management system that fits our unique needs perfectly. For large enterprises or serious financial enthusiasts, the value delivered by this plan is exceptional and worth every cent.",
        rating: 5,
      },
      {
        text: "Implementing the QUANTUM plan has been nothing short of transformative for our enterprise. The custom software development service has allowed us to create a bespoke financial management system that integrates seamlessly with our complex multinational structure. The exclusive market analysis reports have given us a competitive edge in our industry, informing critical strategic decisions. The 24/7 premium support ensures that we're never left waiting when time-sensitive issues arise. This plan has exceeded our highest expectations.",
        rating: 5,
      },
      {
        text: "As a financial services professional, I can confidently say that the QUANTUM plan offers a level of service and customization that is unmatched in the industry. The access to top financial advisors has been instrumental in refining our investment strategies, while the exclusive events have provided incredible networking opportunities. The advanced AI-driven analytics have revolutionized our approach to risk assessment and forecasting. This plan truly delivers an elite service that has surpassed all our expectations.",
        rating: 5,
      },
      {
        text: "The 24/7 premium support offered in the QUANTUM plan has been a game-changer for our global operations. No matter the time zone or the complexity of the issue, we've always received prompt, expert assistance. The dedicated account manager has been proactive in understanding our needs and suggesting optimizations we hadn't even considered. For organizations that demand the best in financial management tools and support, this plan is without peer.",
        rating: 5,
      },
      {
        text: "The exclusive events and seminars included in the QUANTUM plan have provided our team with unparalleled learning and networking opportunities. The insights gained from these events, combined with the proprietary market analysis reports, have significantly enhanced our strategic decision-making process. The priority beta access to new features allows us to stay at the cutting edge of financial technology. For those who take their financial management seriously, this plan is an investment that pays dividends many times over.",
        rating: 5,
      },
    ],
    ads: "Completely ad-free experience for uninterrupted focus on your financial management",
    color: "Gold",
    commitment:
      "Premium annual subscription with option for customized billing arrangements",
    support:
      "24/7 white-glove support with rapid response times and escalation to senior financial experts",
    upgrades:
      "Fully customizable enterprise solutions with ongoing optimization and scaling",
    cta: "Unlock Special Access",
    icon: "Crown",
  },
};
