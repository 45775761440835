import React from "react";
import { motion } from "framer-motion";
import { Database, Binary, Cpu } from "lucide-react";

const QuantumIcon = ({ icon: Icon, delay, darkMode }) => (
  <motion.div
    initial={{ scale: 0, rotate: 0 }}
    animate={{ scale: 1, rotate: 360 }}
    transition={{
      delay,
      duration: 1,
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ duration: 4, repeat: Infinity, ease: "linear" }}
    >
      <Icon
        size={24}
        className={darkMode ? "text-green-400" : "text-green-600"}
      />
    </motion.div>
  </motion.div>
);

const LoadingCallPaticipantAnimation = ({ darkMode, text }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    className={`flex flex-col items-center justify-center w-full h-full p-4`}
  >
    <div className="flex justify-around w-full mb-4">
      <QuantumIcon icon={Database} delay={0} darkMode={darkMode} />
      <QuantumIcon icon={Binary} delay={0.2} darkMode={darkMode} />
      <QuantumIcon icon={Cpu} delay={0.4} darkMode={darkMode} />
    </div>
    <motion.h3
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.6, duration: 0.5 }}
      className={`text-lg font-bold mb-2 ${darkMode ? "text-green-400" : "text-green-700"}`}
    >
      {text || "Initializing Quantum Call"}
    </motion.h3>
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      transition={{ delay: 0.8, duration: 2, repeat: Infinity }}
      className="h-1 bg-green-500 rounded-full mb-2 w-full"
    />
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1, duration: 0.5 }}
      className={`text-sm text-center max-w-md ${darkMode ? "text-green-300" : "text-green-800"}`}
    >
      <motion.span
        animate={{ opacity: [1, 0.5, 1] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        Waiting for quantum member
      </motion.span>{" "}
      <motion.span
        animate={{ opacity: [0.5, 1, 0.5] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        to join the entanglement...
      </motion.span>
    </motion.p>
    <motion.div
      className={`mt-4 font-mono text-xs ${darkMode ? "text-green-400" : "text-green-700"}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1.2, duration: 0.5 }}
    >
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.4 + i * 0.2, duration: 0.5 }}
        >
          {`> ${["Quantum", "Encryption", "Verified"][i]}: `}
          <motion.span
            animate={{ opacity: [1, 0, 1] }}
            transition={{ duration: 1, repeat: Infinity, repeatDelay: 1 }}
          >
            {Math.random().toString(36).substr(2, 8)}
          </motion.span>
        </motion.div>
      ))}
    </motion.div>
  </motion.div>
);

export default LoadingCallPaticipantAnimation;
