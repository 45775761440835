import React, { useState, useMemo } from "react";
import {
  AlertCircle,
  TrendingUp,
  DollarSign,
  Table as TableIcon,
  Calendar,
  ArrowRight,
  ChevronRight,
} from "lucide-react";
import DetailedBreakdown from "./DetailedBreakdown";
import MetricCard from "./MetricCard";
import TimePeriodSelector from "./TimePeriodSelector";
import HistoricalTrendsChart from "./HistoricalTrendsChart";
import AssetCompositionChart from "./AssetCompositionChart";
import FinancialRatios from "./FinancialRatios";

const BalanceSheetVisualization = ({ data, darkMode }) => {
  const [timeframe, setTimeframe] = useState("quarterly");
  const [view, setView] = useState("overview");

  const processedData = useMemo(() => {
    if (!data || !data[timeframe]) return [];

    return Object.entries(data[timeframe])
      .map(([date, values]) => {
        // Basic values
        const totalAssets = Number(values.totalAssets || 0);
        const totalLiab = Number(values.totalLiab || 0);
        const totalEquity = Number(values.totalStockholderEquity || 0);
        const currentAssets = Number(values.totalCurrentAssets || 0);
        const currentLiabilities = Number(values.totalCurrentLiabilities || 0);
        const nonCurrentAssets = Number(values.nonCurrentAssetsTotal || 0);
        const nonCurrentLiabilities = Number(
          values.nonCurrentLiabilitiesTotal || 0,
        );
        const cash = Number(values.cash || 0);
        const inventory = Number(values.inventory || 0);
        const netReceivables = Number(values.netReceivables || 0);
        const propertyPlantEquipment = Number(
          values.propertyPlantEquipmentNet || 0,
        );
        const longTermInvestments = Number(values.longTermInvestments || 0);
        const shortTermDebt = Number(values.shortTermDebt || 0);
        const longTermDebt = Number(values.longTermDebt || 0);
        const retainedEarnings = Number(values.retainedEarnings || 0);
        const commonStock = Number(values.commonStock || 0);
        const netIncome = Number(values.netIncome || 0);
        const revenue = Number(values.totalRevenue || 0);
        const operatingIncome = Number(values.operatingIncome || 0);
        const ebit = Number(values.ebit || 0);
        const marketCap = Number(values.marketCap || 0);

        // Calculated metrics
        const workingCapital = currentAssets - currentLiabilities;
        const netWorth = totalAssets - totalLiab;

        return {
          date,
          totalAssets,
          totalLiab,
          totalEquity,
          currentAssets,
          currentLiabilities,
          nonCurrentAssets,
          nonCurrentLiabilities,
          cash,
          inventory,
          netReceivables,
          propertyPlantEquipment,
          longTermInvestments,
          shortTermDebt,
          longTermDebt,
          retainedEarnings,
          commonStock,
          workingCapital,
          netWorth,
          currentRatio: currentAssets / currentLiabilities || 0,
          quickRatio: (currentAssets - inventory) / currentLiabilities || 0,
          cashRatio: cash / currentLiabilities || 0,
          workingCapitalRatio: workingCapital / totalAssets || 0,
          defensiveInterval:
            (cash + netReceivables) /
              ((totalLiab - currentLiabilities) / 365) || 0,
          debtRatio: totalLiab / totalAssets || 0,
          debtToEquityRatio: totalLiab / totalEquity || 0,
          equityMultiplier: totalAssets / totalEquity || 0,
          timesInterestEarned: ebit / Number(values.interestExpense || 1) || 0,
          debtServiceCoverageRatio:
            operatingIncome / (shortTermDebt + longTermDebt) || 0,
          assetTurnover: revenue / totalAssets || 0,
          inventoryTurnover: revenue / inventory || 0,
          receivablesTurnover: revenue / netReceivables || 0,
          payableTurnover: revenue / Number(values.accountsPayable || 1) || 0,
          fixedAssetTurnover: revenue / nonCurrentAssets || 0,
          grossProfitMargin:
            (revenue - Number(values.costOfRevenue || 0)) / revenue || 0,
          operatingProfitMargin: operatingIncome / revenue || 0,
          netProfitMargin: netIncome / revenue || 0,
          returnOnAssets: netIncome / totalAssets || 0,
          returnOnEquity: netIncome / totalEquity || 0,
          bookValue: totalEquity / Number(values.sharesOutstanding || 1) || 0,
          marketToBookRatio: marketCap / totalEquity || 0,
          priceToEarnings: marketCap / netIncome || 0,
          enterpriseValue: marketCap + totalLiab - cash || 0,
          altmanZScore:
            1.2 * (workingCapital / totalAssets) +
              1.4 * (retainedEarnings / totalAssets) +
              3.3 * (ebit / totalAssets) +
              0.6 * (marketCap / totalLiab) +
              0.999 * (revenue / totalAssets) || 0,
        };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [data, timeframe]);

  if (!processedData.length) {
    return (
      <div
        className={`min-h-screen flex items-center justify-center ${
          darkMode ? "bg-gray-900" : "bg-gray-50"
        }`}
      >
        <div
          className={`max-w-md mx-auto p-8 rounded-2xl shadow-lg text-center ${
            darkMode ? "bg-gray-800" : "bg-white"
          }`}
        >
          <AlertCircle className="w-16 h-16 mx-auto mb-4 text-yellow-500" />
          <h2
            className={`text-xl font-bold mb-2 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            No Balance Sheet Data
          </h2>
          <p className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}>
            No financial data is currently available for analysis.
          </p>
        </div>
      </div>
    );
  }

  const latestData = processedData[processedData.length - 1];
  const previousData = processedData[processedData.length - 2];

  const calculateRatios = (item) => ({
    currentRatio: item.currentAssets / item.currentLiabilities || 0,
    debtToEquity: item.totalLiab / item.totalStockholderEquity || 0,
    assetToEquity: item.totalAssets / item.totalStockholderEquity || 0,
    cashRatio: item.cash / item.currentLiabilities || 0,
    workingCapitalRatio:
      (item.currentAssets - item.currentLiabilities) / item.totalAssets || 0,
    debtToAssets: item.totalLiab / item.totalAssets || 0,
    equityMultiplier: item.totalAssets / item.totalStockholderEquity || 0,
    nonCurrentRatio: item.nonCurrentAssets / item.nonCurrentLiabilities || 0,
  });

  const ratios = calculateRatios(latestData);

  const mainMetrics = [
    {
      title: "Assets",
      icon: DollarSign,
      color: "emerald",
      metrics: [
        { label: "Total Assets", value: latestData.totalAssets },
        { label: "Current Assets", value: latestData.currentAssets },
        { label: "Non-Current Assets", value: latestData.nonCurrentAssets },
        { label: "Cash & Equivalents", value: latestData.cash },
        { label: "Net Receivables", value: latestData.netReceivables },
      ],
    },
    {
      title: "Liabilities",
      icon: ArrowRight,
      color: "blue",
      metrics: [
        { label: "Total Liabilities", value: latestData.totalLiab },
        { label: "Current Liabilities", value: latestData.currentLiabilities },
        {
          label: "Non-Current Liabilities",
          value: latestData.nonCurrentLiabilities,
        },
        { label: "Short Term Debt", value: latestData.shortTermDebt },
        { label: "Long Term Debt", value: latestData.longTermDebt },
      ],
    },
    {
      title: "Equity",
      icon: TrendingUp,
      color: "purple",
      metrics: [
        { label: "Total Equity", value: latestData.totalStockholderEquity },
        { label: "Retained Earnings", value: latestData.retainedEarnings },
        { label: "Common Stock", value: latestData.commonStock },
        {
          label: "Working Capital",
          value: latestData.currentAssets - latestData.currentLiabilities,
        },
        {
          label: "Net Worth",
          value: latestData.totalAssets - latestData.totalLiab,
        },
      ],
    },
  ];

  const navigationTabs = [
    { id: "overview", label: "Overview", icon: DollarSign },
    { id: "trends", label: "Trends", icon: TrendingUp },
    { id: "details", label: "Details", icon: TableIcon },
  ];

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-900"
      }`}
    >
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
        {/* Enhanced Header */}
        <div
          className={`rounded-2xl ${
            darkMode
              ? "bg-gradient-to-r from-gray-900 to-gray-800 border border-gray-800"
              : "bg-gradient-to-r from-gray-50 to-white border border-gray-100"
          } shadow-xl p-6`}
        >
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
            <div className="space-y-2">
              <h1
                className={`text-3xl font-bold tracking-tight ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Balance Sheet Analysis
              </h1>
              <div className="flex items-center gap-2">
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Last updated: {new Date(latestData.date).toLocaleDateString()}
                </p>
                <ChevronRight className="w-4 h-4" />
                <span
                  className={`px-2 py-1 rounded-full text-xs font-medium ${
                    darkMode
                      ? "bg-gray-800 text-gray-300"
                      : "bg-gray-100 text-gray-600"
                  }`}
                >
                  {timeframe.charAt(0).toUpperCase() + timeframe.slice(1)} View
                </span>
              </div>
            </div>
            <TimePeriodSelector
              timeframe={timeframe}
              setTimeframe={setTimeframe}
              darkMode={darkMode}
            />
          </div>
        </div>

        {/* Enhanced Navigation Tabs */}
        <div
          className={`rounded-2xl my-6  ${
            darkMode
              ? "bg-gradient-to-r from-gray-900 to-gray-800 border border-gray-800"
              : "bg-gradient-to-r from-gray-50 to-white border border-gray-100"
          } shadow-xl p-2`}
        >
          <div className="flex gap-2 overflow-x-auto">
            {navigationTabs.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setView(id)}
                className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm font-medium transition-all ${
                  view === id
                    ? `${darkMode ? "bg-green-600" : "bg-green-500"} text-white shadow-lg`
                    : darkMode
                      ? "hover:bg-gray-800 text-gray-300"
                      : "hover:bg-gray-100 text-gray-600"
                }`}
              >
                <Icon className="w-4 h-4" />
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content with Enhanced Transitions */}
        <div className="space-y-6">
          {view === "overview" && (
            <div className="space-y-6 transition-all duration-300">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                {mainMetrics.map((section, index) => (
                  <MetricCard key={index} {...section} darkMode={darkMode} />
                ))}
              </div>
              <FinancialRatios ratios={ratios} darkMode={darkMode} />
            </div>
          )}

          {view === "trends" && (
            <div className="space-y-8 transition-all duration-300">
              <HistoricalTrendsChart data={processedData} darkMode={darkMode} />
              <AssetCompositionChart data={processedData} darkMode={darkMode} />
            </div>
          )}

          {view === "details" && (
            <div className="transition-all duration-300">
              <DetailedBreakdown
                currentData={latestData}
                previousData={previousData}
                darkMode={darkMode}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BalanceSheetVisualization;
