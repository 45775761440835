import React from "react";
import {
  Info,
  Building2,
  Calendar,
  Globe,
  DollarSign,
  Briefcase,
  BarChart4,
  Landmark,
  Hash,
} from "lucide-react";

const FundOverview = ({ data, darkMode }) => {
  const general = data.General;
  const fundData = data.MutualFund_Data;

  // Format currency values
  const formatCurrency = (value) => {
    if (!value) return "N/A";
    try {
      const num = parseFloat(value.replace(/[^0-9.-]+/g, ""));
      if (isNaN(num)) return value;

      if (num >= 1e9) {
        return `$${(num / 1e9).toFixed(2)}B`;
      } else if (num >= 1e6) {
        return `$${(num / 1e6).toFixed(2)}M`;
      } else {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(num);
      }
    } catch {
      return value;
    }
  };

  // Format percentage values
  const formatPercentage = (value) => {
    if (!value) return "N/A";
    try {
      const num = parseFloat(value);
      return isNaN(num) ? value : num.toFixed(2) + "%";
    } catch {
      return value;
    }
  };

  const overviewItems = [
    {
      label: "Fund Name",
      value: general?.Name,
      icon: Building2,
      category: "primary",
    },
    {
      label: "Type",
      value: general?.Type,
      icon: Briefcase,
      category: "secondary",
    },
    {
      label: "Category",
      value: general?.Fund_Category,
      icon: BarChart4,
      category: "secondary",
    },
    {
      label: "Exchange",
      value: general?.Exchange,
      icon: Landmark,
      category: "secondary",
    },
    {
      label: "Currency",
      value: general?.CurrencyName,
      icon: DollarSign,
      category: "info",
    },
    {
      label: "Inception Date",
      value: fundData?.Inception_Date || "N/A",
      icon: Calendar,
      category: "info",
    },
    {
      label: "ISIN",
      value: general?.ISIN || "N/A",
      icon: Hash,
      category: "info",
    },
    {
      label: "Yield",
      value: formatPercentage(fundData?.Yield),
      icon: Globe,
      category: "metric",
    },
    {
      label: "Net Assets",
      value: formatCurrency(fundData?.Portfolio_Net_Assets),
      icon: DollarSign,
      category: "metric",
    },
  ];

  const getCategoryStyle = (category) => {
    const baseStyles =
      "relative p-4 rounded-xl transition-all duration-200 hover:scale-[1.02]";
    const styles = {
      primary: `${baseStyles} ${
        darkMode
          ? "bg-indigo-500/10 border border-indigo-500/20"
          : "bg-indigo-50 border border-indigo-100"
      }`,
      secondary: `${baseStyles} ${
        darkMode
          ? "bg-violet-500/10 border border-violet-500/20"
          : "bg-violet-50 border border-violet-100"
      }`,
      info: `${baseStyles} ${
        darkMode
          ? "bg-gray-800/50 border border-gray-700"
          : "bg-gray-50 border border-gray-200"
      }`,
      metric: `${baseStyles} ${
        darkMode
          ? "bg-emerald-500/10 border border-emerald-500/20"
          : "bg-emerald-50 border border-emerald-100"
      }`,
    };
    return styles[category];
  };

  return (
    <div
      className={`w-full rounded-xl border shadow-sm transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Modern Header */}
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-4">
          <div
            className={`p-3 rounded-xl ${
              darkMode
                ? "bg-cyan-500/10 text-cyan-400"
                : "bg-cyan-50 text-cyan-500"
            }`}
          >
            <Info className="w-6 h-6" />
          </div>
          <div>
            <h3
              className={`text-xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Fund Overview
            </h3>
            <p
              className={`mt-1 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Key information and metrics
            </p>
          </div>
        </div>
      </div>

      {/* Modern Grid Layout */}
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {overviewItems.map((item, index) => {
          const IconComponent = item.icon;
          return (
            <div key={index} className={getCategoryStyle(item.category)}>
              <div className="flex items-start space-x-4">
                <div
                  className={`p-2 rounded-lg ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  }`}
                >
                  <IconComponent
                    className={`w-5 h-5 ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  />
                </div>
                <div className="flex-1">
                  <h4
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {item.label}
                  </h4>
                  <p
                    className={`mt-1 font-semibold ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {item.value || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FundOverview;
