import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  FaUserPlus,
  FaSearch,
  FaLink,
  FaChartLine,
  FaMoneyCheckAlt,
  FaQuestionCircle,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { BsMegaphone } from "react-icons/bs";
import NavButton from "../menu/NavButton";

function HowItWorksSection({ id }) {
  const { darkMode } = useTheme();

  const steps = [
    {
      icon: FaUserPlus,
      title: "Sign Up",
      description:
        "Start your journey by creating a free ClickBank account. It's a simple and fast process that will open the doors to countless opportunities in affiliate marketing. Once you're set up, you'll have access to thousands of high-converting products to promote, including Quantum Money Code. No upfront costs, no complications—just create your account and dive right in.",
      action: "Sign up for ClickBank",
      link: "https://accounts.clickbank.com/signup/",
    },
    {
      icon: FaSearch,
      title: "Find Quantum Money Code",
      description:
        "After signing up, head over to the ClickBank Marketplace, where you’ll discover a variety of lucrative products waiting for you. Search for 'Quantum Money Code' to access a detailed product listing, including commission rates, promotional materials, and more. This step allows you to review everything you need to know before you begin promoting.",
      action: "Explore ClickBank Marketplace",
      link: "https://accounts.clickbank.com/marketplace.htm",
    },
    {
      icon: FaLink,
      title: "Get Your Hop Link",
      description:
        "Once you've decided to promote Quantum Money Code, generate your unique hop link. This affiliate link is your key to earning commissions. It tracks every sale you refer back to you. Every time a customer purchases through your link, you get credit for the sale—no hassle, no guesswork.",
      action: "Learn about HopLinks",
      link: "https://support.clickbank.com/hc/en-us/articles/220358887-Promote-Products-Create-Hoplinks",
    },
    {
      icon: BsMegaphone,
      title: "Promote",
      description:
        "Now it's time to put your marketing skills to work. Promote Quantum Money Code using your hop link on your website, blog, social media channels, or email lists. The more creative you get with your promotions, the more potential buyers you can reach. Share your unique link widely and start driving traffic towards your affiliate offer.",
      action: "Get Marketing Tips",
      link: "#marketing-resources",
    },
    {
      icon: FaChartLine,
      title: "Track Performance",
      description:
        "Keep an eye on your sales and earnings through the ClickBank dashboard. You’ll be able to track how your promotions are performing, analyze sales data, and see where your traffic is converting. Use this information to adjust your strategy and maximize your income potential.",
      action: "Understand Your Analytics",
      link: "https://support.clickbank.com/hc/en-us/articles/220377007-Analyze-Your-Results",
    },
    {
      icon: FaMoneyCheckAlt,
      title: "Get Paid",
      description:
        "Your hard work pays off—literally. ClickBank handles all payments, ensuring that your commissions are sent to you on schedule. With multiple payout options, including direct deposit, receiving your earnings is convenient and hassle-free. Now you can sit back, relax, and enjoy the rewards of your promotional efforts.",
      action: "Review Payment Options",
      link: "https://support.clickbank.com/hc/en-us/articles/220383867-Payment-Methods",
    },
  ];

  return (
    <section
      id={id}
      className={`py-16 px-4 sm:px-6 lg:px-8 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      }`}
    >
      <div className="container mx-auto">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">
          How to Promote
        </h2>
        <div className="max-w-4xl mx-auto">
          <ol className="relative border-l border-gray-200 dark:border-gray-700">
            {steps.map((step, index) => (
              <li key={index} className="mb-10 ml-4 sm:ml-10">
                <span
                  className={`absolute flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 rounded-full -left-4 sm:-left-5 ring-8 ${
                    darkMode
                      ? "bg-green-900 ring-gray-900"
                      : "bg-green-100 ring-white"
                  }`}
                >
                  <step.icon
                    className={`w-4 h-4 sm:w-5 sm:h-5 ${
                      darkMode ? "text-green-400" : "text-green-800"
                    }`}
                  />
                </span>
                <h3 className="flex items-center mb-1 text-base sm:text-lg font-semibold ml-2">
                  {step.title}
                  <span
                    className={`bg-green-100 text-green-800 text-xs sm:text-sm font-medium mr-2 px-2 py-0.5 rounded dark:bg-green-900 dark:text-green-300 ml-2 sm:ml-3`}
                  >
                    Step {index + 1}
                  </span>
                </h3>
                <p className="mb-4 text-sm sm:text-base font-normal">
                  {step.description}
                </p>
                <a
                  href={step.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`inline-flex items-center px-3 py-2 text-xs sm:text-sm font-medium ${
                    darkMode
                      ? "text-green-400 bg-gray-800 hover:bg-gray-700"
                      : "text-green-800 bg-green-100 hover:bg-green-200"
                  } rounded-lg transition duration-300 ease-in-out`}
                >
                  {step.action}{" "}
                  <FaExternalLinkAlt className="ml-2 w-3 h-3 sm:w-4 sm:h-4" />
                </a>
              </li>
            ))}
          </ol>
        </div>

        <div className="mt-12 sm:mt-16 text-center">
          <h3 className="text-xl sm:text-2xl font-semibold mb-4">
            Need Help Getting Started?
          </h3>
          <p className="mb-6 text-sm sm:text-base">
            Our dedicated support team is ready to assist you at every stage.
            Whether you need help signing up, finding your hop link, or
            improving your marketing strategies, we're here to provide the
            guidance and resources you need to succeed.
          </p>
          <div className="flex justify-center mb-8 w-full">
            <NavButton
              icon={FaQuestionCircle}
              isExternal={true}
              className="lg:w-1/4 md:w-1/2 sm:w-3/4"
            >
              Contact Support
            </NavButton>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorksSection;
