import React, { useState, useMemo } from "react";
import {
  AlertCircle,
  TrendingUp,
  DollarSign,
  Table as TableIcon,
  ChevronRight,
  BarChart3,
  Activity,
} from "lucide-react";
import CashFlowMetricCard from "./CashFlowMetricCard";
import TimePeriodSelector from "./TimePeriodSelector";
import CashFlowTrendsChart from "./CashFlowTrendsChart";
import CashFlowCompositionChart from "./CashFlowCompositionChart";
import CashFlowDetailedBreakdown from "./CashFlowDetailedBreakdown";
import CashFlowRatios from "./CashFlowRatios";

const CashFlowVisualization = ({ data, darkMode }) => {
  const [timeframe, setTimeframe] = useState("quarterly");
  const [view, setView] = useState("overview");

  const processedData = useMemo(() => {
    if (!data || !data[timeframe]) return [];

    return Object.entries(data[timeframe])
      .map(([date, values]) => {
        // Basic values
        const beginPeriodCashFlow = Number(values.beginPeriodCashFlow || 0);
        const endPeriodCashFlow = Number(values.endPeriodCashFlow || 0);
        const netIncome = Number(values.netIncome || 0);
        const depreciation = Number(values.depreciation || 0);
        const capitalExpenditures = Number(values.capitalExpenditures || 0);
        const changeInWorkingCapital = Number(
          values.changeInWorkingCapital || 0,
        );
        const freeCashFlow = Number(values.freeCashFlow || 0);
        const operatingCashFlow = Number(
          values.totalCashFromOperatingActivities || 0,
        );
        const investingCashFlow = Number(
          values.totalCashflowsFromInvestingActivities || 0,
        );
        const financingCashFlow = Number(
          values.totalCashFromFinancingActivities || 0,
        );
        const dividendsPaid = Number(values.dividendsPaid || 0);
        const changeInCash = Number(values.changeInCash || 0);
        const changeToInventory = Number(values.changeToInventory || 0);
        const changeToAccountReceivables = Number(
          values.changeToAccountReceivables || 0,
        );
        const changeToLiabilities = Number(values.changeToLiabilities || 0);
        const stockBasedCompensation = Number(
          values.stockBasedCompensation || 0,
        );
        const otherNonCashItems = Number(values.otherNonCashItems || 0);

        // Calculated metrics
        const cashFlowMargin = operatingCashFlow / netIncome || 0;
        const freeCashFlowYield = freeCashFlow / netIncome || 0;
        const operatingCashFlowRatio =
          operatingCashFlow / financingCashFlow || 0;
        const cashFlowCoverageRatio =
          operatingCashFlow / (dividendsPaid || 1) || 0;
        const capexToOperatingCashFlow =
          capitalExpenditures / operatingCashFlow || 0;
        const cashFlowToDebtRatio =
          operatingCashFlow / Math.abs(financingCashFlow) || 0;

        return {
          date,
          // Basic Values
          beginPeriodCashFlow,
          endPeriodCashFlow,
          netIncome,
          depreciation,
          capitalExpenditures,
          changeInWorkingCapital,
          freeCashFlow,
          operatingCashFlow,
          investingCashFlow,
          financingCashFlow,
          dividendsPaid,
          changeInCash,
          changeToInventory,
          changeToAccountReceivables,
          changeToLiabilities,
          stockBasedCompensation,
          otherNonCashItems,

          // Cash Flow Ratios
          cashFlowMargin,
          freeCashFlowYield,
          operatingCashFlowRatio,
          cashFlowCoverageRatio,
          capexToOperatingCashFlow,
          cashFlowToDebtRatio,

          // Additional Metrics
          cashBurnRate: (beginPeriodCashFlow - endPeriodCashFlow) / 3 || 0,
          cashEfficiency: operatingCashFlow / Math.abs(investingCashFlow) || 0,
          reinvestmentRate: capitalExpenditures / operatingCashFlow || 0,
          dividendPayoutRatio: Math.abs(dividendsPaid) / operatingCashFlow || 0,
          cashConversionCycle:
            ((changeToInventory +
              changeToAccountReceivables -
              changeToLiabilities) /
              netIncome) *
              365 || 0,
          qualityOfEarnings: operatingCashFlow / netIncome || 0,
        };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [data, timeframe]);

  if (!processedData.length) {
    return (
      <div
        className={`min-h-screen ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}
      >
        <div
          className={`max-w-7xl mx-auto p-6 rounded-2xl ${
            darkMode
              ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50"
              : "bg-gradient-to-br from-gray-50/50 to-white/50"
          }`}
        >
          <div className="flex flex-col items-center justify-center min-h-[400px] text-center space-y-4">
            <div
              className={`p-4 rounded-full ${
                darkMode ? "bg-gray-800" : "bg-white"
              } shadow-lg`}
            >
              <AlertCircle className="w-12 h-12 text-yellow-500" />
            </div>
            <h2
              className={`text-2xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              No Cash Flow Data Available
            </h2>
            <p
              className={`text-sm ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Please check back later or select a different time period
            </p>
          </div>
        </div>
      </div>
    );
  }

  const latestData = processedData[processedData.length - 1];
  const previousData = processedData[processedData.length - 2];

  const mainMetrics = [
    {
      title: "Operating Activities",
      icon: Activity,
      color: "#10B981",
      metrics: [
        { label: "Operating Cash Flow", value: latestData.operatingCashFlow },
        { label: "Net Income", value: latestData.netIncome },
        { label: "Depreciation", value: latestData.depreciation },
        {
          label: "Working Capital Change",
          value: latestData.changeInWorkingCapital,
        },
        {
          label: "Quality of Earnings",
          value: latestData.qualityOfEarnings,
          isRatio: true,
        },
      ],
    },
    {
      title: "Investing Activities",
      icon: BarChart3,
      color: "#3B82F6",
      metrics: [
        { label: "Investing Cash Flow", value: latestData.investingCashFlow },
        {
          label: "Capital Expenditures",
          value: latestData.capitalExpenditures,
        },
        { label: "Free Cash Flow", value: latestData.freeCashFlow },
        {
          label: "Reinvestment Rate",
          value: latestData.reinvestmentRate,
          isRatio: true,
        },
        {
          label: "Cash Efficiency",
          value: latestData.cashEfficiency,
          isRatio: true,
        },
      ],
    },
    {
      title: "Financing Activities",
      icon: DollarSign,
      color: "#F59E0B",
      metrics: [
        { label: "Financing Cash Flow", value: latestData.financingCashFlow },
        { label: "Dividends Paid", value: latestData.dividendsPaid },
        { label: "Net Change in Cash", value: latestData.changeInCash },
        {
          label: "Cash Flow Coverage",
          value: latestData.cashFlowCoverageRatio,
          isRatio: true,
        },
        {
          label: "Dividend Payout Ratio",
          value: latestData.dividendPayoutRatio,
          isRatio: true,
        },
      ],
    },
  ];

  const navigationItems = [
    { id: "overview", label: "Overview", icon: DollarSign },
    { id: "trends", label: "Trends", icon: TrendingUp },
    { id: "details", label: "Details", icon: TableIcon },
  ];

  return (
    <div className={`min-h-screen ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}>
      <div className="max-w-7xl mx-auto p-2 md:p-2 space-y-6">
        {/* Enhanced Header Section */}
        <div
          className={`rounded-2xl ${
            darkMode
              ? "bg-gradient-to-r from-gray-900 to-gray-800 border border-gray-800"
              : "bg-gradient-to-r from-gray-50 to-white border border-gray-100"
          } shadow-xl p-6`}
        >
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
            <div className="space-y-2">
              <h1
                className={`text-3xl font-bold tracking-tight ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Cash Flow Analysis
              </h1>
              <div className="flex items-center gap-2">
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Last updated: {new Date(latestData.date).toLocaleDateString()}
                </p>
                <ChevronRight className="w-4 h-4" />
                <span
                  className={`px-2 py-1 rounded-full text-xs font-medium ${
                    darkMode
                      ? "bg-gray-800 text-gray-300"
                      : "bg-gray-100 text-gray-600"
                  }`}
                >
                  {timeframe.charAt(0).toUpperCase() + timeframe.slice(1)} View
                </span>
              </div>
            </div>
            <TimePeriodSelector
              timeframe={timeframe}
              setTimeframe={setTimeframe}
              darkMode={darkMode}
            />
          </div>
        </div>

        {/* Enhanced Navigation */}
        <div
          className={`rounded-2xl ${
            darkMode
              ? "bg-gradient-to-r from-gray-900 to-gray-800 border border-gray-800"
              : "bg-gradient-to-r from-gray-50 to-white border border-gray-100"
          } shadow-xl p-2`}
        >
          <div className="flex gap-2 overflow-x-auto">
            {navigationItems.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setView(id)}
                className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm font-medium transition-all ${
                  view === id
                    ? `${darkMode ? "bg-green-600" : "bg-green-500"} text-white shadow-lg`
                    : darkMode
                      ? "hover:bg-gray-800 text-gray-300"
                      : "hover:bg-gray-100 text-gray-600"
                }`}
              >
                <Icon className="w-4 h-4" />
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {view === "overview" && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {mainMetrics.map((section, index) => (
                  <CashFlowMetricCard
                    key={index}
                    title={section.title}
                    metrics={section.metrics}
                    darkMode={darkMode}
                  />
                ))}
              </div>
              <CashFlowRatios
                data={latestData}
                previousData={previousData}
                darkMode={darkMode}
              />
            </>
          )}

          {view === "trends" && (
            <>
              <CashFlowTrendsChart data={processedData} darkMode={darkMode} />
              <CashFlowCompositionChart
                data={processedData}
                darkMode={darkMode}
              />
            </>
          )}

          {view === "details" && (
            <CashFlowDetailedBreakdown
              currentData={latestData}
              previousData={previousData}
              darkMode={darkMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CashFlowVisualization;
