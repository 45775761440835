import React, { useState, useEffect } from "react";
import { FileTextIcon } from "lucide-react";
import { FaTiktok, FaYoutube } from "react-icons/fa";

const VIDEO_TYPES = {
  YOUTUBE: "youtube",
  TIKTOK: "tiktok",
};

const extractVideoId = (url, type) => {
  const patterns = {
    [VIDEO_TYPES.YOUTUBE]:
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    [VIDEO_TYPES.TIKTOK]:
      /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/|v\/|)(\d+)/,
  };
  const match = url.match(patterns[type]);
  return match ? match[1] : null;
};

const VideoPreview = ({ type, videoId }) => {
  const embedUrls = {
    [VIDEO_TYPES.YOUTUBE]: `https://www.youtube.com/embed/${videoId}`,
    [VIDEO_TYPES.TIKTOK]: `https://www.tiktok.com/embed/v2/${videoId}`,
  };

  return (
    <div className="video-preview mt-4">
      <iframe
        className="w-full h-48 sm:h-64 md:h-80 lg:h-96"
        src={embedUrls[type]}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={`${type.charAt(0).toUpperCase() + type.slice(1)} Video Preview`}
      />
    </div>
  );
};

const ContentInput = ({ content, onChange, darkMode }) => {
  const [videoType, setVideoType] = useState(null);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const youtubeId = extractVideoId(content, VIDEO_TYPES.YOUTUBE);
    const tiktokId = extractVideoId(content, VIDEO_TYPES.TIKTOK);

    if (youtubeId) {
      setVideoType(VIDEO_TYPES.YOUTUBE);
      setVideoId(youtubeId);
    } else if (tiktokId) {
      setVideoType(VIDEO_TYPES.TIKTOK);
      setVideoId(tiktokId);
    } else {
      setVideoType(null);
      setVideoId(null);
    }
  }, [content]);

  const handleContentChange = (e) => {
    onChange(e.target.value);
  };

  const getInputStyle = (darkMode) => `
    w-full p-4 pl-12 text-lg border rounded-lg
    ${
      darkMode
        ? "bg-gray-800 text-white border-gray-700"
        : "bg-white text-gray-800 border-gray-300"
    }
    focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300
  `;

  const renderIcon = () => {
    const iconClass = `absolute left-4 top-4 h-6 w-6 ${darkMode ? "text-gray-400" : "text-gray-500"}`;
    switch (videoType) {
      case VIDEO_TYPES.YOUTUBE:
        return <FaYoutube className={iconClass} />;
      case VIDEO_TYPES.TIKTOK:
        return <FaTiktok className={iconClass} />;
      default:
        return <FileTextIcon className={iconClass} />;
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        {renderIcon()}
        {videoType ? (
          <input
            type="text"
            value={content}
            onChange={handleContentChange}
            placeholder={`${videoType.charAt(0).toUpperCase() + videoType.slice(1)} URL detected`}
            className={`${getInputStyle(darkMode)} truncate-ellipsis`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ) : (
          <textarea
            value={content}
            onChange={handleContentChange}
            placeholder="Enter your post or YouTube/TikTok link"
            rows="6"
            className={getInputStyle(darkMode)}
          />
        )}
      </div>
      {videoType && videoId && (
        <VideoPreview type={videoType} videoId={videoId} />
      )}
    </div>
  );
};

export default ContentInput;
