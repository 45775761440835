import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProfile } from "../../contexts/ProfileContext";
import { Mic, MicOff, Video, VideoOff, PhoneOff, User } from "lucide-react";
import LoadingCallPaticipantAnimation from "./LoadingCallPaticipantAnimation";
import { useWebRTC } from "../../hooks/useWebRTC";
import { motion } from "framer-motion";
import CallControlButton from "./CallControlButton";
import CallInterfaceAlert from "./CallInterfaceAlert";

const CallInterface = ({ darkMode }) => {
  const { conversationId } = useParams();
  const { currentlyLoggedInUserProfile } = useProfile();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleEndCall = () => {
    navigate(`/forum/messages/${conversationId}`);
  };

  // Error handler
  const handleError = (error) => {
    setAlertMessage(error);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    navigate("/forum");
  };

  const {
    localStream,
    remoteStreams,
    isAudioEnabled,
    isVideoEnabled,
    toggleAudio,
    toggleVideo,
    endCall,
  } = useWebRTC(
    conversationId,
    token,
    currentlyLoggedInUserProfile,
    handleEndCall,
    handleError, // Pass the error handler to the hook
  );

  return (
    <div
      className={`flex flex-col h-screen ${
        darkMode
          ? "bg-gradient-to-br from-gray-900 to-gray-800"
          : "bg-gradient-to-br from-blue-50 to-indigo-100"
      } text-${darkMode ? "white" : "gray-800"}`}
    >
      <CallInterfaceAlert
        isOpen={alertOpen}
        onClose={handleAlertClose}
        darkMode={darkMode}
        title="Connection Error"
        message={alertMessage}
        buttonText="OK"
      />
      <div className="flex-1 p-4 sm:p-8 flex flex-col items-center justify-center">
        <motion.div
          className="relative w-full max-w-xl mb-4 sm:mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div
            className={`relative ${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-2xl shadow-2xl overflow-hidden`}
          >
            {isVideoEnabled && localStream ? (
              <video
                ref={(video) => {
                  if (video) {
                    video.srcObject = localStream;
                  }
                }}
                autoPlay
                className="w-full h-64 object-cover"
              />
            ) : currentlyLoggedInUserProfile?.profileImageUrl ? (
              <img
                src={currentlyLoggedInUserProfile.profileImageUrl}
                alt="Profile"
                className="w-full h-64 object-cover"
              />
            ) : (
              <User className="w-full h-64 p-16 text-gray-400" />
            )}
            <div className="absolute bottom-0 left-0 right-0 p-2 sm:p-3 bg-black bg-opacity-60 backdrop-blur-sm text-white text-center font-semibold">
              {currentlyLoggedInUserProfile?.username || "You"}
            </div>
          </div>
          {/* Move call control buttons below the video */}
          <div className="flex justify-center space-x-4 mt-4">
            <CallControlButton
              onClick={toggleAudio}
              isEnabled={isAudioEnabled}
              enabledIcon={<Mic />}
              disabledIcon={<MicOff />}
              color={isAudioEnabled ? "bg-green-500" : "bg-red-500"}
            />
            <CallControlButton
              onClick={toggleVideo}
              isEnabled={isVideoEnabled}
              enabledIcon={<Video />}
              disabledIcon={<VideoOff />}
              color={isVideoEnabled ? "bg-green-500" : "bg-red-500"}
            />
            <CallControlButton
              onClick={endCall}
              isEnabled={true}
              enabledIcon={<PhoneOff />}
              disabledIcon={<PhoneOff />}
              color="bg-red-500"
            />
          </div>
        </motion.div>

        {Object.keys(remoteStreams).length === 0 ? (
          <motion.div
            className="mt-8"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <LoadingCallPaticipantAnimation
              darkMode={darkMode}
              text="Waiting for others to join..."
            />
          </motion.div>
        ) : (
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8 w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, staggerChildren: 0.1 }}
          >
            {Object.entries(remoteStreams)
              .filter(([id, { kind }]) => kind === "video")
              .map(([id, { stream, userData, isVideoEnabled }]) => (
                <motion.div
                  key={id}
                  className={`relative ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } rounded-xl shadow-xl overflow-hidden`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  {isVideoEnabled && stream ? (
                    <video
                      ref={(video) => {
                        if (video) {
                          video.srcObject = stream;
                        }
                      }}
                      autoPlay
                      className="w-full h-48 object-cover"
                    />
                  ) : userData?.profileImageUrl ? (
                    <img
                      src={userData.profileImageUrl}
                      alt="Profile"
                      className="w-full h-48 object-cover"
                    />
                  ) : (
                    <User className="w-full h-48 p-12 text-gray-400" />
                  )}
                  <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-60 backdrop-blur-sm text-white text-center font-semibold">
                    {userData?.username || "Remote User"}
                  </div>
                </motion.div>
              ))}
          </motion.div>
        )}
      </div>
      {Object.entries(remoteStreams)
        .filter(([id, { kind }]) => kind === "audio")
        .map(([id, { stream }]) => (
          <audio
            key={id}
            ref={(audio) => {
              if (audio) {
                audio.srcObject = stream;
              }
            }}
            autoPlay
          />
        ))}
    </div>
  );
};

export default CallInterface;
