import React from "react";
import followerItem from "./FollowerItem";
import noFollowers from "./NoFollowers";

const FollowersList = ({ followers, darkMode }) => {
  const isDarkMode = darkMode;
  const textColor = isDarkMode ? "text-green-400" : "text-green-600";
  const secondaryTextColor = isDarkMode ? "text-white" : "text-gray-900";

  return (
    <div className={`p-4 rounded-lg`}>
      <h2 className={`text-2xl font-bold ${textColor} mb-2`}>Your Followers</h2>
      <p className={`${secondaryTextColor} mb-4`}>
        These are the people who follow you. You can interact with them and
        manage your connections here.
      </p>

      {!followers || followers.length === 0 ? (
        noFollowers(textColor, secondaryTextColor)
      ) : (
        <ul className="space-y-4">{followers.map(followerItem)}</ul>
      )}
    </div>
  );
};

export default FollowersList;
