import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import { scroller } from "react-scroll";

const NavButton = ({
  to,
  icon: Icon,
  iconProps = {},
  children,
  isScrollNavigation,
  onClick,
  className = "",
  underlineStyleClass = "",
  isExternal = false,
}) => {
  const { darkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const buttonClasses = `
    flex items-center justify-center
    px-6 py-3 rounded-full
    text-sm font-semibold uppercase tracking-wider
    transition-all duration-300 ease-in-out
    shadow-md hover:shadow-lg
    ${
      darkMode
        ? "bg-gray-800 hover:bg-gray-700 text-white hover:text-green-300"
        : "bg-white hover:bg-gray-100 text-green-600 hover:text-green-700"
    }
    focus:outline-none focus:ring-2 focus:ring-offset-2 
    ${darkMode ? "focus:ring-green-500" : "focus:ring-green-400"}
    transform hover:-translate-y-0.5
    border ${darkMode ? "border-green-400" : "border-green-600"}
    ${className} // Allow override via className prop
  `;

  const defaultIconClasses = `
    w-5 h-5 mr-3 transition-transform group-hover:scale-110
    ${darkMode ? "text-white" : "text-green-600"}
  `;

  const containerVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
    tap: {
      scale: 0.95,
      transition: { duration: 0.1 },
    },
  };

  const handleClick = (event) => {
    if (isExternal) {
      if (onClick) {
        onClick(event);
      }
      return;
    }

    event.preventDefault();

    if (onClick) {
      onClick(event);
    } else if (isScrollNavigation && location.pathname === "/") {
      scroller.scrollTo(to, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: to === "hero-section" ? 0 : -96,
      });
    } else {
      navigate(to);
    }
  };

  const Content = () => (
    <span className="flex items-center group">
      {Icon && (
        <Icon
          className={`${defaultIconClasses} ${iconProps.className || ""}`}
          {...iconProps}
        />
      )}
      <span className="relative">
        {children}
        <span
          className={`
          absolute left-0 right-0 bottom-0 h-0.5 transform scale-x-0 
          group-hover:scale-x-100 transition-transform duration-300 ease-out
          ${darkMode ? "bg-green-400" : "bg-green-600"} 
          ${underlineStyleClass} // Allow override via underlineStyleClass prop
        `}
        />
      </span>
    </span>
  );

  const ButtonContent = (
    <motion.div variants={containerVariants} whileHover="hover" whileTap="tap">
      <Content />
    </motion.div>
  );

  return isExternal ? (
    <a
      href={to}
      className={buttonClasses}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {ButtonContent}
    </a>
  ) : (
    <Link to={to} className={buttonClasses} onClick={handleClick}>
      {ButtonContent}
    </Link>
  );
};

export default NavButton;
