import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { motion } from "framer-motion";
import { FaCheckCircle, FaTimesCircle, FaSpinner } from "react-icons/fa";

const EmailVerification = () => {
  const { token } = useParams();
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  const { verifyEmail, verificationStatus, loading } = useAuthContext();

  useEffect(() => {
    if (token) {
      verifyEmail(token);
    }
  }, []);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex flex-col items-center">
          <FaSpinner className="animate-spin text-4xl mb-4 text-green-500" />
          <p className="text-lg">Verifying your email...</p>
        </div>
      );
    }

    switch (verificationStatus) {
      case "success":
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center"
          >
            <FaCheckCircle className="text-green-500 text-6xl mb-6" />
            <p className="text-xl mb-6">
              Your email has been successfully verified!
            </p>
            <button
              onClick={() => navigate("/forum/login")}
              className={`mt-4 px-6 py-3 rounded-full text-lg font-semibold ${
                darkMode
                  ? "bg-green-600 hover:bg-green-700"
                  : "bg-green-500 hover:bg-green-600"
              } text-white transition-colors duration-300 shadow-lg hover:shadow-xl`}
            >
              Proceed to Login
            </button>
          </motion.div>
        );
      case "error":
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center"
          >
            <FaTimesCircle className="text-red-500 text-6xl mb-6" />
            <p className="text-xl mb-6">
              Verification failed. The link may be invalid or expired.
            </p>
            <button
              onClick={() => navigate("/forum/register")}
              className={`mt-4 px-6 py-3 rounded-full text-lg font-semibold ${
                darkMode
                  ? "bg-green-600 hover:bg-green-700"
                  : "bg-green-500 hover:bg-green-600"
              } text-white transition-colors duration-300 shadow-lg hover:shadow-xl`}
            >
              Return to Registration
            </button>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`w-full max-w-md mx-auto p-8 sm:p-12 rounded-2xl shadow-2xl text-center ${
          darkMode ? "text-white" : "text-gray-900"
        }`}
      >
        <h2
          className={`text-4xl sm:text-5xl font-bold mb-8 ${
            darkMode ? "text-green-400" : "text-green-600"
          }`}
        >
          Email Verification
        </h2>
        {renderContent()}
      </motion.div>
    </div>
  );
};

export default EmailVerification;
