import React from "react";

const TabButton = ({ id, label, icon: Icon, isActive, onClick, darkMode }) => (
  <button
    onClick={() => onClick(id)}
    className={`flex items-center justify-center sm:justify-start gap-2.5 px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-300 transform ${
      isActive
        ? darkMode
          ? "bg-green-500 text-white shadow-lg shadow-green-500/20 scale-105"
          : "bg-green-500 text-white shadow-lg shadow-green-500/20 scale-105"
        : darkMode
          ? "bg-gray-700/50 hover:bg-gray-700 text-gray-300 hover:text-white"
          : "bg-white hover:bg-gray-50 text-gray-600 hover:text-gray-900"
    }`}
  >
    <Icon
      className={`w-5 h-5 transition-transform duration-300 ${
        isActive ? "scale-110" : ""
      }`}
    />
    <span className="hidden sm:inline">{label}</span>
  </button>
);

export default TabButton;
