import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuth } from "../../contexts/AuthContext";
import { useProfile } from "../../contexts/ProfileContext";
import { useUserSubscription } from "../../contexts/UserSubscriptionContext";
import { motion } from "framer-motion";
import ProfileForm from "./ProfileForm";
import ProfileView from "./ProfileView";
import SubscriptionSection from "../subscription/common/SubscriptionSection";

const ProfilePage = () => {
  const { userId } = useParams();
  const { darkMode } = useTheme();
  const { profile, fetchProfile } = useProfile();
  const { user } = useAuth();

  const isUserOwnerOfProfile = user?.id === profile?.id;

  useEffect(() => {
    fetchProfile(userId);
  }, [userId, fetchProfile]);

  const textClass = darkMode ? "text-white" : "text-gray-800";

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className={`max-w-4xl mx-auto ${textClass} rounded-xl shadow-2xl p-4 sm:p-6 md:p-8 my-4 sm:my-8 md:my-12`}
    >
      {isUserOwnerOfProfile ? (
        <>
          <ProfileForm
            darkMode={darkMode}
            profile={profile}
            authenticatedUser={user}
          />
        </>
      ) : (
        <ProfileView darkMode={darkMode} userId={userId} />
      )}
    </motion.div>
  );
};

export default ProfilePage;
