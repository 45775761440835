import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FaRocket } from "react-icons/fa";
import NavButton from "../menu/NavButton";

const CTASection = ({ id }) => {
  const { darkMode } = useTheme();

  const features = [
    { icon: "🚀", text: "Quick Start" },
    { icon: "💰", text: "High Commissions" },
    { icon: "📈", text: "Rapid Growth" },
    { icon: "👥", text: "Large Audience" },
    { icon: "🏆", text: "Top-Rated Product" },
    { icon: "🕒", text: "24/7 Support" },
    { icon: "🔒", text: "Secure Payments" },
    { icon: "📊", text: "Real-Time Analytics" },
    { icon: "🎓", text: "Comprehensive Training" },
  ];

  return (
    <section
      id={id}
      className={`py-20 ${
        darkMode
          ? "bg-gradient-to-br from-gray-900 to-gray-800"
          : "bg-gradient-to-br from-green-50 to-blue-100"
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-2xl overflow-hidden">
          <div className={`p-10 ${darkMode ? "bg-green-700" : "bg-green-600"}`}>
            <h2 className="text-5xl font-extrabold mb-6 text-white text-center leading-tight">
              Ready to Unlock Your Earning Potential?
            </h2>
            <p className="text-2xl text-green-100 mb-8 text-center">
              Join our affiliate program today and start promoting
              high-converting products to skyrocket your income.
            </p>
          </div>
          <div
            className={`p-10 ${darkMode ? "bg-gray-800 text-white" : "bg-white"}`}
          >
            <h3 className="text-3xl font-bold mb-8 text-center">
              Why Choose Our Affiliate Program?
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-center p-4 bg-opacity-10 bg-green-500 rounded-lg transition-all duration-300 hover:bg-opacity-20"
                >
                  <span className="text-4xl mr-4">{feature.icon}</span>
                  <span className="text-xl font-semibold">{feature.text}</span>
                </div>
              ))}
            </div>
            <div className="flex justify-center mb-8 w-full">
              <NavButton
                to="https://www.clickbank.com/"
                icon={FaRocket}
                isExternal={true}
                className="lg:w-1/4 md:w-1/2 sm:w-3/4"
              >
                Get Started Now
              </NavButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
