import React from "react";

const CreateEditPostActionButtons = ({
  darkMode,
  isLoading,
  isFormValid,
  onCancel,
  isEditMode,
}) => {
  return (
    <div className="flex justify-between">
      <button
        type="button"
        onClick={onCancel}
        disabled={isLoading}
        className={`p-3 rounded-md ${
          darkMode
            ? "bg-gray-700 text-white hover:bg-gray-600"
            : "bg-gray-200 text-gray-800 hover:bg-gray-300"
        } transition-colors duration-200`}
      >
        Cancel
      </button>

      <button
        type="submit"
        disabled={isLoading || !isFormValid}
        className={`p-3 rounded-md bg-green-600 text-white hover:bg-green-500 transition-colors duration-200 ${
          (isLoading || !isFormValid) && "opacity-50 cursor-not-allowed"
        }`}
      >
        {isEditMode ? "Update Post" : "Create Post"}
      </button>
    </div>
  );
};

export default CreateEditPostActionButtons;
