import React from "react";
import { TrendingUp, TrendingDown, ArrowRight } from "lucide-react";

const CashFlowMetricCard = ({ title, metrics, darkMode }) => {
  const formatValue = (value, isRatio = false) => {
    if (isRatio) {
      return `${(value * 100).toFixed(2)}%`;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);
  };

  const getValueColor = (value) => {
    if (value > 0) return darkMode ? "text-green-400" : "text-green-600";
    if (value < 0) return darkMode ? "text-red-400" : "text-red-600";
    return darkMode ? "text-gray-400" : "text-gray-600";
  };

  const getBackgroundColor = (value) => {
    if (value > 0) return darkMode ? "bg-green-400/10" : "bg-green-50";
    if (value < 0) return darkMode ? "bg-red-400/10" : "bg-red-50";
    return darkMode ? "bg-gray-700" : "bg-gray-100";
  };

  const getBorderColor = (value) => {
    if (value > 0) return darkMode ? "border-green-400/20" : "border-green-100";
    if (value < 0) return darkMode ? "border-red-400/20" : "border-red-100";
    return darkMode ? "border-gray-700" : "border-gray-200";
  };

  return (
    <div
      className={`rounded-2xl overflow-hidden shadow-xl ${
        darkMode
          ? "bg-gray-900 border border-gray-800"
          : "bg-white border border-gray-100"
      }`}
    >
      {/* Header with gradient */}
      <div
        className={`px-6 py-6 ${
          darkMode
            ? "bg-gradient-to-r from-gray-900 to-gray-800"
            : "bg-gradient-to-r from-gray-50 to-white"
        }`}
      >
        <h3
          className={`text-2xl font-semibold tracking-tight ${
            darkMode ? "text-white" : "text-gray-900"
          }`}
        >
          {title}
        </h3>
        <p
          className={`mt-2 text-sm ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          Key performance indicators and trends
        </p>
      </div>

      {/* Content */}
      <div
        className={`p-6 ${
          darkMode
            ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50"
            : "bg-gradient-to-br from-gray-50/50 to-white/50"
        }`}
      >
        <div className="space-y-4">
          {metrics.map((metric, index) => (
            <div
              key={index}
              className={`group relative rounded-xl p-4 transition-all duration-300 hover:scale-102 ${
                darkMode ? "hover:bg-gray-800" : "hover:bg-gray-50"
              } border ${getBorderColor(metric.value)}`}
            >
              {/* Metric Header */}
              <div className="flex justify-between items-center mb-2">
                <span
                  className={`text-sm font-medium ${
                    darkMode ? "text-gray-300" : "text-gray-700"
                  }`}
                >
                  {metric.label}
                </span>
                <div
                  className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm ${getBackgroundColor(
                    metric.value,
                  )}`}
                >
                  {metric.value > 0 ? (
                    <TrendingUp className="w-4 h-4 text-green-500" />
                  ) : metric.value < 0 ? (
                    <TrendingDown className="w-4 h-4 text-red-500" />
                  ) : null}
                  <span
                    className={`font-semibold ${getValueColor(metric.value)}`}
                  >
                    {formatValue(metric.value, metric.isRatio)}
                  </span>
                </div>
              </div>

              {/* Progress Bar */}
              <div className="mt-2">
                <div className="w-full h-1 rounded-full bg-gray-200 dark:bg-gray-700 overflow-hidden">
                  <div
                    className="h-full transition-all duration-300"
                    style={{
                      width: `${Math.min(Math.abs(metric.value * 100), 100)}%`,
                      backgroundColor: metric.value > 0 ? "#10B981" : "#EF4444",
                    }}
                  />
                </div>
              </div>

              {/* Hover Detail Arrow */}
              <div
                className={`absolute right-4 top-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 group-hover:opacity-100 ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                <ArrowRight className="w-4 h-4" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CashFlowMetricCard;
