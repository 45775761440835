import React from "react";

const CandleColorCustomization = ({
  darkMode,
  chartType,
  candleUpColor,
  setCandleUpColor,
  candleDownColor,
  setCandleDownColor,
}) => {
  if (chartType !== "candlestick") return null;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
      <div className="space-y-3">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Bullish Candle Color
        </label>
        <input
          type="color"
          value={candleUpColor}
          onChange={(e) => setCandleUpColor(e.target.value)}
          className="w-full h-12 rounded-xl cursor-pointer"
        />
      </div>
      <div className="space-y-3">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Bearish Candle Color
        </label>
        <input
          type="color"
          value={candleDownColor}
          onChange={(e) => setCandleDownColor(e.target.value)}
          className="w-full h-12 rounded-xl cursor-pointer"
        />
      </div>
    </div>
  );
};

export default CandleColorCustomization;
