import { useState, useEffect, useRef } from "react";

const useAssetCandlestickData = ({ market_type, symbols, candleInterval }) => {
  const [candlestickData, setCandlestickData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const workerRef = useRef(null);

  useEffect(() => {
    setCandlestickData([]);
    setFullData([]);
    setLoading(true);
    setError(null);

    if (workerRef.current) {
      workerRef.current.terminate();
    }

    workerRef.current = new Worker(
      new URL("../workers/candlestickWorker.js", import.meta.url),
    );

    workerRef.current.onmessage = (event) => {
      const { type, payload } = event.data;
      switch (type) {
        case "candlestickData":
          setCandlestickData((prevData) => [...prevData, payload]);
          break;
        case "fullData":
          setFullData((prevData) => [...prevData, payload]);
          break;
        case "loading":
          setLoading(payload);
          break;
        case "error":
          setError(payload);
          break;
        default:
          console.warn("Unknown message type:", type);
      }
    };

    workerRef.current.postMessage({
      action: "initialize",
      market_type,
      symbols,
      candleInterval: parseInt(candleInterval, 10),
    });

    return () => {
      if (workerRef.current) {
        workerRef.current.postMessage({
          action: "unsubscribe",
          symbols,
        });
        workerRef.current.terminate();
      }
    };
  }, [market_type, symbols, candleInterval]);

  const refreshData = () => {
    setCandlestickData([]);
    setFullData([]);
    setLoading(true);

    if (workerRef.current) {
      workerRef.current.postMessage({
        action: "refresh",
        market_type,
        symbols,
        candleInterval: parseInt(candleInterval, 10),
      });
    }
  };

  return {
    candlestickData,
    fullData,
    loading,
    error,
    refreshData,
  };
};

export default useAssetCandlestickData;
