import { useState, useEffect } from "react";
import { getPremiumApiUrl } from "../config/api";

/**
 * Custom hook to fetch and filter symbols from your backend.
 *
 * @param {string} exchangeCode - The code of the exchange (e.g., 'US', 'CC', 'FOREX').
 * @param {string} symbolType - The type of symbol to filter (e.g., 'common_stock'). Defaults to 'common_stock'.
 * @returns {object} An object containing symbols, loading state, error, and a function to set the query.
 */
export const useSymbolSearch = (exchangeCode, symbolType = "common_stock") => {
  const [symbols, setSymbols] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!exchangeCode) return;

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchSymbols = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = new URLSearchParams({
          exchange: exchangeCode,
        });

        // Include 'symbol_type' parameter only if exchange is not 'CC' or 'FOREX'
        if (symbolType && exchangeCode !== "CC" && exchangeCode !== "FOREX") {
          params.append("symbol_type", symbolType);
        }

        if (query) {
          params.append("q", query);
        }

        const response = await fetch(
          `${getPremiumApiUrl()}/api/symbols/search?${params.toString()}`,
          {
            signal,
          },
        );
        if (!response.ok) {
          throw new Error(`Error fetching symbols: ${response.statusText}`);
        }
        const data = await response.json();
        setSymbols(data);
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSymbols();
    return () => {
      controller.abort();
    };
  }, [exchangeCode, query, symbolType]);

  return { symbols, loading, error, setQuery };
};
