import React, { useEffect, useState } from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryTheme,
} from "victory";
import {
  Calendar,
  TrendingUp,
  Hash,
  ExternalLink,
  Newspaper,
} from "lucide-react";
import useFinancialNews from "../../../../hooks/useFinancialNews";

const AVAILABLE_TAGS = [
  "balance sheet",
  "capital employed",
  "class action",
  "company announcement",
  "consensus eps estimate",
  "consensus estimate",
  "credit rating",
  "discounted cash flow",
  "dividend payments",
  "earnings estimate",
  "earnings growth",
  "earnings per share",
  "earnings release",
  "earnings report",
  "earnings results",
  "earnings surprise",
  "estimate revisions",
  "european regulatory news",
  "financial results",
  "fourth quarter",
  "free cash flow",
  "future cash flows",
  "growth rate",
  "initial public offering",
  "insider ownership",
  "insider transactions",
  "institutional investors",
  "institutional ownership",
  "intrinsic value",
  "market research reports",
  "net income",
  "operating income",
  "present value",
  "press releases",
  "price target",
  "quarterly earnings",
  "quarterly results",
  "ratings",
  "research analysis and reports",
  "return on equity",
  "revenue estimates",
  "revenue growth",
  "roce",
  "roe",
  "share price",
  "shareholder rights",
  "shareholder",
  "shares outstanding",
  "split",
  "strong buy",
  "total revenue",
  "zacks investment research",
  "zacks rank",
];

const StockNewsAndSentiment = ({ symbol, darkMode }) => {
  const {
    newsData,
    sentimentData,
    loading,
    error,
    fetchNewsBySymbol,
    fetchNewsByTag,
    fetchSentiment,
  } = useFinancialNews();
  const [activeTab, setActiveTab] = useState("news");
  const [selectedTag, setSelectedTag] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (symbol) {
        await fetchNewsBySymbol(symbol);
        const today = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);
        await fetchSentiment(
          [symbol],
          threeMonthsAgo.toISOString().split("T")[0],
          today.toISOString().split("T")[0],
        );
      }
    };
    fetchData();
  }, [symbol, fetchNewsBySymbol, fetchSentiment]);

  useEffect(() => {
    if (selectedTag) {
      fetchNewsByTag(selectedTag);
    }
  }, [selectedTag, fetchNewsByTag]);

  const symbolKey = Object.keys(sentimentData).find((key) =>
    key.includes(symbol),
  );
  const sentimentChartData =
    sentimentData[symbolKey]?.map((data) => ({
      x: new Date(data.date),
      y: data.normalized,
    })) ?? [];

  const NewsCard = ({ news }) => (
    <div
      className={`rounded-xl p-6 transition-all duration-300 hover:scale-[1.02] hover:shadow-xl
        ${
          darkMode
            ? "bg-slate-800 shadow-lg shadow-slate-900/20 border border-slate-700"
            : "bg-white shadow-lg shadow-slate-200/50 border border-slate-100"
        }`}
    >
      <div className="space-y-4">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <Newspaper
              className={`w-6 h-6 ${
                darkMode ? "text-green-400" : "text-green-600"
              }`}
            />
          </div>
          <div>
            <h3
              className={`text-lg font-semibold ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              {news.title}
            </h3>
            <div className="mt-2 flex flex-wrap items-center space-x-4 text-sm">
              <div className="flex items-center space-x-1">
                <Calendar className="w-4 h-4" />
                <span
                  className={`${
                    darkMode ? "text-slate-400" : "text-slate-600"
                  }`}
                >
                  {new Date(news.date).toLocaleDateString()}
                </span>
              </div>
              {news.symbols && (
                <div className="flex flex-wrap items-center space-x-2">
                  {news.symbols.map((sym) => (
                    <span
                      key={sym}
                      className={`px-2 py-1 text-xs rounded-full font-medium
                        ${
                          darkMode
                            ? "bg-green-900/30 text-green-300"
                            : "bg-green-100 text-green-700"
                        }`}
                    >
                      {sym}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <p
          className={`text-sm ${
            darkMode ? "text-slate-300" : "text-slate-700"
          }`}
        >
          {news.content}
        </p>
        <div className="flex justify-end">
          <a
            href={news.link}
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-flex items-center space-x-1 text-sm font-medium transition-colors
              ${
                darkMode
                  ? "text-blue-400 hover:text-blue-300"
                  : "text-blue-600 hover:text-blue-700"
              }`}
          >
            <span>Read full article</span>
            <ExternalLink className="w-4 h-4" />
          </a>
        </div>
      </div>
    </div>
  );

  if (error) {
    return (
      <div
        className={`p-6 rounded-xl ${
          darkMode ? "bg-red-900/20 text-red-300" : "bg-red-50 text-red-600"
        }`}
      >
        Error: {error}
      </div>
    );
  }

  const TabButton = ({ icon: Icon, label, isActive }) => (
    <button
      onClick={() => setActiveTab(label.toLowerCase())}
      className={`flex-1 py-2 text-sm font-medium transition-colors duration-200
        ${
          isActive
            ? darkMode
              ? "text-green-400 border-b-2 border-green-400"
              : "text-green-600 border-b-2 border-green-600"
            : darkMode
              ? "text-slate-400 hover:text-slate-200"
              : "text-slate-600 hover:text-slate-900"
        }`}
    >
      <div className="flex items-center justify-center space-x-2">
        <Icon className="w-4 h-4" />
        <span>{label}</span>
      </div>
    </button>
  );

  return (
    <div className="p-4 sm:p-6">
      <div
        className={`rounded-xl ${
          darkMode
            ? "bg-slate-800 shadow-lg shadow-slate-900/20 border border-slate-700"
            : "bg-white shadow-lg shadow-slate-200/50 border border-slate-100"
        }`}
      >
        {/* Header */}
        <div className="p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <div>
              <h2
                className={`text-xl sm:text-2xl font-bold flex items-center space-x-2 ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                <Newspaper className="w-6 h-6" />
                <span>{symbol} News & Sentiment</span>
              </h2>
              <p
                className={`mt-2 text-sm ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                Latest updates and market sentiment analysis
              </p>
            </div>
            {loading && (
              <div
                className={`mt-4 sm:mt-0 px-4 py-2 rounded-full text-sm ${
                  darkMode
                    ? "bg-slate-700 text-slate-300"
                    : "bg-slate-100 text-slate-600"
                }`}
              >
                Loading...
              </div>
            )}
          </div>
        </div>

        {/* Tabs */}
        <div
          className={`border-b ${
            darkMode ? "border-slate-700" : "border-slate-200"
          }`}
        >
          <div className="flex">
            <TabButton
              icon={Calendar}
              label="News"
              isActive={activeTab === "news"}
            />
            <TabButton
              icon={TrendingUp}
              label="Sentiment"
              isActive={activeTab === "sentiment"}
            />
            <TabButton
              icon={Hash}
              label="Tags"
              isActive={activeTab === "tags"}
            />
          </div>
        </div>

        {/* Content */}
        <div className="p-4 sm:p-6">
          {activeTab === "news" && (
            <div className="grid grid-cols-1 gap-4">
              {newsData.map((news, index) => (
                <NewsCard key={index} news={news} />
              ))}
            </div>
          )}

          {activeTab === "sentiment" && (
            <div
              className={`rounded-xl p-4 sm:p-6 ${
                darkMode
                  ? "bg-slate-800 shadow-lg shadow-slate-900/20 border border-slate-700"
                  : "bg-white shadow-lg shadow-slate-200/50 border border-slate-100"
              }`}
            >
              <h3
                className={`text-lg font-semibold mb-4 ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                Market Sentiment Analysis
              </h3>
              <div className="w-full">
                <VictoryChart
                  theme={VictoryTheme.material}
                  containerComponent={
                    <VictoryVoronoiContainer
                      voronoiDimension="x"
                      labels={({ datum }) => `Sentiment: ${datum.y.toFixed(2)}`}
                      labelComponent={
                        <VictoryTooltip
                          style={{
                            fontSize: 12,
                            fill: darkMode ? "white" : "black",
                          }}
                          flyoutStyle={{
                            fill: darkMode ? "rgba(15, 23, 42, 0.9)" : "white",
                            stroke: darkMode
                              ? "rgba(255,255,255,0.2)"
                              : "rgba(0,0,0,0.2)",
                            strokeWidth: 1,
                          }}
                          cornerRadius={8}
                        />
                      }
                    />
                  }
                  padding={{ top: 20, bottom: 50, left: 60, right: 20 }}
                  scale={{ x: "time" }}
                  style={{ parent: { maxWidth: "100%", height: "auto" } }}
                >
                  <VictoryAxis
                    tickFormat={(date) => new Date(date).toLocaleDateString()}
                    style={{
                      axis: {
                        stroke: darkMode ? "#334155" : "#E2E8F0",
                      },
                      tickLabels: {
                        fill: darkMode ? "#9CA3AF" : "#475569",
                        fontSize: 10,
                        padding: 8,
                      },
                      grid: { stroke: "none" },
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    domain={[-1, 1]}
                    style={{
                      axis: {
                        stroke: darkMode ? "#334155" : "#E2E8F0",
                      },
                      tickLabels: {
                        fill: darkMode ? "#9CA3AF" : "#475569",
                        fontSize: 10,
                        padding: 8,
                      },
                      grid: {
                        stroke: darkMode
                          ? "rgba(148, 163, 184, 0.2)"
                          : "rgba(203, 213, 225, 0.8)",
                        strokeDasharray: "4, 4",
                      },
                    }}
                  />
                  <VictoryLine
                    data={sentimentChartData}
                    style={{
                      data: {
                        stroke: darkMode ? "#38BDF8" : "#3B82F6",
                        strokeWidth: 2.5,
                      },
                    }}
                  />
                </VictoryChart>
              </div>
            </div>
          )}

          {activeTab === "tags" && (
            <div className="space-y-6">
              <div
                className={`p-4 sm:p-6 rounded-xl ${
                  darkMode
                    ? "bg-slate-800 shadow-lg shadow-slate-900/20 border border-slate-700"
                    : "bg-white shadow-lg shadow-slate-200/50 border border-slate-100"
                }`}
              >
                <select
                  value={selectedTag}
                  onChange={(e) => setSelectedTag(e.target.value)}
                  className={`w-full p-3 text-sm rounded-lg border transition-colors
                    ${
                      darkMode
                        ? "bg-slate-700 text-white border-slate-600 focus:border-blue-500"
                        : "bg-white text-slate-900 border-slate-300 focus:border-blue-500"
                    }`}
                >
                  <option value="">Select a news tag...</option>
                  {AVAILABLE_TAGS.map((tag) => (
                    <option key={tag} value={tag}>
                      {tag}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid grid-cols-1 gap-4">
                {newsData.map((news, index) => (
                  <NewsCard key={index} news={news} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StockNewsAndSentiment;
