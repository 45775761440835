import { useState, useCallback } from "react";
import { getApiUrl } from "../config/api";

const useSharePost = (initialShareCount) => {
  const [shareCount, setShareCount] = useState(initialShareCount);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sharePost = useCallback(async (postId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${getApiUrl()}/api/posts/${postId}/share`, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to share post");
      }

      const data = await response.json();
      setShareCount(data.shareCount);
      return true;
    } catch (err) {
      setError("Failed to share post. Please try again later.");
      console.error("Error sharing post:", err);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    sharePost,
    shareCount,
    loading,
    error,
  };
};

export default useSharePost;
