import React, { useEffect, useRef } from "react";
import { User, Clock } from "lucide-react";

const MessageList = ({ messages, darkMode, currentUserId }) => {
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffMinutes = Math.floor((now - date) / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
    } else {
      return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    }
  };

  const extractVideoId = (url) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const tiktokRegex =
      /https:\/\/(?:www\.)?tiktok\.com\/@[\w.-]+\/video\/(\d+)/;

    const youtubeMatch = url.match(youtubeRegex);
    const tiktokMatch = url.match(tiktokRegex);

    if (youtubeMatch) return { type: "youtube", id: youtubeMatch[1] };
    if (tiktokMatch) return { type: "tiktok", id: tiktokMatch[1] };
    return null;
  };

  const renderVideoEmbed = (url) => {
    const video = extractVideoId(url);
    if (!video) return null;

    if (video.type === "youtube") {
      return (
        <iframe
          width="100%"
          height="200"
          src={`https://www.youtube.com/embed/${video.id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mt-2 rounded-lg"
        ></iframe>
      );
    } else if (video.type === "tiktok") {
      return (
        <iframe
          src={`https://www.tiktok.com/embed/v2/${video.id}`}
          width="100%"
          height="300"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mt-2 rounded-lg"
        ></iframe>
      );
    }
  };

  const sortedMessages = [...messages].sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  );

  return (
    <div
      ref={messagesContainerRef}
      className={`space-y-6 mb-4 overflow-y-auto px-6 py-4 scrollbar-hide flex flex-col`}
      style={{ maxHeight: "calc(100vh - 200px)" }}
    >
      {sortedMessages.map((message) => {
        const isCurrentUser = message.sender.id === currentUserId;
        const messageAlign = isCurrentUser ? "justify-end" : "justify-start";
        const bubbleColor = isCurrentUser
          ? "bg-green-500 text-white"
          : darkMode
            ? "bg-gray-700 text-white"
            : "bg-white text-gray-900";
        const textAlign = isCurrentUser ? "text-right" : "text-left";

        return (
          <div
            key={message.id}
            className={`flex ${messageAlign} items-end animate-fadeIn mb-6`}
          >
            <div className={`flex flex-col ${textAlign} max-w-xs lg:max-w-md`}>
              <div
                className={`flex items-center space-x-2 mb-1 ${isCurrentUser ? "justify-end" : "justify-start"}`}
              >
                <span
                  className={`font-semibold text-sm ${darkMode ? "text-gray-300" : "text-gray-700"}`}
                >
                  {message.sender.username}
                </span>
                <span
                  className={`text-xs flex items-center ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                >
                  <Clock size={12} className="mr-1" />
                  {formatDate(message.createdAt)}
                </span>
              </div>
              <div className={`p-3 rounded-lg shadow-md ${bubbleColor}`}>
                {message.imageUrl && (
                  <img
                    src={message.imageUrl}
                    alt="Shared image"
                    className="max-w-full h-auto rounded-lg mb-2"
                  />
                )}
                <p className="break-words text-sm">{message.content}</p>
                {renderVideoEmbed(message.content)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageList;
