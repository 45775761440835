import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaHome,
  FaPlus,
  FaSearch,
  FaEnvelope,
  FaSignOutAlt,
  FaUser,
  FaCrown,
  FaBolt,
} from "react-icons/fa";
import { Moon, Sun, Menu, X, Bell, BellOff } from "lucide-react";
import { useAlerts } from "../../contexts/AlertsContext";
import { useProfile } from "../../contexts/ProfileContext";
import { useAuth } from "../../contexts/AuthContext";

const Sidebar = ({ onLogout, darkMode, toggleTheme }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { hasUnreadAlerts } = useAlerts();
  const { currentlyLoggedInUserProfile, getCurrentUserProfile } = useProfile();
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated && user) {
      getCurrentUserProfile();
    }
  }, [isAuthenticated, user, getCurrentUserProfile]);

  const navItems = [
    { name: "Home", to: "/forum", icon: FaHome },
    { name: "Create Post", to: "/forum/create", icon: FaPlus },
    { name: "Search Users", to: "/forum/users/search", icon: FaSearch },
    { name: "Messages", to: "/forum/messages", icon: FaEnvelope },
    {
      name: "Notifications",
      to: "/forum/notifications",
      icon: ({ className }) => <AlertsBellIcon className={className} />,
    },
    {
      name: "Premium Plans",
      to: "/forum/pricing",
      icon: FaBolt,
    },
    {
      name: "Premium Features",
      to: "/forum/premium-features",
      icon: FaCrown,
    },
  ];

  if (isAuthenticated) {
    navItems.push({
      name: "Logout",
      to: "#",
      icon: FaSignOutAlt,
      onClick: onLogout,
    });
  } else {
    navItems.push({ name: "Login", to: "/forum/login", icon: FaSignOutAlt });
  }

  const toggleSidebar = () => setIsOpen(!isOpen);

  const AlertsBellIcon = ({ className }) => {
    return hasUnreadAlerts ? (
      <Bell
        className={`${className} text-yellow-500 animate-pulse transition-colors duration-300`}
      />
    ) : (
      <BellOff className={`${className} transition-colors duration-300`} />
    );
  };

  const ProfileImage = () => {
    if (currentlyLoggedInUserProfile?.profileImageUrl) {
      return (
        <img
          src={currentlyLoggedInUserProfile.profileImageUrl}
          alt="Profile"
          className="w-8 h-8 rounded-full object-cover"
        />
      );
    } else {
      return (
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center ${darkMode ? "bg-white text-gray-900" : "bg-green-600 text-white"}`}
        >
          <FaUser className="text-sm" />
        </div>
      );
    }
  };

  return (
    <>
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-50 md:hidden bg-green-500 p-2 rounded-full shadow-lg"
      >
        {isOpen ? (
          <X size={24} className="text-white" />
        ) : (
          <Menu size={24} className="text-white" />
        )}
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      <aside
        className={`w-72 h-screen fixed left-0 top-0 z-50 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 transition-transform duration-300 ease-in-out ${
          darkMode ? "bg-gray-900 text-gray-100" : "bg-white text-gray-800"
        } shadow-xl flex flex-col`}
      >
        <div className="p-6 mb-8 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
          <Link
            to="/forum"
            className="text-2xl font-bold tracking-tight text-yellow-500 hover:text-yellow-600 transition-colors duration-200"
          >
            QMC Forum
          </Link>
          <button
            onClick={toggleSidebar}
            className="md:hidden text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X size={24} />
          </button>
        </div>
        <nav className="flex-grow overflow-y-auto space-y-4 px-4">
          {isAuthenticated && (
            <Link
              to={`/forum/profile/${currentlyLoggedInUserProfile?.id || user?.id}`}
              className={`flex items-center space-x-4 px-4 py-3 rounded-lg transition-all duration-200 ${
                location.pathname ===
                `/forum/profile/${currentlyLoggedInUserProfile?.id || user?.id}`
                  ? darkMode
                    ? "bg-gray-800 text-green-400"
                    : "bg-green-100 text-green-800"
                  : darkMode
                    ? "hover:bg-gray-800 hover:text-green-400"
                    : "hover:bg-green-50 hover:text-green-700"
              }`}
              onClick={toggleSidebar}
            >
              <ProfileImage />
              <span className="font-medium text-lg">
                {currentlyLoggedInUserProfile?.username ||
                  user?.username ||
                  "Profile"}
              </span>
            </Link>
          )}
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.to}
              className={`flex items-center space-x-4 px-4 py-3 rounded-lg transition-all duration-200 ${
                location.pathname === item.to
                  ? darkMode
                    ? "bg-gray-800 text-green-400"
                    : "bg-green-100 text-green-800"
                  : darkMode
                    ? "hover:bg-gray-800 hover:text-green-400"
                    : "hover:bg-green-50 hover:text-green-700"
              }`}
              onClick={(e) => {
                if (item.onClick) item.onClick(e);
                toggleSidebar();
              }}
            >
              {typeof item.icon === "function" ? (
                item.icon({
                  className: `w-6 h-6 flex-shrink-0 ${
                    location.pathname === item.to ? "text-green-500" : ""
                  }`,
                })
              ) : (
                <item.icon
                  className={`w-6 h-6 flex-shrink-0 ${
                    location.pathname === item.to ? "text-green-500" : ""
                  }`}
                />
              )}
              <span className="font-medium text-lg">{item.name}</span>
            </Link>
          ))}
        </nav>
        <div className="p-6 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={toggleTheme}
            className={`flex items-center justify-center w-full p-3 rounded-lg ${
              darkMode
                ? "hover:bg-gray-700 text-green-400"
                : "hover:bg-green-200 text-green-800"
            } transition-colors duration-200`}
          >
            {darkMode ? (
              <Sun className="w-6 h-6 mr-4" />
            ) : (
              <Moon className="w-6 h-6 mr-4" />
            )}
            <span className="text-lg">
              {darkMode ? "Light Mode" : "Dark Mode"}
            </span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
