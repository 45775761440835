import React, { useMemo } from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLegend,
  VictoryTheme,
} from "victory";
import {
  TrendingUp,
  ArrowUpRight,
  ArrowDownRight,
  LineChart,
  DollarSign,
} from "lucide-react";

export const IncomeTrendsChart = ({ data, darkMode }) => {
  const colors = {
    revenue: "#10B981",
    grossProfit: "#3B82F6",
    operatingIncome: "#F59E0B",
    netIncome: "#8B5CF6",
    ebitda: "#EC4899",
  };

  const baseTheme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        grid: {
          stroke: darkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)",
          strokeDasharray: "4,4",
        },
        tickLabels: {
          fill: darkMode ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.9)",
          fontSize: 11,
          fontFamily: "'Inter', system-ui, sans-serif",
        },
      },
    },
  };

  const formatYAxis = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const tooltipFormat = (datum) => {
    const metrics = {
      revenue: "Total Revenue",
      grossProfit: "Gross Profit",
      operatingIncome: "Operating Income",
      netIncome: "Net Income",
      ebitda: "EBITDA",
    };

    const date = new Date(datum.x).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
    });
    return `${metrics[datum.childName] || datum.childName}\n${formatYAxis(datum.y)}\n${date}`;
  };

  const chartData = useMemo(() => {
    return data.map((item) => ({
      date: new Date(item.date),
      revenue: item.totalRevenue,
      grossProfit: item.grossProfit,
      operatingIncome: item.operatingIncome,
      netIncome: item.netIncome,
      ebitda: item.ebitda,
    }));
  }, [data]);

  const calculateGrowth = (metric) => {
    const firstValue = chartData[0][metric];
    const lastValue = chartData[chartData.length - 1][metric];
    return ((lastValue - firstValue) / Math.abs(firstValue)) * 100;
  };

  const MetricCard = ({ label, value, growth, color, icon: Icon }) => (
    <div
      className={`relative overflow-hidden rounded-2xl transition-all duration-200 
      ${darkMode ? "bg-gray-800/50 hover:bg-gray-800/70" : "bg-white hover:bg-gray-50"}
      backdrop-blur-sm group shadow-sm hover:shadow-md`}
    >
      <div className="p-5 relative z-10">
        <div className="flex items-start justify-between gap-4">
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-1">
              <Icon
                className={`w-4 h-4 ${darkMode ? "text-gray-400" : "text-gray-500"}`}
              />
              <p
                className={`text-sm font-medium
                ${darkMode ? "text-gray-400" : "text-gray-500"}`}
              >
                {label}
              </p>
            </div>
            <p
              className="text-2xl font-semibold tracking-tight transition-colors duration-200"
              style={{ color }}
            >
              {value}
            </p>
          </div>
          {growth !== undefined && (
            <div
              className={`flex items-center gap-1 text-sm font-medium px-2.5 py-1.5 rounded-lg
              transition-colors duration-200
              ${
                growth > 0
                  ? "text-green-500 bg-green-500/10 group-hover:bg-green-500/15"
                  : "text-red-500 bg-red-500/10 group-hover:bg-red-500/15"
              }`}
            >
              {growth > 0 ? (
                <ArrowUpRight className="w-4 h-4" />
              ) : (
                <ArrowDownRight className="w-4 h-4" />
              )}
              <span>{Math.abs(growth).toFixed(1)}%</span>
            </div>
          )}
        </div>
      </div>
      <div
        className="absolute inset-0 opacity-5 transition-opacity duration-200 group-hover:opacity-10"
        style={{ background: `linear-gradient(145deg, ${color}, transparent)` }}
      ></div>
    </div>
  );

  return (
    <div
      className={`w-full transition-all duration-200 ease-in-out 
      ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      <div className="max-w-7xl mx-auto">
        <div
          className={`rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm
          ${darkMode ? "bg-gray-800/50" : "bg-white"}`}
        >
          {/* Header */}
          <div
            className={`px-6 py-5 border-b
            ${darkMode ? "border-gray-700/50" : "border-gray-100"}`}
          >
            <div className="flex items-center gap-3">
              <LineChart
                className={`w-5 h-5 
                ${darkMode ? "text-gray-400" : "text-gray-500"}`}
              />
              <div>
                <h2
                  className={`text-xl font-semibold tracking-tight
                  ${darkMode ? "text-white" : "text-gray-900"}`}
                >
                  Income Statement Trends
                </h2>
                <p
                  className={`mt-1 text-sm font-medium
                  ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                >
                  Comprehensive analysis of income metrics over time
                </p>
              </div>
            </div>
          </div>

          <div className="p-2 space-y-6">
            {/* Main Chart */}
            <div
              className={`rounded-xl p-6 
              ${darkMode ? "bg-gray-800/30" : "bg-gray-50/80"}
              border ${darkMode ? "border-gray-700/50" : "border-gray-200/50"}`}
            >
              <div className="h-[400px] sm:h-[500px]">
                <VictoryChart
                  theme={baseTheme}
                  padding={{ top: 50, right: 50, bottom: 50, left: 70 }}
                  scale={{ x: "time" }}
                  height={500}
                  width={800}
                  containerComponent={
                    <VictoryVoronoiContainer
                      labels={({ datum }) => tooltipFormat(datum)}
                      labelComponent={
                        <VictoryTooltip
                          style={{
                            fontSize: 11,
                            fontFamily: "'Inter', system-ui, sans-serif",
                          }}
                          flyoutStyle={{
                            fill: darkMode ? "rgba(17,24,39,0.95)" : "white",
                            stroke: darkMode
                              ? "rgba(255,255,255,0.1)"
                              : "rgba(0,0,0,0.1)",
                            strokeWidth: 1,
                            filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.1))",
                          }}
                          cornerRadius={6}
                          flyoutPadding={{
                            top: 8,
                            bottom: 8,
                            left: 12,
                            right: 12,
                          }}
                        />
                      }
                    />
                  }
                >
                  <VictoryLegend
                    x={50}
                    y={0}
                    orientation="horizontal"
                    gutter={24}
                    style={{
                      labels: {
                        fontSize: 11,
                        fontFamily: "'Inter', system-ui, sans-serif",
                        fill: darkMode
                          ? "rgba(255,255,255,0.9)"
                          : "rgba(0,0,0,0.9)",
                      },
                    }}
                    data={[
                      { name: "Revenue", symbol: { fill: colors.revenue } },
                      {
                        name: "Gross Profit",
                        symbol: { fill: colors.grossProfit },
                      },
                      {
                        name: "Operating Income",
                        symbol: { fill: colors.operatingIncome },
                      },
                      {
                        name: "Net Income",
                        symbol: { fill: colors.netIncome },
                      },
                      { name: "EBITDA", symbol: { fill: colors.ebitda } },
                    ]}
                  />

                  <VictoryAxis
                    tickFormat={(date) =>
                      new Date(date).toLocaleDateString(undefined, {
                        year: "2-digit",
                        month: "short",
                      })
                    }
                    tickCount={6}
                    style={{
                      tickLabels: { fontSize: 11, padding: 8 },
                      grid: { stroke: "none" },
                    }}
                  />

                  <VictoryAxis
                    dependentAxis
                    tickFormat={formatYAxis}
                    style={{
                      tickLabels: { fontSize: 11, padding: 8 },
                      grid: { strokeWidth: 0.5 },
                    }}
                  />

                  {[
                    { key: "revenue", color: colors.revenue },
                    { key: "grossProfit", color: colors.grossProfit },
                    { key: "operatingIncome", color: colors.operatingIncome },
                    { key: "netIncome", color: colors.netIncome },
                    { key: "ebitda", color: colors.ebitda },
                  ].map(({ key, color }) => (
                    <VictoryLine
                      key={key}
                      name={key}
                      data={chartData}
                      x="date"
                      y={key}
                      style={{
                        data: {
                          stroke: color,
                          strokeWidth: 2,
                          opacity: 0.85,
                        },
                      }}
                      animate={{
                        onLoad: { duration: 500 },
                        duration: 200,
                      }}
                    />
                  ))}
                </VictoryChart>
              </div>
            </div>

            {/* Metrics Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {[
                {
                  label: "Average Revenue",
                  value: formatYAxis(
                    chartData.reduce((acc, curr) => acc + curr.revenue, 0) /
                      chartData.length,
                  ),
                  growth: calculateGrowth("revenue"),
                  color: colors.revenue,
                  icon: DollarSign,
                },
                {
                  label: "Average Gross Profit",
                  value: formatYAxis(
                    chartData.reduce((acc, curr) => acc + curr.grossProfit, 0) /
                      chartData.length,
                  ),
                  growth: calculateGrowth("grossProfit"),
                  color: colors.grossProfit,
                  icon: TrendingUp,
                },
                {
                  label: "Average Operating Income",
                  value: formatYAxis(
                    chartData.reduce(
                      (acc, curr) => acc + curr.operatingIncome,
                      0,
                    ) / chartData.length,
                  ),
                  growth: calculateGrowth("operatingIncome"),
                  color: colors.operatingIncome,
                  icon: LineChart,
                },
                {
                  label: "Average Net Income",
                  value: formatYAxis(
                    chartData.reduce((acc, curr) => acc + curr.netIncome, 0) /
                      chartData.length,
                  ),
                  growth: calculateGrowth("netIncome"),
                  color: colors.netIncome,
                  icon: TrendingUp,
                },
              ].map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeTrendsChart;
