import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  LogLevel,
} from "@azure/msal-browser";

// Google Auth function
export const getGoogleToken = () => {
  return new Promise((resolve, reject) => {
    function handleCredentialResponse(response) {
      if (response.credential) {
        resolve(response.credential); // JWT ID token
      } else {
        reject("Google login failed.");
      }
    }

    if (window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.prompt(); // Display the One Tap prompt
    } else {
      reject("Google SDK not loaded.");
    }
  });
};

// Facebook Auth function
export const getFacebookToken = () => {
  return new Promise((resolve, reject) => {
    if (window.FB) {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            resolve(response.authResponse.accessToken);
          } else {
            reject("Facebook login failed.");
          }
        },
        { scope: "public_profile,email" },
      );
    } else {
      reject("Facebook SDK not loaded.");
    }
  });
};

// Microsoft Auth function
// Configuration for MSAL instance
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://www.quantummoneycode.com/forum/allPosts",
  },
  cache: {
    cacheLocation: "localStorage", // You can also use 'sessionStorage'
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error: // Use LogLevel directly
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
    },
  },
};

// Create a singleton MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

export const getMicrosoftToken = async () => {
  try {
    // Initialize the MSAL instance before using it
    await msalInstance.initialize();

    const loginRequest = {
      scopes: ["User.Read"],
    };

    const loginResponse = await msalInstance.loginPopup(loginRequest);

    const account = msalInstance.getAllAccounts()[0];

    const tokenRequest = {
      scopes: ["User.Read"],
      account: account,
    };

    const tokenResponse = await msalInstance
      .acquireTokenSilent(tokenRequest)
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenPopup(tokenRequest);
        } else {
          throw error;
        }
      });

    return tokenResponse.accessToken;
  } catch (error) {
    throw new Error("Microsoft login failed: " + error.message);
  }
};
