import React from "react";
import { Link } from "react-router-dom";
import { FaUserPlus, FaUsers } from "react-icons/fa";

const SocialMediaActions = ({ buttonClass }) => {
  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mt-6 space-y-3 sm:space-y-0 sm:space-x-4">
        <Link
          to="/forum/following"
          className={`flex-1 sm:flex-initial px-4 py-2 text-sm rounded-lg ${buttonClass} text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center`}
        >
          <FaUserPlus className="mr-2 text-white" /> Following
        </Link>
        <Link
          to="/forum/followers"
          className={`flex-1 sm:flex-initial px-4 py-2 text-sm rounded-lg ${buttonClass} text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center`}
        >
          <FaUsers className="mr-2 text-white" /> Followers
        </Link>
      </div>
    </div>
  );
};

export default SocialMediaActions;
