import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../config/api";

const useCreateEditDeletePost = (id) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState(null);
  const navigate = useNavigate();

  const fetchPost = useCallback(async (postId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}/api/posts/${postId}`);
      if (response.ok) {
        const data = await response.json();
        setPostData(data);
      } else {
        setError("Failed to fetch post data");
      }
    } catch (err) {
      setError("An error occurred while fetching post data");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const createEditPost = async (formData) => {
    setIsLoading(true);
    setError("");
    try {
      const url = id
        ? `${getApiUrl()}/api/posts/${id}`
        : `${getApiUrl()}/api/posts`;
      const method = id ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        navigate(`/forum`);
        return true;
      } else {
        setError(
          data.message ||
            `Failed to ${id ? "update" : "create"} post. Please try again.`,
        );
        return false;
      }
    } catch (err) {
      console.error(`Error ${id ? "updating" : "creating"} post:`, err);
      setError("An error occurred. Please try again.");
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const deletePost = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(`${getApiUrl()}/api/posts/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        navigate("/forum/allPosts");
        return true;
      } else {
        const data = await response.json();
        setError(data.message || "Failed to delete post. Please try again.");
        return false;
      }
    } catch (err) {
      console.error("Error deleting post:", err);
      setError("An error occurred while deleting the post. Please try again.");
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createEditPost,
    deletePost,
    error,
    isLoading,
    postData,
    isEditMode: !!id,
    fetchPost,
  };
};

export default useCreateEditDeletePost;
