import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import TestimonialCard from "./TestimonialCard";

const testimonials = [
  {
    quote:
      "My business was on the brink of collapse, and I was desperate for a solution. The Quantum Money Code gave me the solution to turn things around, and now my business is thriving. I can't thank you enough.",
    author: "Sarah J.",
    age: 45,
    location: "New York, USA",
  },
  {
    quote:
      "I was drowning in debt and couldn't see a way out. After using The Quantum Money Code, I managed to clear my debt within six months and even saved up for a vacation in Spain! This program truly changed my life.",
    author: "Geoffrey M.",
    age: 34,
    location: "London, UK",
  },
  {
    quote:
      "I had always struggled with money, living paycheck to paycheck. But after applying the techniques in The Quantum Money Code, I received an unexpected promotion and a significant salary increase! My financial life has never been better.",
    author: "Matthias R.",
    age: 41,
    location: "Berlin, Germany",
  },
  {
    quote:
      "After years of financial stress, The Quantum Money Code helped me finally achieve the financial stability I've always dreamed of. I've paid off my mortgage and even started investing in my future.",
    author: "Carlos T.",
    age: 38,
    location: "Madrid, Spain",
  },
  {
    quote:
      "I was skeptical at first, but The Quantum Money Code completely exceeded my expectations. I went from barely making ends meet to having more than enough to enjoy life comfortably.",
    author: "Emily K.",
    age: 29,
    location: "Sydney, Australia",
  },
  {
    quote:
      "My student loan debt was crushing me, but The Quantum Money Code showed me how to manage my finances better and get out of debt faster than I ever thought possible.",
    author: "Jason L.",
    age: 27,
    location: "Toronto, Canada",
  },
  {
    quote:
      "I've tried so many programs, but nothing worked until The Quantum Money Code. Now I have a clear path to financial freedom and have already seen amazing results!",
    author: "Linda P.",
    age: 52,
    location: "Chicago, USA",
  },
  {
    quote:
      "Thanks to The Quantum Money Code, I was able to finally start the business I've always wanted. It's now profitable, and I'm living my dream.",
    author: "Ahmed Z.",
    age: 33,
    location: "Dubai, UAE",
  },
  {
    quote:
      "My family was struggling financially, but The Quantum Money Code gave us hope and practical steps to improve our situation. We're now in a much better place.",
    author: "Maria S.",
    age: 39,
    location: "Sao Paulo, Brazil",
  },
  {
    quote:
      "The Quantum Money Code is a game-changer. It's like having a financial expert guide you every step of the way. I'm now confident in my financial decisions.",
    author: "David H.",
    age: 47,
    location: "London, UK",
  },
  {
    quote:
      "I was facing bankruptcy, but The Quantum Money Code turned everything around for me. Now, I'm on the road to financial recovery with a solid plan in place.",
    author: "Rachel W.",
    age: 42,
    location: "Los Angeles, USA",
  },
  {
    quote:
      "I never thought I could save money while living in an expensive city, but The Quantum Money Code taught me strategies that really work. I'm now saving more than ever.",
    author: "Tom R.",
    age: 35,
    location: "San Francisco, USA",
  },
  {
    quote:
      "The Quantum Money Code helped me finally take control of my finances. I'm no longer living in fear of bills or debt collectors.",
    author: "Hannah L.",
    age: 30,
    location: "Dublin, Ireland",
  },
  {
    quote:
      "My financial anxiety was overwhelming, but The Quantum Money Code provided the peace of mind I needed. I'm now financially secure and planning for the future.",
    author: "Sophie M.",
    age: 28,
    location: "Paris, France",
  },
  {
    quote:
      "The Quantum Money Code is practical and easy to follow. I've already seen significant improvements in my financial situation.",
    author: "Lucas V.",
    age: 36,
    location: "Amsterdam, Netherlands",
  },
];

function TestimonialSection({ id }) {
  const { darkMode } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 3 + testimonials.length) % testimonials.length,
    );
  };

  return (
    <section
      id={id}
      className={`pt-24 pb-16 ${
        darkMode ? "bg-gray-800" : "bg-gradient-to-br from-green-50 to-blue-100"
      }`}
    >
      <div className="container mx-auto px-4">
        <h2
          className={`text-3xl font-extrabold text-center mb-12 ${
            darkMode ? "text-white" : "text-gray-900"
          }`}
        >
          What Our Customers Are Saying
        </h2>

        {/* Carousel for larger screens */}
        <div className="hidden md:flex justify-center items-center space-x-4">
          <button
            onClick={prevTestimonial}
            className={`p-2 rounded-full ${
              darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
            } shadow-md hover:bg-green-500 hover:text-white transition duration-300`}
          >
            <FaChevronLeft size={20} />
          </button>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
            {testimonials
              .slice(currentIndex, currentIndex + 3)
              .map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  testimonial={testimonial}
                  darkMode={darkMode}
                />
              ))}
          </div>
          <button
            onClick={nextTestimonial}
            className={`p-2 rounded-full ${
              darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
            } shadow-md hover:bg-green-500 hover:text-white transition duration-300`}
          >
            <FaChevronRight size={20} />
          </button>
        </div>

        {/* Carousel for smaller screens */}
        <div className="md:hidden">
          <TestimonialCard
            testimonial={testimonials[currentIndex]}
            darkMode={darkMode}
          />
          <div className="flex justify-center mt-6 space-x-4">
            <button
              onClick={prevTestimonial}
              className={`p-2 rounded-full ${
                darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              } shadow-md hover:bg-green-500 hover:text-white transition duration-300`}
            >
              <FaChevronLeft size={20} />
            </button>
            <button
              onClick={nextTestimonial}
              className={`p-2 rounded-full ${
                darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              } shadow-md hover:bg-green-500 hover:text-white transition duration-300`}
            >
              <FaChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialSection;
