import React from "react";
import {
  TrendingUp,
  AlertCircle,
  CheckCircle2,
  ChartPieIcon,
  Info,
} from "lucide-react";

const ratioCategories = {
  currentRatio: {
    name: "Current Ratio",
    description: "Measures ability to pay short-term obligations",
    threshold: 1.5,
    icon: TrendingUp,
    format: (value) => value.toFixed(2),
  },
  debtToEquity: {
    name: "Debt to Equity",
    description: "Total liabilities divided by shareholder equity",
    threshold: 2,
    icon: ChartPieIcon,
    format: (value) => value.toFixed(2),
    invertedThreshold: true,
  },
  assetToEquity: {
    name: "Asset to Equity",
    description: "Total assets divided by total equity",
    threshold: 2.5,
    icon: TrendingUp,
    format: (value) => value.toFixed(2),
  },
  cashRatio: {
    name: "Cash Ratio",
    description: "Cash and equivalents to current liabilities",
    threshold: 0.5,
    icon: TrendingUp,
    format: (value) => value.toFixed(2),
  },
  workingCapitalRatio: {
    name: "Working Capital Ratio",
    description: "Working capital relative to total assets",
    threshold: 0.2,
    icon: ChartPieIcon,
    format: (value) => value.toFixed(2),
  },
  debtToAssets: {
    name: "Debt to Assets",
    description: "Total debt relative to total assets",
    threshold: 0.5,
    icon: ChartPieIcon,
    format: (value) => value.toFixed(2),
    invertedThreshold: true,
  },
  equityMultiplier: {
    name: "Equity Multiplier",
    description: "Total assets to shareholders' equity",
    threshold: 2,
    icon: TrendingUp,
    format: (value) => value.toFixed(2),
  },
  nonCurrentRatio: {
    name: "Non-Current Ratio",
    description: "Non-current assets to non-current liabilities",
    threshold: 1.5,
    icon: ChartPieIcon,
    format: (value) => value.toFixed(2),
  },
};

const FinancialRatios = ({ ratios, darkMode }) => {
  const getRatioStatus = (key, value) => {
    const category = ratioCategories[key];
    if (!category) return { status: "neutral", color: "gray" };

    const isHealthy = category.invertedThreshold
      ? value <= category.threshold
      : value >= category.threshold;

    return {
      status: isHealthy ? "healthy" : "attention",
      color: isHealthy ? "emerald" : "yellow",
      icon: isHealthy ? CheckCircle2 : AlertCircle,
    };
  };

  return (
    <div
      className={`rounded-2xl overflow-hidden border ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-100"
      } shadow-lg transition-all duration-300`}
    >
      {/* Header */}
      <div
        className={`px-6 py-4 border-b ${
          darkMode ? "border-gray-800" : "border-gray-100"
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div
              className={`p-2 rounded-xl ${
                darkMode ? "bg-gray-800" : "bg-gray-50"
              }`}
            >
              <ChartPieIcon className="w-5 h-5 text-purple-500" />
            </div>
            <div>
              <h3
                className={`text-lg font-bold ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Financial Ratios
              </h3>
              <p
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                Key performance indicators and health metrics
              </p>
            </div>
          </div>

          <div
            className={`hidden sm:flex items-center gap-2 px-4 py-2 rounded-lg text-sm ${
              darkMode ? "bg-gray-800" : "bg-gray-50"
            }`}
          >
            <Info className="w-4 h-4" />
            <span>Hover for details</span>
          </div>
        </div>
      </div>

      {/* Ratio Grid */}
      <div className="p-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {Object.entries(ratios).map(([key, value]) => {
            const category = ratioCategories[key];
            if (!category) return null;

            const {
              status,
              color,
              icon: StatusIcon,
            } = getRatioStatus(key, value);

            return (
              <div
                key={key}
                className={`group relative p-4 rounded-xl border transition-all duration-200 hover:shadow-md ${
                  darkMode
                    ? "bg-gray-800/50 border-gray-700 hover:bg-gray-800"
                    : "bg-gray-50/50 border-gray-100 hover:bg-white"
                }`}
              >
                {/* Tooltip */}
                <div
                  className={`absolute z-10 w-64 p-3 rounded-lg text-sm 
                  opacity-0 invisible group-hover:opacity-100 group-hover:visible
                  transition-all duration-200 -translate-y-2 group-hover:translate-y-0
                  bottom-full left-1/2 -translate-x-1/2 mb-2
                  ${darkMode ? "bg-gray-800 text-gray-200" : "bg-white text-gray-600"}
                  shadow-lg border ${darkMode ? "border-gray-700" : "border-gray-200"}`}
                >
                  <p>{category.description}</p>
                  <div
                    className={`absolute w-2 h-2 bottom-0 left-1/2 -translate-x-1/2 translate-y-1 rotate-45
                    ${darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"}`}
                  />
                </div>

                {/* Content */}
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1 min-w-0">
                    <h4
                      className={`text-sm font-medium mb-3 ${
                        darkMode ? "text-gray-200" : "text-gray-900"
                      }`}
                    >
                      {category.name}
                    </h4>
                    <div
                      className={`text-2xl font-bold mb-2 ${
                        darkMode ? "text-white" : "text-gray-900"
                      }`}
                    >
                      {category.format(value)}
                    </div>
                  </div>
                  <StatusIcon
                    className={`w-5 h-5 text-${color}-500 shrink-0`}
                  />
                </div>

                {/* Status Indicator */}
                <div
                  className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-lg text-xs font-medium
                  ${
                    status === "healthy"
                      ? darkMode
                        ? "bg-emerald-500/10 text-emerald-400"
                        : "bg-emerald-50 text-emerald-600"
                      : darkMode
                        ? "bg-yellow-500/10 text-yellow-400"
                        : "bg-yellow-50 text-yellow-600"
                  }`}
                >
                  {status === "healthy" ? "Healthy" : "Needs Attention"}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FinancialRatios;
