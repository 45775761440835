import React from "react";
import {
  LineChart,
  TrendingUp,
  TrendingDown,
  ArrowRight,
  Activity,
  ChevronUp,
  ChevronDown,
  TimerReset,
  Target,
  BarChart2,
  ArrowUpRight,
  ArrowDownRight,
} from "lucide-react";

const Technicals = ({
  data,
  currentPrice,
  darkMode,
  formatNumber,
  formatPercent,
}) => {
  if (!data) return null;

  const PriceComparison = ({ value, label }) => {
    const difference = currentPrice - value;
    const percentDiff = (difference / value) * 100;
    const isPositive = difference > 0;
    const isNegative = difference < 0;

    const getBackgroundStyle = () => {
      return darkMode
        ? "bg-slate-800 shadow-lg shadow-slate-900/20"
        : "bg-white shadow-lg shadow-slate-200/50";
    };

    const getTextColor = () => {
      return darkMode ? "text-slate-200" : "text-slate-700";
    };

    return (
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <Target className="w-6 h-6 text-blue-500" />
              <h3
                className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
              >
                {label}
              </h3>
            </div>
            <div className="space-y-2">
              <p
                className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                ${formatNumber(value)}
              </p>
              <div
                className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium ${
                  isPositive
                    ? "bg-green-50 text-green-600"
                    : isNegative
                      ? "bg-red-50 text-red-600"
                      : darkMode
                        ? "bg-slate-700 text-slate-300"
                        : "bg-slate-100 text-slate-600"
                }`}
              >
                {isPositive ? (
                  <ArrowUpRight size={16} />
                ) : isNegative ? (
                  <ArrowDownRight size={16} />
                ) : (
                  <ArrowRight size={16} />
                )}
                {isPositive ? "+" : ""}
                {formatNumber(difference)} ({formatPercent(percentDiff / 100)})
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TechnicalCard = ({ title, value, subtitle, icon: Icon, tooltip }) => {
    const getBackgroundStyle = () => {
      return darkMode
        ? "bg-slate-800 shadow-lg shadow-slate-900/20"
        : "bg-white shadow-lg shadow-slate-200/50";
    };

    const getTextColor = () => {
      return darkMode ? "text-slate-200" : "text-slate-700";
    };

    return (
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <Icon className="w-6 h-6 text-blue-500" />
              <h3
                className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
              >
                {title}
              </h3>
            </div>
            <div className="space-y-2">
              <p
                className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                {value}
              </p>
              {subtitle && (
                <p
                  className={`text-sm font-medium ${
                    darkMode ? "text-slate-400" : "text-slate-600"
                  }`}
                >
                  {subtitle}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const maSignals = {
    "50MA": {
      position: currentPrice > data["50DayMA"] ? "Above" : "Below",
      difference: ((currentPrice - data["50DayMA"]) / data["50DayMA"]) * 100,
    },
    "200MA": {
      position: currentPrice > data["200DayMA"] ? "Above" : "Below",
      difference: ((currentPrice - data["200DayMA"]) / data["200DayMA"]) * 100,
    },
  };

  const SectionWrapper = ({ title, icon: Icon, children }) => {
    const getBackgroundStyle = () => {
      return darkMode
        ? "bg-slate-800 shadow-lg shadow-slate-900/20"
        : "bg-white shadow-lg shadow-slate-200/50";
    };

    const getTextColor = () => {
      return darkMode ? "text-slate-200" : "text-slate-700";
    };

    return (
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-6">
            <div className="flex items-center space-x-3">
              <Icon className="w-6 h-6 text-blue-500" />
              <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                {title}
              </h3>
            </div>
            {children}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Key Technical Indicators */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        <TechnicalCard
          title="Beta"
          value={formatNumber(data.Beta)}
          subtitle={data.Beta < 1 ? "Less Volatile" : "More Volatile"}
          icon={Activity}
        />
        <TechnicalCard
          title="Short Ratio"
          value={formatNumber(data.ShortRatio)}
          subtitle="Days to Cover"
          icon={TimerReset}
        />
        <TechnicalCard
          title="Short Interest"
          value={formatNumber(data.SharesShort)}
          subtitle={`${formatPercent(data.ShortPercent)} of Float`}
          icon={TrendingDown}
        />
        <TechnicalCard
          title="Short Change"
          value={formatPercent(
            (data.SharesShort - data.SharesShortPriorMonth) /
              data.SharesShortPriorMonth,
          )}
          subtitle="vs Prior Month"
          icon={BarChart2}
        />
      </div>

      {/* Price Levels */}
      <SectionWrapper title="Price Levels" icon={Target}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          <PriceComparison
            value={data["52WeekHigh"]}
            label="52 Week High"
            currentPrice={currentPrice}
          />
          <PriceComparison
            value={data["52WeekLow"]}
            label="52 Week Low"
            currentPrice={currentPrice}
          />
          <PriceComparison
            value={data["50DayMA"]}
            label="50 Day MA"
            currentPrice={currentPrice}
          />
          <PriceComparison
            value={data["200DayMA"]}
            label="200 Day MA"
            currentPrice={currentPrice}
          />
        </div>
      </SectionWrapper>

      {/* Moving Average Analysis */}
      <SectionWrapper title="Moving Average Analysis" icon={LineChart}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          {Object.entries(maSignals).map(([ma, signal]) => (
            <div
              key={ma}
              className={`rounded-xl ${
                darkMode ? "bg-slate-700" : "bg-slate-50"
              } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
            >
              <div className="p-4 sm:p-5">
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h4
                      className={`text-sm sm:text-base font-medium tracking-wide ${
                        darkMode ? "text-slate-200" : "text-slate-700"
                      }`}
                    >
                      {ma?.replace("MA", " Day Moving Average")}
                    </h4>
                    <div
                      className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium ${
                        signal.position === "Above"
                          ? "bg-green-50 text-green-600"
                          : "bg-red-50 text-red-600"
                      }`}
                    >
                      {signal.position === "Above" ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )}
                      {signal.position}
                    </div>
                  </div>
                  <div
                    className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium ${
                      signal.difference > 0
                        ? "bg-green-50 text-green-600"
                        : "bg-red-50 text-red-600"
                    }`}
                  >
                    {signal.difference > 0 ? (
                      <ArrowUpRight size={16} />
                    ) : (
                      <ArrowDownRight size={16} />
                    )}
                    {signal.difference > 0 ? "+" : ""}
                    {formatPercent(signal.difference / 100)} from MA
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default Technicals;
