import { useState, useCallback } from "react";
import { getApiUrl } from "../config/api";

const useEditDeleteComment = (initialComment) => {
  const [comment, setComment] = useState(initialComment);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(initialComment.content);

  const handleEdit = useCallback(async () => {
    if (!editedContent.trim()) return;

    try {
      const response = await fetch(
        `${getApiUrl()}/api/comments/${comment.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ content: editedContent }),
        },
      );

      if (response.ok) {
        const updatedComment = await response.json();
        setComment(updatedComment);
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Failed to edit comment", error);
    }
  }, [comment.id, editedContent]);

  const handleDelete = useCallback(async () => {
    try {
      const response = await fetch(
        `${getApiUrl()}/api/comments/${comment.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      if (response.ok) {
        return true;
      }
    } catch (error) {
      console.error("Failed to delete comment", error);
    }
    return false;
  }, [comment.id]);

  return {
    comment,
    isEditing,
    setIsEditing,
    editedContent,
    setEditedContent,
    handleEdit,
    handleDelete,
  };
};

export default useEditDeleteComment;
