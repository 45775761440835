import React from "react";

const InfoItem = ({ icon: Icon, label, value, darkMode }) => (
  <div
    className={`flex items-center space-x-2 ${darkMode ? "text-white" : "text-green-800"} text-xs sm:text-sm`}
  >
    <Icon
      className={`${darkMode ? "text-green-400" : "text-green-600"} text-base sm:text-lg flex-shrink-0`}
    />
    <span className="font-medium">{label}:</span>
    <span className="truncate">{value}</span>
  </div>
);

export default InfoItem;
