import React from "react";

const PriceDisplay = ({ latestData, isNegative, marketType }) => {
  const trendColor = isNegative ? "text-red-500" : "text-green-500";

  const getPrice = () => {
    switch (marketType) {
      case "us":
        return latestData?.p;
      case "us-quote":
        return `${latestData?.bp}/${latestData?.ap}`;
      case "forex":
        return `${latestData?.b}/${latestData?.a}`;
      case "crypto":
        return latestData?.p;
      default:
        return "—";
    }
  };

  return (
    <div className={`text-lg font-semibold ${trendColor}`}>{getPrice()}</div>
  );
};

export default PriceDisplay;
