import { useState, useEffect, useCallback } from "react";
import { getApiUrl } from "../config/api";

export function useToggleFollower(followerId) {
  const [isFollower, setIsFollower] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFollowerStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/${followerId}/follower-status`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Failed to fetch follower status");
      }
      const data = await response.json();
      setIsFollower(data.isFollower);
    } catch (error) {
      console.error("Error fetching follower status:", error);
    } finally {
      setIsLoading(false);
    }
  }, [followerId]);

  useEffect(() => {
    fetchFollowerStatus();
  }, [fetchFollowerStatus]);

  const toggleFollower = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/${followerId}/toggle-follower`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Failed to toggle follower status",
        );
      }

      const data = await response.json();
      setIsFollower(data.isFollower);
    } catch (error) {
      console.error("Error toggling follower:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isFollower, isLoading, toggleFollower };
}
