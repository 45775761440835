import React from "react";
import {
  Clock,
  ArrowUpDown,
  Tag,
  TrendingUp,
  Bitcoin,
  Info,
  DollarSign,
} from "lucide-react";

const CryptoTradeConditionsComponent = ({ tradeData, darkMode }) => {
  if (!tradeData || tradeData.length === 0) return null;

  const latestTrade = tradeData[tradeData.length - 1];
  const previousTrade = tradeData[tradeData.length - 2];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 8,
      minimumFractionDigits: 2,
    }).format(number);
  };

  const priceChange = latestTrade?.p - previousTrade?.p;
  const priceChangePercent = (priceChange / previousTrade?.p) * 100;
  const isPositiveChange = priceChange >= 0;

  const MetricCard = ({
    label,
    value,
    secondaryValue,
    icon: Icon,
    color,
    isLarge,
  }) => (
    <div
      className={`relative overflow-hidden rounded-2xl ${
        darkMode
          ? "bg-slate-800/80 border-slate-700"
          : "bg-white/90 border-slate-200"
      } backdrop-blur-lg border p-4 ${isLarge ? "lg:p-6" : ""}`}
    >
      <div className="relative z-10">
        <div className="flex justify-between items-start">
          <span
            className={`text-sm font-medium ${
              darkMode ? "text-slate-300" : "text-slate-600"
            }`}
          >
            {label}
          </span>
          <div className={`p-2 rounded-lg ${color.bgDark}`}>
            <Icon className={`w-4 h-4 ${color.text}`} />
          </div>
        </div>
        <div className={`mt-2 ${isLarge ? "lg:mt-3" : ""}`}>
          <div
            className={`text-xl ${
              isLarge ? "lg:text-2xl" : ""
            } font-bold ${darkMode ? "text-white" : "text-slate-900"} ${color.glow}`}
          >
            {value}
          </div>
          {secondaryValue && (
            <div
              className={`text-sm ${
                darkMode ? "text-slate-400" : "text-slate-500"
              } mt-1`}
            >
              {secondaryValue}
            </div>
          )}
        </div>
      </div>
      <div className={`absolute inset-0 opacity-5 ${color.bgDark}`} />
    </div>
  );

  return (
    <div
      className={`w-full max-w-6xl mx-auto space-y-6 ${
        darkMode
          ? "bg-slate-900/95 border-slate-800"
          : "bg-white/95 border-slate-200"
      } rounded-3xl p-6 border`}
    >
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-amber-500/10 rounded-xl border border-amber-500/20">
            <Bitcoin className="w-8 h-8 text-amber-500" />
          </div>
          <div>
            <h1
              className={`text-2xl font-bold ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              Latest Trade Details
            </h1>
            <div
              className={`font-medium ${
                darkMode ? "text-slate-300" : "text-slate-600"
              }`}
            >
              {latestTrade?.s}
            </div>
          </div>
        </div>
        <div
          className={`flex items-center gap-2 ${
            darkMode
              ? "bg-slate-800 border-slate-700"
              : "bg-slate-100 border-slate-200"
          } backdrop-blur-lg px-4 py-2 rounded-xl border`}
        >
          <Clock
            className={`w-4 h-4 ${
              darkMode ? "text-slate-300" : "text-slate-600"
            }`}
          />
          <span
            className={`text-sm ${
              darkMode ? "text-slate-300" : "text-slate-600"
            }`}
          >
            {formatTimestamp(latestTrade?.t)}
          </span>
        </div>
      </div>

      {/* Main Metrics Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <MetricCard
          label="Current Price"
          value={`$${formatNumber(latestTrade?.p)}`}
          icon={DollarSign}
          color={{
            text: "text-blue-400",
            bgDark: "bg-blue-900",
            glow: "drop-shadow-[0_0_15px_rgba(59,130,246,0.2)]",
          }}
          isLarge
        />
        <MetricCard
          label="Trade Size"
          value={`${formatNumber(latestTrade?.q)}`}
          icon={ArrowUpDown}
          color={{
            text: "text-purple-400",
            bgDark: "bg-purple-900",
            glow: "drop-shadow-[0_0_15px_rgba(167,139,250,0.2)]",
          }}
          isLarge
        />
        <MetricCard
          label="Trade Value"
          value={`$${formatNumber(latestTrade?.p * latestTrade?.q)}`}
          icon={Tag}
          color={{
            text: "text-emerald-400",
            bgDark: "bg-emerald-900",
            glow: "drop-shadow-[0_0_15px_rgba(52,211,153,0.2)]",
          }}
          isLarge
        />
        <MetricCard
          label="24h Change"
          value={`${latestTrade?.dc >= 0 ? "+" : ""}${formatNumber(
            latestTrade?.dc,
          )}%`}
          secondaryValue={`$${formatNumber(latestTrade?.dd)}`}
          icon={TrendingUp}
          color={{
            text: latestTrade?.dc >= 0 ? "text-green-400" : "text-red-400",
            bgDark: latestTrade?.dc >= 0 ? "bg-green-900" : "bg-red-900",
            glow:
              latestTrade?.dc >= 0
                ? "drop-shadow-[0_0_15px_rgba(74,222,128,0.2)]"
                : "drop-shadow-[0_0_15px_rgba(248,113,113,0.2)]",
          }}
          isLarge
        />
      </div>

      {/* Detailed Statistics */}
      <div>
        <h2
          className={`text-xl font-bold ${
            darkMode ? "text-white" : "text-slate-900"
          } mb-4 px-2`}
        >
          Detailed Statistics
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <MetricCard
            label="Trade Time"
            value={formatTimestamp(latestTrade?.t)}
            icon={Clock}
            color={{
              text: "text-blue-400",
              bgDark: "bg-blue-900",
              glow: "drop-shadow-[0_0_15px_rgba(59,130,246,0.2)]",
            }}
          />
          <MetricCard
            label="Volume"
            value={`${formatNumber(latestTrade?.q)}`}
            icon={ArrowUpDown}
            color={{
              text: "text-purple-400",
              bgDark: "bg-purple-900",
              glow: "drop-shadow-[0_0_15px_rgba(167,139,250,0.2)]",
            }}
          />
          <MetricCard
            label="Price"
            value={`$${formatNumber(latestTrade?.p)}`}
            icon={Tag}
            color={{
              text: "text-emerald-400",
              bgDark: "bg-emerald-900",
              glow: "drop-shadow-[0_0_15px_rgba(52,211,153,0.2)]",
            }}
          />
          <MetricCard
            label="Daily Change"
            value={`${latestTrade?.dc >= 0 ? "+" : ""}${formatNumber(
              latestTrade?.dc,
            )}%`}
            secondaryValue={`$${formatNumber(latestTrade?.dd)}`}
            icon={TrendingUp}
            color={{
              text: latestTrade?.dc >= 0 ? "text-green-400" : "text-red-400",
              bgDark: latestTrade?.dc >= 0 ? "bg-green-900" : "bg-red-900",
              glow:
                latestTrade?.dc >= 0
                  ? "drop-shadow-[0_0_15px_rgba(74,222,128,0.2)]"
                  : "drop-shadow-[0_0_15px_rgba(248,113,113,0.2)]",
            }}
          />
        </div>
      </div>

      {/* Info Footer */}
      <div
        className={`flex items-start gap-3 ${
          darkMode
            ? "bg-slate-800/50 border-slate-700"
            : "bg-slate-100/50 border-slate-200"
        } rounded-xl p-4 border`}
      >
        <div
          className={`p-2 rounded-lg ${
            darkMode ? "bg-slate-700" : "bg-slate-200"
          }`}
        >
          <Info
            className={`w-4 h-4 ${
              darkMode ? "text-slate-300" : "text-slate-600"
            }`}
          />
        </div>
        <p
          className={`text-sm ${
            darkMode ? "text-slate-300" : "text-slate-600"
          } leading-relaxed`}
        >
          All trade details are updated in real-time. Price changes are
          calculated based on the previous trade. Daily changes are calculated
          from the previous day's closing price. Cryptocurrency trading involves
          substantial risk; please ensure you understand these indicators before
          trading.
        </p>
      </div>
    </div>
  );
};

export default CryptoTradeConditionsComponent;
