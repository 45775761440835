import React, { useEffect, useState } from "react";
import { useProfile } from "../../contexts/ProfileContext";
import {
  FaPhone,
  FaMapMarkerAlt,
  FaEnvelope,
  FaCalendarAlt,
  FaUserFriends,
  FaThumbsUp,
  FaComment,
  FaPencilAlt,
  FaExclamationTriangle,
  FaUserSlash,
} from "react-icons/fa";
import ActivityCard from "./ActivityCard";
import InfoItem from "./InfoItem";
import BioSection from "./BioSection";
import TabButton from "./TabButton";

const ProfileView = ({ userId, darkMode }) => {
  const {
    profile,
    profileStats,
    recentActivity,
    loading,
    error,
    fetchProfile,
    fetchProfileStats,
    fetchRecentActivity,
  } = useProfile();

  const [activeTab, setActiveTab] = useState("posts");

  useEffect(() => {
    fetchProfile(userId);
    fetchProfileStats(userId);
    fetchRecentActivity(userId);
  }, [userId, fetchProfile, fetchProfileStats, fetchRecentActivity]);

  const textClass = darkMode ? "text-white" : "text-green-800";
  const accentClass = darkMode ? "text-green-400" : "text-green-600";
  const bgClass = darkMode ? "bg-gray-800" : "bg-white";

  if (loading) {
    return (
      <div className={`text-center ${textClass} p-4`}>
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-900 mx-auto"></div>
        <p className="mt-4 text-sm">Loading profile data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`text-center ${textClass} p-4`}>
        <FaExclamationTriangle className="text-red-500 text-2xl mb-2" />
        <p className="text-sm">Error: {error}</p>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className={`text-center ${textClass} p-4`}>
        <FaUserSlash className="text-green-500 text-2xl mb-2" />
        <p className="text-sm">No profile data available.</p>
      </div>
    );
  }

  const infoItems = [
    { icon: FaEnvelope, label: "Email", value: profile.email },
    { icon: FaPhone, label: "Phone", value: profile.phone || "Not provided" },
    {
      icon: FaMapMarkerAlt,
      label: "Location",
      value: profile.location || "Not provided",
    },
    {
      icon: FaCalendarAlt,
      label: "Joined",
      value: profile.createdAt
        ? new Date(profile.createdAt).toLocaleDateString()
        : "Unknown",
    },
  ];

  const statsItems = [
    { icon: FaPencilAlt, label: "Posts", value: profileStats?.postCount || 0 },
    {
      icon: FaComment,
      label: "Comments",
      value: profileStats?.commentCount || 0,
    },
    { icon: FaThumbsUp, label: "Likes", value: profileStats?.likeCount || 0 },
  ];

  const connectionsItems = [
    { label: "Followers", value: profileStats?.followerCount || 0 },
    { label: "Following", value: profileStats?.followingCount || 0 },
  ];

  const tabItems = [
    { id: "posts", label: "Posts", icon: FaPencilAlt },
    { id: "comments", label: "Comments", icon: FaComment },
    { id: "likes", label: "Likes", icon: FaThumbsUp },
  ];

  const activityMap = {
    posts: recentActivity?.recentPosts || [],
    comments: recentActivity?.recentComments || [],
    likes: recentActivity?.recentLikes || [],
  };

  return (
    <div className="space-y-4 px-2 sm:px-3 py-4 max-w-4xl mx-auto">
      <div className="text-center">
        <div className="relative inline-block">
          <img
            src={profile.profileImageUrl || "/default-profile-image.jpg"}
            alt={`${profile.username}'s profile`}
            className="w-20 h-20 sm:w-24 sm:h-24 rounded-full object-cover border-4 border-green-500 shadow-lg"
          />
          <div
            className={`absolute bottom-0 right-0 ${bgClass} rounded-full p-1 shadow-md`}
          >
            <FaUserFriends className={`${accentClass} text-base sm:text-lg`} />
          </div>
        </div>
        <h1 className={`text-xl sm:text-2xl font-bold ${textClass} mt-3`}>
          {profile.username}
        </h1>
        <BioSection
          bio={profile.bio || "No bio provided"}
          darkMode={darkMode}
        />
      </div>

      <div
        className={`${bgClass} rounded-lg p-3 sm:p-4 space-y-2 sm:space-y-3 shadow-lg`}
      >
        {infoItems.map((item, index) => (
          <InfoItem
            key={index}
            icon={item.icon}
            label={item.label}
            value={item.value}
            darkMode={darkMode}
          />
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className={`${bgClass} rounded-lg p-3 sm:p-4 shadow-lg`}>
          <h2
            className={`text-base sm:text-lg font-semibold ${textClass} mb-2 sm:mb-3`}
          >
            Stats
          </h2>
          <div className="space-y-2 text-xs sm:text-sm">
            {statsItems.map((item, index) => (
              <p key={index} className={`${textClass} flex items-center`}>
                <item.icon className={`${accentClass} mr-2`} />
                {item.label}: {item.value}
              </p>
            ))}
          </div>
        </div>
        <div className={`${bgClass} rounded-lg p-3 sm:p-4 shadow-lg`}>
          <h2
            className={`text-base sm:text-lg font-semibold ${textClass} mb-2 sm:mb-3`}
          >
            Connections
          </h2>
          <div className="space-y-2 text-xs sm:text-sm">
            {connectionsItems.map((item, index) => (
              <div key={index} className={`flex items-center ${textClass}`}>
                <FaUserFriends className={`mr-2 ${accentClass}`} />
                <span>
                  {item.label}: {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`${bgClass} rounded-lg p-3 sm:p-4 shadow-lg`}>
        <div className="flex space-x-1 mb-3 sm:mb-4 overflow-x-auto">
          {tabItems.map((tab) => (
            <TabButton
              key={tab.id}
              label={tab.label}
              isActive={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
              icon={tab.icon}
              darkMode={darkMode}
            />
          ))}
        </div>

        <div className="space-y-2 sm:space-y-3">
          {activityMap[activeTab].map((item) => (
            <ActivityCard
              key={item.id}
              type={activeTab.slice(0, -1)} // Remove 's' from the end
              item={item}
              darkMode={darkMode}
            />
          ))}
        </div>
      </div>

      <p
        className={`text-xs ${darkMode ? "text-green-400" : "text-green-600"} text-center`}
      >
        Last updated:{" "}
        {profile.updatedAt
          ? new Date(profile.updatedAt).toLocaleString()
          : "Unknown"}
      </p>
    </div>
  );
};

export default ProfileView;
