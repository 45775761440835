import { useMediaQuery } from "react-responsive";

const useResponsive = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 641px) and (max-width: 1199px)",
  });

  return { isSmallScreen, isMediumScreen };
};

export default useResponsive;
