import { useState, useEffect } from "react";
import { getApiUrl } from "../config/api";

export const useFollowing = (page, pageSize) => {
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${getApiUrl()}/api/users/following/paginated?page=${page}&limit=${pageSize}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch following");
        }
        const data = await response.json();
        setFollowing(data.following);
        setTotalPages(data.pages);
        setError(null);
      } catch (err) {
        setError("Failed to fetch following");
      } finally {
        setLoading(false);
      }
    };

    fetchFollowing();
  }, [page, pageSize]);

  const unfollowUser = async (userId) => {
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/unfollow/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Failed to unfollow user");
      }
      setFollowing(following.filter((user) => user.id !== userId));
    } catch (err) {
      setError("Failed to unfollow user");
    }
  };

  return { following, loading, error, totalPages, unfollowUser };
};
