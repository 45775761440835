import React from "react";
import {
  PieChart,
  BarChart2,
  CircleDot,
  CircleDollarSign,
  TrendingDown,
} from "lucide-react";

const SharesStats = ({ data, darkMode, formatNumber, formatPercent }) => {
  if (!data) return null;

  const publicPercent = 100 - (data.PercentInsiders + data.PercentInstitutions);

  // Utility functions for styling
  const getBackgroundStyle = () => {
    return darkMode
      ? "bg-slate-800 shadow-lg shadow-slate-900/20"
      : "bg-white shadow-lg shadow-slate-200/50";
  };

  const getTextColor = () => {
    return darkMode ? "text-slate-200" : "text-slate-700";
  };

  // Components
  const OwnershipBar = ({ percent, label, color }) => (
    <div className="space-y-2.5">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <div className={`w-3 h-3 rounded-full ${color}`} />
          <span
            className={`text-sm font-medium ${
              darkMode ? "text-slate-200" : "text-slate-700"
            }`}
          >
            {label}
          </span>
        </div>
        <span
          className={`text-sm font-bold px-2.5 py-1 rounded-lg ${
            darkMode ? "bg-slate-700 text-white" : "bg-slate-100 text-slate-900"
          }`}
        >
          {formatPercent(percent / 100)}
        </span>
      </div>
      <div
        className={`h-2.5 w-full rounded-full overflow-hidden ${
          darkMode ? "bg-slate-700" : "bg-slate-200"
        }`}
      >
        <div
          className={`h-full rounded-full ${color}`}
          style={{ width: `${percent}%` }}
        />
      </div>
    </div>
  );

  const MetricCard = ({ title, value, subtitle, icon: Icon }) => (
    <div
      className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
        darkMode ? "border border-slate-700" : "border border-slate-100"
      }`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <Icon className="w-6 h-6 text-blue-500" />
            <h3
              className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
            >
              {title}
            </h3>
          </div>
          <div className="space-y-2">
            <p
              className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              {value}
            </p>
            {subtitle && (
              <p
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const SectionWrapper = ({ title, icon: Icon, children }) => (
    <div
      className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:shadow-xl ${
        darkMode ? "border border-slate-700" : "border border-slate-100"
      }`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-6">
          <div className="flex items-center space-x-3">
            <Icon className="w-6 h-6 text-blue-500" />
            <h3 className={`text-lg font-semibold ${getTextColor()}`}>
              {title}
            </h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );

  const StatBox = ({ color, label, percent, shares }) => (
    <div
      className={`rounded-xl ${
        darkMode ? "bg-slate-700" : "bg-slate-50"
      } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <div className={`w-3 h-3 rounded-full ${color}`} />
            <span
              className={`text-sm font-medium ${
                darkMode ? "text-slate-200" : "text-slate-700"
              }`}
            >
              {label}
            </span>
          </div>
          <div
            className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
              darkMode ? "text-white" : "text-slate-900"
            }`}
          >
            {formatPercent(percent / 100)}
          </div>
          <div
            className={`text-sm font-medium ${
              darkMode ? "text-slate-400" : "text-slate-600"
            }`}
          >
            {formatNumber(shares)} shares
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Share Structure */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
        <MetricCard
          title="Shares Outstanding"
          value={formatNumber(data.SharesOutstanding)}
          subtitle="Total shares issued"
          icon={CircleDot}
        />
        <MetricCard
          title="Float"
          value={formatNumber(data.SharesFloat)}
          subtitle="Available for trading"
          icon={CircleDollarSign}
        />
        <MetricCard
          title="Short Interest"
          value={formatPercent(data.ShortPercentFloat)}
          subtitle="% of Float"
          icon={TrendingDown}
        />
      </div>

      {/* Ownership Distribution */}
      <SectionWrapper title="Ownership Distribution" icon={PieChart}>
        <div className="space-y-5">
          <OwnershipBar
            percent={data.PercentInstitutions}
            label="Institutional Ownership"
            color="bg-blue-500"
          />
          <OwnershipBar
            percent={data.PercentInsiders}
            label="Insider Ownership"
            color="bg-green-500"
          />
          <OwnershipBar
            percent={publicPercent}
            label="Public Float"
            color="bg-purple-500"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6">
          <StatBox
            color="bg-blue-500"
            label="Institutional"
            percent={data.PercentInstitutions}
            shares={(data.SharesOutstanding * data.PercentInstitutions) / 100}
          />
          <StatBox
            color="bg-green-500"
            label="Insider"
            percent={data.PercentInsiders}
            shares={(data.SharesOutstanding * data.PercentInsiders) / 100}
          />
          <StatBox
            color="bg-purple-500"
            label="Public"
            percent={publicPercent}
            shares={(data.SharesOutstanding * publicPercent) / 100}
          />
        </div>
      </SectionWrapper>

      {/* Float Statistics */}
      <SectionWrapper title="Float Statistics" icon={BarChart2}>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <div
            className={`rounded-xl ${
              darkMode ? "bg-slate-700" : "bg-slate-50"
            } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
          >
            <div className="p-4 sm:p-5">
              <div className="space-y-2">
                <div
                  className={`text-sm font-medium ${
                    darkMode ? "text-slate-400" : "text-slate-600"
                  }`}
                >
                  Float / Outstanding
                </div>
                <div
                  className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                    darkMode ? "text-white" : "text-slate-900"
                  }`}
                >
                  {formatPercent(data.SharesFloat / data.SharesOutstanding)}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`rounded-xl ${
              darkMode ? "bg-slate-700" : "bg-slate-50"
            } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
          >
            <div className="p-4 sm:p-5">
              <div className="space-y-2">
                <div
                  className={`text-sm font-medium ${
                    darkMode ? "text-slate-400" : "text-slate-600"
                  }`}
                >
                  Short % of Float
                </div>
                <div
                  className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                    darkMode ? "text-white" : "text-slate-900"
                  }`}
                >
                  {formatPercent(data.ShortPercentFloat)}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`rounded-xl ${
              darkMode ? "bg-slate-700" : "bg-slate-50"
            } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
          >
            <div className="p-4 sm:p-5">
              <div className="space-y-2">
                <div
                  className={`text-sm font-medium ${
                    darkMode ? "text-slate-400" : "text-slate-600"
                  }`}
                >
                  Restricted Shares
                </div>
                <div
                  className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                    darkMode ? "text-white" : "text-slate-900"
                  }`}
                >
                  {formatNumber(data.SharesOutstanding - data.SharesFloat)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export default SharesStats;
