import React from "react";

const DeleteConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  darkMode,
  title = "Delete Post",
  description = "Are you sure you want to delete this post? This action cannot be undone.",
  cancelButtonText = "Cancel",
  confirmButtonText = "Delete",
}) => {
  if (!isOpen) return null;

  const baseClasses =
    "fixed inset-0 flex items-center justify-center z-50 p-4 sm:p-0";
  const overlayClasses = "absolute inset-0 bg-black opacity-50";
  const dialogClasses = `relative p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-sm sm:max-w-md ${
    darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
  }`;
  const titleClasses = `text-lg sm:text-xl font-bold mb-2 sm:mb-4 ${
    darkMode ? "text-green-400" : "text-green-600"
  }`;
  const descriptionClasses = `text-sm sm:text-base mb-4 sm:mb-6 ${
    darkMode ? "text-gray-300" : "text-gray-600"
  }`;
  const buttonBaseClasses =
    "px-3 py-1.5 sm:px-4 sm:py-2 rounded-md font-semibold text-sm sm:text-base";
  const cancelButtonClasses = `${buttonBaseClasses} mr-2 sm:mr-4 ${
    darkMode
      ? "bg-gray-700 text-white hover:bg-gray-600"
      : "bg-gray-100 text-gray-900 hover:bg-gray-200"
  }`;
  const confirmButtonClasses = `${buttonBaseClasses} ${
    darkMode
      ? "bg-red-600 text-white hover:bg-red-700"
      : "bg-red-500 text-white hover:bg-red-600"
  }`;

  return (
    <div className={baseClasses}>
      <div className={overlayClasses} onClick={onClose}></div>
      <div className={dialogClasses}>
        <h2 className={titleClasses}>{title}</h2>
        <p className={descriptionClasses}>{description}</p>
        <div className="flex justify-end">
          <button onClick={onClose} className={cancelButtonClasses}>
            {cancelButtonText}
          </button>
          <button onClick={onConfirm} className={confirmButtonClasses}>
            {confirmButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationDialog;
