import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const EnhancedPaginator = ({
  currentPage,
  totalPages,
  onPageChange,
  darkMode,
}) => {
  const pageNumbers = [];
  const maxVisiblePages = 5;

  // Generate array of page numbers to display
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const getVisiblePages = () => {
    if (totalPages <= maxVisiblePages) {
      return pageNumbers;
    }

    const halfVisible = Math.floor(maxVisiblePages / 2);
    let start = Math.max(currentPage - halfVisible, 1);
    let end = Math.min(start + maxVisiblePages - 1, totalPages);

    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(end - maxVisiblePages + 1, 1);
    }

    return pageNumbers.slice(start - 1, end);
  };

  const visiblePages = getVisiblePages();

  if (totalPages <= 1) {
    return null;
  }

  const baseButtonStyle = `flex items-center justify-center w-10 h-10 rounded-full transition-all duration-300 ${
    darkMode
      ? "text-green-300 hover:bg-gray-700"
      : "text-green-700 hover:bg-gray-200"
  }`;

  const activeButtonStyle = `${baseButtonStyle} ${
    darkMode ? "bg-green-700 text-white" : "bg-green-500 text-white"
  }`;

  return (
    <div className="flex justify-center items-center mt-8 space-x-2">
      <button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        className={`${baseButtonStyle} ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
        aria-label="Previous page"
      >
        <ChevronLeft size={20} />
      </button>

      {visiblePages[0] > 1 && (
        <>
          <button onClick={() => onPageChange(1)} className={baseButtonStyle}>
            1
          </button>
          {visiblePages[0] > 2 && <span className="text-gray-400">...</span>}
        </>
      )}

      {visiblePages.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={
            number === currentPage ? activeButtonStyle : baseButtonStyle
          }
        >
          {number}
        </button>
      ))}

      {visiblePages[visiblePages.length - 1] < totalPages && (
        <>
          {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
            <span className="text-gray-400">...</span>
          )}
          <button
            onClick={() => onPageChange(totalPages)}
            className={baseButtonStyle}
          >
            {totalPages}
          </button>
        </>
      )}

      <button
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
        className={`${baseButtonStyle} ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
        aria-label="Next page"
      >
        <ChevronRight size={20} />
      </button>
    </div>
  );
};

export default EnhancedPaginator;
