import React from "react";

const DatePeriodControls = ({
  darkMode,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  period,
  setPeriod,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="space-y-2">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          From Date
        </label>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className={`w-full p-4 rounded-xl shadow-sm transition-all duration-200 ${
            darkMode
              ? "bg-gray-800/50 text-white focus:ring-2 focus:ring-emerald-500"
              : "bg-white text-gray-900 focus:ring-2 focus:ring-emerald-500"
          }`}
        />
      </div>
      <div className="space-y-2">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          To Date
        </label>
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className={`w-full p-4 rounded-xl shadow-sm transition-all duration-200 ${
            darkMode
              ? "bg-gray-800/50 text-white focus:ring-2 focus:ring-emerald-500"
              : "bg-white text-gray-900 focus:ring-2 focus:ring-emerald-500"
          }`}
        />
      </div>
      <div className="space-y-2">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Period
        </label>
        <select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          className={`w-full p-4 rounded-xl shadow-sm transition-all duration-200 ${
            darkMode
              ? "bg-gray-800/50 text-white focus:ring-2 focus:ring-emerald-500"
              : "bg-white text-gray-900 focus:ring-2 focus:ring-emerald-500"
          }`}
        >
          <option value="d">Daily</option>
          <option value="w">Weekly</option>
          <option value="m">Monthly</option>
        </select>
      </div>
    </div>
  );
};

export default DatePeriodControls;
