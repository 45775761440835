import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  FaPercent,
  FaChartLine,
  FaTools,
  FaUserFriends,
  FaMoneyBillWave,
  FaRocket,
  FaChevronLeft,
  FaChevronRight,
  FaExternalLinkAlt,
} from "react-icons/fa";
import AffiliateTestimonialCard from "./AffiliateTestimonialCard";
import NavButton from "../menu/NavButton";

function BenefitsSection({ id }) {
  const { darkMode } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonialsPerSlide = window.innerWidth < 768 ? 1 : 3;

  const benefits = [
    {
      icon: FaPercent,
      title: "High Commission Rates",
      description:
        "Earn up to 75% commission on every sale you generate. Our competitive rates ensure you maximize your earnings with every conversion.",
    },
    {
      icon: FaChartLine,
      title: "High Conversion Rates",
      description:
        "Our optimized sales funnel ensures maximum conversions. Benefit from our extensive A/B testing and conversion rate optimization strategies.",
    },
    {
      icon: FaTools,
      title: "Marketing Resources",
      description:
        "Access a wide range of promotional materials through ClickBank. Get banners, email templates, and social media assets to boost your marketing efforts.",
    },
    {
      icon: FaUserFriends,
      title: "Established Platform",
      description:
        "Leverage ClickBank's reliable tracking and payment system. Enjoy the benefits of a trusted platform with years of experience in affiliate marketing.",
    },
    {
      icon: FaMoneyBillWave,
      title: "Recurring Commissions",
      description:
        "Earn ongoing commissions from subscription-based products. Build a steady stream of income with our recurring revenue model.",
    },
    {
      icon: FaRocket,
      title: "Global Marketplace",
      description:
        "Reach a worldwide audience through ClickBank's platform. Tap into international markets and expand your earning potential globally.",
    },
  ];

  const affiliateTestimonials = [
    {
      quote:
        "Promoting Quantum Money Code has been a game-changer for my affiliate business. The high commission rates and conversion-optimized funnel have significantly boosted my earnings.",
      author: "Sarah J.",
      earnings: "$5,000+",
    },
    {
      quote:
        "The marketing resources provided are top-notch. It's made promoting the product so much easier and more effective.",
      author: "Mike T.",
      earnings: "$3,000+",
    },
    {
      quote:
        "I've never seen such high conversion rates. The Quantum Money Code sells itself, making my job as an affiliate much easier.",
      author: "Emma R.",
      earnings: "$75,000+",
    },
    {
      quote:
        "The support team is fantastic. They're always there to help with any questions or issues, which has been crucial to my success as an affiliate.",
      author: "David L.",
      earnings: "$40,000+",
    },
    {
      quote:
        "The recurring commissions have allowed me to build a stable, passive income stream. It's truly changed my life.",
      author: "Alex M.",
      earnings: "$6,000+",
    },
    {
      quote:
        "The Quantum Money Code has transformed the way I approach affiliate marketing. The commissions are generous, and the support team is outstanding.",
      author: "Sophia P.",
      earnings: "$80,000+",
    },
    {
      quote:
        "I never expected to see such results so quickly! The Quantum Money Code's funnel is optimized to convert, and it really shows in my earnings.",
      author: "James K.",
      earnings: "$55,000+",
    },
    {
      quote:
        "Working with Quantum Money Code has been a seamless experience. The commissions are consistent, and the marketing materials are incredibly helpful.",
      author: "Linda H.",
      earnings: "$12,000+",
    },
    {
      quote:
        "I’ve tried other affiliate programs before, but none have delivered the results that Quantum Money Code has. My earnings have skyrocketed!",
      author: "Ethan W.",
      earnings: "$90,000+",
    },
    {
      quote:
        "The recurring commissions model is a game-changer. I’ve built a reliable income stream that keeps growing with each new customer.",
      author: "Olivia M.",
      earnings: "$65,000+",
    },
  ];

  const wrappedTestimonials = (startIndex) => {
    const wrapped = [];
    for (let i = 0; i < testimonialsPerSlide; i++) {
      wrapped.push(
        affiliateTestimonials[(startIndex + i) % affiliateTestimonials.length],
      );
    }
    return wrapped;
  };

  const nextTestimonial = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex + testimonialsPerSlide) % affiliateTestimonials.length,
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - testimonialsPerSlide + affiliateTestimonials.length) %
        affiliateTestimonials.length,
    );
  };

  return (
    <section
      id={id}
      className={`py-12 ${darkMode ? "bg-gray-800" : "bg-gray-100"}`}
    >
      <div className="container mx-auto px-4 relative">
        <div className="max-w-2xl mx-auto text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">
            Why Promote Quantum Money Code?
          </h2>
          <p className="text-lg">
            Join our affiliate program and unlock a world of opportunities. With
            Quantum Money Code, you're not just promoting a product – you're
            partnering with a brand committed to your success.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className={`p-4 sm:p-6 rounded-lg ${
                darkMode ? "bg-gray-700" : "bg-white"
              } transition-all duration-300 hover:shadow-lg transform hover:-translate-y-1`}
            >
              <benefit.icon
                className={`w-10 h-10 mb-4 ${
                  darkMode ? "text-green-400" : "text-green-600"
                }`}
              />
              <h3 className="text-lg font-semibold mb-2">{benefit.title}</h3>
              <p className={`${darkMode ? "text-gray-300" : "text-gray-600"}`}>
                {benefit.description}
              </p>
            </div>
          ))}
        </div>

        <div className="relative">
          <h3 className="text-2xl font-bold mb-6 text-center">
            What Our Top Affiliates Say
          </h3>

          <div className="flex justify-between items-center">
            <button
              onClick={prevTestimonial}
              className={`p-2 sm:p-3 rounded-full ${
                darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              } shadow-md hover:bg-green-500 hover:text-white transition duration-300`}
            >
              <FaChevronLeft size={18} />
            </button>

            <div className="flex-1 mx-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {wrappedTestimonials(currentIndex).map((testimonial, index) => (
                  <AffiliateTestimonialCard
                    key={index}
                    testimonial={testimonial}
                    darkMode={darkMode}
                  />
                ))}
              </div>
            </div>

            <button
              onClick={nextTestimonial}
              className={`p-2 sm:p-3 rounded-full ${
                darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              } shadow-md hover:bg-green-500 hover:text-white transition duration-300`}
            >
              <FaChevronRight size={18} />
            </button>
          </div>
        </div>

        <div className="flex justify-center mt-12 w-full">
          <NavButton
            icon={FaExternalLinkAlt}
            isExternal={true}
            className="lg:w-1/3 md:w-1/3 sm:w-3/4"
          >
            Join Our Affiliate Program
          </NavButton>
        </div>
      </div>
    </section>
  );
}

export default BenefitsSection;
