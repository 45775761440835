import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { getApiUrl } from "../config/api";

const ProfileContext = createContext(null);

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [profileStats, setProfileStats] = useState(null);
  const [recentActivity, setRecentActivity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentlyLoggedInUserProfile, setCurrentlyLoggedInUserProfile] =
    useState(null);

  const fetchProfile = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${getApiUrl()}/api/profile/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProfile(data);
      } else {
        throw new Error("Failed to fetch profile");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchProfileStats = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/profile/${userId}/stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        setProfileStats(data);
      } else {
        throw new Error("Failed to fetch profile stats");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchRecentActivity = useCallback(async (userId, limit = 5) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/profile/${userId}/activity?limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        setRecentActivity(data);
      } else {
        throw new Error("Failed to fetch recent activity");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const searchProfiles = useCallback(async (query, page = 1, limit = 5) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/profile/search?query=${query}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to search profiles");
      }
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const getCurrentUserProfile = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${getApiUrl()}/api/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentlyLoggedInUserProfile(data);
        return data;
      } else {
        throw new Error("Failed to fetch current user profile");
      }
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateProfile = async (userId, profileData, imageFile) => {
    setLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      Object.keys(profileData).forEach((key) => {
        formData.append(key, profileData[key]);
      });
      if (imageFile) {
        formData.append("profileImage", imageFile);
      }

      const response = await fetch(`${getApiUrl()}/api/profile/${userId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentlyLoggedInUserProfile(data);
        setProfile(data);
        return true;
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (err) {
      setError(err.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const resetProfile = useCallback(() => {
    setProfile(null);
    setCurrentlyLoggedInUserProfile(null);
    setProfileStats(null);
    setRecentActivity(null);
  }, []);

  useEffect(() => {
    window.resetProfile = resetProfile;
    return () => {
      delete window.resetProfile;
    };
  }, [resetProfile]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        profileStats,
        recentActivity,
        loading,
        error,
        fetchProfile,
        fetchProfileStats,
        fetchRecentActivity,
        searchProfiles,
        getCurrentUserProfile,
        currentlyLoggedInUserProfile,
        updateProfile,
        resetProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return context;
};
