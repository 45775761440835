import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  FaUser,
  FaMapMarkerAlt,
  FaEnvelope,
  FaStar,
  FaIdCard,
} from "react-icons/fa";
import FollowerToggleButton from "./FollowerToggleButton";
import { Badge } from "../../avatar/Badge";

const FollowerItem = ({
  id,
  profileImageUrl,
  username,
  isFollowing,
  bio,
  location,
  isFavorite,
  isDarkMode,
  handleFollowUser,
  textColor,
  secondaryTextColor,
}) => {
  const navigate = useNavigate();

  const handleMessageClick = () => {
    navigate(`/forum/messages/new/${id}`);
  };

  const handleProfileClick = () => {
    navigate(`/forum/profile/${id}`);
  };

  return (
    <motion.li
      key={id}
      className={`flex flex-col lg:flex-row items-start justify-between py-4 border-b last:border-b-0 ${
        isDarkMode ? "border-gray-700" : "border-gray-200"
      }`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex items-start space-x-4 mb-4 lg:mb-0 w-full lg:w-3/5">
        <div className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0">
          {profileImageUrl ? (
            <img
              src={profileImageUrl}
              alt={username}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center">
              <FaUser size={20} className="text-gray-600" />
            </div>
          )}
        </div>
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <span className={`font-semibold ${textColor} flex items-center`}>
              {username}
              {isFavorite && (
                <FaStar size={16} className="ml-2 text-yellow-500" />
              )}
            </span>
          </div>
          {bio && (
            <p
              className={`${secondaryTextColor} text-sm mt-1 line-clamp-2 hover:line-clamp-none transition-all duration-300`}
            >
              {bio}
            </p>
          )}
          {location && (
            <div
              className={`flex items-center ${secondaryTextColor} text-xs mt-1`}
            >
              <FaMapMarkerAlt size={12} className="mr-1" />
              {location}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-start lg:justify-end gap-2 mt-2 lg:mt-0 w-full lg:w-2/5">
        <Badge
          variant="secondary"
          darkMode={isDarkMode}
          className="cursor-pointer text-xs sm:text-sm py-1 px-2 sm:px-3"
          onClick={handleProfileClick}
        >
          <FaIdCard size={20} className="mr-2 hidden sm:inline" />
          Profile
        </Badge>
        <FollowerToggleButton
          followerId={id}
          initialIsFollowing={isFollowing}
          darkMode={isDarkMode}
          onFollow={handleFollowUser}
        />
      </div>
    </motion.li>
  );
};

export default FollowerItem;
