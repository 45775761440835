import React from "react";
import FundamentalsDisplaySections from "./FundamentalsDisplaySections";
import BalanceSheetVisualization from "../../financials/balance-sheet/BalanceSheetVisualization";
import CashFlowVisualization from "../../financials/cash-flow/CashFlowVisualization";
import IncomeStatementVisualization from "../../financials/income-statement/IncomeStatementVisualization";
import StockNewsAndSentiment from "../../news/StockNewsAndSentiment";
import ETFVisualization from "../../etf/ETFVisualization";
import FundVisualization from "../../fund/FundVisualization";

const MainContent = ({
  activeTab,
  data,
  darkMode,
  fundamentalsViewSections,
  openSections,
  toggleSection,
  symbol,
  tickerType,
}) => (
  <div
    className={`rounded-2xl transition-colors duration-300 ${
      darkMode ? "bg-gray-900/50" : "bg-gray-50"
    }`}
  >
    {activeTab === "overview" && data && (
      <div className="space-y-5">
        {fundamentalsViewSections.map((section) => (
          <FundamentalsDisplaySections
            key={section.id}
            title={section.title}
            icon={section.icon}
            isOpen={openSections.includes(section.id)}
            onToggle={() => toggleSection(section.id)}
            darkMode={darkMode}
          >
            {section.content}
          </FundamentalsDisplaySections>
        ))}
      </div>
    )}

    {activeTab === "balance-sheet" && data && (
      <div className="w-full">
        <BalanceSheetVisualization
          data={data.Financials?.Balance_Sheet}
          darkMode={darkMode}
        />
      </div>
    )}

    {activeTab === "cash-flow" && data && (
      <div className="w-full">
        <CashFlowVisualization
          data={data.Financials?.Cash_Flow}
          darkMode={darkMode}
        />
      </div>
    )}

    {activeTab === "income-statement" && data && (
      <div className="w-full">
        <IncomeStatementVisualization
          data={data.Financials?.Income_Statement}
          darkMode={darkMode}
        />
      </div>
    )}

    {activeTab === "news" && data && (
      <div className="w-full">
        <StockNewsAndSentiment symbol={symbol} darkMode={darkMode} />
      </div>
    )}

    {activeTab === "etf" && data && (
      <div className="w-full">
        <ETFVisualization data={data} darkMode={darkMode} />
      </div>
    )}

    {activeTab === "fund" && data && (
      <div className="w-full">
        <FundVisualization data={data} darkMode={darkMode} />
      </div>
    )}
  </div>
);

export default MainContent;
