import React from "react";
import {
  TrendingUp,
  TrendingDown,
  Clock,
  Calendar,
  AlertCircle,
} from "lucide-react";

const QuarterlyCard = ({
  quarter,
  date,
  darkMode,
  formatNumber,
  formatPercent,
  formatDate,
}) => {
  const getEarningsSurpriseColor = (surprise) => {
    if (!surprise) return darkMode ? "text-gray-400" : "text-gray-600";
    return surprise > 0 ? "text-green-500" : "text-red-500";
  };

  return (
    <div
      className={`p-5 border-b last:border-b-0 transition-colors duration-200 hover:bg-opacity-50
        ${darkMode ? "border-gray-700 hover:bg-gray-700" : "border-gray-200 hover:bg-gray-50"}`}
    >
      <div className="flex justify-between items-start mb-4">
        <div>
          <div
            className={`text-lg font-semibold tracking-tight ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            {new Date(date).toLocaleDateString(undefined, {
              year: "numeric",
              month: "short",
            })}
          </div>
          <div
            className={`text-sm mt-1 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            {formatDate(quarter.reportDate)}
            <span className="ml-2 inline-flex items-center">
              {quarter.beforeAfterMarket === "BeforeMarket"
                ? "(BMO)"
                : quarter.beforeAfterMarket === "AfterMarket"
                  ? "(AMC)"
                  : ""}
            </span>
          </div>
        </div>
        <div
          className="flex items-center gap-2 bg-opacity-10 rounded-full px-3 py-1
          ${quarter.surprisePercent > 0 ? 'bg-green-500' : quarter.surprisePercent < 0 ? 'bg-red-500' : 'bg-gray-500'}"
        >
          <span
            className={`font-medium ${getEarningsSurpriseColor(quarter.surprisePercent)}`}
          >
            {quarter.surprisePercent > 0 && "+"}
            {formatPercent(quarter.surprisePercent)}
          </span>
          {quarter.surprisePercent > 0 ? (
            <TrendingUp size={18} className="text-green-500" />
          ) : quarter.surprisePercent < 0 ? (
            <TrendingDown size={18} className="text-red-500" />
          ) : null}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div
          className="p-3 rounded-lg transition-colors duration-200
          ${darkMode ? 'bg-gray-700/50 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'}"
        >
          <div
            className={`text-sm font-medium mb-1 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            EPS Est.
          </div>
          <div
            className={`text-lg font-semibold ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            {formatNumber(quarter.epsEstimate)}
          </div>
        </div>
        <div
          className="p-3 rounded-lg transition-colors duration-200
          ${darkMode ? 'bg-gray-700/50 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'}"
        >
          <div
            className={`text-sm font-medium mb-1 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            EPS Actual
          </div>
          <div
            className={`text-lg font-semibold ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            {formatNumber(quarter.epsActual)}
          </div>
        </div>
      </div>
    </div>
  );
};

const EarningsHistory = ({
  data,
  darkMode,
  formatNumber,
  formatCurrency,
  formatPercent,
  formatDate,
  MetricCard,
}) => {
  if (!data) return null;

  const recentHistory = Object.entries(data.History || {})
    .filter(([_, quarter]) => quarter.epsActual !== null)
    .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
    .slice(0, 8);

  const recentAnnual = Object.entries(data.Annual || {})
    .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
    .slice(0, 5);

  const nextQuarterEstimate = Object.values(data.Trend || {}).find(
    (trend) => trend.period === "+1q",
  );

  const nextYearEstimate = Object.values(data.Trend || {}).find(
    (trend) => trend.period === "+1y",
  );

  const getEarningsSurpriseColor = (surprise) => {
    if (!surprise) return darkMode ? "text-gray-400" : "text-gray-600";
    return surprise > 0 ? "text-green-500" : "text-red-500";
  };

  return (
    <div className="space-y-8">
      {/* Estimates Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
        <MetricCard
          title="Next Quarter EPS Est."
          value={formatNumber(nextQuarterEstimate?.earningsEstimateAvg)}
          subtitle={`Growth: ${formatPercent(nextQuarterEstimate?.growth)}`}
          tooltip="Average analyst estimate for next quarter earnings per share"
          darkMode={darkMode}
        />
        <MetricCard
          title="Next Year EPS Est."
          value={formatNumber(nextYearEstimate?.earningsEstimateAvg)}
          subtitle={`Growth: ${formatPercent(nextYearEstimate?.growth)}`}
          tooltip="Average analyst estimate for next fiscal year earnings per share"
          darkMode={darkMode}
        />
        <MetricCard
          title="Analyst Coverage"
          value={formatNumber(
            nextQuarterEstimate?.earningsEstimateNumberOfAnalysts,
          )}
          subtitle="Following Quarter"
          tooltip="Number of analysts providing earnings estimates"
          darkMode={darkMode}
        />
      </div>

      {/* Quarterly History */}
      <div
        className={`rounded-xl overflow-hidden border transition-colors duration-200
        ${darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"}`}
      >
        <div
          className="px-6 py-4 border-b flex items-center justify-between
          ${darkMode ? 'border-gray-700' : 'border-gray-200'}"
        >
          <h3
            className={`text-lg font-semibold ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            Quarterly Earnings History
          </h3>
        </div>

        {/* Desktop Table View */}
        <div className="hidden sm:block overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}>
                <th className="text-left py-4 px-6 font-medium">Quarter</th>
                <th className="text-right py-4 px-6 font-medium">EPS Est.</th>
                <th className="text-right py-4 px-6 font-medium">EPS Actual</th>
                <th className="text-right py-4 px-6 font-medium">Surprise</th>
                <th className="text-left py-4 px-6 font-medium">Report Date</th>
              </tr>
            </thead>
            <tbody>
              {recentHistory.map(([date, quarter]) => (
                <tr
                  key={date}
                  className={`border-t transition-colors duration-200
                    ${
                      darkMode
                        ? "border-gray-700 hover:bg-gray-700/50"
                        : "border-gray-200 hover:bg-gray-50"
                    }`}
                >
                  <td
                    className={`py-4 px-6 ${darkMode ? "text-gray-300" : "text-gray-900"}`}
                  >
                    {new Date(date).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "short",
                    })}
                  </td>
                  <td
                    className={`py-4 px-6 text-right ${darkMode ? "text-gray-300" : "text-gray-900"}`}
                  >
                    {formatNumber(quarter.epsEstimate)}
                  </td>
                  <td
                    className={`py-4 px-6 text-right ${darkMode ? "text-gray-300" : "text-gray-900"}`}
                  >
                    {formatNumber(quarter.epsActual)}
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex items-center justify-end gap-2">
                      <span
                        className={`${getEarningsSurpriseColor(quarter.surprisePercent)} font-medium`}
                      >
                        {quarter.surprisePercent > 0 && "+"}
                        {formatPercent(quarter.surprisePercent)}
                      </span>
                      {quarter.surprisePercent > 0 ? (
                        <TrendingUp size={18} className="text-green-500" />
                      ) : quarter.surprisePercent < 0 ? (
                        <TrendingDown size={18} className="text-red-500" />
                      ) : null}
                    </div>
                  </td>
                  <td
                    className={`py-4 px-6 ${darkMode ? "text-gray-300" : "text-gray-900"}`}
                  >
                    {formatDate(quarter.reportDate)}
                    <span className="ml-1 text-sm">
                      {quarter.beforeAfterMarket === "BeforeMarket"
                        ? "(BMO)"
                        : quarter.beforeAfterMarket === "AfterMarket"
                          ? "(AMC)"
                          : ""}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Card View */}
        <div className="sm:hidden">
          {recentHistory.map(([date, quarter]) => (
            <QuarterlyCard
              key={date}
              date={date}
              quarter={quarter}
              darkMode={darkMode}
              formatNumber={formatNumber}
              formatPercent={formatPercent}
              formatDate={formatDate}
            />
          ))}
        </div>
      </div>

      {/* Annual History */}
      <div
        className={`rounded-xl border transition-colors duration-200
        ${darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"}`}
      >
        <div
          className="px-6 py-4 border-b flex items-center justify-between
          ${darkMode ? 'border-gray-700' : 'border-gray-200'}"
        >
          <h3
            className={`text-lg font-semibold ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            Annual Earnings History
          </h3>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
            {recentAnnual.map(([date, annual]) => (
              <div
                key={date}
                className={`p-4 rounded-lg transition-all duration-200 transform hover:scale-105
                  ${
                    darkMode
                      ? "bg-gray-700 hover:bg-gray-600 hover:shadow-lg hover:shadow-gray-900/20"
                      : "bg-gray-50 hover:bg-gray-100 hover:shadow-lg hover:shadow-gray-200/50"
                  }`}
              >
                <div
                  className={`text-sm font-medium ${darkMode ? "text-gray-400" : "text-gray-600"}`}
                >
                  FY {new Date(date).getFullYear()}
                </div>
                <div
                  className={`text-xl font-semibold mt-2 ${darkMode ? "text-white" : "text-gray-900"}`}
                >
                  {formatNumber(annual.epsActual)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarningsHistory;
