import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import CreateEditPostTopicSelect from "./CreateEditPostTopicSelect";
import ContentInput from "./ContentInput";

const CreateEditPostForm = ({
  onSubmit,
  darkMode,
  children,
  initialData,
  onContentChange,
}) => {
  const { id } = useParams();
  const [formState, setFormState] = useState({
    title: "",
    content: "",
    topic: "quantumMoneyCode",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (id && initialData) {
      setFormState({
        title: initialData.title,
        content: initialData.content,
        topic: initialData.topic,
      });
    } else {
      setFormState({
        title: "",
        content: "",
        topic: "quantumMoneyCode",
      });
    }
  }, [id, initialData]);

  useEffect(() => {
    setIsFormValid(
      formState.title.trim() !== "" &&
        formState.topic.trim() !== "" &&
        formState.content.trim() !== "",
    );
  }, [formState]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleContentChange = useCallback(
    (value) => {
      setFormState((prev) => ({ ...prev, content: value }));
      onContentChange(value);
    },
    [onContentChange],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!isFormValid) return;
      onSubmit(formState);
    },
    [isFormValid, onSubmit, formState],
  );

  return (
    <motion.form
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      onSubmit={handleSubmit}
      className="space-y-6 container mx-auto max-w-3xl"
    >
      <div className="space-y-2">
        <input
          id="title"
          name="title"
          type="text"
          value={formState.title}
          onChange={handleChange}
          placeholder="Enter your post title"
          className={`w-full p-4 text-lg border rounded-lg ${
            darkMode
              ? "bg-gray-800 text-white border-gray-700"
              : "bg-white text-gray-800 border-gray-300"
          } focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300`}
        />
      </div>

      <CreateEditPostTopicSelect
        topic={formState.topic}
        onChange={handleChange}
        darkMode={darkMode}
      />

      <ContentInput
        content={formState.content}
        onChange={handleContentChange}
        darkMode={darkMode}
      />

      {children(formState, handleChange, isFormValid)}
    </motion.form>
  );
};

export default CreateEditPostForm;
