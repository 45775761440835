import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const ForumLayout = ({ children }) => {
  const location = useLocation();
  const shouldShowTitle = location.pathname === "/forum";

  return (
    <div className="flex flex-col min-h-screen mt-4">
      <div className="flex-grow">
        {shouldShowTitle && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          ></motion.div>
        )}

        <div className="md:p-4 mb-8 mx-auto w-full max-w-7xl">
          <div className="md:ml-72 lg:ml-72">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ForumLayout;
