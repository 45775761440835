import React from "react";
import HeroSection from "./HeroSection";
import FeatureSection from "./FeatureSection";
import CTASection from "./CTASection";
import TestimonialSection from "./TestimonialSection";

function Landing() {
  return (
    <>
      <HeroSection id="hero-section" />
      <FeatureSection id="feature-section" />
      <TestimonialSection id="testimonial-section" />
      <CTASection id="cta-section" />
    </>
  );
}

export default Landing;
