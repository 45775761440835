import React from "react";

const LoadingState = ({ darkMode }) => (
  <div
    className={`w-full rounded-2xl shadow-xl overflow-hidden ${
      darkMode ? "bg-gray-800" : "bg-white"
    }`}
  >
    <div className="h-96 flex items-center justify-center">
      <div
        className={`flex flex-col items-center gap-4 ${
          darkMode ? "text-gray-300" : "text-gray-600"
        }`}
      >
        <div className="relative w-14 h-14">
          <div className="absolute inset-0 rounded-full border-4 border-t-green-500 border-r-transparent border-b-transparent border-l-transparent animate-spin" />
          <div className="absolute inset-2 rounded-full border-4 border-t-green-400 border-r-transparent border-b-transparent border-l-transparent animate-spin" />
        </div>
        <p className="text-lg font-medium animate-pulse">
          Loading fundamentals data...
        </p>
      </div>
    </div>
  </div>
);

export default LoadingState;
