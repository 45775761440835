import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
} from "victory";
import { TrendingUp, Info, ArrowUpRight, ArrowDownRight } from "lucide-react";
import { formatCurrency } from "./FormatHelpers";

const HistoricalTrendsChart = ({ data, darkMode }) => {
  const baseTheme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        grid: {
          stroke: darkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)",
        },
        tickLabels: {
          fill: darkMode ? "#fff" : "#000",
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
  };

  const metrics = [
    {
      key: "totalAssets",
      name: "Total Assets",
      color: "#10B981",
      description: "Total value of all assets",
      icon: "💰",
    },
    {
      key: "totalLiab",
      name: "Total Liabilities",
      color: "#EF4444",
      description: "Total value of all debts and obligations",
      icon: "📊",
    },
    {
      key: "totalEquity",
      name: "Total Equity",
      color: "#3B82F6",
      description: "Net value owned by shareholders",
      icon: "📈",
    },
    {
      key: "workingCapital",
      name: "Working Capital",
      color: "#8B5CF6",
      description: "Available funds for day-to-day operations",
      icon: "💼",
    },
    {
      key: "netWorth",
      name: "Net Worth",
      color: "#F59E0B",
      description: "Total assets minus total liabilities",
      icon: "⭐",
    },
  ];

  const formatTooltipValue = (value) => {
    if (Math.abs(value) >= 1e12) return `${(value / 1e12).toFixed(2)}T`;
    if (Math.abs(value) >= 1e9) return `${(value / 1e9).toFixed(2)}B`;
    if (Math.abs(value) >= 1e6) return `${(value / 1e6).toFixed(2)}M`;
    return value.toFixed(2);
  };

  const calculateGrowth = (metric) => {
    if (data.length < 2) return 0;
    const current = data[data.length - 1][metric];
    const previous = data[data.length - 2][metric];
    return ((current - previous) / previous) * 100;
  };

  return (
    <div
      className={`rounded-2xl overflow-hidden border shadow-lg transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-100"
      }`}
    >
      {/* Enhanced Header */}
      <div
        className={`border-b ${
          darkMode ? "border-gray-800" : "border-gray-100"
        }`}
      >
        <div className="p-2 space-y-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="flex items-start sm:items-center gap-4">
              <div
                className={`p-3 rounded-xl ${
                  darkMode
                    ? "bg-blue-500/10 text-blue-400"
                    : "bg-blue-50 text-blue-500"
                }`}
              >
                <TrendingUp className="w-6 h-6" />
              </div>
              <div>
                <h3
                  className={`text-xl font-bold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Historical Trends
                </h3>
                <p
                  className={`mt-1 text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Analysis of key financial metrics over time
                </p>
              </div>
            </div>

            <div
              className={`flex items-center gap-2 px-4 py-2.5 rounded-xl text-sm font-medium ${
                darkMode
                  ? "bg-gray-800 text-gray-300 hover:bg-gray-700"
                  : "bg-gray-50 text-gray-600 hover:bg-gray-100"
              }`}
            >
              <Info className="w-4 h-4" />
              <span>Interactive Chart</span>
            </div>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="p-6">
        <div className="h-96 md:h-[500px] lg:h-[600px]">
          <VictoryChart
            theme={baseTheme}
            padding={{ top: 50, right: 50, bottom: 50, left: 70 }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => {
                  const value = formatCurrency(datum.y);
                  const date = new Date(datum.x).toLocaleDateString();
                  const additionalMetrics = datum.additionalMetrics || {};

                  const tooltipLines = [
                    `${datum.metricName}`,
                    `Value: ${value}`,
                    `Date: ${date}`,
                  ];

                  if (datum.key === "totalAssets") {
                    tooltipLines.push(
                      `ROA: ${formatTooltipValue(
                        additionalMetrics.returnOnAssets * 100,
                      )}%`,
                      `Asset Turnover: ${formatTooltipValue(
                        additionalMetrics.assetTurnover,
                      )}x`,
                    );
                  } else if (datum.key === "totalLiab") {
                    tooltipLines.push(
                      `Debt Ratio: ${formatTooltipValue(
                        additionalMetrics.debtRatio * 100,
                      )}%`,
                      `Debt Service Coverage: ${formatTooltipValue(
                        additionalMetrics.debtServiceCoverageRatio,
                      )}x`,
                    );
                  } else if (datum.key === "totalEquity") {
                    tooltipLines.push(
                      `ROE: ${formatTooltipValue(
                        additionalMetrics.returnOnEquity * 100,
                      )}%`,
                      `Equity Multiplier: ${formatTooltipValue(
                        additionalMetrics.equityMultiplier,
                      )}x`,
                    );
                  }

                  return tooltipLines.join("\n");
                }}
                labelComponent={
                  <VictoryTooltip
                    style={{
                      fontSize: 12,
                      fill: darkMode ? "white" : "black",
                    }}
                    flyoutStyle={{
                      fill: darkMode ? "rgba(17,24,39,0.95)" : "white",
                      stroke: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                      filter: "drop-shadow(0 4px 6px rgb(0 0 0 / 0.1))",
                      strokeWidth: 1,
                    }}
                    cornerRadius={8}
                    flyoutPadding={{
                      top: 12,
                      bottom: 12,
                      left: 16,
                      right: 16,
                    }}
                  />
                }
              />
            }
          >
            <VictoryLegend
              x={50}
              y={0}
              orientation="horizontal"
              gutter={20}
              itemsPerRow={3}
              style={{
                labels: {
                  fontSize: 12,
                  fill: darkMode ? "#fff" : "#000",
                },
                data: { opacity: 0.8 },
              }}
              data={metrics.map((metric) => ({
                name: metric.name,
                symbol: { fill: metric.color },
              }))}
            />
            <VictoryAxis
              tickFormat={(date) => new Date(date).getFullYear()}
              tickCount={4}
              style={{
                tickLabels: {
                  fontSize: 12,
                  padding: 8,
                },
                grid: { stroke: "none" },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={formatCurrency}
              style={{
                tickLabels: {
                  fontSize: 12,
                  padding: 8,
                },
                grid: { strokeWidth: 0.5, strokeDasharray: "4, 4" },
              }}
            />
            {metrics.map((metric) => (
              <VictoryLine
                key={metric.key}
                data={data.map((d) => ({
                  x: d.date,
                  y: d[metric.key],
                  metricName: metric.name,
                  key: metric.key,
                  additionalMetrics: {
                    returnOnAssets: d.returnOnAssets,
                    returnOnEquity: d.returnOnEquity,
                    debtRatio: d.debtRatio,
                    debtServiceCoverageRatio: d.debtServiceCoverageRatio,
                    equityMultiplier: d.equityMultiplier,
                    assetTurnover: d.assetTurnover,
                  },
                }))}
                style={{
                  data: {
                    stroke: metric.color,
                    strokeWidth: 2.5,
                  },
                }}
              />
            ))}
          </VictoryChart>
        </div>

        {/* Enhanced Legend Summary */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-8">
          {metrics.map((metric) => {
            const growth = calculateGrowth(metric.key);

            return (
              <div
                key={metric.key}
                className={`group relative flex flex-col p-4 rounded-xl border transition-all duration-300 hover:shadow-md ${
                  darkMode
                    ? "bg-gray-800/50 border-gray-700 hover:bg-gray-800"
                    : "bg-gray-50/50 border-gray-100 hover:bg-white"
                }`}
              >
                {/* Tooltip */}
                <div
                  className={`absolute z-10 w-64 p-3 rounded-lg text-sm 
                    opacity-0 invisible group-hover:opacity-100 group-hover:visible
                    transition-all duration-200 -translate-y-2 group-hover:translate-y-0
                    bottom-full left-1/2 -translate-x-1/2 mb-2
                    ${
                      darkMode
                        ? "bg-gray-800 text-gray-200"
                        : "bg-white text-gray-600"
                    }
                    shadow-lg border ${
                      darkMode ? "border-gray-700" : "border-gray-200"
                    }`}
                >
                  <p>{metric.description}</p>
                  <div
                    className={`absolute w-2 h-2 bottom-0 left-1/2 -translate-x-1/2 translate-y-1 rotate-45
                      ${
                        darkMode
                          ? "bg-gray-800 border-gray-700"
                          : "bg-white border-gray-200"
                      }`}
                  />
                </div>

                <div className="flex items-center justify-between mb-2">
                  <span
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    {metric.icon} {metric.name}
                  </span>
                  <div
                    className={`flex items-center gap-1 text-xs font-medium ${
                      growth > 0
                        ? "text-emerald-500"
                        : growth < 0
                          ? "text-red-500"
                          : darkMode
                            ? "text-gray-400"
                            : "text-gray-500"
                    }`}
                  >
                    {growth > 0 ? (
                      <ArrowUpRight className="w-3 h-3" />
                    ) : growth < 0 ? (
                      <ArrowDownRight className="w-3 h-3" />
                    ) : null}
                    {Math.abs(growth).toFixed(1)}%
                  </div>
                </div>

                <span
                  className={`text-lg font-bold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                  style={{ color: metric.color }}
                >
                  {formatCurrency(data[data.length - 1]?.[metric.key] || 0)}
                </span>

                <div
                  className="absolute bottom-0 left-0 h-1 transition-all duration-300"
                  style={{
                    width: `${Math.min(Math.abs(growth), 100)}%`,
                    backgroundColor: metric.color,
                    opacity: 0.3,
                    borderRadius: "0 4px 0 0",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HistoricalTrendsChart;
