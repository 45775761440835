import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircle, ArrowRight } from "lucide-react";
import { plans } from "./Plans";

const PricingComponent = ({ darkMode }) => {
  const navigate = useNavigate();

  const handlePlanSelection = (planName) => {
    navigate(`/forum/plan/${planName.toLowerCase()}`);
  };

  return (
    <section
      className={`${
        darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-50 text-gray-900"
      } py-16 px-4 sm:px-6 lg:px-8`}
    >
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-base font-semibold uppercase tracking-wide text-green-500 mb-2">
            Elevate Your Financial Knowledge
          </h2>
          <h1 className="text-4xl md:text-5xl font-extrabold mb-6">
            Choose Your Financial Journey
          </h1>
          <p className="max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-400">
            Select the plan that aligns with your financial goals. From
            beginners to seasoned professionals, we have tailored options to
            suit every need and budget.
          </p>
        </div>

        {/* Pricing Cards */}
        <div className="space-y-12">
          {/* Top row with two cards */}
          <div className="grid gap-8 lg:gap-12 grid-cols-1 lg:grid-cols-2">
            {plans.slice(0, 2).map((plan, index) => (
              <PricingCard
                key={index}
                plan={plan}
                darkMode={darkMode}
                onSelect={handlePlanSelection}
              />
            ))}
          </div>

          {/* Bottom row with one centered card */}
          <div className="flex justify-center">
            <div className="w-full lg:w-2/3">
              <PricingCard
                plan={plans[2]}
                darkMode={darkMode}
                onSelect={handlePlanSelection}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PricingCard = ({ plan, darkMode, onSelect }) => (
  <div
    className={`${
      darkMode ? "bg-gray-800" : "bg-white"
    } rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl flex flex-col h-full`}
  >
    <div className="p-8 flex-grow">
      <h3 className="text-3xl font-bold mb-4">{plan.name}</h3>
      <div className="text-5xl font-bold mb-6">
        {plan.price}
        <span className="text-xl font-normal text-gray-500 dark:text-gray-400">
          /month
        </span>
      </div>
      <p className="text-lg text-gray-500 dark:text-gray-400 mb-8">
        {plan.description}
      </p>
      <ul className="mb-8 space-y-4">
        {plan.features.map((feature, i) => (
          <li key={i} className="flex items-start">
            <CheckCircle className="h-6 w-6 text-green-500 mr-3 flex-shrink-0 mt-0.5" />
            <span className="text-base">{feature}</span>
          </li>
        ))}
      </ul>
      <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">
        Ad Policy: {plan.ads}
      </p>
    </div>
    <div className={`p-8`}>
      <button
        onClick={() => onSelect(plan.name)}
        className={`w-full py-4 px-6 rounded-lg font-semibold text-lg text-white transition-colors duration-200 ${
          plan.popular
            ? "bg-green-500 hover:bg-green-600"
            : "bg-gray-600 hover:bg-gray-700"
        } flex items-center justify-center`}
      >
        {plan.cta}
        <ArrowRight className="ml-2 h-5 w-5" />
      </button>
    </div>
  </div>
);

export default PricingComponent;
