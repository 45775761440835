import React from "react";
import { motion } from "framer-motion";
import { FaMoon, FaSun } from "react-icons/fa";

const ThemeToggleText = ({ darkMode, toggleTheme, className = "" }) => (
  <motion.span
    onClick={toggleTheme}
    className={`cursor-pointer focus:outline-none mx-4 ${className}`}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    {darkMode ? (
      <FaSun className="w-6 h-6 text-yellow-400 hover:text-yellow-300 transition-all duration-300" />
    ) : (
      <FaMoon className="w-6 h-6 text-gray-600 hover:text-gray-400 transition-all duration-300" />
    )}
  </motion.span>
);

export default ThemeToggleText;
