// ControlsSection.jsx
import React, { useEffect } from "react";
import ExchangeSelect from "./ExchangeSelect";
import SymbolSearch from "./SymbolSearch";
import DatePeriodControls from "./DatePeriodControls";
import ChartControls from "./ChartControls";
import TechnicalIndicators from "./TechnicalIndicators";
import CandleColorCustomization from "./CandleColorCustomization";
import ActionButtons from "./ActionButtons";

const ControlsSection = (props) => {
  const {
    darkMode,
    symbol,
    selectedExchange,
    searchTerm,
    setQuery,
    setIsExchangeSelectOpen,
    setIsSymbolSelectOpen,
    setSearchTerm,
    isExchangeSelectOpen,
    isSymbolSelectOpen,
    loadingSymbols,
    symbols,
    exchanges,
    handleExchangeSelect,
    handleSymbolSelect,
    period,
    setPeriod,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    chartType,
    setChartType,
    showGridlines,
    setShowGridlines,
    candleUpColor,
    setCandleUpColor,
    candleDownColor,
    setCandleDownColor,
    setZoomDomain,
    exportData,
    showEMA,
    setShowEMA,
    showSMA,
    setShowSMA,
    showBollingerBands,
    setShowBollingerBands,
    showMACD,
    setShowMACD,
    showRSI,
    setShowRSI,
  } = props;

  useEffect(() => {
    setQuery(searchTerm);
  }, [searchTerm, setQuery]);

  const closeAllDropdowns = () => {
    setIsExchangeSelectOpen(false);
    setIsSymbolSelectOpen(false);
  };

  return (
    <div
      className={`p-8 space-y-8 transition-colors duration-200 ${
        darkMode ? "bg-gray-900" : "bg-gray-50"
      }`}
    >
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="space-y-2">
          <h2
            className={`text-2xl font-bold tracking-tight ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Market Data Controls
          </h2>
          <p
            className={`text-lg ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            {symbol ? `${symbol}.${selectedExchange.Code}` : "Select a Symbol"}
          </p>
        </div>
      </div>

      {/* Symbol Search Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ExchangeSelect
          darkMode={darkMode}
          selectedExchange={selectedExchange}
          setIsExchangeSelectOpen={setIsExchangeSelectOpen}
          isExchangeSelectOpen={isExchangeSelectOpen}
          exchanges={exchanges}
          handleExchangeSelect={handleExchangeSelect}
          closeAllDropdowns={closeAllDropdowns}
        />

        <SymbolSearch
          darkMode={darkMode}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isSymbolSelectOpen={isSymbolSelectOpen}
          setIsSymbolSelectOpen={setIsSymbolSelectOpen}
          loadingSymbols={loadingSymbols}
          symbols={symbols}
          handleSymbolSelect={handleSymbolSelect}
          closeAllDropdowns={closeAllDropdowns}
        />
      </div>

      {/* Date and Period Controls */}
      <DatePeriodControls
        darkMode={darkMode}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        period={period}
        setPeriod={setPeriod}
      />

      {/* Chart Controls */}
      <div
        className={`p-6 rounded-xl backdrop-blur-sm ${
          darkMode ? "bg-gray-800/50" : "bg-white"
        } shadow-lg`}
      >
        <ChartControls
          darkMode={darkMode}
          chartType={chartType}
          setChartType={setChartType}
          showGridlines={showGridlines}
          setShowGridlines={setShowGridlines}
        />

        <TechnicalIndicators
          darkMode={darkMode}
          symbol={symbol} // Pass the symbol prop here
          showEMA={showEMA}
          setShowEMA={setShowEMA}
          showSMA={showSMA}
          setShowSMA={setShowSMA}
          showBollingerBands={showBollingerBands}
          setShowBollingerBands={setShowBollingerBands}
          showMACD={showMACD}
          setShowMACD={setShowMACD}
          showRSI={showRSI}
          setShowRSI={setShowRSI}
        />

        <CandleColorCustomization
          darkMode={darkMode}
          chartType={chartType}
          candleUpColor={candleUpColor}
          setCandleUpColor={setCandleUpColor}
          candleDownColor={candleDownColor}
          setCandleDownColor={setCandleDownColor}
        />
      </div>

      {/* Action Buttons */}
      <ActionButtons setZoomDomain={setZoomDomain} exportData={exportData} />
    </div>
  );
};

export default ControlsSection;
