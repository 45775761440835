import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import HeroSection from "./HeroSection";
import BenefitsSection from "./BenefitsSection";
import HowItWorksSection from "./HowItWorksSection";
import CTASection from "./CTASection";

function Affiliates() {
  const { darkMode } = useTheme();

  return (
    <div
      className={`min-h-screen ${darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"}`}
    >
      <HeroSection />
      <BenefitsSection />
      <HowItWorksSection id="how-it-works" />
      <CTASection />
    </div>
  );
}

export default Affiliates;
