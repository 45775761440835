import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import { useProfile } from "../../contexts/ProfileContext";
import { useTheme } from "../../contexts/ThemeContext";

const ProfileLink = () => {
  const { user } = useAuth();
  const { currentlyLoggedInUserProfile, getCurrentUserProfile } = useProfile();
  const { darkMode } = useTheme();

  useEffect(() => {
    if (user) {
      getCurrentUserProfile();
    }
  }, [user, getCurrentUserProfile]);

  if (!user || !currentlyLoggedInUserProfile) {
    return null;
  }

  return (
    <Link
      to={`/forum/profile/${currentlyLoggedInUserProfile.id}`}
      className="flex items-center"
    >
      {currentlyLoggedInUserProfile.profileImageUrl ? (
        <img
          src={currentlyLoggedInUserProfile.profileImageUrl}
          alt="Profile"
          className="w-8 h-8 rounded-full object-cover"
        />
      ) : darkMode ? (
        <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
          <FaUser className="text-gray-900 text-sm" />
        </div>
      ) : (
        <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center">
          <FaUser className="text-white text-sm" />
        </div>
      )}
    </Link>
  );
};

export default ProfileLink;
