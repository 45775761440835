import React from "react";
import {
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
  VictoryContainer,
  VictoryLegend,
} from "victory";
import { PieChart } from "lucide-react";

const AssetAllocationChart = ({ data, darkMode }) => {
  // Enhanced color palette for better visual appeal
  const colorScale = [
    "#3B82F6", // blue-500
    "#10B981", // emerald-500
    "#F59E0B", // amber-500
    "#6366F1", // indigo-500
    "#EC4899", // pink-500
    "#8B5CF6", // violet-500
    "#14B8A6", // teal-500
    "#F97316", // orange-500
  ];

  const allocationData = Object.values(
    data.MutualFund_Data?.Asset_Allocation || {},
  )
    .map((item) => ({
      x: item.Type,
      y: parseFloat(item["Net_%"]),
      label: `${item.Type}\n${parseFloat(item["Net_%"]).toFixed(1)}%`,
    }))
    .filter((item) => !isNaN(item.y) && item.y > 0);

  // Sort data by percentage for better visualization
  allocationData.sort((a, b) => b.y - a.y);

  const theme = {
    ...VictoryTheme.material,
    pie: {
      ...VictoryTheme.material.pie,
      style: {
        data: {
          stroke: darkMode ? "#1F2937" : "#FFFFFF",
          strokeWidth: 2,
        },
        labels: {
          fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
          fontSize: 12,
          fontWeight: "500",
        },
      },
    },
  };

  // Create legend data
  const legendData = allocationData.map((item, index) => ({
    name: `${item.x} (${item.y.toFixed(1)}%)`,
    symbol: { fill: colorScale[index % colorScale.length] },
  }));

  return (
    <div
      className={`w-full rounded-xl border shadow-sm transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Modern Header */}
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-4">
          <div
            className={`p-3 rounded-xl ${
              darkMode
                ? "bg-blue-500/10 text-blue-400"
                : "bg-blue-50 text-blue-500"
            }`}
          >
            <PieChart className="w-6 h-6" />
          </div>
          <div>
            <h3
              className={`text-xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Asset Allocation
            </h3>
            <p
              className={`mt-1 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Portfolio distribution across asset classes
            </p>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="p-6">
        {allocationData.length > 0 ? (
          <div className="flex flex-col items-center">
            <div className="w-full max-w-lg">
              <VictoryPie
                data={allocationData}
                theme={theme}
                colorScale={colorScale}
                labelComponent={
                  <VictoryTooltip
                    flyoutStyle={{
                      fill: darkMode ? "rgb(31, 41, 55)" : "white",
                      stroke: darkMode
                        ? "rgb(55, 65, 81)"
                        : "rgb(229, 231, 235)",
                      strokeWidth: 1,
                      filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
                    }}
                    style={{
                      fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                    flyoutPadding={{ top: 8, bottom: 8, left: 12, right: 12 }}
                    cornerRadius={8}
                  />
                }
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                innerRadius={70} // Creates a donut chart effect
                padding={{ top: 20, bottom: 20, left: 80, right: 80 }}
                containerComponent={<VictoryContainer responsive={true} />}
              />
              <VictoryLegend
                colorScale={colorScale}
                orientation="horizontal"
                gutter={20}
                rowGutter={{ top: 0, bottom: -10 }}
                style={{
                  labels: {
                    fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
                    fontSize: 12,
                  },
                }}
                data={legendData}
                itemsPerRow={2}
                containerComponent={
                  <VictoryContainer
                    responsive={true}
                    style={{ marginTop: 20 }}
                  />
                }
              />
            </div>
          </div>
        ) : (
          <p
            className={`text-center ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            No asset allocation data available.
          </p>
        )}
      </div>
    </div>
  );
};

export default AssetAllocationChart;
