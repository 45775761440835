import React from "react";
import { RotateCcw, Download } from "lucide-react";

const ActionButtons = ({ setZoomDomain, exportData }) => {
  return (
    <div className="flex gap-4 mt-4">
      <button
        onClick={() => setZoomDomain(undefined)}
        className="px-6 py-3 bg-emerald-500 text-white rounded-xl hover:bg-emerald-600 transition-all duration-200 shadow-lg flex items-center gap-2 font-medium"
      >
        <RotateCcw className="w-5 h-5" />
        Reset Zoom
      </button>
      <button
        onClick={exportData}
        className="px-6 py-3 bg-emerald-500 text-white rounded-xl hover:bg-emerald-600 transition-all duration-200 shadow-lg flex items-center gap-2 font-medium"
      >
        <Download className="w-5 h-5" />
        Export Data
      </button>
    </div>
  );
};

export default ActionButtons;
