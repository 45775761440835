import { useState } from "react";
import { getApiUrl } from "../config/api";

const useHandleComment = (id, isAuthenticated, setPost, refreshComments) => {
  const [commentContent, setCommentContent] = useState("");

  const handleComment = async (e) => {
    e.preventDefault();
    if (!isAuthenticated || !commentContent.trim()) return;
    try {
      const response = await fetch(`${getApiUrl()}/api/comments/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ content: commentContent }),
      });

      if (response.ok) {
        const newComment = await response.json();
        setPost((prev) => ({
          ...prev,
          comments: [...prev.comments, newComment],
        }));
        setCommentContent("");
        refreshComments();
      }
    } catch (err) {
      console.error("Failed to post comment", err);
    }
  };

  return { commentContent, setCommentContent, handleComment };
};

export default useHandleComment;
