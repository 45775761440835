import React, { useCallback, useState } from "react";
import {
  Bell,
  BellOff,
  X,
  Check,
  Trash2,
  ExternalLink,
  RefreshCw,
} from "lucide-react";
import { useAlerts } from "../../contexts/AlertsContext";
import { useNavigate } from "react-router-dom";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const AlertsDropdown = ({ isOpen, closeModal, darkMode }) => {
  const {
    alerts,
    markAlertAsRead,
    markAllAlertsAsRead,
    deleteAlert,
    hasUnreadAlerts,
    refreshAlerts,
  } = useAlerts();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const navigate = useNavigate();

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    await refreshAlerts();
    setIsRefreshing(false);
  }, [refreshAlerts]);

  if (!isOpen) return null;

  const handleViewPost = (alert) => {
    markAlertAsRead(alert.id);
    navigate(`/forum/post/${alert.postId}`);
    closeModal();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end p-4">
      <div
        className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm transition-opacity"
        onClick={closeModal}
      ></div>
      <div
        className={`${
          darkMode ? "bg-gray-900 text-gray-100" : "bg-white text-gray-900"
        } relative w-full max-w-md rounded-lg shadow-2xl ring-1 ${
          darkMode ? "ring-gray-700" : "ring-gray-200"
        } transition-all duration-300 ease-in-out transform ${
          isOpen ? "translate-y-0 opacity-100" : "translate-y-4 opacity-0"
        }`}
      >
        <div className="max-h-[80vh] overflow-y-auto">
          <div
            className={`px-6 py-4 flex justify-between items-center border-b ${
              darkMode ? "border-gray-700" : "border-gray-200"
            }`}
          >
            <span className="text-xl font-semibold flex items-center">
              <Bell
                className={`mr-2 ${
                  darkMode ? "text-green-400" : "text-green-500"
                }`}
                size={24}
              />
              Notifications
            </span>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleRefresh}
                className={`p-1 rounded-full ${
                  darkMode
                    ? "text-green-400 hover:text-green-300 hover:bg-gray-700"
                    : "text-green-600 hover:text-green-700 hover:bg-green-100"
                } transition duration-200 ease-in-out`}
                disabled={isRefreshing}
              >
                <RefreshCw
                  size={20}
                  className={isRefreshing ? "animate-spin" : ""}
                />
              </button>
              {hasUnreadAlerts && (
                <button
                  onClick={markAllAlertsAsRead}
                  className={`text-sm font-medium ${
                    darkMode
                      ? "text-green-400 hover:text-green-300"
                      : "text-green-600 hover:text-green-700"
                  } transition duration-200 ease-in-out hover:underline`}
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>

          {alerts.length === 0 ? (
            <div
              className={`px-6 py-8 text-center ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              <Bell className="mx-auto mb-4" size={32} />
              <p>No new notifications</p>
            </div>
          ) : (
            alerts.map((alert) => (
              <div
                key={alert.id}
                className={`m-3 p-4 rounded-lg transition-all duration-150 ease-in-out ${
                  alert.isRead
                    ? darkMode
                      ? "bg-gray-800 text-gray-300"
                      : "bg-gray-100 text-gray-600"
                    : darkMode
                      ? "bg-gray-700 hover:bg-gray-600"
                      : "bg-white hover:bg-gray-50 shadow-md"
                } ${
                  !alert.isRead &&
                  `border-l-4 ${
                    darkMode ? "border-green-400" : "border-green-500"
                  }`
                }`}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-grow pr-4">
                    <h3
                      className={`font-semibold mb-1 ${
                        darkMode ? "text-gray-100" : "text-gray-900"
                      }`}
                    >
                      {alert.title}
                    </h3>
                    <p
                      className={`text-sm mb-2 ${
                        darkMode ? "text-gray-300" : "text-gray-600"
                      }`}
                    >
                      {alert.content}
                    </p>
                    <div
                      className={`text-xs ${
                        darkMode ? "text-gray-400" : "text-gray-500"
                      } flex items-center justify-between`}
                    >
                      <span className="flex items-center">
                        <Bell size={12} className="mr-1" />
                        {formatDate(alert.createdAt)}
                      </span>
                      <button
                        className={`flex items-center ${
                          darkMode
                            ? "text-green-400 hover:text-green-300"
                            : "text-green-600 hover:text-green-700"
                        }`}
                        onClick={() => handleViewPost(alert)}
                      >
                        View Post <ExternalLink size={12} className="ml-1" />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {!alert.isRead && (
                      <button
                        onClick={() => markAlertAsRead(alert.id)}
                        className={`${
                          darkMode
                            ? "text-green-400 hover:text-green-300 hover:bg-gray-600"
                            : "text-green-600 hover:text-green-700 hover:bg-green-100"
                        } transition duration-200 ease-in-out p-1 rounded-full`}
                        title="Mark as read"
                      >
                        <Check size={16} />
                      </button>
                    )}
                    <button
                      onClick={() => deleteAlert(alert.id)}
                      className={`${
                        darkMode
                          ? "text-red-400 hover:text-red-300 hover:bg-gray-600"
                          : "text-red-600 hover:text-red-700 hover:bg-red-100"
                      } transition duration-200 ease-in-out p-1 rounded-full`}
                      title="Delete notification"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div
          className={`px-6 py-4 flex justify-end border-t ${
            darkMode ? "border-gray-700" : "border-gray-200"
          }`}
        >
          <button
            onClick={closeModal}
            className={`text-sm font-medium flex items-center px-4 py-2 text-gray-100 rounded-md ${
              darkMode
                ? "bg-green-600 hover:bg-green-500"
                : "bg-green-500 hover:bg-green-600"
            } transition duration-200 ease-in-out`}
          >
            Close <X className="ml-2" size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

const AlertsBellIcon = ({ darkMode }) => {
  const { hasUnreadAlerts } = useAlerts();

  const commonClasses = `transition-colors duration-300`;

  return hasUnreadAlerts ? (
    <Bell
      size={24}
      className={`${darkMode ? "text-yellow-500" : "text-yellow-600"} animate-pulse ${commonClasses}`}
    />
  ) : (
    <BellOff size={24} className={commonClasses} />
  );
};

export { AlertsDropdown, AlertsBellIcon };
