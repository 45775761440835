import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  FaCheckCircle,
  FaUsers,
  FaLightbulb,
  FaChartLine,
  FaLock,
  FaGem,
  FaRocket,
  FaKey,
} from "react-icons/fa";
import { motion } from "framer-motion";

function About() {
  const { darkMode } = useTheme();

  const sections = [
    {
      icon: FaCheckCircle,
      title: "Proven Success",
      description: `Quantum Money Code has a longstanding history of transforming lives by providing powerful financial tools. Our proven methodologies have been successfully applied across diverse walks of life, consistently delivering life-changing results.`,
    },
    {
      icon: FaUsers,
      title: "Community Support",
      description: `When you join Quantum Money Code, you're not merely investing in a program – you’re becoming part of an energetic and supportive community. Our members are passionate, driven, and united by a shared goal: achieving lasting financial freedom.`,
    },
    {
      icon: FaLightbulb,
      title: "Innovative Strategies",
      description: `Harness the cutting-edge strategies offered by Quantum Money Code, which combines quantum thinking and advanced financial insights. Our unique approach helps you tap into your full financial potential like never before.`,
    },
    {
      icon: FaChartLine,
      title: "Accelerated Financial Growth",
      description: `Our program is designed to supercharge your financial progress, whether you're laying your financial foundation or looking for new avenues to expand wealth. Quantum Money Code identifies untapped growth opportunities.`,
    },
    {
      icon: FaLock,
      title: "Financial Security",
      description: `Security is the bedrock of sustainable wealth. Quantum Money Code equips you with the knowledge and tools to safeguard your finances, ensuring your wealth grows with stability and resilience.`,
    },
    {
      icon: FaGem,
      title: "Hidden Opportunities",
      description: `Uncover hidden gems in the financial world that are often overlooked by the masses. We dive deep into emerging markets and revolutionary financial technologies, providing insights that help you stay ahead of the curve.`,
    },
    {
      icon: FaRocket,
      title: "Rapid Wealth Accumulation",
      description: `For those ready to take bold steps, Quantum Money Code delivers rapid wealth-building tactics. Our accelerated strategies empower you to achieve exponential growth and bring your financial dreams within reach, faster than you ever thought possible.`,
    },
    {
      icon: FaKey,
      title: "Unlock Financial Freedom",
      description: `True financial freedom is attainable with the right tools, and Quantum Money Code provides you with the key to unlock it. Break free from the limitations of conventional thinking and discover the path to lasting financial independence.`,
    },
  ];

  return (
    <section
      className={`min-h-screen py-20 px-4 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-800"
      } transition-colors duration-500`}
    >
      <div className="container mx-auto max-w-6xl">
        {/* Section Heading */}
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={`text-5xl font-extrabold text-center mt-10 mb-10 ${
            darkMode ? "text-green-400" : "text-green-600"
          } transition-colors duration-500
      md:mt-16 md:mb-16  // Add larger margin for desktop screens
      lg:mt-24 lg:mb-24`} // Even larger margin for larger screens
        >
          Ready to Change Your Life?
        </motion.h1>

        {/* Introductory Paragraph */}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className={`text-xl text-center leading-relaxed max-w-3xl mx-auto mb-20 ${
            darkMode ? "text-gray-300" : "text-gray-700"
          } transition-colors duration-500`}
        >
          The Quantum Money Code is a revolutionary financial system crafted to
          help individuals from all backgrounds attain financial freedom. Our
          approach blends innovative strategies, actionable insights, and
          community-driven support, empowering you to control your financial
          future with confidence.
        </motion.p>

        {/* Feature Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`bg-opacity-10 rounded-lg p-6 ${
                darkMode ? "bg-green-800" : "bg-green-100"
              } hover:shadow-lg transition-shadow duration-300`}
            >
              <div className="flex items-center mb-4">
                <section.icon className="text-green-500 w-8 h-8 mr-3" />
                <h2 className="text-2xl font-semibold">{section.title}</h2>
              </div>
              <p
                className={`${
                  darkMode ? "text-gray-300" : "text-gray-700"
                } text-lg leading-relaxed`}
              >
                {section.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
