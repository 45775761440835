import React, { useState, useRef, useEffect } from "react";
import { Info } from "lucide-react";

const MetricCard = ({ title, value, subtitle, darkMode, tooltip }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const buttonRef = useRef(null);

  // Handle click outside to close tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  // Helper function to determine background style
  const getBackgroundStyle = () => {
    if (darkMode) {
      return "bg-gradient-to-br from-gray-800 to-gray-800/95";
    }
    return "bg-white";
  };

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  return (
    <div
      className={`relative group overflow-visible transition-all duration-300
      p-5 rounded-xl border hover:shadow-lg transform hover:scale-[1.02]
      ${
        darkMode
          ? "border-gray-700 hover:shadow-black/10 hover:border-gray-600"
          : "border-gray-200 hover:shadow-gray-200/50 hover:border-gray-300"
      }
      ${getBackgroundStyle()}`}
    >
      {/* Subtle background accent */}
      <div
        className={`absolute inset-0 transition-opacity duration-300 opacity-0 group-hover:opacity-100
        ${
          darkMode
            ? "bg-gradient-to-br from-green-500/5 to-transparent"
            : "bg-gradient-to-br from-green-50/50 to-transparent"
        }`}
      />

      {/* Content Container */}
      <div className="relative">
        {/* Header */}
        <div className="flex justify-between items-start mb-3">
          <div className="space-y-1">
            <span
              className={`text-sm font-medium tracking-wide
              ${darkMode ? "text-gray-400" : "text-gray-600"}`}
            >
              {title}
            </span>
          </div>

          {/* Tooltip Container */}
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={toggleTooltip}
              onMouseEnter={() => setIsTooltipVisible(true)}
              onMouseLeave={(e) => {
                // Only hide on mouse leave if we're not on mobile
                if (window.matchMedia("(min-width: 768px)").matches) {
                  setIsTooltipVisible(false);
                }
              }}
              className={`p-1.5 rounded-full transition-colors duration-200
              ${darkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"}
              active:bg-opacity-80 focus:outline-none focus:ring-2
              ${darkMode ? "focus:ring-gray-600" : "focus:ring-gray-300"}`}
              type="button"
              aria-label="Show more information"
              aria-expanded={isTooltipVisible}
            >
              <Info
                size={16}
                className={`transition-colors duration-200
                  ${
                    darkMode
                      ? "text-gray-400 hover:text-gray-300"
                      : "text-gray-500 hover:text-gray-600"
                  } 
                  cursor-help`}
              />
            </button>

            {/* Tooltip Content */}
            {isTooltipVisible && (
              <div
                ref={tooltipRef}
                className={`
                  absolute z-50 w-64 p-4 rounded-xl shadow-xl
                  transform transition-all duration-200
                  md:right-0 md:-translate-x-0
                  right-full translate-x-2
                  mt-2
                  ${
                    darkMode
                      ? "bg-gray-800 text-gray-200 border border-gray-700"
                      : "bg-white text-gray-700 border border-gray-200"
                  }
                `}
                role="tooltip"
              >
                <div className="text-sm leading-relaxed">{tooltip}</div>

                {/* Tooltip Arrow */}
                <div
                  className={`
                    absolute w-4 h-4 transform rotate-45
                    -top-2 
                    md:right-4
                    right-2
                    ${
                      darkMode
                        ? "bg-gray-800 border-l border-t border-gray-700"
                        : "bg-white border-l border-t border-gray-200"
                    }
                  `}
                />
              </div>
            )}
          </div>
        </div>

        {/* Value */}
        <div className="space-y-2">
          <p
            className={`text-2xl font-bold tracking-tight transition-colors duration-200
            ${
              darkMode
                ? "text-white group-hover:text-green-400"
                : "text-gray-900 group-hover:text-green-600"
            }`}
          >
            {value}
          </p>

          {/* Subtitle */}
          {subtitle && (
            <p
              className={`text-sm font-medium transition-colors duration-200
              ${
                darkMode
                  ? "text-gray-400 group-hover:text-gray-300"
                  : "text-gray-500 group-hover:text-gray-600"
              }`}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MetricCard;
