import React from "react";
import {
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from "victory";
import { TrendingUp } from "lucide-react";

const PerformanceChart = ({ data, darkMode }) => {
  const performance = data.ETF_Data?.Performance;

  const performanceData = [
    { period: "5Y", value: parseFloat(performance?.Returns_5Y) },
    { period: "3Y", value: parseFloat(performance?.Returns_3Y) },
    { period: "1Y", value: parseFloat(performance?.Returns_1Y) },
    { period: "YTD", value: parseFloat(performance?.Returns_YTD) },
  ].filter((item) => !isNaN(item.value) && item.value !== 0);

  // Enhanced theme with modern styling
  const theme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        axis: {
          stroke: darkMode
            ? "rgba(156, 163, 175, 0.6)"
            : "rgba(107, 114, 128, 0.6)",
          strokeWidth: 1,
        },
        grid: {
          stroke: darkMode
            ? "rgba(55, 65, 81, 0.3)"
            : "rgba(229, 231, 235, 0.8)",
          strokeDasharray: "4",
        },
        tickLabels: {
          fill: darkMode ? "rgb(156, 163, 175)" : "rgb(107, 114, 128)",
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
  };

  return (
    <div
      className={`w-full rounded-xl border shadow-sm transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Modern Header */}
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-4">
          <div
            className={`p-3 rounded-xl ${
              darkMode
                ? "bg-emerald-500/10 text-emerald-400"
                : "bg-emerald-50 text-emerald-500"
            }`}
          >
            <TrendingUp className="w-6 h-6" />
          </div>
          <div>
            <h3
              className={`text-xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Performance
            </h3>
            <p
              className={`mt-1 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Historical performance over different periods
            </p>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="p-6">
        <VictoryChart
          theme={theme}
          height={280}
          padding={{ top: 40, right: 40, bottom: 40, left: 60 }}
          domainPadding={{ x: 50 }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) =>
                `${datum.period}: ${datum.value.toFixed(2)}%`
              }
              labelComponent={
                <VictoryTooltip
                  flyoutStyle={{
                    fill: darkMode ? "rgb(31, 41, 55)" : "white",
                    stroke: darkMode ? "rgb(55, 65, 81)" : "rgb(229, 231, 235)",
                    strokeWidth: 1,
                    filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
                  }}
                  style={{
                    fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
                    fontSize: 12,
                    fontWeight: "500",
                  }}
                  flyoutPadding={{ top: 8, bottom: 8, left: 12, right: 12 }}
                  cornerRadius={8}
                />
              }
            />
          }
        >
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => `${t}%`}
            style={{
              grid: {
                stroke: darkMode
                  ? "rgba(55, 65, 81, 0.3)"
                  : "rgba(229, 231, 235, 0.8)",
              },
            }}
          />
          <VictoryAxis
            style={{
              grid: { stroke: "transparent" },
            }}
          />
          <VictoryLine
            data={performanceData}
            x="period"
            y="value"
            style={{
              data: {
                stroke: darkMode ? "#34D399" : "#059669",
                strokeWidth: 3,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default PerformanceChart;
