import React, { useState } from "react";
import { User, Clock, ThumbsUp, Trash, Edit, Check, X } from "lucide-react";
import { motion } from "framer-motion";
import useCommentLike from "../../hooks/useCommentLike";
import useRelativeTime from "../../hooks/useRelativeTime";
import useEditDeleteComment from "../../hooks/useEditDeleteComment";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";

const CommentItem = ({
  comment: initialComment,
  darkMode,
  onLike,
  currentlyLoggedInUserId,
  onCommentDeleted,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    comment,
    isEditing,
    setIsEditing,
    editedContent,
    setEditedContent,
    handleEdit,
    handleDelete,
  } = useEditDeleteComment(initialComment);

  const { isLiked, likeCount, toggleLike } = useCommentLike(comment);

  const relativeTime = useRelativeTime(new Date(comment.updatedAt));

  const handleLike = async () => {
    const result = await toggleLike();
    if (result) {
      onLike(comment.id, result.isLiked, result.likeCount);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const isDeleted = await handleDelete();
    if (isDeleted) {
      onCommentDeleted(comment.id);
    }
    setIsDeleteDialogOpen(false);
  };

  const isAuthor = currentlyLoggedInUserId === comment.author.id;

  if (!comment) return null;

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        className={`p-4 sm:p-6 mb-6 rounded-2xl shadow-lg transition-all duration-300 ease-in-out ${
          darkMode ? "bg-gray-800 text-gray-200" : "bg-white text-gray-900"
        }`}
      >
        <div className="flex flex-col sm:flex-row sm:items-start sm:space-x-4">
          <div className="flex-shrink-0 mb-4 sm:mb-0">
            {comment.author?.profileImageUrl ? (
              <img
                src={comment.author.profileImageUrl}
                alt={comment.author.username}
                className="w-12 h-12 rounded-full object-cover transition-transform duration-200 hover:scale-110 ring-2 ring-blue-500"
              />
            ) : (
              <div
                className={`w-12 h-12 rounded-full flex items-center justify-center transition-transform duration-200 hover:scale-110 ${
                  darkMode ? "bg-gray-700" : "bg-gray-200"
                }`}
              >
                <User
                  size={24}
                  className={darkMode ? "text-gray-300" : "text-gray-600"}
                />
              </div>
            )}
          </div>
          <div className="flex-1 space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <h4
                className={`font-bold text-lg mb-1 sm:mb-0 ${
                  darkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                {comment.author?.username || "Unknown User"}
              </h4>
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="mr-1" size={14} />
                <span>{relativeTime}</span>
              </div>
            </div>
            {isEditing ? (
              <div className="space-y-3">
                <textarea
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                  className={`w-full p-3 rounded-lg transition-colors duration-200 focus:ring-2 focus:ring-blue-500 ${
                    darkMode
                      ? "bg-gray-700 text-gray-300"
                      : "bg-gray-100 text-gray-900"
                  }`}
                  rows={3}
                />
                <div className="flex justify-end space-x-3">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleEdit}
                    className="p-2 rounded-full bg-green-500 text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    <Check size={18} />
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsEditing(false)}
                    className="p-2 rounded-full bg-red-500 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    <X size={18} />
                  </motion.button>
                </div>
              </div>
            ) : (
              <div className="w-full">
                <p className="text-base leading-relaxed">{comment.content}</p>
              </div>
            )}
            <div className="flex items-center justify-between w-full">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleLike}
                className={`flex items-center justify-center px-4 py-2 rounded-full transition-colors duration-200 ${
                  isLiked
                    ? "bg-green-500 text-white"
                    : darkMode
                      ? "text-gray-200 hover:bg-gray-600"
                      : "text-gray-700 hover:bg-gray-300"
                }`}
              >
                <ThumbsUp className="mr-2" size={16} />
                {likeCount === 0
                  ? "Like"
                  : `${likeCount} ${likeCount === 1 ? "Like" : "Likes"}`}
              </motion.button>
              {isAuthor && !isEditing && (
                <div className="flex space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setIsEditing(true)}
                    className={`p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      darkMode
                        ? "text-gray-300 hover:bg-gray-600"
                        : "text-gray-600 hover:bg-gray-300"
                    }`}
                  >
                    <Edit size={18} />
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleDeleteClick}
                    className={`p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 ${
                      darkMode
                        ? "text-gray-300 hover:bg-gray-600"
                        : "text-gray-600 hover:bg-gray-300"
                    }`}
                  >
                    <Trash size={18} />
                  </motion.button>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>

      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        darkMode={darkMode}
        title="Delete Comment"
        description="Are you sure you want to delete this comment? This action cannot be undone."
        cancelButtonText="Cancel"
        confirmButtonText="Delete Comment"
      />
    </>
  );
};

export default CommentItem;
