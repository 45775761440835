import { useState, useEffect, useCallback } from "react";
import { getApiUrl } from "../config/api";

export const useFollowManagement = (followeeId) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFollowStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/${followeeId}/follow-status`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Failed to fetch follow status");
      }
      const data = await response.json();
      setIsFollowing(data.isFollowing);
    } catch (error) {
      console.error("Error fetching follow status:", error);
    } finally {
      setIsLoading(false);
    }
  }, [followeeId]);

  useEffect(() => {
    fetchFollowStatus();
  }, [fetchFollowStatus]);

  const toggleFollow = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/${followeeId}/toggle-follow`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to toggle follow status");
      }

      await fetchFollowStatus();
    } catch (error) {
      console.error("Error toggling follow:", error);
      setIsLoading(false);
    }
  };

  return { isFollowing, isLoading, toggleFollow };
};
