import React from "react";
import { motion } from "framer-motion";

const CallInterfaceAlert = ({
  isOpen,
  onClose,
  darkMode,
  title = "Error",
  message = "An unexpected error occurred.",
  buttonText = "OK",
}) => {
  if (!isOpen) return null;

  const baseClasses =
    "fixed inset-0 flex items-center justify-center z-50 p-4 sm:p-0";
  const overlayClasses =
    "absolute inset-0 bg-black bg-opacity-50 backdrop-blur-md";
  const dialogClasses = `relative p-6 rounded-lg shadow-xl w-full max-w-sm sm:max-w-md ${
    darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
  }`;
  const titleClasses = `text-lg sm:text-xl font-bold mb-4 ${
    darkMode ? "text-red-400" : "text-red-600"
  }`;
  const messageClasses = `text-sm sm:text-base mb-6 ${
    darkMode ? "text-gray-300" : "text-gray-600"
  }`;
  const buttonClasses = `px-4 py-2 rounded-md font-semibold text-sm sm:text-base w-full ${
    darkMode
      ? "bg-green-500 text-white hover:bg-green-600"
      : "bg-green-600 text-white hover:bg-green-700"
  }`;

  return (
    <div className={baseClasses}>
      <div className={overlayClasses}></div>
      <motion.div
        className={dialogClasses}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
      >
        <h2 className={titleClasses}>{title}</h2>
        <p className={messageClasses}>{message}</p>
        <button onClick={onClose} className={buttonClasses}>
          {buttonText}
        </button>
      </motion.div>
    </div>
  );
};

export default CallInterfaceAlert;
