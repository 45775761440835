import React, { useEffect } from "react";
import { Building2, Search, ChevronDown } from "lucide-react";

const Header = ({
  data,
  symbol,
  darkMode,
  selectedExchange,
  selectedType,
  searchTerm,
  setSearchTerm,
  isSymbolSelectOpen,
  setIsSymbolSelectOpen,
  isExchangeSelectOpen,
  setIsExchangeSelectOpen,
  isTypeSelectOpen,
  setIsTypeSelectOpen,
  SUPPORTED_EXCHANGE_CODES,
  tickerTypes,
  symbols,
  loadingSymbols,
  handleExchangeSelect,
  handleTypeSelect,
  handleSymbolSelect,
  setQuery,
}) => {
  useEffect(() => {
    setQuery(searchTerm);
  }, [searchTerm, setQuery]);

  const closeAllDropdowns = () => {
    setIsExchangeSelectOpen(false);
    setIsTypeSelectOpen(false);
    setIsSymbolSelectOpen(false);
  };

  return (
    <div
      className={`p-8 space-y-8 transition-colors duration-200 ${
        darkMode ? "bg-gray-900" : "bg-gray-50"
      }`}
    >
      {/* Header Title Section */}
      <div className="flex items-center gap-6">
        <div
          className={`p-4 rounded-2xl transform transition-all duration-200 hover:scale-105 ${
            darkMode ? "bg-gray-800/50" : "bg-white"
          } shadow-lg backdrop-blur-sm`}
        >
          <Building2
            className={`w-8 h-8 ${
              darkMode ? "text-emerald-400" : "text-emerald-500"
            }`}
          />
        </div>
        <div className="space-y-3">
          <h1
            className={`text-3xl font-bold tracking-tight ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            {data?.General?.Name || symbol || "Select a Symbol"}
          </h1>
          {data?.General?.Exchange && (
            <div className="flex flex-wrap gap-2">
              {[
                data.General.Exchange,
                data.General.Sector,
                data.General.Industry,
              ]
                .filter(Boolean)
                .map((item, index) => (
                  <span
                    key={index}
                    className={`px-4 py-1.5 text-sm rounded-full transition-colors duration-200 ${
                      darkMode
                        ? "bg-gray-800/50 text-gray-300"
                        : "bg-white text-gray-600"
                    } shadow-md backdrop-blur-sm`}
                  >
                    {item}
                  </span>
                ))}
            </div>
          )}
        </div>
      </div>

      {/* Controls Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Exchange Selector */}
        <div className="space-y-2 relative">
          <label
            className={`text-sm font-medium ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Exchange
          </label>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsExchangeSelectOpen(!isExchangeSelectOpen);
              setIsTypeSelectOpen(false);
              setIsSymbolSelectOpen(false);
            }}
            className={`group w-full p-4 text-left rounded-xl shadow-sm transition-all duration-200 flex justify-between items-center ${
              darkMode
                ? "bg-gray-800/50 text-white hover:bg-gray-700/50"
                : "bg-white text-gray-900 hover:bg-gray-50"
            }`}
          >
            <span>
              {selectedExchange.Name} ({selectedExchange.Code})
            </span>
            <ChevronDown
              className={`w-4 h-4 transition-transform duration-200 ${
                isExchangeSelectOpen ? "rotate-180" : ""
              }`}
            />
          </button>
          {isExchangeSelectOpen && (
            <div
              className={`absolute mt-2 w-full rounded-xl shadow-xl z-50 max-h-64 overflow-y-auto transform transition-all duration-200 ${
                darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
              }`}
            >
              {SUPPORTED_EXCHANGE_CODES.map((exchange) => (
                <button
                  key={exchange.Code}
                  onClick={() => {
                    handleExchangeSelect(exchange);
                    closeAllDropdowns();
                  }}
                  className={`w-full p-4 text-left transition-colors duration-200 ${
                    darkMode
                      ? `${selectedExchange.Code === exchange.Code ? "bg-gray-700" : ""} 
                         hover:bg-gray-700/70`
                      : `${selectedExchange.Code === exchange.Code ? "bg-blue-50" : ""} 
                         hover:bg-blue-50/70`
                  }`}
                >
                  {exchange.Name} ({exchange.Code})
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Type Selector */}
        <div className="space-y-2 relative">
          <label
            className={`text-sm font-medium ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Type
          </label>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsTypeSelectOpen(!isTypeSelectOpen);
              setIsExchangeSelectOpen(false);
              setIsSymbolSelectOpen(false);
            }}
            className={`group w-full p-4 text-left rounded-xl shadow-sm transition-all duration-200 flex justify-between items-center ${
              darkMode
                ? "bg-gray-800/50 text-white hover:bg-gray-700/50"
                : "bg-white text-gray-900 hover:bg-gray-50"
            }`}
          >
            <span>{selectedType.label}</span>
            <ChevronDown
              className={`w-4 h-4 transition-transform duration-200 ${
                isTypeSelectOpen ? "rotate-180" : ""
              }`}
            />
          </button>
          {isTypeSelectOpen && (
            <div
              className={`absolute mt-2 w-full rounded-xl shadow-xl z-50 transform transition-all duration-200 ${
                darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
              }`}
            >
              {tickerTypes.map((type) => (
                <button
                  key={type.value}
                  onClick={() => {
                    handleTypeSelect(type);
                    closeAllDropdowns();
                  }}
                  className={`w-full p-4 text-left transition-colors duration-200 ${
                    darkMode
                      ? `${selectedType.value === type.value ? "bg-gray-700" : ""} 
                         hover:bg-gray-700/70`
                      : `${selectedType.value === type.value ? "bg-blue-50" : ""} 
                         hover:bg-blue-50/70`
                  }`}
                >
                  {type.label}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Symbol Search */}
        <div className="space-y-2 relative">
          <label
            className={`text-sm font-medium ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Symbol
          </label>
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toUpperCase());
                setIsSymbolSelectOpen(true);
                setIsExchangeSelectOpen(false);
                setIsTypeSelectOpen(false);
              }}
              placeholder="Search symbol..."
              className={`w-full p-4 pl-12 rounded-xl shadow-sm transition-all duration-200 ${
                darkMode
                  ? "bg-gray-800/50 text-white placeholder-gray-400 focus:ring-2 focus:ring-emerald-500"
                  : "bg-white text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-emerald-500"
              }`}
            />
            <Search className="absolute left-4 top-4 w-5 h-5 text-gray-400" />
            {isSymbolSelectOpen && (
              <div
                className={`absolute mt-2 w-full rounded-xl shadow-xl z-50 max-h-64 overflow-y-auto transform transition-all duration-200 ${
                  darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
                }`}
              >
                {loadingSymbols ? (
                  <div className="p-4">Loading...</div>
                ) : symbols.length === 0 ? (
                  <div className="p-4">No symbols found</div>
                ) : (
                  symbols
                    .filter(
                      (symbol) => !["CC", "FOREX"].includes(symbol.Exchange),
                    )
                    .map((symbol) => (
                      <button
                        key={symbol.Code}
                        onClick={() => {
                          handleSymbolSelect(symbol.Code);
                          closeAllDropdowns();
                        }}
                        className={`w-full p-4 text-left transition-colors duration-200 ${
                          darkMode
                            ? "hover:bg-gray-700/70"
                            : "hover:bg-blue-50/70"
                        }`}
                      >
                        <div className="font-medium">{symbol.Name}</div>
                        <div
                          className={
                            darkMode ? "text-gray-400" : "text-gray-500"
                          }
                        >
                          {symbol.Code} - {symbol.Exchange}
                        </div>
                      </button>
                    ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
