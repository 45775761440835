import { useState, useEffect, useCallback } from "react";
import { getApiUrl } from "../config/api";

const useUserSearch = (initialQuery = "") => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState(initialQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const searchUsers = useCallback(
    async (page = 1) => {
      if (!query) {
        setUsers([]);
        setTotalPages(1);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${getApiUrl()}/api/users/search?q=${encodeURIComponent(query)}&page=${page}&limit=5`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.pagination.totalPages);
        setCurrentPage(data.pagination.currentPage);
      } catch (err) {
        setError("An error occurred while searching for users");
        console.error("Error searching users:", err);
      } finally {
        setLoading(false);
      }
    },
    [query],
  );

  useEffect(() => {
    searchUsers(1);
  }, [query, searchUsers]);

  const handleSearch = (newQuery) => {
    setQuery(newQuery);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    searchUsers(newPage);
  };

  return {
    users,
    loading,
    error,
    query,
    currentPage,
    totalPages,
    handleSearch,
    handlePageChange,
  };
};

export default useUserSearch;
