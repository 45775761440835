import React from "react";

const TypingIndicator = ({ usernames, darkMode }) => {
  const textColor = darkMode ? "text-green-300" : "text-green-600";
  const dotColor = darkMode ? "bg-green-300" : "bg-green-600";

  const formatUsernames = (usernames) => {
    if (usernames.length === 1) return usernames[0];
    if (usernames.length === 2) return `${usernames[0]} and ${usernames[1]}`;
    if (usernames.length > 2)
      return `${usernames[0]}, ${usernames[1]}, and ${usernames.length - 2} other${usernames.length > 3 ? "s" : ""}`;
    return "";
  };

  return (
    <div className={`flex items-center ${textColor} text-sm p-2`}>
      <span className="mr-2">
        {formatUsernames(usernames)} {usernames.length === 1 ? "is" : "are"}{" "}
        typing
      </span>
      <div className="flex space-x-1">
        {[0, 1, 2].map((i) => (
          <div
            key={i}
            className={`w-1.5 h-1.5 ${dotColor} rounded-full animate-pulse`}
            style={{ animationDelay: `${i * 150}ms` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default TypingIndicator;
