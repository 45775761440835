import React from "react";
import { motion } from "framer-motion";

const FollowStatusMessage = ({ icon: Icon, message, type, darkMode }) => {
  const getColorClasses = () => {
    if (type === "error") {
      return darkMode ? "text-red-400" : "text-red-600";
    } else if (type === "loading") {
      return darkMode ? "text-green-400" : "text-green-600";
    } else {
      return darkMode ? "text-green-400" : "text-green-600";
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`flex flex-col items-center justify-center h-64 ${getColorClasses()}`}
    >
      <Icon
        className={`text-4xl ${type === "loading" ? "animate-spin" : "animate-bounce"} mb-4`}
      />
      <p className="text-lg font-semibold">{message}</p>
    </motion.div>
  );
};

export default FollowStatusMessage;
