export const formatNumber = (num) => {
  if (!num) return "N/A";
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  }).format(num);
};

export const formatPercent = (num) => {
  if (!num) return "N/A";
  return `${Number(num).toFixed(2)}%`;
};

export const formatDate = (date) => {
  if (!date) return "N/A";
  return new Date(date).toLocaleDateString();
};

export const formatCurrency = (num, currency = "USD") => {
  if (!num) return "N/A";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    notation: "compact",
    maximumFractionDigits: 2,
  }).format(num);
};
