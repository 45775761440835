import React from "react";
import { ChevronRight, ChevronDown } from "lucide-react";

const FundamentalsDisplaySections = ({
  title,
  children,
  icon: Icon,
  isOpen,
  onToggle,
  darkMode,
}) => {
  const getBackgroundStyle = () => {
    if (darkMode) {
      return isOpen ? "bg-gray-800" : "bg-gray-800 hover:bg-gray-750";
    }
    return isOpen ? "bg-white" : "bg-white hover:bg-gray-50";
  };

  return (
    <div
      className={`rounded-xl border transition-all duration-300 ease-in-out
      ${darkMode ? "border-gray-700" : "border-gray-200"}
      ${isOpen ? "shadow-lg" : "shadow-md"}
      ${darkMode ? (isOpen ? "shadow-black/10" : "") : isOpen ? "shadow-gray-200/50" : ""}
      overflow-hidden`}
    >
      <div
        className={`group px-5 py-4 flex items-center justify-between cursor-pointer 
          transition-all duration-300 ${getBackgroundStyle()}`}
        onClick={onToggle}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => e.key === "Enter" && onToggle()}
      >
        <div className="flex items-center gap-3">
          <div
            className={`p-2 rounded-lg transition-colors duration-300
            ${
              darkMode
                ? isOpen
                  ? "bg-gray-700"
                  : "bg-gray-700/50"
                : isOpen
                  ? "bg-gray-100"
                  : "bg-gray-50"
            }`}
          >
            <Icon
              className={`w-5 h-5 transition-colors duration-300
                ${
                  darkMode
                    ? isOpen
                      ? "text-green-400"
                      : "text-gray-400"
                    : isOpen
                      ? "text-green-500"
                      : "text-gray-500"
                }`}
            />
          </div>
          <h3
            className={`font-semibold text-base transition-colors duration-300
            ${
              darkMode
                ? isOpen
                  ? "text-white"
                  : "text-gray-200"
                : isOpen
                  ? "text-gray-900"
                  : "text-gray-700"
            }`}
          >
            {title}
          </h3>
        </div>
        <div
          className={`p-1.5 rounded-lg transition-all duration-300 transform
          ${
            darkMode
              ? "bg-gray-700/0 group-hover:bg-gray-700/50"
              : "bg-gray-100/0 group-hover:bg-gray-100"
          }
          ${isOpen ? "rotate-180" : "rotate-0"}`}
        >
          <ChevronDown
            size={18}
            className={`transition-colors duration-300
              ${darkMode ? "text-gray-400" : "text-gray-500"}
              group-hover:${darkMode ? "text-gray-200" : "text-gray-700"}`}
          />
        </div>
      </div>

      <div
        className={`grid transition-all duration-300 ease-in-out
        ${isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
      >
        <div className="overflow-hidden">
          <div
            className={`p-5 border-t transition-colors duration-300
            ${
              darkMode
                ? "border-gray-700/50 bg-gray-800/50"
                : "border-gray-100 bg-gray-50/50"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundamentalsDisplaySections;
