import React from "react";
import { AlertCircle } from "lucide-react";

const ErrorState = ({ darkMode, error }) => {
  const is500Error = error.message?.includes("500");

  return (
    <div
      className={`w-full rounded-2xl shadow-xl overflow-hidden ${
        darkMode ? "bg-gray-800" : "bg-white"
      }`}
    >
      <div
        className={`p-6 ${
          darkMode ? "bg-red-900/20" : "bg-red-50"
        } flex items-start gap-4`}
      >
        <div
          className={`p-3 rounded-full ${
            darkMode ? "bg-red-500/20" : "bg-red-100"
          }`}
        >
          <AlertCircle
            className={`w-6 h-6 ${darkMode ? "text-red-400" : "text-red-500"}`}
          />
        </div>
        <div className="space-y-2">
          <h3
            className={`text-lg font-semibold ${
              darkMode ? "text-red-200" : "text-red-800"
            }`}
          >
            {is500Error ? "No Data Available" : "Error Loading Data"}
          </h3>
          <div className="space-y-1">
            <p
              className={`text-sm ${
                darkMode ? "text-red-300" : "text-red-600"
              }`}
            >
              {is500Error
                ? "We couldn't retrieve fundamental data for this company or asset at this time."
                : error.message}
            </p>
            {is500Error && (
              <p
                className={`text-sm ${
                  darkMode ? "text-red-300/80" : "text-red-600/80"
                }`}
              >
                This may happen if the asset is not supported or if there was an
                issue accessing the data.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorState;
