import React from "react";
import {
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
  VictoryContainer,
  VictoryLegend,
} from "victory";
import { Globe } from "lucide-react";

const WorldRegionsChart = ({ data, darkMode }) => {
  const regions = data.MutualFund_Data?.World_Regions || {};

  const regionData = [];

  Object.entries(regions).forEach(([regionGroup, countries]) => {
    Object.values(countries).forEach((country) => {
      if (country["Stocks_%"] !== null) {
        regionData.push({
          x: country.Name,
          y: parseFloat(country["Stocks_%"]),
          label: `${country.Name}\n${parseFloat(country["Stocks_%"]).toFixed(
            1,
          )}%`,
        });
      }
    });
  });

  // Enhanced color palette for better visual appeal
  const colorScale = [
    "#3B82F6", // blue-500
    "#10B981", // emerald-500
    "#F59E0B", // amber-500
    "#6366F1", // indigo-500
    "#EC4899", // pink-500
    "#8B5CF6", // violet-500
    "#14B8A6", // teal-500
    "#F97316", // orange-500
  ];

  // Sort data by percentage for better visualization
  regionData.sort((a, b) => b.y - a.y);

  const theme = {
    ...VictoryTheme.material,
    pie: {
      ...VictoryTheme.material.pie,
      style: {
        data: {
          stroke: darkMode ? "#1F2937" : "#FFFFFF",
          strokeWidth: 2,
        },
        labels: {
          fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
          fontSize: 12,
          fontWeight: "500",
        },
      },
    },
  };

  // Create legend data
  const legendData = regionData.map((item, index) => ({
    name: `${item.x} (${item.y.toFixed(1)}%)`,
    symbol: { fill: colorScale[index % colorScale.length] },
  }));

  return (
    <div
      className={`w-full rounded-xl border shadow-sm transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Modern Header */}
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-4">
          <div
            className={`p-3 rounded-xl ${
              darkMode
                ? "bg-green-500/10 text-green-400"
                : "bg-green-50 text-green-500"
            }`}
          >
            <Globe className="w-6 h-6" />
          </div>
          <div>
            <h3
              className={`text-xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              World Regions Allocation
            </h3>
            <p
              className={`mt-1 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Geographic distribution of holdings
            </p>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="p-6">
        {regionData.length > 0 ? (
          <div className="flex flex-col items-center">
            <div className="w-full max-w-lg">
              <VictoryPie
                data={regionData}
                theme={theme}
                colorScale={colorScale}
                labelComponent={
                  <VictoryTooltip
                    flyoutStyle={{
                      fill: darkMode ? "rgb(31, 41, 55)" : "white",
                      stroke: darkMode
                        ? "rgb(55, 65, 81)"
                        : "rgb(229, 231, 235)",
                      strokeWidth: 1,
                      filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
                    }}
                    style={{
                      fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                    flyoutPadding={{ top: 8, bottom: 8, left: 12, right: 12 }}
                    cornerRadius={8}
                  />
                }
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                innerRadius={70} // Creates a donut chart effect
                padding={{ top: 20, bottom: 20, left: 80, right: 80 }}
                containerComponent={<VictoryContainer responsive={true} />}
              />
              <VictoryLegend
                colorScale={colorScale}
                orientation="horizontal"
                gutter={20}
                rowGutter={{ top: 0, bottom: -10 }}
                style={{
                  labels: {
                    fill: darkMode ? "rgb(229, 231, 235)" : "rgb(17, 24, 39)",
                    fontSize: 12,
                  },
                }}
                data={legendData}
                itemsPerRow={2}
                containerComponent={
                  <VictoryContainer
                    responsive={true}
                    style={{ marginTop: 20 }}
                  />
                }
              />
            </div>
          </div>
        ) : (
          <p
            className={`text-center ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            No world regions data available.
          </p>
        )}
      </div>
    </div>
  );
};

export default WorldRegionsChart;
