import React from "react";
import { CalendarDays, Calendar } from "lucide-react";

const TimePeriodSelector = ({ timeframe, setTimeframe, darkMode }) => {
  const periods = [
    {
      id: "quarterly",
      label: "Quarterly",
      icon: CalendarDays,
      description: "View quarterly financial data",
    },
    {
      id: "yearly",
      label: "Yearly",
      icon: Calendar,
      description: "View annual financial data",
    },
  ];

  return (
    <div
      className={`p-1 rounded-xl inline-flex items-center gap-1 
      ${darkMode ? "bg-gray-800/80" : "bg-gray-100/80"}`}
    >
      {periods.map(({ id, label, icon: Icon, description }) => (
        <button
          key={id}
          onClick={() => setTimeframe(id)}
          className={`
            relative group flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
            transition-all duration-200 ease-in-out
            ${
              timeframe === id
                ? darkMode
                  ? "bg-green-500 text-white shadow-sm shadow-green-500/20"
                  : "bg-green-500 text-white shadow-sm shadow-green-500/20"
                : darkMode
                  ? "text-gray-400 hover:text-white hover:bg-gray-700/50"
                  : "text-gray-600 hover:text-gray-900 hover:bg-white"
            }
          `}
          aria-label={description}
        >
          <Icon className="w-4 h-4" />
          <span>{label}</span>

          {/* Tooltip */}
          <div
            className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 
            text-xs font-medium rounded-md whitespace-nowrap opacity-0 pointer-events-none
            transition-opacity duration-200 group-hover:opacity-100
            ${darkMode 
              ? 'bg-gray-800 text-gray-200 shadow-lg shadow-black/20' 
              : 'bg-white text-gray-600 shadow-lg shadow-gray-200/50'
            }"
          >
            {description}
          </div>
        </button>
      ))}
    </div>
  );
};

export default TimePeriodSelector;
