import React from "react";
import { TrendingUp, TrendingDown, Info } from "lucide-react";

const CashFlowRatios = ({ data, darkMode }) => {
  const getRatioColor = (value, threshold, inverse = false) => {
    if (inverse) {
      if (value > threshold.high)
        return darkMode ? "text-red-400" : "text-red-600";
      if (value < threshold.low)
        return darkMode ? "text-green-400" : "text-green-600";
    } else {
      if (value > threshold.high)
        return darkMode ? "text-green-400" : "text-green-600";
      if (value < threshold.low)
        return darkMode ? "text-red-400" : "text-red-600";
    }
    return darkMode ? "text-gray-300" : "text-gray-700";
  };

  const getRatioBackground = (value, threshold, inverse = false) => {
    if (inverse) {
      if (value > threshold.high)
        return darkMode ? "bg-red-400/10" : "bg-red-50";
      if (value < threshold.low)
        return darkMode ? "bg-green-400/10" : "bg-green-50";
    } else {
      if (value > threshold.high)
        return darkMode ? "bg-green-400/10" : "bg-green-50";
      if (value < threshold.low)
        return darkMode ? "bg-red-400/10" : "bg-red-50";
    }
    return darkMode ? "bg-gray-700" : "bg-gray-100";
  };

  const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;
  const formatRatio = (value) => value.toFixed(2);

  const ratios = [
    {
      category: "Liquidity Ratios",
      icon: "💧",
      items: [
        {
          name: "Operating Cash Flow Ratio",
          value: data.operatingCashFlow / Math.abs(data.financingCashFlow),
          format: formatRatio,
          threshold: { low: 1, high: 1.5 },
          description: "Ability to cover short-term obligations",
        },
        {
          name: "Cash Flow Coverage Ratio",
          value: data.operatingCashFlow / Math.abs(data.investingCashFlow),
          format: formatRatio,
          threshold: { low: 1, high: 1.5 },
          description: "Ability to pay for investments",
        },
      ],
    },
    {
      category: "Efficiency Ratios",
      icon: "⚡",
      items: [
        {
          name: "Cash Flow to Income",
          value: data.operatingCashFlow / data.netIncome,
          format: formatRatio,
          threshold: { low: 0.8, high: 1.2 },
          description: "Quality of earnings",
        },
        {
          name: "Free Cash Flow Yield",
          value: data.freeCashFlow / data.netIncome,
          format: formatPercentage,
          threshold: { low: 0.05, high: 0.1 },
          description: "Cash available after capital expenditures",
        },
      ],
    },
    {
      category: "Solvency Ratios",
      icon: "🛡️",
      items: [
        {
          name: "Cash Flow to Debt",
          value: data.operatingCashFlow / Math.abs(data.financingCashFlow),
          format: formatRatio,
          threshold: { low: 0.2, high: 0.4 },
          description: "Ability to pay total debt",
        },
        {
          name: "Dividend Coverage",
          value: Math.abs(data.operatingCashFlow / data.dividendsPaid),
          format: formatRatio,
          threshold: { low: 1.5, high: 2 },
          description: "Ability to pay dividends",
        },
      ],
    },
  ];

  const getThresholdStatus = (value, threshold, inverse = false) => {
    if (inverse) {
      if (value > threshold.high)
        return { icon: TrendingDown, color: "text-red-500" };
      if (value < threshold.low)
        return { icon: TrendingUp, color: "text-green-500" };
    } else {
      if (value > threshold.high)
        return { icon: TrendingUp, color: "text-green-500" };
      if (value < threshold.low)
        return { icon: TrendingDown, color: "text-red-500" };
    }
    return null;
  };

  return (
    <div
      className={`rounded-2xl overflow-hidden shadow-xl ${
        darkMode
          ? "bg-gray-900 border border-gray-800"
          : "bg-white border border-gray-100"
      }`}
    >
      {/* Header */}
      <div
        className={`px-6 py-6 ${
          darkMode
            ? "bg-gradient-to-r from-gray-900 to-gray-800"
            : "bg-gradient-to-r from-gray-50 to-white"
        }`}
      >
        <h3
          className={`text-2xl font-semibold tracking-tight ${
            darkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Cash Flow Ratios
        </h3>
        <p
          className={`mt-2 text-sm ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          Key financial ratios and performance metrics
        </p>
      </div>

      {/* Content */}
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {ratios.map((category, idx) => (
            <div
              key={idx}
              className={`rounded-xl p-6 ${
                darkMode
                  ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg"
                  : "bg-gradient-to-br from-gray-50/50 to-white/50 backdrop-blur-lg"
              } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
            >
              {/* Category Header */}
              <div className="flex items-center gap-3 mb-6">
                <span className="text-2xl">{category.icon}</span>
                <h4
                  className={`text-lg font-semibold ${
                    darkMode ? "text-gray-200" : "text-gray-700"
                  }`}
                >
                  {category.category}
                </h4>
              </div>

              {/* Ratios */}
              <div className="space-y-6">
                {category.items.map((ratio, ratioIdx) => {
                  const status = getThresholdStatus(
                    ratio.value,
                    ratio.threshold,
                  );
                  return (
                    <div key={ratioIdx} className="group relative">
                      {/* Ratio Header */}
                      <div className="flex justify-between items-center mb-2">
                        <span
                          className={`text-sm font-medium ${
                            darkMode ? "text-gray-300" : "text-gray-700"
                          }`}
                        >
                          {ratio.name}
                        </span>
                        <div
                          className={`flex items-center gap-2 px-3 py-1 rounded-full ${getRatioBackground(
                            ratio.value,
                            ratio.threshold,
                          )}`}
                        >
                          {status && (
                            <status.icon
                              className={`w-4 h-4 ${status.color}`}
                            />
                          )}
                          <span
                            className={`font-semibold ${getRatioColor(
                              ratio.value,
                              ratio.threshold,
                            )}`}
                          >
                            {ratio.format(ratio.value)}
                          </span>
                        </div>
                      </div>

                      {/* Description with Info Icon */}
                      <div
                        className={`flex items-start gap-2 text-xs ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      >
                        <Info className="w-4 h-4 mt-0.5 flex-shrink-0" />
                        <p>{ratio.description}</p>
                      </div>

                      {/* Progress Bar */}
                      <div className="mt-3">
                        <div className="w-full h-1 rounded-full bg-gray-200 dark:bg-gray-700 overflow-hidden">
                          <div
                            className="h-full transition-all duration-300"
                            style={{
                              width: `${Math.min(Math.abs((ratio.value / ratio.threshold.high) * 100), 100)}%`,
                              backgroundColor: getRatioColor(
                                ratio.value,
                                ratio.threshold,
                              ).includes("green")
                                ? "#10B981"
                                : getRatioColor(
                                      ratio.value,
                                      ratio.threshold,
                                    ).includes("red")
                                  ? "#EF4444"
                                  : "#6B7280",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CashFlowRatios;
