import React, { useState } from "react";
import { User, Clock, Edit, Trash2 } from "lucide-react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import FollowingToggleButton from "../follow/following/FollowingToggleButton";
import useRelativeTime from "../../hooks/useRelativeTime";
import useCreateEditDeletePost from "../../hooks/useCreateEditDeletePost";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import VideoEmbed from "./VideoEmbed";

const PostHeader = ({ post, darkMode, onDeletePost }) => {
  const { title, author, createdAt, content, updatedAt } = post;
  const { profileImageUrl, username, isFollowing, id: authorId } = author || {};
  const { user } = useAuth();
  const relativeTime = useRelativeTime(new Date(updatedAt));
  const navigate = useNavigate();
  const { deletePost, error, isLoading } = useCreateEditDeletePost(post.id);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const baseTheme = darkMode ? "dark" : "light";
  const themeColors = {
    light: {
      bg: "bg-white",
      text: "text-gray-900",
      title: "text-green-600",
      authorName: "text-gray-700",
      content: "text-gray-700",
      link: "text-blue-600",
      icon: "text-gray-600",
      iconHover: "hover:text-gray-800",
      cardBg: "bg-white",
    },
    dark: {
      bg: "bg-gray-900",
      text: "text-gray-200",
      title: "text-green-400",
      authorName: "text-gray-300",
      content: "text-gray-300",
      link: "text-blue-400",
      icon: "text-gray-400",
      iconHover: "hover:text-gray-200",
      cardBg: "bg-gray-800",
    },
  };

  const theme = themeColors[baseTheme];

  const isCurrentUserAuthor = user && user.id === authorId;

  const handleEdit = () => {
    navigate(`/forum/post/${post.id}/edit`);
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    const success = await deletePost();
    if (success) {
      onDeletePost(post.id);
    }
    setIsDeleteDialogOpen(false);
  };

  const renderContent = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className={`${theme.link} hover:underline transition-colors duration-200 break-words`}
          >
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div
      className={`mb-8 ${theme.text} ${theme.bg} max-w-4xl mx-auto sm:px-6 lg:px-8 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
    >
      <div className="py-6 px-3">
        <h1
          className={`text-3xl sm:text-4xl font-bold mb-6 ${theme.title} transition-colors duration-200`}
        >
          {title}
        </h1>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
          <div className="flex items-center mb-4 sm:mb-0">
            <div
              className={`w-12 h-12 sm:w-14 sm:h-14 rounded-full flex items-center justify-center overflow-hidden ${profileImageUrl ? "" : theme.cardBg} transition-colors duration-200`}
            >
              {profileImageUrl ? (
                <img
                  src={profileImageUrl}
                  alt={username || "Unknown Author"}
                  className="w-full h-full object-cover"
                />
              ) : (
                <User className={theme.icon} size={28} />
              )}
            </div>
            <div className="ml-4">
              <h3
                className={`font-semibold text-lg sm:text-xl ${theme.authorName} transition-colors duration-200`}
              >
                {username || "Unknown Author"}
              </h3>
              <div className="flex items-center text-sm text-gray-500 mt-1">
                <Clock className="mr-2" size={14} />
                <span>{relativeTime}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            {authorId && !isCurrentUserAuthor && (
              <FollowingToggleButton
                followeeId={authorId}
                initialIsFollowing={isFollowing}
                darkMode={darkMode}
              />
            )}
            {isCurrentUserAuthor && (
              <div className="flex ml-4">
                <button
                  onClick={handleEdit}
                  className={`mr-4 ${theme.icon} ${theme.iconHover} transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                  aria-label="Edit post"
                >
                  <Edit size={22} />
                </button>
                <button
                  onClick={handleDelete}
                  className={`${theme.icon} ${theme.iconHover} transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
                  aria-label="Delete post"
                >
                  <Trash2 size={22} />
                </button>
              </div>
            )}
          </div>
        </div>
        <VideoEmbed content={content} />
        <div
          className={`${theme.cardBg} rounded-lg p-6 mt-6 transition-colors duration-200`}
        >
          <p
            className={`text-base leading-relaxed ${theme.content} max-w-3xl break-words`}
          >
            {renderContent(content)}
          </p>
        </div>
      </div>
      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={confirmDelete}
        darkMode={darkMode}
        title="Delete Post"
        description="Are you sure you want to delete this post? This action cannot be undone."
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
      />
    </div>
  );
};

export default PostHeader;
