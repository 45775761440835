import React from "react";
import { Loader } from "lucide-react";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import { useFollowManagement } from "../../../hooks/useFollowManagement";

const FollowingToggleButton = ({ followeeId, darkMode }) => {
  const { isFollowing, isLoading, toggleFollow } =
    useFollowManagement(followeeId);

  const baseClasses =
    "px-4 py-2 rounded-full text-sm font-semibold transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center justify-center shadow-md hover:shadow-lg";
  const loadingClasses = "opacity-75 cursor-not-allowed";

  const getButtonClasses = () => {
    if (isLoading)
      return `${baseClasses} ${loadingClasses} bg-gray-300 text-gray-700`;
    if (isFollowing) {
      return darkMode
        ? `${baseClasses} bg-gray-700 text-white hover:bg-red-600`
        : `${baseClasses} bg-gray-200 text-gray-800 hover:bg-red-100 hover:text-red-600`;
    }
    return darkMode
      ? `${baseClasses} bg-green-600 text-white hover:bg-green-700`
      : `${baseClasses} bg-green-500 text-white hover:bg-green-600`;
  };

  return (
    <button
      onClick={toggleFollow}
      disabled={isLoading}
      className={getButtonClasses()}
    >
      {isLoading ? (
        <>
          <Loader className="animate-spin mr-2" size={18} />
          Loading
        </>
      ) : isFollowing ? (
        <>
          <RiUserUnfollowLine className="mr-2" size={18} />
          Unfollow
        </>
      ) : (
        <>
          <RiUserFollowLine className="mr-2" size={18} />
          Follow
        </>
      )}
    </button>
  );
};

export default FollowingToggleButton;
