import React from "react";
import { motion } from "framer-motion";
import { FaSpinner, FaExclamationTriangle, FaSearch } from "react-icons/fa";

const UserSearchStatusMessage = ({
  loading,
  error,
  darkMode,
  noUsersFound,
  hasSearchQuery,
}) => {
  if (!loading && !error && !(noUsersFound && hasSearchQuery)) return null;

  const iconClass = `w-12 h-12 mb-4 ${darkMode ? "text-green-400" : "text-green-600"}`;
  const textClass = `text-lg ${darkMode ? "text-gray-300" : "text-gray-600"}`;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`flex flex-col items-center justify-center p-8 rounded-lg ${
        darkMode ? "bg-gray-800" : "bg-white"
      } shadow-lg`}
    >
      {loading && (
        <>
          <FaSpinner className={`${iconClass} animate-spin`} />
          <p className={textClass}>Loading users...</p>
        </>
      )}
      {error && (
        <>
          <FaExclamationTriangle className={`${iconClass} text-yellow-500`} />
          <p className={`${textClass} text-red-500`}>{error}</p>
        </>
      )}
      {noUsersFound && hasSearchQuery && (
        <>
          <FaSearch className={`${iconClass} text-green-500`} />
          <p className={textClass}>No users found.</p>
        </>
      )}
    </motion.div>
  );
};

export default UserSearchStatusMessage;
