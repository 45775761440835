import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FaSearch, FaUser, FaIdCard } from "react-icons/fa";
import { FaRegMessage } from "react-icons/fa6";
import { ImProfile } from "react-icons/im";
import { motion } from "framer-motion";
import Paginator from "../common/Paginator";
import useUserSearch from "../../hooks/useUserSearch";
import { useNavigate } from "react-router-dom";
import { Badge } from "../avatar/Badge";
import UserSearchStatusMessage from "./UserSearchStatusMessage";
import { useAuth } from "../../contexts/AuthContext";

const UserSearch = () => {
  const {
    users,
    loading,
    error,
    query,
    currentPage,
    totalPages,
    handleSearch,
    handlePageChange,
  } = useUserSearch();
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleInputChange = (e) => {
    handleSearch(e.target.value);
  };

  const truncateEmail = (email) => {
    if (email.length > 25) {
      return email.substring(0, 22) + "...";
    }
    return email;
  };

  const handleProfileClick = (userId) => {
    navigate(`/forum/profile/${userId}`);
  };

  const handleSendMessage = (userId) => {
    navigate(`/forum/messages/new?singleParticipant=${userId}`);
  };

  const noUsersFound = !loading && !error && users.length === 0;
  const hasSearchQuery = query.trim().length > 0;

  return (
    <section
      className={`min-h-screen py-10 px-4 ${
        darkMode ? "text-white" : "text-gray-800"
      } transition-colors duration-500`}
    >
      <div className="container mx-auto max-w-6xl">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mt-5 mb-8 md:mt-10 md:mb-12"
        >
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl font-extrabold ${
              darkMode ? "text-green-400" : "text-green-600"
            } transition-colors duration-500`}
          >
            Discover Your Quantum Network
          </h1>
          <p
            className={`mt-2 text-lg sm:text-xl ${
              darkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Connect with fellow Quantum Money Code enthusiasts
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="mb-8 relative"
        >
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Search for users..."
            className={`w-full p-3 sm:p-4 pl-10 sm:pl-12 text-base sm:text-lg border rounded-lg ${
              darkMode
                ? "bg-gray-800 text-white border-gray-700"
                : "bg-white text-gray-800 border-gray-300"
            } focus:outline-none focus:ring-2 focus:ring-green-500`}
          />
          <FaSearch
            className={`absolute left-3 sm:left-4 top-1/2 transform -translate-y-1/2 ${
              darkMode ? "text-gray-400" : "text-gray-500"
            }`}
          />
        </motion.div>

        <UserSearchStatusMessage
          loading={loading}
          error={error}
          darkMode={darkMode}
          noUsersFound={noUsersFound}
          hasSearchQuery={hasSearchQuery}
        />

        {!loading && !error && users.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            {users.map((user) => (
              <motion.div
                key={user.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={`rounded-lg p-4 sm:p-6 ${
                  darkMode ? "bg-gray-800" : "bg-white"
                } hover:shadow-lg transition-shadow duration-300`}
              >
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                  <div className="flex items-center mb-4 sm:mb-0">
                    {user.profileImageUrl ? (
                      <img
                        src={user.profileImageUrl}
                        alt={`${user.username}'s profile`}
                        className="w-12 h-12 rounded-full mr-4"
                      />
                    ) : (
                      <FaUser className="w-12 h-12 rounded-full mr-4 text-gray-400" />
                    )}
                    <div>
                      <h2
                        className={`text-xl font-semibold ${darkMode ? "text-green-400" : "text-green-600"}`}
                      >
                        {user.username}
                      </h2>
                      <p
                        className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
                        title={user.email}
                      >
                        {truncateEmail(user.email)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <Badge
                      variant="secondary"
                      darkMode={darkMode}
                      className="cursor-pointer text-xs sm:text-sm py-1 px-2 sm:px-3"
                      onClick={() => handleProfileClick(user.id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ImProfile size={16} className="mr-2 hidden sm:inline" />
                      Profile
                    </Badge>
                    <Badge
                      variant="secondary"
                      darkMode={darkMode}
                      className="cursor-pointer text-xs sm:text-sm py-1 px-2 sm:px-3"
                      onClick={() => handleSendMessage(user.id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaRegMessage
                        size={16}
                        className="mr-2 hidden sm:inline"
                      />
                      Message
                    </Badge>
                  </div>
                </div>
                {user.bio && (
                  <p
                    className={`text-base leading-relaxed mt-4 ${darkMode ? "text-gray-300" : "text-gray-700"}`}
                  >
                    {user.bio}
                  </p>
                )}
                {user.location && (
                  <p
                    className={`text-sm mt-2 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
                  >
                    {user.location}
                  </p>
                )}
              </motion.div>
            ))}
          </motion.div>
        )}

        {totalPages > 1 && (
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            darkMode={darkMode}
          />
        )}
      </div>
    </section>
  );
};

export default UserSearch;
