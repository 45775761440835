import React, { useState } from "react";
import {
  Building2,
  TrendingUp,
  TrendingDown,
  ArrowUpDown,
  Briefcase,
  ArrowDownRight,
  ArrowUpRight,
} from "lucide-react";

const HoldersInfo = ({
  data,
  darkMode,
  formatNumber,
  formatPercent,
  formatDate,
}) => {
  if (!data) return null;

  const getTotalHoldings = (holders) => {
    return Object.values(holders || {}).reduce(
      (sum, holder) => sum + holder.totalShares,
      0,
    );
  };

  const institutionalHoldings = getTotalHoldings(data.Institutions);
  const fundHoldings = getTotalHoldings(data.Funds);

  const SummaryCard = ({ title, value }) => {
    const getBackgroundStyle = () => {
      return darkMode
        ? "bg-slate-800 shadow-lg shadow-slate-900/20"
        : "bg-white shadow-lg shadow-slate-200/50";
    };

    const getTextColor = () => {
      return darkMode ? "text-slate-200" : "text-slate-700";
    };

    return (
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <Briefcase className="w-6 h-6 text-blue-500" />
              <h3
                className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
              >
                {title}
              </h3>
            </div>
            <div className="space-y-2">
              <p
                className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                {value}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const HoldersTable = ({
    data,
    darkMode,
    formatNumber,
    formatPercent,
    formatDate,
    title,
    icon: Icon,
  }) => {
    const [sortConfig, setSortConfig] = useState({
      key: "totalShares",
      direction: "desc",
    });

    const holders = Object.values(data || {});

    const sortedHolders = [...holders].sort((a, b) => {
      if (sortConfig.key === "name") {
        return sortConfig.direction === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
    });

    const handleSort = (key) => {
      setSortConfig((prevSort) => ({
        key,
        direction:
          prevSort.key === key && prevSort.direction === "desc"
            ? "asc"
            : "desc",
      }));
    };

    const getBackgroundStyle = () => {
      return darkMode
        ? "bg-slate-800 shadow-lg shadow-slate-900/20"
        : "bg-white shadow-lg shadow-slate-200/50";
    };

    const getTextColor = () => {
      return darkMode ? "text-slate-200" : "text-slate-700";
    };

    const HolderCard = ({ holder }) => (
      <div
        className={`rounded-xl ${
          darkMode ? "bg-slate-700" : "bg-slate-50"
        } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg mb-4`}
      >
        <div className="p-4 sm:p-5">
          <div className="flex justify-between items-start mb-4">
            <div
              className={`font-medium text-base ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              {holder.name}
            </div>
            <div
              className={`px-3 py-1 rounded-full flex items-center gap-1.5 text-sm font-medium ${
                holder.change_p > 0
                  ? "bg-green-50 text-green-600"
                  : holder.change_p < 0
                    ? "bg-red-50 text-red-600"
                    : darkMode
                      ? "bg-slate-700 text-slate-400"
                      : "bg-slate-100 text-slate-600"
              }`}
            >
              {holder.change_p > 0 ? (
                <ArrowUpRight size={16} />
              ) : holder.change_p < 0 ? (
                <ArrowDownRight size={16} />
              ) : null}
              {formatPercent(holder.change_p / 100)}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div
              className={`rounded-lg p-4 ${
                darkMode ? "bg-slate-700" : "bg-slate-50"
              }`}
            >
              <div
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                Shares %
              </div>
              <div
                className={`text-base font-semibold ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                {formatPercent(holder.totalShares / 100)}
              </div>
            </div>
            <div
              className={`rounded-lg p-4 ${
                darkMode ? "bg-slate-700" : "bg-slate-50"
              }`}
            >
              <div
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                Position
              </div>
              <div
                className={`text-base font-semibold ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                {formatNumber(holder.currentShares)}
              </div>
            </div>
            <div
              className={`rounded-lg p-4 ${
                darkMode ? "bg-slate-700" : "bg-slate-50"
              }`}
            >
              <div
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                Assets %
              </div>
              <div
                className={`text-base font-semibold ${
                  darkMode ? "text-white" : "text-slate-900"
                }`}
              >
                {formatPercent(holder.totalAssets / 100)}
              </div>
            </div>
            <div
              className={`rounded-lg p-4 ${
                darkMode ? "bg-slate-700" : "bg-slate-50"
              }`}
            >
              <div
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                Last Report
              </div>
              <div
                className={`text-base font-semibold ${
                  darkMode ? "text-slate-300" : "text-slate-700"
                }`}
              >
                {formatDate(holder.date)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const SortHeader = ({ label, sortKey }) => (
      <th
        className={`py-3 px-4 text-left cursor-pointer ${
          darkMode
            ? "text-slate-400 hover:text-slate-200"
            : "text-slate-600 hover:text-slate-900"
        }`}
        onClick={() => handleSort(sortKey)}
      >
        <div className="flex items-center gap-2">
          {label}
          <ArrowUpDown
            size={16}
            className={`transition-all duration-200 ${
              sortConfig.key === sortKey
                ? "opacity-100"
                : "opacity-50 hover:opacity-75"
            }`}
          />
        </div>
      </th>
    );

    return (
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <Icon className="w-6 h-6 text-blue-500" />
                <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                  {title}
                </h3>
              </div>
              <div
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  darkMode
                    ? "bg-slate-700 text-slate-300"
                    : "bg-slate-100 text-slate-600"
                }`}
              >
                {holders.length} holders
              </div>
            </div>

            {/* Desktop Table View */}
            <div className="hidden sm:block overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr
                    className={`border-b ${
                      darkMode ? "border-slate-700" : "border-slate-200"
                    }`}
                  >
                    <SortHeader label="Holder" sortKey="name" />
                    <SortHeader label="Shares %" sortKey="totalShares" />
                    <SortHeader label="Position" sortKey="currentShares" />
                    <SortHeader label="Change" sortKey="change_p" />
                    <SortHeader label="Assets %" sortKey="totalAssets" />
                    <th
                      className={`py-3 px-4 text-left ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    >
                      Last Report
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedHolders.map((holder, index) => (
                    <tr
                      key={index}
                      className={`border-b last:border-b-0 ${
                        darkMode
                          ? "border-slate-700 hover:bg-slate-700"
                          : "border-slate-200 hover:bg-slate-50"
                      }`}
                    >
                      <td
                        className={`py-3 px-4 font-medium ${
                          darkMode ? "text-white" : "text-slate-900"
                        }`}
                      >
                        {holder.name}
                      </td>
                      <td
                        className={`py-3 px-4 ${
                          darkMode ? "text-white" : "text-slate-900"
                        }`}
                      >
                        {formatPercent(holder.totalShares / 100)}
                      </td>
                      <td
                        className={`py-3 px-4 ${
                          darkMode ? "text-white" : "text-slate-900"
                        }`}
                      >
                        {formatNumber(holder.currentShares)}
                      </td>
                      <td className="py-3 px-4">
                        <div
                          className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium ${
                            holder.change_p > 0
                              ? "bg-green-50 text-green-600"
                              : holder.change_p < 0
                                ? "bg-red-50 text-red-600"
                                : darkMode
                                  ? "bg-slate-700 text-slate-400"
                                  : "bg-slate-100 text-slate-600"
                          }`}
                        >
                          {holder.change_p > 0 ? (
                            <ArrowUpRight size={16} />
                          ) : holder.change_p < 0 ? (
                            <ArrowDownRight size={16} />
                          ) : null}
                          {formatPercent(holder.change_p / 100)}
                        </div>
                      </td>
                      <td
                        className={`py-3 px-4 ${
                          darkMode ? "text-white" : "text-slate-900"
                        }`}
                      >
                        {formatPercent(holder.totalAssets / 100)}
                      </td>
                      <td
                        className={`py-3 px-4 ${
                          darkMode ? "text-slate-400" : "text-slate-600"
                        }`}
                      >
                        {formatDate(holder.date)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Mobile Card View */}
            <div className="sm:hidden space-y-4">
              {sortedHolders.map((holder, index) => (
                <HolderCard key={index} holder={holder} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
        <SummaryCard
          title="Institutional Holdings"
          value={formatPercent(institutionalHoldings / 100)}
        />
        <SummaryCard
          title="Fund Holdings"
          value={formatPercent(fundHoldings / 100)}
        />
        <SummaryCard
          title="Total Holdings"
          value={formatPercent((institutionalHoldings + fundHoldings) / 100)}
        />
      </div>

      {/* Institutions Table */}
      <HoldersTable
        data={data.Institutions}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatPercent={formatPercent}
        formatDate={formatDate}
        title="Institutional Holders"
        icon={Building2}
      />

      {/* Funds Table */}
      <HoldersTable
        data={data.Funds}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatPercent={formatPercent}
        formatDate={formatDate}
        title="Fund Holders"
        icon={Briefcase}
      />
    </div>
  );
};

export default HoldersInfo;
