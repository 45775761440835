import React from "react";

const CashFlowDetailedBreakdown = ({ currentData, previousData, darkMode }) => {
  const formatValue = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const getPercentageChange = (current, previous) => {
    if (!previous) return null;
    return ((current - previous) / Math.abs(previous)) * 100;
  };

  const sections = [
    {
      title: "Operating Activities",
      color: "#10B981", // Green
      items: [
        {
          label: "Net Income",
          value: currentData.netIncome,
          previousValue: previousData?.netIncome,
        },
        {
          label: "Depreciation",
          value: currentData.depreciation,
          previousValue: previousData?.depreciation,
        },
        {
          label: "Working Capital Changes",
          value: currentData.changeInWorkingCapital,
          previousValue: previousData?.changeInWorkingCapital,
        },
        {
          label: "Operating Cash Flow",
          value: currentData.operatingCashFlow,
          previousValue: previousData?.operatingCashFlow,
          isTotal: true,
        },
      ],
    },
    {
      title: "Investing Activities",
      color: "#3B82F6", // Blue
      items: [
        {
          label: "Capital Expenditures",
          value: currentData.capitalExpenditures,
          previousValue: previousData?.capitalExpenditures,
        },
        {
          label: "Other Non-Cash Items",
          value: currentData.otherNonCashItems,
          previousValue: previousData?.otherNonCashItems,
        },
        {
          label: "Investment Cash Flow",
          value: currentData.investingCashFlow,
          previousValue: previousData?.investingCashFlow,
          isTotal: true,
        },
      ],
    },
    {
      title: "Financing Activities",
      color: "#F59E0B", // Amber
      items: [
        {
          label: "Dividends Paid",
          value: currentData.dividendsPaid,
          previousValue: previousData?.dividendsPaid,
        },
        {
          label: "Stock-Based Compensation",
          value: currentData.stockBasedCompensation,
          previousValue: previousData?.stockBasedCompensation,
        },
        {
          label: "Financing Cash Flow",
          value: currentData.financingCashFlow,
          previousValue: previousData?.financingCashFlow,
          isTotal: true,
        },
      ],
    },
  ];

  return (
    <div
      className={`rounded-2xl overflow-hidden ${
        darkMode
          ? "bg-gray-900 border border-gray-800"
          : "bg-white border border-gray-100"
      } shadow-xl`}
    >
      {/* Header */}
      <div
        className={`px-6 py-6 ${
          darkMode
            ? "bg-gradient-to-r from-gray-900 to-gray-800"
            : "bg-gradient-to-r from-gray-50 to-white"
        }`}
      >
        <h3
          className={`text-2xl font-semibold tracking-tight ${
            darkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Detailed Cash Flow Breakdown
        </h3>
        <p
          className={`mt-2 text-sm ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          Comprehensive analysis of cash flow components
        </p>
      </div>

      {/* Content */}
      <div className="p-6 space-y-8">
        {sections.map((section, idx) => (
          <div
            key={idx}
            className={`rounded-xl p-6 ${
              darkMode
                ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg"
                : "bg-gradient-to-br from-gray-50/50 to-white/50 backdrop-blur-lg"
            } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
          >
            <div className="flex items-center gap-3 mb-6">
              <div
                className="w-1 h-8 rounded-full"
                style={{ backgroundColor: section.color }}
              />
              <h4
                className={`text-lg font-semibold ${
                  darkMode ? "text-gray-200" : "text-gray-700"
                }`}
              >
                {section.title}
              </h4>
            </div>

            <div className="space-y-4">
              {section.items.map((item, itemIdx) => {
                const percentChange = getPercentageChange(
                  item.value,
                  item.previousValue,
                );
                return (
                  <div
                    key={itemIdx}
                    className={`group transition-all duration-200 ${
                      item.isTotal
                        ? `mt-6 pt-4 border-t ${darkMode ? "border-gray-700" : "border-gray-200"}`
                        : ""
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <span
                        className={`${
                          darkMode ? "text-gray-400" : "text-gray-600"
                        } ${item.isTotal ? "font-medium" : ""} transition-colors duration-200 group-hover:text-gray-300`}
                      >
                        {item.label}
                      </span>
                      <div className="flex items-center gap-4">
                        <span
                          className={`font-medium ${
                            darkMode ? "text-white" : "text-gray-900"
                          } ${item.isTotal ? "text-lg" : ""}`}
                        >
                          {formatValue(item.value)}
                        </span>
                        {percentChange !== null && (
                          <div
                            className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium ${
                              percentChange > 0
                                ? "text-green-500 bg-green-500/10"
                                : percentChange < 0
                                  ? "text-red-500 bg-red-500/10"
                                  : darkMode
                                    ? "text-gray-400 bg-gray-700"
                                    : "text-gray-600 bg-gray-100"
                            }`}
                          >
                            <span>{percentChange > 0 ? "↑" : "↓"}</span>
                            <span>{Math.abs(percentChange).toFixed(1)}%</span>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Progress bar for visualization */}
                    {!item.isTotal && (
                      <div className="mt-2 w-full h-1 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                        <div
                          className="h-full rounded-full transition-all duration-300 opacity-0 group-hover:opacity-100"
                          style={{
                            width: `${Math.min(Math.abs((item.value / currentData.operatingCashFlow) * 100), 100)}%`,
                            backgroundColor: section.color,
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CashFlowDetailedBreakdown;
