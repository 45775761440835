import React from "react";
import { ChevronDown } from "lucide-react";

const ExchangeSelect = ({
  darkMode,
  selectedExchange,
  setIsExchangeSelectOpen,
  isExchangeSelectOpen,
  exchanges,
  handleExchangeSelect,
  closeAllDropdowns,
}) => {
  return (
    <div className="space-y-2 relative">
      <label
        className={`text-sm font-medium ${
          darkMode ? "text-gray-300" : "text-gray-700"
        }`}
      >
        Exchange
      </label>
      <button
        onClick={() => setIsExchangeSelectOpen(!isExchangeSelectOpen)}
        className={`w-full p-4 text-left rounded-xl shadow-sm transition-all duration-200 flex justify-between items-center ${
          darkMode
            ? "bg-gray-800/50 text-white hover:bg-gray-700/50"
            : "bg-white text-gray-900 hover:bg-gray-50"
        }`}
      >
        <span>
          {selectedExchange.Code} - {selectedExchange.Name}
        </span>
        <ChevronDown
          className={`w-4 h-4 transition-transform duration-200 ${
            isExchangeSelectOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isExchangeSelectOpen && (
        <div
          className={`absolute mt-2 w-full max-h-64 overflow-y-auto rounded-xl shadow-xl z-50 ${
            darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
          }`}
        >
          {exchanges.map((exchange) => (
            <button
              key={exchange.Code}
              onClick={() => {
                handleExchangeSelect(exchange);
                closeAllDropdowns();
              }}
              className={`w-full p-4 text-left transition-colors duration-200 ${
                darkMode ? "hover:bg-gray-700/70" : "hover:bg-blue-50/70"
              }`}
            >
              {exchange.Code} - {exchange.Name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExchangeSelect;
