import {
  Leaf,
  BarChart3,
  Target,
  Building2,
  TrendingUp,
  Users,
  DollarSign,
  LineChart,
  Calculator,
} from "lucide-react";
import AnalystRatings from "../AnalystRatings";
import ESGScores from "../ESGScores";
import EarningsHistory from "../EarningsHistory";
import GeneralInfo from "../GeneralInfo";
import HighlightsInfo from "../HighlightsInfo";
import HoldersInfo from "../HoldersInfo";
import InsiderTransactions from "../InsiderTransactions";
import SharesStats from "../SharesStats";
import SplitsDividends from "../SplitsDividends";
import Technicals from "../Technicals";
import Valuation from "../Valuation";
import OutstandingShares from "../OutstandingShares";
import MetricCard from "../MetricCard";
import {
  formatDate,
  formatNumber,
  formatCurrency,
  formatPercent,
} from "../FormatHelpers";

export const fundamentalsSections = (data, darkMode) => [
  {
    id: "general",
    title: "Company Information",
    icon: Building2,
    content: (
      <GeneralInfo
        data={data.General}
        darkMode={darkMode}
        formatDate={formatDate}
        MetricCard={MetricCard}
      />
    ),
  },
  {
    id: "analystRatings",
    title: "Analyst Ratings",
    icon: Target,
    content: data.AnalystRatings && (
      <AnalystRatings
        data={data.AnalystRatings}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatCurrency={formatCurrency}
        MetricCard={MetricCard}
      />
    ),
  },
  {
    id: "esgScores",
    title: "ESG Scores",
    icon: Leaf,
    content: data.ESGScores && (
      <ESGScores
        data={data.ESGScores}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatDate={formatDate}
        MetricCard={MetricCard}
      />
    ),
  },
  {
    id: "earnings",
    title: "Earnings History",
    icon: BarChart3,
    content: (
      <EarningsHistory
        data={{
          History: data.Earnings?.History,
          Trend: data.Earnings?.Trend,
          Annual: data.Earnings?.Annual,
        }}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatCurrency={formatCurrency}
        formatPercent={formatPercent}
        formatDate={formatDate}
        MetricCard={MetricCard}
      />
    ),
  },
  {
    id: "highlights",
    title: "Key Highlights",
    icon: TrendingUp,
    content: (
      <HighlightsInfo
        data={data.Highlights}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatCurrency={formatCurrency}
        formatPercent={formatPercent}
        formatDate={formatDate}
        MetricCard={MetricCard}
      />
    ),
  },
  {
    id: "holders",
    title: "Institutional & Fund Holders",
    icon: Building2,
    content: (
      <HoldersInfo
        data={data.Holders}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatPercent={formatPercent}
        formatDate={formatDate}
      />
    ),
  },
  {
    id: "insiderTransactions",
    title: "Insider Trading Activity",
    icon: Users,
    content: (
      <InsiderTransactions
        data={data.InsiderTransactions}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatCurrency={formatCurrency}
        formatDate={formatDate}
      />
    ),
  },
  {
    id: "sharesStats",
    title: "Share Statistics",
    icon: Users,
    content: (
      <SharesStats
        data={data.SharesStats}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatPercent={formatPercent}
      />
    ),
  },
  {
    id: "splitsDividends",
    title: "Dividends & Splits",
    icon: DollarSign,
    content: (
      <SplitsDividends
        data={data.SplitsDividends}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatPercent={formatPercent}
        formatDate={formatDate}
      />
    ),
  },
  {
    id: "technicals",
    title: "Technical Indicators",
    icon: LineChart,
    content: (
      <Technicals
        data={data.Technicals}
        currentPrice={data.price}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatPercent={formatPercent}
      />
    ),
  },
  {
    id: "valuation",
    title: "Valuation Metrics",
    icon: Calculator,
    content: (
      <Valuation
        data={data.Valuation}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatCurrency={formatCurrency}
      />
    ),
  },
  {
    id: "outstandingShares",
    title: "Outstanding Shares",
    icon: Users,
    content: (
      <OutstandingShares
        data={data.outstandingShares}
        darkMode={darkMode}
        formatNumber={formatNumber}
        formatDate={formatDate}
      />
    ),
  },
];
