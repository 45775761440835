import React, { useState } from "react";
import { useFollowers } from "../../../hooks/useFollowers";
import { useTheme } from "../../../contexts/ThemeContext";
import Paginator from "../../common/Paginator";
import FollowStatusMessage from "../FollowStatusMessage";
import FollowersList from "../follower/FollowersList";
import { Loader, AlertCircle } from "lucide-react";

const FollowersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const { followers, loading, error, totalPages, removeFollower } =
    useFollowers(currentPage, pageSize);
  const { darkMode } = useTheme();

  const handlePageChange = setCurrentPage;

  const styles = {
    container: darkMode ? "text-gray-100" : "text-gray-900",
    card: darkMode ? "border-gray-700" : "border-gray-200",
    title: darkMode ? "text-green-400" : "text-green-600",
  };

  if (loading || error) {
    const icon = loading ? Loader : AlertCircle;
    const message = loading ? "Loading..." : error;
    return (
      <FollowStatusMessage
        icon={icon}
        message={message}
        type={loading ? "loading" : "error"}
        darkMode={darkMode}
      />
    );
  }

  return (
    <div
      className={`followers-page-container flex flex-col min-h-screen ${styles.container}`}
    >
      <div className="content-container flex-grow container mx-auto px-4 py-8">
        <div className={`rounded-lg shadow-lg p-6`}>
          <FollowersList
            followers={followers}
            removeFollower={removeFollower}
            darkMode={darkMode}
          />
        </div>
        <div className="pagination-section mt-8">
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            darkMode={darkMode}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowersPage;
