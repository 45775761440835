import React, { useMemo } from "react";
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryStack,
  VictoryLegend,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryTheme,
} from "victory";

export const IncomeCompositionChart = ({ data, darkMode }) => {
  const colors = {
    costOfRevenue: "#F87171",
    operatingExpenses: "#FBBF24",
    otherExpenses: "#A78BFA",
    netIncome: "#34D399",
  };

  const baseTheme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        grid: {
          stroke: darkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)",
          strokeDasharray: "4,4",
        },
        tickLabels: {
          fill: darkMode ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.9)",
          fontSize: 11,
          fontFamily: "'Inter', system-ui, sans-serif",
        },
      },
    },
  };

  const formatYAxis = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const chartData = useMemo(() => {
    return data.map((item) => ({
      date: new Date(item.date),
      costOfRevenue: item.costOfRevenue,
      operatingExpenses: item.totalOperatingExpenses,
      otherExpenses:
        item.interestExpense +
        item.incomeTaxExpense +
        (item.otherOperatingExpenses || 0),
      netIncome: item.netIncome,
    }));
  }, [data]);

  return (
    <div
      className={`w-full transition-all duration-200 ease-in-out ${
        darkMode ? "bg-gray-900" : "bg-white"
      }`}
    >
      <div className="max-w-7xl mx-auto">
        <div
          className={`rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm ${
            darkMode ? "bg-gray-800/50" : "bg-white"
          }`}
        >
          {/* Header Section */}
          <div
            className={`px-6 py-5 border-b ${
              darkMode ? "border-gray-700" : "border-gray-100"
            }`}
          >
            <h2
              className={`text-xl font-semibold tracking-tight ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Income Composition Analysis
            </h2>
            <p
              className={`mt-1 text-sm font-medium ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Breakdown of revenue allocation and expenses over time
            </p>
          </div>

          {/* Chart Section */}
          <div className="p-4 sm:p-6">
            <div
              className={`rounded-xl p-4 sm:p-6 transition-colors ${
                darkMode ? "bg-gray-800/50" : "bg-gray-50/80"
              }`}
            >
              <div className="h-[300px] sm:h-[400px] w-full">
                <VictoryChart
                  theme={baseTheme}
                  padding={{
                    top: 50,
                    right: 30,
                    bottom: 50,
                    left: 60,
                  }}
                  scale={{ x: "time" }}
                  height={400}
                  width={800}
                  domainPadding={{ x: 20 }}
                  containerComponent={
                    <VictoryVoronoiContainer
                      labels={({ datum }) => {
                        const date = new Date(datum.date).toLocaleDateString(
                          undefined,
                          {
                            year: "numeric",
                            month: "short",
                          },
                        );
                        return `${datum._stack}\n${formatYAxis(datum.y)}\n${date}`;
                      }}
                      labelComponent={
                        <VictoryTooltip
                          style={{
                            fontSize: 11,
                            fontFamily: "'Inter', system-ui, sans-serif",
                          }}
                          flyoutStyle={{
                            fill: darkMode ? "rgba(17,24,39,0.95)" : "white",
                            stroke: darkMode
                              ? "rgba(255,255,255,0.1)"
                              : "rgba(0,0,0,0.1)",
                            strokeWidth: 1,
                            filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.1))",
                          }}
                          cornerRadius={6}
                          flyoutPadding={{
                            top: 8,
                            bottom: 8,
                            left: 12,
                            right: 12,
                          }}
                        />
                      }
                    />
                  }
                >
                  <VictoryLegend
                    x={50}
                    y={0}
                    orientation="horizontal"
                    gutter={24}
                    style={{
                      labels: {
                        fontSize: 11,
                        fontFamily: "'Inter', system-ui, sans-serif",
                        fill: darkMode
                          ? "rgba(255,255,255,0.9)"
                          : "rgba(0,0,0,0.9)",
                      },
                    }}
                    data={[
                      {
                        name: "Cost of Revenue",
                        symbol: { fill: colors.costOfRevenue },
                      },
                      {
                        name: "Operating Expenses",
                        symbol: { fill: colors.operatingExpenses },
                      },
                      {
                        name: "Other Expenses",
                        symbol: { fill: colors.otherExpenses },
                      },
                      {
                        name: "Net Income",
                        symbol: { fill: colors.netIncome },
                      },
                    ]}
                  />

                  <VictoryAxis
                    tickFormat={(date) =>
                      new Date(date).toLocaleDateString(undefined, {
                        year: "2-digit",
                        quarter: "q",
                      })
                    }
                    tickCount={6}
                    style={{
                      tickLabels: {
                        fontSize: 11,
                        padding: 8,
                        fontFamily: "'Inter', system-ui, sans-serif",
                      },
                      grid: { stroke: "none" },
                    }}
                  />

                  <VictoryAxis
                    dependentAxis
                    tickFormat={formatYAxis}
                    style={{
                      tickLabels: {
                        fontSize: 11,
                        padding: 8,
                        fontFamily: "'Inter', system-ui, sans-serif",
                      },
                      grid: {
                        stroke: darkMode
                          ? "rgba(255,255,255,0.05)"
                          : "rgba(0,0,0,0.05)",
                        strokeWidth: 0.5,
                        strokeDasharray: "4,4",
                      },
                    }}
                  />

                  <VictoryStack>
                    {[
                      { key: "costOfRevenue", color: colors.costOfRevenue },
                      {
                        key: "operatingExpenses",
                        color: colors.operatingExpenses,
                      },
                      { key: "otherExpenses", color: colors.otherExpenses },
                      { key: "netIncome", color: colors.netIncome },
                    ].map(({ key, color }) => (
                      <VictoryBar
                        key={key}
                        data={chartData}
                        x="date"
                        y={key}
                        style={{
                          data: {
                            fill: color,
                            opacity: 0.85,
                          },
                        }}
                        animate={{
                          onLoad: { duration: 500 },
                          duration: 200,
                        }}
                      />
                    ))}
                  </VictoryStack>
                </VictoryChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeCompositionChart;
