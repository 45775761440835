import React from "react";
import followingItem from "./FollowingItem";
import noFollowing from "./NoFollowing";

const FollowingList = ({ following, darkMode }) => {
  const isDarkMode = darkMode;
  const textColor = isDarkMode ? "text-green-400" : "text-green-600";
  const secondaryTextColor = isDarkMode ? "text-white" : "text-gray-900";

  return (
    <div className={`p-4 rounded-lg`}>
      <h2 className={`text-2xl font-bold ${textColor} mb-2`}>
        People You Follow
      </h2>
      <p className={`${secondaryTextColor} mb-4`}>
        This is a list of people you're currently following. You can manage your
        connections and interact with them here.
      </p>

      {!following || following.length === 0 ? (
        noFollowing()
      ) : (
        <ul className="space-y-4">{following.map(followingItem)}</ul>
      )}
    </div>
  );
};

export default FollowingList;
