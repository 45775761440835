import React from "react";
import { BarChart3, LineChart, Table2 } from "lucide-react";

const ChartControls = ({
  darkMode,
  chartType,
  setChartType,
  showGridlines,
  setShowGridlines,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Chart Type Toggle */}
      <div className="space-y-3">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Chart Type
        </label>
        <div className="flex gap-4">
          <button
            onClick={() => setChartType("candlestick")}
            className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
              chartType === "candlestick"
                ? "bg-emerald-500 text-white shadow-lg"
                : darkMode
                  ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            <BarChart3 className="w-5 h-5" />
            Candlestick
          </button>
          <button
            onClick={() => setChartType("line")}
            className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
              chartType === "line"
                ? "bg-emerald-500 text-white shadow-lg"
                : darkMode
                  ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            <LineChart className="w-5 h-5" />
            Line
          </button>
        </div>
      </div>

      {/* Gridlines Toggle */}
      <div className="space-y-3">
        <label
          className={`text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Display Options
        </label>
        <button
          onClick={() => setShowGridlines(!showGridlines)}
          className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
            showGridlines
              ? "bg-emerald-500 text-white shadow-lg"
              : darkMode
                ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          }`}
        >
          <Table2 className="w-5 h-5" />
          Show Gridlines
        </button>
      </div>
    </div>
  );
};

export default ChartControls;
