import React from "react";

const variantStyles = {
  default: {
    light: "bg-green-100 text-green-800 hover:bg-green-200",
    dark: "bg-green-800 text-green-100 hover:bg-green-700",
  },
  secondary: {
    light: "bg-green-100 text-green-800 hover:bg-green-200",
    dark: "bg-green-700 text-gray-100 hover:bg-green-600",
  },
  destructive: {
    light: "bg-red-100 text-red-800 hover:bg-red-200",
    dark: "bg-red-800 text-red-100 hover:bg-red-700",
  },
  outline: {
    light:
      "bg-transparent text-green-800 border border-gray-300 hover:bg-green-100",
    dark: "bg-transparent text-gray-100 border border-gray-600 hover:bg-green-800",
  },
};

const Badge = React.forwardRef(
  (
    {
      className = "",
      variant = "default",
      darkMode = false,
      children,
      ...props
    },
    ref,
  ) => {
    const baseStyle =
      "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2";
    const variantStyle = variantStyles[variant] || variantStyles.default;
    const modeStyle = darkMode ? variantStyle.dark : variantStyle.light;

    return (
      <div
        ref={ref}
        className={`${baseStyle} ${modeStyle} ${className}`}
        {...props}
      >
        {children}
      </div>
    );
  },
);

Badge.displayName = "Badge";

export { Badge };
