import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  FaUser,
  FaLock,
  FaSignInAlt,
  FaFacebookF,
  FaMicrosoft,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import useFacebookSDK from "../../hooks/useFacebookSDK"; // Import the custom hook

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { darkMode } = useTheme();
  const { login, loginWithSocial, loading } = useAuthContext();

  // Custom hooks to check if SDKs are loaded
  const isFbSdkLoaded = useFacebookSDK();
  const [isGoogleSdkLoaded, setIsGoogleSdkLoaded] = useState(false);

  // Check if Google SDK is loaded
  useEffect(() => {
    const checkGoogleSDK = setInterval(() => {
      if (window.google && window.google.accounts) {
        setIsGoogleSdkLoaded(true);
        clearInterval(checkGoogleSDK);
      }
    }, 100);
    return () => clearInterval(checkGoogleSDK);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
    } catch (err) {
      setError(err.message || "An error occurred. Please try again.");
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      await loginWithSocial(provider);
    } catch (err) {
      setError(
        err.message || `Failed to login with ${provider}. Please try again.`,
      );
    }
  };

  const inputClasses = `w-full px-4 py-3 rounded-lg border text-gray-900 ${
    darkMode
      ? "border-gray-700 placeholder-gray-500"
      : "border-gray-300 placeholder-gray-400"
  } focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300`;

  const SocialButton = ({ icon, label, color, onClick, disabled }) => (
    <motion.button
      whileHover={!disabled ? { scale: 1.02 } : {}}
      whileTap={!disabled ? { scale: 0.98 } : {}}
      onClick={onClick}
      className={`w-full flex items-center justify-center px-4 py-3 rounded-lg 
        ${color} ${disabled ? "opacity-50 cursor-not-allowed" : ""}
        text-white font-medium transition-all duration-300 text-base
        shadow-md hover:shadow-lg`}
      disabled={disabled}
    >
      {icon}
      <span className="ml-2">{label}</span>
    </motion.button>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md mx-auto p-8 rounded-2xl shadow-2xl"
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className={`text-4xl font-bold mb-8 text-center ${
          darkMode ? "text-green-400" : "text-green-600"
        }`}
      >
        Welcome Back
      </motion.h2>
      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`p-4 mb-6 rounded-lg text-base ${
            darkMode ? "bg-red-900/50 text-red-200" : "bg-red-100 text-red-700"
          }`}
        >
          <p className="text-center font-medium">{error}</p>
        </motion.div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="username"
            className={`block mb-2 font-medium text-base ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Username
          </label>
          <div className="relative">
            <FaUser
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-xl ${
                darkMode ? "text-gray-500" : "text-gray-400"
              }`}
            />
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className={`${inputClasses} pl-10 text-base`}
              placeholder="Enter your username"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className={`block mb-2 font-medium text-base ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Password
          </label>
          <div className="relative">
            <FaLock
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-xl ${
                darkMode ? "text-gray-500" : "text-gray-400"
              }`}
            />
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={`${inputClasses} pl-10 text-base`}
              placeholder="Enter your password"
            />
          </div>
        </div>
        <motion.button
          type="submit"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          className={`w-full ${
            darkMode
              ? "bg-green-600 hover:bg-green-700"
              : "bg-green-500 hover:bg-green-600"
          } text-white font-bold py-4 px-6 rounded-lg transition-all duration-300 flex items-center justify-center shadow-lg text-lg`}
          disabled={loading}
        >
          <FaSignInAlt className="mr-2 text-xl" />
          {loading ? "Signing In..." : "Sign In"}
        </motion.button>
      </form>

      <div className="mt-8">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div
              className={`w-full border-t ${
                darkMode ? "border-gray-700" : "border-gray-300"
              }`}
            ></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span
              className={`px-2 ${
                darkMode
                  ? "bg-gray-900 text-gray-400"
                  : "bg-white text-gray-500"
              }`}
            >
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <SocialButton
            icon={<FaFacebookF className="text-xl" />}
            label="Continue with Facebook"
            color="bg-blue-600 hover:bg-blue-700"
            onClick={() => handleSocialLogin("facebook")}
            disabled={!isFbSdkLoaded || loading}
          />
          <SocialButton
            icon={<FcGoogle className="text-xl" />}
            label="Continue with Google"
            color="bg-white hover:bg-gray-50 !text-gray-700 border border-gray-300"
            onClick={() => handleSocialLogin("google")}
            disabled={!isGoogleSdkLoaded || loading}
          />
        </div>
      </div>

      <div className="mt-8 text-center">
        <p
          className={`text-base ${darkMode ? "text-gray-400" : "text-gray-600"}`}
        >
          Don't have an account?
        </p>
        <Link
          to="/forum/register"
          className={`font-medium ${
            darkMode
              ? "text-green-400 hover:text-green-300"
              : "text-green-600 hover:text-green-700"
          }`}
        >
          Create an account
        </Link>
      </div>
    </motion.div>
  );
};

export default Login;
