import React from "react";
import { FaUserFriends } from "react-icons/fa";

const noFollowers = (textColor, secondaryTextColor) => (
  <div
    className={`flex flex-col items-center justify-center h-64 ${textColor}`}
  >
    <FaUserFriends size={48} className="mb-4" />
    <p className="text-xl font-semibold">No followers yet</p>
    <p className={`mt-2 ${secondaryTextColor}`}>
      When people follow you, they'll appear here.
    </p>
  </div>
);

export default noFollowers;
