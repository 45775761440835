import React from "react";
import { motion } from "framer-motion";
import { useLocation, Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { FaEnvelope, FaCheckCircle } from "react-icons/fa";

const RegistrationSuccess = () => {
  const location = useLocation();
  const { email, message } = location.state || {};
  const { darkMode } = useTheme();

  const baseClasses = "font-sans antialiased";

  return (
    <div
      className={`${baseClasses} min-h-screen flex items-center justify-center p-4`}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className={`w-full max-w-lg p-8 rounded-3xl shadow-2xl ${
          darkMode
            ? "bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg"
            : "bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg"
        }`}
      >
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
          className="text-center mb-8"
        >
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            <FaCheckCircle
              className={`mx-auto text-7xl ${darkMode ? "text-green-400" : "text-green-500"}`}
            />
          </motion.div>
          <h2
            className={`text-4xl font-bold mt-6 ${darkMode ? "text-green-400" : "text-green-600"}`}
          >
            Registration Successful!
          </h2>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="text-center mb-10"
        >
          <div className="relative inline-block">
            <FaEnvelope
              className={`text-5xl mb-6 ${darkMode ? "text-green-400" : "text-green-500"}`}
            />
          </div>
          <p
            className={`text-lg mb-4 ${darkMode ? "text-gray-300" : "text-gray-600"}`}
          >
            {message || (
              <>
                An email has been sent to{" "}
                <span
                  className={`font-bold px-3 py-1 rounded-full ${
                    darkMode ? "text-green-300" : "text-green-700"
                  }`}
                >
                  {email}
                </span>
              </>
            )}
          </p>
          <p
            className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-500"}`}
          >
            Please check your inbox and follow the instructions to verify your
            account.
          </p>
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <Link
            to="/forum/login"
            className={`block w-full text-center py-4 px-6 rounded-full text-lg font-semibold transition-all duration-300 transform hover:scale-105 hover:shadow-lg ${
              darkMode ? "bg-green-500 text-white" : "bg-green-600 text-white"
            }`}
          >
            Return to Login
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default RegistrationSuccess;
