import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { CheckCircle, Star, ChevronDown, ChevronUp } from "lucide-react";
import {
  FaLightbulb,
  FaChartLine,
  FaKey,
  FaUsers,
  FaLock,
  FaGem,
  FaRocket,
  FaCheckCircle,
  FaDollarSign,
} from "react-icons/fa";
import NavButton from "../menu/NavButton";

const features = [
  { Icon: FaLightbulb, text: "Innovative Strategies" },
  { Icon: FaChartLine, text: "Accelerated Financial Growth" },
  { Icon: FaKey, text: "Unlock Financial Freedom" },
  { Icon: FaUsers, text: "Supportive Community" },
  { Icon: FaLock, text: "Financial Security and Stability" },
  { Icon: FaGem, text: "Hidden Market Opportunities" },
  { Icon: FaRocket, text: "Rapid Wealth Accumulation" },
  { Icon: FaCheckCircle, text: "Proven Success for Clients" },
  { Icon: FaDollarSign, text: "High Return Potential" },
];

const benefits = [
  "Access to advanced financial strategies",
  "Guidance for creating a secure financial future",
  "Community-driven support and resources",
  "Detailed insights into financial growth opportunities",
  "Easy-to-use financial planning tools",
  "Potential for significant financial improvement",
];

const testimonials = [
  {
    name: "Alex T.",
    quote: "Quantum Money Code has transformed my financial outlook!",
    rating: 5,
  },
  {
    name: "Samantha K.",
    quote: "The community and guidance have been incredibly helpful.",
    rating: 5,
  },
  {
    name: "David L.",
    quote:
      "The program provided me with the tools to take control of my finances.",
    rating: 4,
  },
];

const faqs = [
  {
    question: "What is Quantum Money Code?",
    answer:
      "Quantum Money Code is a comprehensive program designed to assist individuals in taking charge of their financial future. It provides a blend of innovative strategies, expert guidance, and community support, empowering users to make informed financial decisions that help them achieve long-term financial security and freedom.",
  },
  {
    question: "How has Quantum Money Code achieved proven success?",
    answer:
      "Quantum Money Code has a proven track record of success, helping numerous clients improve their financial standing. By providing tailored financial strategies and tools, the program supports individuals from diverse backgrounds in achieving meaningful financial progress, whether they are just starting their journey or looking to optimize existing wealth.",
  },
  {
    question:
      "What kind of community support can I expect from Quantum Money Code?",
    answer:
      "Quantum Money Code creates a strong, supportive community where members can connect, share experiences, and receive guidance from both peers and experts. This community fosters a sense of collaboration, helping participants stay motivated and committed to achieving their financial goals while learning from others’ successes and challenges.",
  },
  {
    question: "How does Quantum Money Code use innovative strategies?",
    answer:
      "Quantum Money Code integrates cutting-edge financial strategies with time-tested principles, offering users a clear and practical framework to follow. The program encourages users to think outside of conventional financial wisdom by identifying emerging opportunities and implementing forward-thinking approaches to build wealth and security.",
  },
  {
    question: "Can Quantum Money Code accelerate my financial growth?",
    answer:
      "Yes, Quantum Money Code is specifically designed to help accelerate your financial growth. The program identifies and capitalizes on untapped opportunities, teaching users how to optimize their financial decisions for maximum impact. With its focus on actionable strategies, Quantum Money Code equips you to achieve faster and more sustainable financial progress.",
  },
  {
    question: "How does Quantum Money Code ensure financial security?",
    answer:
      "Financial security is a core focus of Quantum Money Code. The program provides comprehensive guidance on safeguarding finances, from building emergency funds to protecting assets and planning for the future. With a clear emphasis on long-term financial resilience, Quantum Money Code helps users feel more secure and confident as they navigate their financial journey.",
  },
  {
    question:
      "Are there hidden opportunities in the market that Quantum Money Code uncovers?",
    answer:
      "Quantum Money Code is designed to uncover financial opportunities that are often missed by traditional approaches. The program offers deep insights into emerging markets and innovative financial technologies, helping users identify and take advantage of hidden opportunities that can enhance their financial portfolio.",
  },
  {
    question: "Can Quantum Money Code help me accumulate wealth rapidly?",
    answer:
      "For individuals seeking rapid wealth accumulation, Quantum Money Code provides the tools and strategies to help achieve that goal. The program is structured to accelerate the wealth-building process by offering advanced techniques that enable users to make informed decisions and capitalize on high-potential financial opportunities.",
  },
  {
    question: "Can Quantum Money Code help me achieve financial freedom?",
    answer:
      "Yes, Quantum Money Code is designed to empower individuals to achieve financial freedom. Through a combination of innovative strategies, actionable insights, and expert guidance, the program equips users with the tools they need to break free from financial constraints and build a sustainable, independent lifestyle that aligns with their values and goals.",
  },
  {
    question: "Is Quantum Money Code user-friendly for beginners?",
    answer:
      "Quantum Money Code is designed to be accessible for both beginners and experienced individuals alike. The program simplifies complex financial concepts, breaking them down into clear, actionable steps that anyone can follow. This ensures that users at all levels can take meaningful steps towards improving their financial situation, with the support of the program and its community.",
  },
];

const CTASection = ({ id }) => {
  const { darkMode } = useTheme();
  const [expandedFaq, setExpandedFaq] = useState(null);

  const toggleFaq = (index) =>
    setExpandedFaq(expandedFaq === index ? null : index);

  return (
    <section
      className={`py-20 ${darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"}`}
    >
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto overflow-hidden rounded-2xl shadow-2xl hover:shadow-3xl transition-shadow duration-300 bg-white dark:bg-gray-800">
          <CTAHeader darkMode={darkMode} />
          <Content
            darkMode={darkMode}
            expandedFaq={expandedFaq}
            toggleFaq={toggleFaq}
            id={id}
          />
        </div>
      </div>
    </section>
  );
};

const CTAHeader = ({ darkMode }) => (
  <div className={`p-10 ${darkMode ? "bg-green-600" : "bg-green-500"}`}>
    <h2 className="text-4xl sm:text-5xl font-extrabold text-white text-center mb-4">
      Ready to Take Control of Your Financial Future?
    </h2>
    <p className="text-xl sm:text-2xl text-green-100 text-center max-w-3xl mx-auto">
      Join Quantum Money Code today and unlock the tools and strategies needed
      to potentially transform your financial future.
    </p>
  </div>
);

const Content = ({ darkMode, expandedFaq, toggleFaq, id }) => (
  <div className={`p-10 ${darkMode ? "bg-gray-900" : "bg-gray-100"}`}>
    <FeaturesGrid darkMode={darkMode} />
    <FAQSection
      darkMode={darkMode}
      expandedFaq={expandedFaq}
      toggleFaq={toggleFaq}
    />
    <BenefitsList darkMode={darkMode} />
    <TestimonialGrid darkMode={darkMode} id={id} />
    <div className="w-full flex justify-center">
      <NavButton
        isExternal={true}
        to="https://www.clickbank.com/"
        icon={FaRocket}
        className="lg:w-1/4 md:w-1/2 sm:w-3/4"
      >
        Get Started Now
      </NavButton>
    </div>
  </div>
);

const FeaturesGrid = ({ darkMode }) => (
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 mb-10">
    {features.map(({ Icon, text }, index) => (
      <div
        key={index}
        className={`text-center p-6 ${darkMode ? "bg-gray-800" : "bg-white"} rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300`}
      >
        <Icon
          className={`w-16 h-16 mx-auto mb-4 ${darkMode ? "text-green-400" : "text-green-500"}`}
        />
        <span className="text-xl font-semibold">{text}</span>
      </div>
    ))}
  </div>
);

const BenefitsList = ({ darkMode }) => (
  <div className="mb-10">
    <h3
      className={`text-2xl font-bold mb-6 ${darkMode ? "text-green-400" : "text-green-600"}`}
    >
      Why Choose Quantum Money Code?
    </h3>
    <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {benefits.map((benefit, index) => (
        <li key={index} className="flex items-center">
          <CheckCircle
            className={`w-5 h-5 mr-3 ${darkMode ? "text-green-400" : "text-green-500"}`}
          />
          <span>{benefit}</span>
        </li>
      ))}
    </ul>
  </div>
);

const TestimonialGrid = ({ darkMode, id }) => (
  <div className="mb-10">
    <h3
      className={`text-2xl font-bold mb-6 ${darkMode ? "text-green-400" : "text-green-600"}`}
      id={id}
    >
      What Our Clients Say
    </h3>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {testimonials.map((testimonial, index) => (
        <div
          key={index}
          className={`p-6 rounded-lg ${darkMode ? "bg-gray-800" : "bg-gray-50"} shadow-md`}
        >
          <p className="mb-4 italic">"{testimonial.quote}"</p>
          <div className="flex items-center justify-between">
            <span className="font-semibold">{testimonial.name}</span>
            <div className="flex">
              {[...Array(testimonial.rating)].map((_, i) => (
                <Star
                  key={i}
                  className="w-5 h-5 text-yellow-400 fill-current"
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const FAQSection = ({ darkMode, expandedFaq, toggleFaq }) => (
  <div className="mb-10">
    <h3
      className={`text-2xl font-bold mb-6 ${darkMode ? "text-green-400" : "text-green-600"}`}
    >
      Frequently Asked Questions
    </h3>
    <div className="space-y-4">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`p-4 rounded-lg ${darkMode ? "bg-gray-800" : "bg-gray-50"} shadow-md`}
        >
          <button
            className="flex justify-between items-center w-full text-left font-semibold"
            onClick={() => toggleFaq(index)}
          >
            {faq.question}
            {expandedFaq === index ? (
              <ChevronUp className="w-5 h-5" />
            ) : (
              <ChevronDown className="w-5 h-5" />
            )}
          </button>
          {expandedFaq === index && <p className="mt-2">{faq.answer}</p>}
        </div>
      ))}
    </div>
  </div>
);

export default CTASection;
