import React from "react";
import {
  Activity,
  TrendingUp,
  TrendingDown,
  LineChart,
  ArrowRight,
} from "lucide-react";

const TechnicalIndicators = ({ data, darkMode }) => {
  const technicals = data.Technicals;

  // Format number to appropriate decimal places and add commas
  const formatNumber = (value) => {
    if (value === null || value === undefined) return "N/A";
    // Check if it's a small decimal number (like Beta)
    if (Math.abs(value) < 10) {
      return Number(value).toFixed(2);
    }
    // For larger numbers, format with commas and 2 decimal places
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const indicators = [
    {
      label: "Beta",
      value: technicals?.Beta,
      icon: Activity,
      description: "Market sensitivity",
      type: "metric",
    },
    {
      label: "52-Week High",
      value: technicals?.["52WeekHigh"],
      icon: TrendingUp,
      description: "Highest price in past year",
      type: "price",
    },
    {
      label: "52-Week Low",
      value: technicals?.["52WeekLow"],
      icon: TrendingDown,
      description: "Lowest price in past year",
      type: "price",
    },
    {
      label: "50-Day MA",
      value: technicals?.["50DayMA"],
      icon: LineChart,
      description: "Short-term trend",
      type: "price",
    },
    {
      label: "200-Day MA",
      value: technicals?.["200DayMA"],
      icon: LineChart,
      description: "Long-term trend",
      type: "price",
    },
  ];

  const getIndicatorStyle = (type) => {
    const baseStyle =
      "flex items-start space-x-4 p-4 rounded-xl transition-all duration-200 hover:scale-[1.02]";
    if (type === "metric") {
      return `${baseStyle} ${
        darkMode
          ? "bg-blue-500/10 border border-blue-500/20"
          : "bg-blue-50 border border-blue-100"
      }`;
    }
    return `${baseStyle} ${
      darkMode
        ? "bg-gray-800/50 border border-gray-700"
        : "bg-gray-50 border border-gray-200"
    }`;
  };

  return (
    <div
      className={`w-full rounded-xl border shadow-sm transition-all duration-300 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Modern Header */}
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-4">
          <div
            className={`p-3 rounded-xl ${
              darkMode
                ? "bg-indigo-500/10 text-indigo-400"
                : "bg-indigo-50 text-indigo-500"
            }`}
          >
            <Activity className="w-6 h-6" />
          </div>
          <div>
            <h3
              className={`text-xl font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Technical Indicators
            </h3>
            <p
              className={`mt-1 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Key market performance metrics
            </p>
          </div>
        </div>
      </div>

      {/* Modern Content Grid */}
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {indicators.map((item, index) => {
          const IconComponent = item.icon;
          return (
            <div key={index} className={getIndicatorStyle(item.type)}>
              <div
                className={`p-2 rounded-lg ${
                  darkMode ? "bg-gray-800" : "bg-white"
                }`}
              >
                <IconComponent
                  className={`w-5 h-5 ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                />
              </div>
              <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between">
                  <p
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {item.label}
                  </p>
                  <ArrowRight
                    className={`w-4 h-4 ${
                      darkMode ? "text-gray-600" : "text-gray-400"
                    }`}
                  />
                </div>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {formatNumber(item.value)}
                </p>
                <p
                  className={`text-xs ${
                    darkMode ? "text-gray-500" : "text-gray-400"
                  }`}
                >
                  {item.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TechnicalIndicators;
