import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { motion } from "framer-motion";
import { FaUserShield, FaDatabase, FaCookie, FaEnvelope } from "react-icons/fa";

function PrivacyPolicy() {
  const { darkMode } = useTheme();

  const sections = [
    {
      icon: FaUserShield,
      title: "Information We Collect",
      content: `We collect information you provide directly to us, including name, email address, and account credentials. We also automatically collect certain information about your device and how you interact with our services.`,
    },
    {
      icon: FaDatabase,
      title: "How We Use Your Information",
      content: `We use the information we collect to provide, maintain, and improve our services, communicate with you, and ensure platform security. Your data helps us personalize your experience and offer relevant content.`,
    },
    {
      icon: FaCookie,
      title: "Cookies and Tracking",
      content: `We use cookies and similar tracking technologies to track activity on our platform and hold certain information. You can instruct your browser to refuse all cookies or indicate when a cookie is being sent.`,
    },
    {
      icon: FaEnvelope,
      title: "Communications",
      content: `With your consent, we may send you emails about our services, updates, and promotions. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided.`,
    },
  ];

  return (
    <section
      className={`min-h-screen py-20 px-4 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-800"
      } transition-colors duration-500`}
    >
      <div className="container mx-auto max-w-6xl">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={`text-5xl font-extrabold text-center mb-10 ${
            darkMode ? "text-green-400" : "text-green-600"
          } transition-colors duration-500`}
        >
          Privacy Policy
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className={`text-xl text-center leading-relaxed max-w-3xl mx-auto mb-20 ${
            darkMode ? "text-gray-300" : "text-gray-700"
          } transition-colors duration-500`}
        >
          Your privacy is important to us. This policy outlines how we collect,
          use, and protect your personal information when you use Quantum Money
          Code.
        </motion.p>

        <div className="space-y-8">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`${
                darkMode ? "bg-green-800" : "bg-green-100"
              } bg-opacity-10 rounded-lg p-6`}
            >
              <div className="flex items-center mb-4">
                <section.icon className="text-green-500 w-8 h-8 mr-3" />
                <h2 className="text-2xl font-semibold">{section.title}</h2>
              </div>
              <p
                className={`${
                  darkMode ? "text-gray-300" : "text-gray-700"
                } text-lg leading-relaxed`}
              >
                {section.content}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className={`text-center mt-12 ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          Last updated: November 2024
        </motion.p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
