import React, { useEffect } from "react";
import {
  Layout,
  BarChart3,
  DollarSign,
  BookOpen,
  Newspaper,
  ShoppingBasket,
  Percent,
} from "lucide-react";
import TabButton from "./TabButton";

const NavigationTabs = ({
  activeTab,
  setActiveTab,
  darkMode,
  data,
  tickerType,
}) => {
  let tabsToRender = [];

  if (
    data &&
    (tickerType === "common_stock" ||
      tickerType === "preferred_stock" ||
      tickerType === "stock")
  ) {
    tabsToRender = [
      {
        id: "overview",
        label: "Overview",
        icon: Layout,
      },
      {
        id: "balance-sheet",
        label: "Balance Sheet",
        icon: BarChart3,
      },
      {
        id: "cash-flow",
        label: "Cash Flow",
        icon: DollarSign,
      },
      {
        id: "income-statement",
        label: "Income Statement",
        icon: BookOpen,
      },
      {
        id: "news",
        label: "News",
        icon: Newspaper,
      },
    ];
  } else if (data && tickerType === "etf") {
    tabsToRender = [
      {
        id: "etf",
        label: "ETF",
        icon: ShoppingBasket,
      },
    ];
  } else if (data && tickerType === "fund") {
    tabsToRender = [
      {
        id: "fund",
        label: "Fund",
        icon: Percent,
      },
    ];
  }

  useEffect(() => {
    if (!tabsToRender.some((tab) => tab.id === activeTab)) {
      setActiveTab(tabsToRender[0]?.id || "overview");
    }
  }, [tickerType, data]);

  return (
    <div className="px-6 py-5">
      <div className="flex gap-3 overflow-x-auto pb-3">
        {tabsToRender.map((tab) => (
          <TabButton
            key={tab.id}
            id={tab.id}
            label={tab.label}
            icon={tab.icon}
            isActive={activeTab === tab.id}
            onClick={setActiveTab}
            darkMode={darkMode}
          />
        ))}
      </div>
    </div>
  );
};

export default NavigationTabs;
