import React from "react";
import {
  ArrowUpRight,
  ArrowDownRight,
  Clock,
  Scale,
  TrendingUp,
  Repeat,
  Globe,
  ArrowLeftRight,
  Info,
} from "lucide-react";

const ForexTradeConditionsComponent = ({ tradeData, darkMode }) => {
  if (!tradeData || tradeData.length === 0) return null;

  const latestQuote = tradeData[tradeData.length - 1];
  const previousQuote = tradeData[tradeData.length - 2];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const formatPips = (price) => {
    const numPrice = Number(price);
    return (numPrice * 10000).toFixed(1);
  };

  const formatPrice = (price, precision = 4) => {
    const numPrice = Number(price);
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(numPrice);
  };

  const calculateSpread = () => {
    const askPrice = Number(latestQuote?.a);
    const bidPrice = Number(latestQuote?.b);
    const spread = askPrice - bidPrice;
    return {
      absolute: formatPrice(spread, 5),
      pips: formatPips(spread),
    };
  };

  const { absolute: spreadAbsolute, pips: spreadPips } = calculateSpread();
  const isDailyChangePositive = Number(latestQuote?.dc) >= 0;
  const midPrice = (Number(latestQuote?.a) + Number(latestQuote?.b)) / 2 || 0;
  const previousMidPrice =
    (Number(previousQuote?.a) + Number(previousQuote?.b)) / 2 || 0;
  const midPriceChange = midPrice - previousMidPrice;
  const midPriceChangePercent =
    previousMidPrice !== 0 ? (midPriceChange / previousMidPrice) * 100 : 0;

  return (
    <div
      className={`w-full max-w-6xl mx-auto rounded-xl shadow-lg transition-all duration-300 ${
        darkMode ? "bg-gray-900" : "bg-white"
      }`}
    >
      {/* Header with glass effect */}
      <div
        className={`px-6 py-6 backdrop-blur-sm border-b transition-colors duration-300 ${
          darkMode
            ? "border-gray-800 bg-gray-900/95"
            : "border-gray-200 bg-white/95"
        }`}
      >
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div className="flex items-center gap-5">
            <div
              className={`p-3 rounded-xl transform transition-all duration-300 hover:scale-105 ${
                darkMode ? "bg-blue-600/20" : "bg-blue-100"
              }`}
            >
              <Globe
                className={`w-8 h-8 transition-colors duration-300 ${
                  darkMode ? "text-blue-400" : "text-blue-600"
                }`}
              />
            </div>
            <div>
              <h2
                className={`text-3xl font-bold tracking-tight transition-colors duration-300 ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                {latestQuote?.s}
              </h2>
              <div className="flex items-center gap-2 mt-1">
                <span
                  className={`text-base transition-colors duration-300 ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Forex Market
                </span>
              </div>
            </div>
          </div>
          <div
            className={`flex items-center gap-2 px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-300 hover:scale-102 ${
              darkMode
                ? "bg-gray-800/80 text-gray-300"
                : "bg-gray-100 text-gray-600"
            }`}
          >
            <Clock className="w-5 h-5" />
            <span>{formatTimestamp(latestQuote?.t)}</span>
          </div>
        </div>
      </div>

      {/* Content with enhanced spacing and animations */}
      <div className="p-6 space-y-8">
        {/* Price Cards with hover effects */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Ask Card */}
          <div
            className={`p-6 rounded-xl transition-all duration-300 transform hover:scale-102 hover:shadow-xl ${
              darkMode ? "bg-red-900/20" : "bg-red-50"
            } border-l-4 border-red-500`}
          >
            <div className="flex justify-between items-start mb-6">
              <div>
                <span
                  className={`text-base font-medium transition-colors duration-300 ${
                    darkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  Ask Price
                </span>
                <div
                  className={`text-4xl font-bold mt-2 transition-colors duration-300 ${
                    darkMode ? "text-red-400" : "text-red-600"
                  }`}
                >
                  {formatPrice(latestQuote?.a)}
                </div>
              </div>
              <ArrowUpRight
                className={`w-8 h-8 transition-colors duration-300 ${
                  darkMode ? "text-red-400" : "text-red-500"
                }`}
              />
            </div>
            <div
              className={`text-lg transition-colors duration-300 ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              {formatPips(latestQuote?.a)} pips
            </div>
          </div>

          {/* Bid Card */}
          <div
            className={`p-6 rounded-xl transition-all duration-300 transform hover:scale-102 hover:shadow-xl ${
              darkMode ? "bg-green-900/20" : "bg-green-50"
            } border-l-4 border-green-500`}
          >
            <div className="flex justify-between items-start mb-6">
              <div>
                <span
                  className={`text-base font-medium transition-colors duration-300 ${
                    darkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  Bid Price
                </span>
                <div
                  className={`text-4xl font-bold mt-2 transition-colors duration-300 ${
                    darkMode ? "text-green-400" : "text-green-600"
                  }`}
                >
                  {formatPrice(latestQuote?.b)}
                </div>
              </div>
              <ArrowDownRight
                className={`w-8 h-8 transition-colors duration-300 ${
                  darkMode ? "text-green-400" : "text-green-500"
                }`}
              />
            </div>
            <div
              className={`text-lg transition-colors duration-300 ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              {formatPips(latestQuote?.b)} pips
            </div>
          </div>
        </div>

        {/* Market Statistics with enhanced visuals */}
        <div
          className={`p-8 rounded-xl transition-all duration-300 ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <h3
            className={`text-2xl font-bold mb-8 transition-colors duration-300 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Market Statistics
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              {
                label: "Spread",
                value: `${spreadPips} pips`,
                subValue: `${spreadAbsolute} absolute`,
                icon: Scale,
                color: "text-blue-500",
                bgColor: darkMode ? "bg-blue-900/30" : "bg-blue-100",
                hoverColor: darkMode
                  ? "hover:bg-blue-900/40"
                  : "hover:bg-blue-200",
              },
              {
                label: "Mid Price",
                value: formatPrice(midPrice),
                subValue: `${
                  midPriceChangePercent >= 0 ? "+" : ""
                }${midPriceChangePercent.toFixed(2)}%`,
                icon: ArrowLeftRight,
                color:
                  midPriceChangePercent >= 0
                    ? "text-green-500"
                    : "text-red-500",
                bgColor: darkMode
                  ? midPriceChangePercent >= 0
                    ? "bg-green-900/30"
                    : "bg-red-900/30"
                  : midPriceChangePercent >= 0
                    ? "bg-green-100"
                    : "bg-red-100",
                hoverColor: darkMode
                  ? midPriceChangePercent >= 0
                    ? "hover:bg-green-900/40"
                    : "hover:bg-red-900/40"
                  : midPriceChangePercent >= 0
                    ? "hover:bg-green-200"
                    : "hover:bg-red-200",
              },
              {
                label: "24h Change",
                value: `${isDailyChangePositive ? "+" : ""}${Number(
                  latestQuote?.dc,
                ).toFixed(2)}%`,
                subValue: `${formatPrice(latestQuote?.dd)}`,
                icon: TrendingUp,
                color: isDailyChangePositive
                  ? "text-green-500"
                  : "text-red-500",
                bgColor: darkMode
                  ? isDailyChangePositive
                    ? "bg-green-900/30"
                    : "bg-red-900/30"
                  : isDailyChangePositive
                    ? "bg-green-100"
                    : "bg-red-100",
                hoverColor: darkMode
                  ? isDailyChangePositive
                    ? "hover:bg-green-900/40"
                    : "hover:bg-red-900/40"
                  : isDailyChangePositive
                    ? "hover:bg-green-200"
                    : "hover:bg-red-200",
              },
              {
                label: "Market Hours",
                value: "24/5",
                subValue: "Mon-Fri",
                icon: Repeat,
                color: "text-purple-500",
                bgColor: darkMode ? "bg-purple-900/30" : "bg-purple-100",
                hoverColor: darkMode
                  ? "hover:bg-purple-900/40"
                  : "hover:bg-purple-200",
              },
            ].map((stat, index) => (
              <div
                key={index}
                className={`flex items-center p-4 rounded-xl transition-all duration-300 transform hover:scale-102 ${stat.bgColor} ${stat.hoverColor}`}
              >
                <div className={`p-3 rounded-xl ${stat.color} bg-opacity-20`}>
                  <stat.icon className={`w-6 h-6 ${stat.color}`} />
                </div>
                <div className="ml-4">
                  <p
                    className={`text-sm font-medium transition-colors duration-300 ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {stat.label}
                  </p>
                  <p className={`text-xl font-bold ${stat.color}`}>
                    {stat.value}
                  </p>
                  {stat.subValue && (
                    <p
                      className={`text-sm transition-colors duration-300 ${
                        darkMode ? "text-gray-500" : "text-gray-600"
                      }`}
                    >
                      {stat.subValue}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Information Footer with enhanced styling */}
        <div
          className={`flex items-start gap-4 p-6 rounded-xl transition-all duration-300 ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <Info
            className={`w-6 h-6 mt-1 transition-colors duration-300 ${
              darkMode ? "text-blue-400" : "text-blue-600"
            }`}
          />
          <p
            className={`text-base leading-relaxed transition-colors duration-300 ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            All trade details are updated in real-time. Spread is calculated
            based on the latest ask and bid prices. Mid price changes reflect
            market movements. Trading involves risk; please ensure you
            understand these indicators before making investment decisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForexTradeConditionsComponent;
