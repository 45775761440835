import React, { useState, useMemo } from "react";
import {
  AlertCircle,
  TrendingUp,
  DollarSign,
  Table as TableIcon,
  ChevronRight,
} from "lucide-react";
import IncomeMetricCard from "./IncomeMetricCard";
import IncomeTimePeriodSelector from "./IncomeTimePeriodSelector";
import IncomeTrendsChart from "./IncomeTrendsChart";
import IncomeCompositionChart from "./IncomeCompositionChart";
import IncomeDetailedBreakdown from "./IncomeDetailedBreakdown";
import IncomeRatios from "./IncomeRatios";

const IncomeStatementVisualization = ({ data, darkMode }) => {
  const [timeframe, setTimeframe] = useState("quarterly");
  const [view, setView] = useState("overview");

  const processedData = useMemo(() => {
    if (!data || !data[timeframe]) return [];

    return Object.entries(data[timeframe])
      .map(([date, values]) => {
        // Basic values
        const totalRevenue = Number(values.totalRevenue || 0);
        const costOfRevenue = Number(values.costOfRevenue || 0);
        const grossProfit = Number(values.grossProfit || 0);
        const operatingIncome = Number(values.operatingIncome || 0);
        const netIncome = Number(values.netIncome || 0);
        const ebit = Number(values.ebit || 0);
        const ebitda = Number(values.ebitda || 0);
        const depreciationAndAmortization = Number(
          values.depreciationAndAmortization || 0,
        );
        const totalOperatingExpenses = Number(
          values.totalOperatingExpenses || 0,
        );
        const incomeTaxExpense = Number(values.incomeTaxExpense || 0);
        const sellingGeneralAdministrative = Number(
          values.sellingGeneralAdministrative || 0,
        );
        const interestExpense = Number(values.interestExpense || 0);
        const incomeBeforeTax = Number(values.incomeBeforeTax || 0);
        const otherOperatingExpenses = Number(
          values.otherOperatingExpenses || 0,
        );

        // Calculated metrics and ratios
        const grossMargin = (grossProfit / totalRevenue) * 100 || 0;
        const operatingMargin = (operatingIncome / totalRevenue) * 100 || 0;
        const netMargin = (netIncome / totalRevenue) * 100 || 0;
        const ebitMargin = (ebit / totalRevenue) * 100 || 0;
        const ebitdaMargin = (ebitda / totalRevenue) * 100 || 0;
        const effectiveTaxRate =
          (incomeTaxExpense / incomeBeforeTax) * 100 || 0;
        const sgaToRevenue =
          (sellingGeneralAdministrative / totalRevenue) * 100 || 0;
        const interestCoverage =
          operatingIncome / Math.abs(interestExpense) || 0;

        return {
          date,
          // Basic Values and calculated values...
          totalRevenue,
          costOfRevenue,
          grossProfit,
          operatingIncome,
          netIncome,
          ebit,
          ebitda,
          depreciationAndAmortization,
          totalOperatingExpenses,
          incomeTaxExpense,
          sellingGeneralAdministrative,
          interestExpense,
          incomeBeforeTax,
          otherOperatingExpenses,

          // Financial Ratios
          grossMargin,
          operatingMargin,
          netMargin,
          ebitMargin,
          ebitdaMargin,
          effectiveTaxRate,
          sgaToRevenue,
          interestCoverage,

          // Additional Metrics
          revenuePerEmployee: totalRevenue / 1000,
          profitPerEmployee: netIncome / 1000,
          operatingExpenseRatio: (totalOperatingExpenses / totalRevenue) * 100,
          costOfRevenueRatio: (costOfRevenue / totalRevenue) * 100,
        };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [data, timeframe]);

  if (!processedData.length) {
    return (
      <div
        className={`min-h-screen flex items-center justify-center
        ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}
      >
        <div
          className={`rounded-2xl p-8 text-center max-w-md mx-auto 
          ${darkMode ? "bg-gray-800" : "bg-white"} shadow-lg`}
        >
          <AlertCircle className="w-12 h-12 text-amber-500 mx-auto mb-4" />
          <h2
            className={`text-xl font-semibold mb-2 
            ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            No Data Available
          </h2>
          <p
            className={`text-sm 
            ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            No income statement data is currently available for analysis
          </p>
        </div>
      </div>
    );
  }

  const latestData = processedData[processedData.length - 1];
  const previousData = processedData[processedData.length - 2];

  const mainMetrics = [
    {
      title: "Revenue & Profitability",
      metrics: [
        { label: "Total Revenue", value: latestData.totalRevenue },
        { label: "Gross Profit", value: latestData.grossProfit },
        { label: "Operating Income", value: latestData.operatingIncome },
        { label: "Net Income", value: latestData.netIncome },
        { label: "Gross Margin", value: latestData.grossMargin, isRatio: true },
      ],
    },
    {
      title: "Operating Performance",
      metrics: [
        { label: "EBIT", value: latestData.ebit },
        { label: "EBITDA", value: latestData.ebitda },
        {
          label: "Operating Expenses",
          value: latestData.totalOperatingExpenses,
        },
        { label: "SG&A", value: latestData.sellingGeneralAdministrative },
        {
          label: "Operating Margin",
          value: latestData.operatingMargin,
          isRatio: true,
        },
      ],
    },
    {
      title: "Other Metrics",
      metrics: [
        { label: "Income Before Tax", value: latestData.incomeBeforeTax },
        { label: "Income Tax", value: latestData.incomeTaxExpense },
        { label: "Interest Expense", value: latestData.interestExpense },
        {
          label: "Depreciation & Amortization",
          value: latestData.depreciationAndAmortization,
        },
        { label: "Net Margin", value: latestData.netMargin, isRatio: true },
      ],
    },
  ];

  return (
    <div
      className={`min-h-screen transition-colors duration-200 
      ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}
    >
      <div className="max-w-7xl mx-auto p-2 sm:p-2 space-y-6">
        {/* Header Section */}
        <div
          className={`rounded-2xl ${
            darkMode
              ? "bg-gradient-to-r from-gray-900 to-gray-800 border border-gray-800"
              : "bg-gradient-to-r from-gray-50 to-white border border-gray-100"
          } shadow-xl p-6`}
        >
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
            <div className="space-y-2">
              <h1
                className={`text-3xl font-bold tracking-tight ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Income Statement Analysis
              </h1>
              <div className="flex items-center gap-2">
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Last updated: {new Date(latestData.date).toLocaleDateString()}
                </p>
                <ChevronRight className="w-4 h-4" />
                <span
                  className={`px-2 py-1 rounded-full text-xs font-medium ${
                    darkMode
                      ? "bg-gray-800 text-gray-300"
                      : "bg-gray-100 text-gray-600"
                  }`}
                >
                  {timeframe.charAt(0).toUpperCase() + timeframe.slice(1)} View
                </span>
              </div>
            </div>
            <IncomeTimePeriodSelector
              timeframe={timeframe}
              setTimeframe={setTimeframe}
              darkMode={darkMode}
            />
          </div>
        </div>

        {/* Navigation Tabs */}
        <div
          className={`rounded-2xl ${
            darkMode
              ? "bg-gradient-to-r from-gray-900 to-gray-800 border border-gray-800"
              : "bg-gradient-to-r from-gray-50 to-white border border-gray-100"
          } shadow-xl p-2`}
        >
          <div className="flex gap-2 overflow-x-auto">
            {[
              { id: "overview", label: "Overview", icon: DollarSign },
              { id: "trends", label: "Trends", icon: TrendingUp },
              { id: "details", label: "Details", icon: TableIcon },
            ].map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setView(id)}
                className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm font-medium transition-all ${
                  view === id
                    ? `${darkMode ? "bg-green-600" : "bg-green-500"} text-white shadow-lg`
                    : darkMode
                      ? "hover:bg-gray-800 text-gray-300"
                      : "hover:bg-gray-100 text-gray-600"
                }`}
              >
                <Icon className="w-4 h-4" />
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div
          className={`rounded-2xl p-6 ${
            darkMode ? "bg-gray-800/50" : "bg-white"
          } shadow-lg backdrop-blur-sm space-y-6`}
        >
          {view === "overview" && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {mainMetrics.map((section, index) => (
                  <IncomeMetricCard
                    key={index}
                    title={section.title}
                    metrics={section.metrics}
                    darkMode={darkMode}
                  />
                ))}
              </div>
              <IncomeRatios
                data={latestData}
                previousData={previousData}
                darkMode={darkMode}
              />
            </>
          )}

          {view === "trends" && (
            <>
              <IncomeTrendsChart data={processedData} darkMode={darkMode} />
              <IncomeCompositionChart
                data={processedData}
                darkMode={darkMode}
              />
            </>
          )}

          {view === "details" && (
            <IncomeDetailedBreakdown
              currentData={latestData}
              previousData={previousData}
              darkMode={darkMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IncomeStatementVisualization;
