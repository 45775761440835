import React, { useMemo } from "react";
import {
  VictoryChart,
  VictoryCandlestick,
  VictoryLine,
  VictoryAxis,
  VictoryZoomContainer,
  VictoryTheme,
} from "victory";
import { timeFormat } from "d3-time-format";

const ChartSection = ({
  darkMode,
  loading,
  error,
  marketData,
  chartType,
  zoomDomain,
  setZoomDomain,
  candleUpColor,
  candleDownColor,
  showGridlines,
  transformData,
  showEMA,
  showSMA,
  showBollingerBands,
  showMACD,
  showRSI,
}) => {
  // Move all Hooks to the top level, before any return statements

  // Transform data for Victory Charts
  const priceData = useMemo(
    () => transformData(marketData?.price_data),
    [marketData, transformData],
  );

  // Prepare indicator data
  const indicatorSeries = useMemo(() => {
    const series = {};
    const signals = marketData?.signals || {};

    Object.keys(signals).forEach((indicatorKey) => {
      const indicator = signals[indicatorKey];
      if (indicator.series) {
        series[indicatorKey.toLowerCase()] = indicator.series.map((item) => {
          const transformedItem = { date: new Date(item.date) };
          Object.keys(item).forEach((key) => {
            if (key !== "date") {
              transformedItem[key] = item[key];
            }
          });
          return transformedItem;
        });
      }
    });
    return series;
  }, [marketData]);

  // Define theme colors
  const theme = {
    backgroundColor: darkMode ? "#1F2937" : "#FFFFFF",
    textColor: darkMode ? "#E5E7EB" : "#1F2937",
    gridLineColor: showGridlines
      ? darkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)"
      : "transparent",
    tooltipBackground: darkMode ? "#374151" : "#FFFFFF",
    tooltipTextColor: darkMode ? "#FFFFFF" : "#000000",
    axisStroke: darkMode ? "#E5E7EB" : "#1F2937",
    crossHairColor: darkMode ? "#E5E7EB" : "#1F2937",
    indicators: {
      ema: "#FF5733", // Red
      sma: "#33A1FF", // Blue
      bollingerBands: {
        top: "#FFC300", // Gold
        middle: "#DAF7A6", // Light Green
        bottom: "#FFC300",
        fill: darkMode ? "rgba(255, 195, 0, 0.1)" : "rgba(255, 195, 0, 0.3)",
      },
      macd: {
        macd: "#FF5733", // Red
        signal: "#C70039", // Crimson
        divergence: darkMode ? "#DAF7A6" : "#900C3F",
      },
      rsi: "#581845", // Purple
    },
  };

  // Define custom date formatter for x-axis
  const xAxisDateFormat = timeFormat("%b %d, '%y"); // e.g., "Jan 01, '21"

  // Handle loading and error states AFTER Hooks
  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-full flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  // Handle empty data case
  if (!priceData || priceData.length === 0) {
    return (
      <div
        style={{
          backgroundColor: theme.backgroundColor,
          padding: "1rem",
          borderRadius: "1rem",
          width: "100%",
          minHeight: "600px",
        }}
      >
        <div
          className="w-full h-full flex items-center justify-center"
          style={{ color: theme.textColor }}
        >
          No data available for the selected parameters.
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: theme.backgroundColor,
        padding: "1rem",
        borderRadius: "1rem",
        width: "100%",
      }}
    >
      {/* Main Price Chart */}
      <VictoryChart
        domainPadding={{ x: 25 }}
        scale={{ x: "time" }}
        height={400}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryZoomContainer
            zoomDimension="x"
            zoomDomain={zoomDomain}
            onZoomDomainChange={setZoomDomain}
          />
        }
      >
        <VictoryAxis
          tickFormat={xAxisDateFormat}
          style={{
            axis: { stroke: theme.axisStroke },
            tickLabels: { fill: theme.textColor, fontSize: 10 },
            grid: { stroke: theme.gridLineColor },
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: theme.axisStroke },
            tickLabels: { fill: theme.textColor, fontSize: 10 },
            grid: { stroke: theme.gridLineColor },
          }}
        />
        {chartType === "candlestick" ? (
          <VictoryCandlestick
            data={priceData}
            x="date"
            open="open"
            high="high"
            low="low"
            close="close"
            candleColors={{
              positive: candleUpColor,
              negative: candleDownColor,
            }}
          />
        ) : (
          <VictoryLine
            data={priceData}
            x="date"
            y="close"
            style={{ data: { stroke: candleUpColor } }}
          />
        )}
        {/* Overlay EMA */}
        {showEMA && indicatorSeries["ema"] && (
          <VictoryLine
            data={indicatorSeries["ema"]}
            x="date"
            y="value"
            style={{ data: { stroke: theme.indicators.ema } }}
          />
        )}
        {/* Overlay SMA */}
        {showSMA && indicatorSeries["sma"] && (
          <VictoryLine
            data={indicatorSeries["sma"]}
            x="date"
            y="value"
            style={{ data: { stroke: theme.indicators.sma } }}
          />
        )}
        {/* Overlay Bollinger Bands */}
        {showBollingerBands && indicatorSeries["bbands"] && (
          <>
            <VictoryLine
              data={indicatorSeries["bbands"]}
              x="date"
              y="upper_band"
              style={{ data: { stroke: theme.indicators.bollingerBands.top } }}
            />
            <VictoryLine
              data={indicatorSeries["bbands"]}
              x="date"
              y="middle_band"
              style={{
                data: { stroke: theme.indicators.bollingerBands.middle },
              }}
            />
            <VictoryLine
              data={indicatorSeries["bbands"]}
              x="date"
              y="lower_band"
              style={{
                data: { stroke: theme.indicators.bollingerBands.bottom },
              }}
            />
          </>
        )}
      </VictoryChart>

      {/* MACD Chart */}
      {showMACD && indicatorSeries["macd"] && (
        <VictoryChart
          scale={{ x: "time" }}
          height={200}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomContainer
              zoomDimension="x"
              zoomDomain={zoomDomain}
              onZoomDomainChange={setZoomDomain}
            />
          }
        >
          <VictoryAxis
            tickFormat={xAxisDateFormat}
            style={{
              axis: { stroke: theme.axisStroke },
              tickLabels: { fill: theme.textColor, fontSize: 10 },
              grid: { stroke: theme.gridLineColor },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: theme.axisStroke },
              tickLabels: { fill: theme.textColor, fontSize: 10 },
              grid: { stroke: theme.gridLineColor },
            }}
          />
          <VictoryLine
            data={indicatorSeries["macd"].map((d) => ({
              date: d.date,
              value: d.macd_value,
            }))}
            x="date"
            y="value"
            style={{ data: { stroke: theme.indicators.macd.macd } }}
          />
          <VictoryLine
            data={indicatorSeries["macd"].map((d) => ({
              date: d.date,
              value: d.signal_line,
            }))}
            x="date"
            y="value"
            style={{ data: { stroke: theme.indicators.macd.signal } }}
          />
          {/* Optionally, add histogram for divergence */}
        </VictoryChart>
      )}

      {/* RSI Chart */}
      {showRSI && indicatorSeries["rsi"] && (
        <VictoryChart
          domain={{ y: [0, 100] }}
          scale={{ x: "time" }}
          height={200}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomContainer
              zoomDimension="x"
              zoomDomain={zoomDomain}
              onZoomDomainChange={setZoomDomain}
            />
          }
        >
          <VictoryAxis
            tickFormat={xAxisDateFormat}
            style={{
              axis: { stroke: theme.axisStroke },
              tickLabels: { fill: theme.textColor, fontSize: 10 },
              grid: { stroke: theme.gridLineColor },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[30, 50, 70]}
            style={{
              axis: { stroke: theme.axisStroke },
              tickLabels: { fill: theme.textColor, fontSize: 10 },
              grid: { stroke: theme.gridLineColor },
            }}
          />
          <VictoryLine
            data={indicatorSeries["rsi"]}
            x="date"
            y="value"
            style={{ data: { stroke: theme.indicators.rsi } }}
          />
          {/* Overbought and Oversold Lines */}
          <VictoryLine
            y={() => 70}
            style={{ data: { stroke: "red", strokeDasharray: "5,5" } }}
          />
          <VictoryLine
            y={() => 30}
            style={{ data: { stroke: "green", strokeDasharray: "5,5" } }}
          />
        </VictoryChart>
      )}
    </div>
  );
};

export default ChartSection;
