import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { motion } from "framer-motion";

const NotFound = () => {
  const { darkMode } = useTheme();

  return (
    <div
      className={`flex flex-col items-center justify-center min-h-screen ${
        darkMode ? "bg-gray-900 text-gray-200" : "bg-gray-50 text-gray-900"
      } transition-colors duration-500 p-4 text-center`}
    >
      <motion.svg
        className="w-48 h-48 sm:w-64 sm:h-64 mx-auto mb-6 sm:mb-8"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <circle
          cx="100"
          cy="100"
          r="80"
          fill={darkMode ? "#1F2937" : "#F3F4F6"}
          stroke={darkMode ? "#34D399" : "#059669"}
          strokeWidth="8"
        />
        <text
          x="100"
          y="120"
          fontFamily="Arial, sans-serif"
          fontSize="60"
          fontWeight="bold"
          fill={darkMode ? "#34D399" : "#059669"}
          textAnchor="middle"
        >
          404
        </text>
      </motion.svg>

      <motion.h2
        className={`text-3xl sm:text-4xl md:text-5xl font-extrabold mb-3 sm:mb-4 ${
          darkMode ? "text-green-400" : "text-green-600"
        }`}
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Page Not Found
      </motion.h2>

      <motion.p
        className="text-lg sm:text-xl md:text-2xl font-light mb-6 sm:mb-8 px-4"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        Oops! The page you're looking for is not available.
      </motion.p>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        <Link
          to="/"
          className={`inline-block px-6 py-3 rounded-full text-base sm:text-lg font-medium ${
            darkMode
              ? "bg-green-400 text-gray-900 hover:bg-green-500"
              : "bg-green-600 text-white hover:bg-green-700"
          } transition-colors duration-300 transform hover:scale-105`}
        >
          Return to Homepage
        </Link>
      </motion.div>
    </div>
  );
};

export default NotFound;
