import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Check, Star, Zap, PieChart, Briefcase, Crown } from "lucide-react";
import { planDetails } from "./Plans";
import SubscriptionPurchase from "./SubscriptionPurchase";

const PlanDetails = ({ darkMode }) => {
  const [activeTab, setActiveTab] = useState("description");
  const [plan, setPlan] = useState(null);
  const { planType } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedPlan = planDetails[planType];
    if (selectedPlan) {
      setPlan(selectedPlan);
    } else {
      navigate("/404");
    }
  }, [planType, navigate]);

  if (!plan) return <div>Loading...</div>;

  const getIconComponent = (iconName) => {
    switch (iconName) {
      case "Zap":
        return Zap;
      case "PieChart":
        return PieChart;
      case "Briefcase":
        return Briefcase;
      case "Crown":
        return Crown;
      default:
        return null;
    }
  };

  const IconComponent = getIconComponent(plan.icon);

  const bgColor = darkMode
    ? "bg-gray-900 text-gray-100"
    : "bg-gray-50 text-gray-900";
  const cardBg = darkMode ? "bg-gray-800" : "bg-white";

  const tabContent = {
    description: (
      <p className="text-base leading-relaxed">{plan.description}</p>
    ),
    features: (
      <ul className="space-y-3">
        {plan.features.map((feature, i) => (
          <li key={i} className="flex items-start">
            <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
            <span className="text-base">{feature}</span>
          </li>
        ))}
      </ul>
    ),
    reviews: (
      <div className="space-y-6">
        {plan.reviews.map((review, index) => (
          <div
            key={index}
            className={`p-4 sm:p-5 rounded-xl ${
              darkMode
                ? "bg-gray-700 text-gray-100"
                : "bg-gray-100 text-gray-900"
            }`}
          >
            <p className="text-base italic mb-3">"{review.text}"</p>
            <div className="flex items-center">
              {[...Array(review.rating)].map((_, i) => (
                <Star
                  key={i}
                  className="w-5 h-5 text-yellow-400 fill-current"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    ),
    details: (
      <div className="text-base space-y-3">
        {["ads", "commitment", "support", "upgrades"].map((detail) => (
          <p key={detail}>
            <strong>{detail.charAt(0).toUpperCase() + detail.slice(1)}:</strong>{" "}
            {plan[detail]}
          </p>
        ))}
      </div>
    ),
  };

  return (
    <section className={`py-10 sm:py-16 px-4`}>
      <div className="max-w-7xl mx-auto">
        <div className="grid gap-8 lg:gap-12 lg:grid-cols-2 items-start">
          {/* Left Side Content */}
          <div className={`rounded-2xl shadow-xl overflow-hidden ${cardBg}`}>
            <div className="p-6 sm:p-8">
              {plan.popular && (
                <div className="flex justify-end">
                  <span className="inline-block px-3 py-1 text-xs font-semibold rounded-full mb-2 bg-green-100 text-green-800">
                    Most Popular
                  </span>
                </div>
              )}
              <div className="flex items-center mb-6">
                {IconComponent && (
                  <IconComponent className="h-10 w-10 text-green-500 mr-3" />
                )}
                <h2 className="text-3xl font-extrabold">{plan.name} Plan</h2>
              </div>
              <p className="text-xl font-bold mb-4">
                {plan.price}
                {plan.price !== "Free" && (
                  <span className="text-base font-medium ml-1">/month</span>
                )}
              </p>

              {/* Tab Navigation */}
              <div className="flex flex-wrap gap-2 mb-8">
                {Object.keys(tabContent).map((tab) => (
                  <button
                    key={tab}
                    className={`px-4 py-2 text-sm font-medium rounded-full transition-colors
                      ${
                        activeTab === tab
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}
              </div>

              {/* Tab Content */}
              <div className="mb-8">{tabContent[activeTab]}</div>
            </div>
          </div>

          {/* Right Side Content */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`rounded-2xl shadow-2xl overflow-hidden ${cardBg}`}
          >
            {/* Top Section */}
            <div
              className={`p-6 sm:p-8 ${
                darkMode ? "bg-gray-800" : "bg-gray-50"
              }`}
            >
              <div className="flex items-center justify-center mb-8">
                <div className="relative">
                  <div
                    className={`absolute inset-0 rounded-full animate-ping opacity-20 ${
                      darkMode ? "bg-green-700" : "bg-green-500"
                    }`}
                  ></div>
                  <div
                    className={`relative rounded-full p-5 ${
                      darkMode ? "bg-green-700" : "bg-green-500"
                    }`}
                  >
                    {IconComponent && (
                      <IconComponent className="h-12 w-12 text-white" />
                    )}
                  </div>
                </div>
              </div>
              <h3
                className={`text-2xl font-bold mb-4 text-center ${
                  darkMode ? "text-green-400" : "text-green-600"
                }`}
              >
                Why choose {plan.name}?
              </h3>
              <p
                className={`text-base text-center leading-relaxed ${
                  darkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                {plan.description.split(".")[0] + "."}
              </p>
              <p
                className={`text-base mt-6 text-center leading-relaxed ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {plan.additionalInfo}
              </p>
            </div>

            {/* Purchase Section */}
            <div
              className={`p-6 sm:p-8 rounded-b-2xl ${
                darkMode
                  ? "bg-gradient-to-br from-green-800 to-green-600"
                  : "bg-gradient-to-bl from-green-500 to-green-400"
              }`}
            >
              <h4 className="text-2xl font-bold mb-4 text-white text-center">
                Ready to Upgrade?
              </h4>
              <p className="text-base text-white text-center mb-6">
                Unlock all features with the {plan.name} Plan.
              </p>
              {/* SubscriptionPurchase component */}
              <div className="flex justify-center">
                <SubscriptionPurchase plan={plan} darkMode={darkMode} />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default PlanDetails;
