import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const inspiringMessages = [
  `Boost Your Wealth Today 💰`,
  `Grow Financial Freedom 📈`,
  `Build Lasting Riches 🚀`,
  `Achieve Wealth Goals 🏆`,
  `Master Financial Growth 💵`,
  `Thrive with Wealth 🌟`,
  `Accelerate Your Finances ⏩`,
  `Secure Your Wealth 🛡️`,
  `Unlock Financial Success 🔓`,
  `Strategize for Wealth 📊`,
  `Set Your Money Goals 🎯`,
  `Empower Your Finances ⚡`,
  `Take Charge of Wealth 💪`,
  `Unlock Your Potential 💼`,
  `Elevate Your Finances 🚀`,
  `Build Your Wealth Path 🏗️`,
  `Freedom Through Wealth 🕊️`,
  `Supercharge Your Wealth ⚡`,
  `Create Wealth Success 🏆`,
  `Secure Your Financial Future 🔐`,
  `Grow Your Wealth Fast 🌱`,
  `Expand Your Finances 📈`,
  `Thrive with Financial Power 💼`,
  `Achieve Your Wealth Goals 🎯`,
  `Reach Financial Success 🏆`,
  `Build Wealth Now 🛠️`,
  `Unlock Your Rich Future 🔓`,
  `Thrive with Money Growth 💵`,
  `Grow Your Financial Future 🌱`,
  `Drive Financial Growth 🌟`,
];

const ChangingTitle = () => {
  const [currentMessage, setCurrentMessage] = useState(inspiringMessages[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        const currentIndex = inspiringMessages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % inspiringMessages.length;
        return inspiringMessages[nextIndex];
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{currentMessage}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default ChangingTitle;
