import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import * as mediasoupClient from "mediasoup-client";
import { getApiUrl } from "../config/api";

export const useWebRTC = (
  conversationId,
  token,
  userData,
  onEndCall,
  onError,
) => {
  const [localStream, setLocalStream] = useState(null);
  const localStreamRef = useRef(null);
  const [remoteStreams, setRemoteStreams] = useState({});
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);

  const socketRef = useRef(null);
  const deviceRef = useRef(null);
  const producerTransportRef = useRef(null);
  const audioProducerRef = useRef(null);
  const videoProducerRef = useRef(null);
  const consumingTransportsRef = useRef({});
  const consumersRef = useRef({});
  const userDataRef = useRef({});

  useEffect(() => {
    socketRef.current = io(`${getApiUrl()}/signals`, {
      auth: { token },
    });

    socketRef.current.on("connection-success", ({ socketId }) => {
      console.log("Connected with socket ID:", socketId);
      getLocalStream();
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const getLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 400, ideal: 720, max: 1080 },
        },
      });
      localStreamRef.current = stream;
      setLocalStream(stream);
      joinRoom();
    } catch (error) {
      console.error("Error accessing media devices:", error);
      if (onError) {
        onError(
          "Could not access camera or microphone. Please check permissions.",
        );
      }
    }
  };

  const joinRoom = () => {
    socketRef.current.emit("joinRoom", { roomName: conversationId }, (data) => {
      if (data.error) {
        console.error("Error joining room:", data.error);
        if (onError) {
          onError(data.error);
        }
        return;
      }
      console.log("Router RTP Capabilities:", data.rtpCapabilities);
      createDevice(data.rtpCapabilities);
    });
  };

  const createDevice = async (rtpCapabilities) => {
    try {
      deviceRef.current = new mediasoupClient.Device();
      await deviceRef.current.load({ routerRtpCapabilities: rtpCapabilities });
      console.log("Device loaded:", deviceRef.current);
      createSendTransport();
    } catch (error) {
      console.error("Failed to create device:", error);
      if (onError) {
        onError("Failed to create device: " + error.message);
      }
      if (error.name === "UnsupportedError") {
        console.warn("Browser not supported");
        alert("Your browser is not supported for video calls.");
      }
    }
  };

  const createSendTransport = () => {
    socketRef.current.emit(
      "createWebRtcTransport",
      { consumer: false },
      ({ params }) => {
        if (params.error) {
          console.error("Error creating WebRTC Transport:", params.error);
          if (onError) {
            onError("Error creating WebRTC Transport: " + params.error);
          }
          return;
        }

        producerTransportRef.current =
          deviceRef.current.createSendTransport(params);

        producerTransportRef.current.on(
          "connect",
          async ({ dtlsParameters }, callback, errback) => {
            try {
              await socketRef.current.emit("transport-connect", {
                dtlsParameters,
              });
              callback();
            } catch (error) {
              console.error("Error connecting transport:", error);
              errback(error);
              if (onError) {
                onError("Error connecting transport: " + error.message);
              }
            }
          },
        );

        producerTransportRef.current.on(
          "produce",
          async (parameters, callback, errback) => {
            try {
              socketRef.current.emit(
                "transport-produce",
                {
                  kind: parameters.kind,
                  rtpParameters: parameters.rtpParameters,
                  appData: {
                    userData,
                  },
                },
                ({ id, producersExist }) => {
                  callback({ id });
                  if (producersExist) getProducers();
                },
              );
            } catch (error) {
              console.error("Error producing transport:", error);
              errback(error);
              if (onError) {
                onError("Error producing transport: " + error.message);
              }
            }
          },
        );

        connectSendTransport();
      },
    );
  };

  const connectSendTransport = async () => {
    const stream = localStreamRef.current;
    if (!stream) {
      console.error("Local stream is not available.");
      if (onError) {
        onError("Local stream is not available.");
      }
      return;
    }

    try {
      audioProducerRef.current = await producerTransportRef.current.produce({
        track: stream.getAudioTracks()[0],
        appData: { userData },
      });

      videoProducerRef.current = await producerTransportRef.current.produce({
        track: stream.getVideoTracks()[0],
        appData: { userData },
      });

      audioProducerRef.current.on("trackended", () => {
        console.log("Audio track ended.");
      });

      videoProducerRef.current.on("trackended", () => {
        console.log("Video track ended.");
      });
    } catch (error) {
      console.error("Error producing tracks:", error);
      if (onError) {
        onError("Error producing tracks: " + error.message);
      }
    }
  };

  const getProducers = () => {
    socketRef.current.emit("getProducers", (producerList) => {
      console.log("Producer List:", producerList);
      producerList.forEach(({ producerId, userData }) => {
        userDataRef.current[producerId] = userData;
        signalNewConsumerTransport(producerId);
      });
    });
  };

  const signalNewConsumerTransport = async (remoteProducerId) => {
    if (consumingTransportsRef.current[remoteProducerId]) return;

    socketRef.current.emit(
      "createWebRtcTransport",
      { consumer: true },
      ({ params }) => {
        if (params.error) {
          console.error("Error creating consumer transport:", params.error);
          if (onError) {
            onError("Error creating consumer transport: " + params.error);
          }
          return;
        }

        let consumerTransport;
        try {
          consumerTransport = deviceRef.current.createRecvTransport(params);
        } catch (error) {
          console.error("Error creating consumer transport:", error);
          if (onError) {
            onError("Error creating consumer transport: " + error.message);
          }
          return;
        }

        consumerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback, errback) => {
            try {
              await socketRef.current.emit("transport-recv-connect", {
                dtlsParameters,
                serverConsumerTransportId: params.id,
              });
              callback();
            } catch (error) {
              console.error("Error connecting consumer transport:", error);
              errback(error);
              if (onError) {
                onError(
                  "Error connecting consumer transport: " + error.message,
                );
              }
            }
          },
        );

        consumingTransportsRef.current[remoteProducerId] = consumerTransport;

        connectRecvTransport(consumerTransport, remoteProducerId, params.id);
      },
    );
  };

  const connectRecvTransport = async (
    consumerTransport,
    remoteProducerId,
    serverConsumerTransportId,
  ) => {
    socketRef.current.emit(
      "consume",
      {
        rtpCapabilities: deviceRef.current.rtpCapabilities,
        remoteProducerId,
        serverConsumerTransportId,
      },
      async ({ params }) => {
        if (params.error) {
          console.error("Cannot consume:", params.error);
          if (onError) {
            onError("Cannot consume: " + params.error);
          }
          return;
        }

        try {
          const consumer = await consumerTransport.consume({
            id: params.id,
            producerId: params.producerId,
            kind: params.kind,
            rtpParameters: params.rtpParameters,
          });

          const stream = new MediaStream([consumer.track]);

          consumersRef.current[remoteProducerId] = consumer;

          setRemoteStreams((prev) => ({
            ...prev,
            [remoteProducerId]: {
              stream,
              kind: params.kind,
              userData: userDataRef.current[remoteProducerId],
              isVideoEnabled: true,
            },
          }));

          consumer.on("trackended", () => {
            console.log("Consumer track ended.");
          });

          consumer.on("transportclose", () => {
            console.log("Consumer transport closed.");
          });

          socketRef.current.emit("consumer-resume", {
            serverConsumerId: params.serverConsumerId,
          });
        } catch (error) {
          console.error("Error consuming remote track:", error);
          if (onError) {
            onError("Error consuming remote track: " + error.message);
          }
        }
      },
    );
  };

  useEffect(() => {
    socketRef.current.on("new-producer", ({ producerId, userData }) => {
      console.log("New producer:", producerId);
      userDataRef.current[producerId] = userData;
      signalNewConsumerTransport(producerId);
    });

    socketRef.current.on("producer-closed", ({ remoteProducerId }) => {
      console.log("Producer closed:", remoteProducerId);
      setRemoteStreams((prev) => {
        const updatedStreams = { ...prev };
        delete updatedStreams[remoteProducerId];
        return updatedStreams;
      });
    });

    socketRef.current.on("producer-paused", ({ producerId }) => {
      console.log("Producer paused:", producerId);
      if (consumersRef.current[producerId]) {
        consumersRef.current[producerId].pause();
      }
      setRemoteStreams((prev) => {
        if (prev[producerId]) {
          return {
            ...prev,
            [producerId]: {
              ...prev[producerId],
              isVideoEnabled: false,
            },
          };
        }
        return prev;
      });
    });

    socketRef.current.on("producer-resumed", ({ producerId }) => {
      console.log("Producer resumed:", producerId);
      if (consumersRef.current[producerId]) {
        consumersRef.current[producerId].resume();
      }
      setRemoteStreams((prev) => {
        if (prev[producerId]) {
          return {
            ...prev,
            [producerId]: {
              ...prev[producerId],
              isVideoEnabled: true,
            },
          };
        }
        return prev;
      });
    });

    return () => {
      socketRef.current.off("new-producer");
      socketRef.current.off("producer-closed");
      socketRef.current.off("producer-paused");
      socketRef.current.off("producer-resumed");
    };
  }, []);

  const toggleAudio = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setIsAudioEnabled((prev) => !prev);

      if (audioProducerRef.current) {
        if (isAudioEnabled) {
          audioProducerRef.current.pause();
          socketRef.current.emit("producer-pause", {
            producerId: audioProducerRef.current.id,
          });
        } else {
          audioProducerRef.current.resume();
          socketRef.current.emit("producer-resume", {
            producerId: audioProducerRef.current.id,
          });
        }
      }
    }
  };

  const toggleVideo = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setIsVideoEnabled((prev) => !prev);

      if (videoProducerRef.current) {
        if (isVideoEnabled) {
          videoProducerRef.current.pause();
          socketRef.current.emit("producer-pause", {
            producerId: videoProducerRef.current.id,
          });
        } else {
          videoProducerRef.current.resume();
          socketRef.current.emit("producer-resume", {
            producerId: videoProducerRef.current.id,
          });
        }
      }
    }
  };

  const endCall = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
      localStreamRef.current = null;
      setLocalStream(null);
    }

    if (audioProducerRef.current) {
      audioProducerRef.current.close();
      audioProducerRef.current = null;
    }
    if (videoProducerRef.current) {
      videoProducerRef.current.close();
      videoProducerRef.current = null;
    }

    if (producerTransportRef.current) {
      producerTransportRef.current.close();
      producerTransportRef.current = null;
    }

    Object.values(consumingTransportsRef.current).forEach((transport) => {
      transport.close();
    });
    consumingTransportsRef.current = {};

    socketRef.current.emit("leaveRoom");

    socketRef.current.disconnect();

    if (onEndCall) {
      onEndCall();
    }
  };

  return {
    localStream,
    remoteStreams,
    isAudioEnabled,
    isVideoEnabled,
    toggleAudio,
    toggleVideo,
    endCall,
  };
};
