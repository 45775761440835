import React from "react";
import { FaUsers } from "react-icons/fa";

const noFollowing = (textColor, secondaryTextColor) => (
  <div
    className={`flex flex-col items-center justify-center h-64 ${textColor}`}
  >
    <FaUsers size={48} className="mb-4 text-green-600" />
    <p className="text-xl font-semibold text-green-600">
      Not following anyone yet
    </p>
    <p className={`mt-2 ${secondaryTextColor}`}>
      When you follow people, they'll appear here.
    </p>
  </div>
);

export default noFollowing;
