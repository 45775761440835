import React, { useState, useEffect } from "react";
import { useFundamentals } from "../../../../../hooks/useFundamentals";
import { useSymbolSearch } from "../../../../../hooks/useSymbolSearch";
import { exchanges } from "../../../../../constants/EXCHANGES";
import { fundamentalsSections } from "./FundamentalsSections";

import Header from "./Header";
import NavigationTabs from "./NavigationTabs";
import MainContent from "./MainContent";
import LoadingState from "./LoadingState";
import ErrorState from "./ErrorState";

const tickerTypes = [
  { value: "common_stock", label: "Common Stock" },
  { value: "preferred_stock", label: "Preferred Stock" },
  { value: "stock", label: "Stock (Common & Preferred)" },
  { value: "etf", label: "ETF" },
  { value: "fund", label: "Fund" },
];

const FundamentalsDisplay = ({ darkMode }) => {
  const [symbol, setSymbol] = useState("AAPL");
  const [activeTab, setActiveTab] = useState("overview");
  const [openSections, setOpenSections] = useState(["general"]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSymbolSelectOpen, setIsSymbolSelectOpen] = useState(false);
  const [isExchangeSelectOpen, setIsExchangeSelectOpen] = useState(false);
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);

  const SUPPORTED_EXCHANGE_CODES = exchanges.filter(
    (exchange) => !["CC", "FOREX"].includes(exchange.Code),
  );

  const [selectedExchange, setSelectedExchange] = useState(
    SUPPORTED_EXCHANGE_CODES[0],
  );
  const [selectedType, setSelectedType] = useState(tickerTypes[0]);

  // Updated useFundamentals to include exchangeCode
  const { data, isLoading, error } = useFundamentals(
    symbol,
    selectedExchange.Code,
  );

  const {
    symbols,
    loading: loadingSymbols,
    setQuery,
  } = useSymbolSearch(selectedExchange.Code, selectedType.value);

  const toggleSection = (section) => {
    setOpenSections((prev) =>
      prev.includes(section)
        ? prev.filter((s) => s !== section)
        : [...prev, section],
    );
  };

  const handleSymbolSelect = (symbolCode) => {
    setSymbol(symbolCode);
    setIsSymbolSelectOpen(false);
    setSearchTerm("");
    setQuery("");
  };

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
    setIsExchangeSelectOpen(false);
    setSearchTerm("");
    setSymbol("");
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setIsTypeSelectOpen(false);
    setSearchTerm("");
    setSymbol("");
  };

  const fundamentalsViewSections = data
    ? fundamentalsSections(data, darkMode)
    : [];

  // Synchronize activeTab with selectedType
  useEffect(() => {
    // Define default tabs for each tickerType
    const defaultTabs = {
      common_stock: "overview",
      preferred_stock: "overview",
      stock: "overview",
      etf: "etf",
      fund: "fund",
    };

    // Set activeTab to the default tab for the selected tickerType
    setActiveTab(defaultTabs[selectedType.value] || "overview");
    // Reset openSections if needed
    setOpenSections([]);
  }, [selectedType]);

  // Loading State
  if (isLoading && !data) {
    return <LoadingState darkMode={darkMode} />;
  }

  // Error State
  if (error) {
    return <ErrorState darkMode={darkMode} error={error} />;
  }

  return (
    <div
      className={`w-full rounded-2xl shadow-xl overflow-hidden transition-colors duration-300 ${
        darkMode ? "bg-gray-800 border border-gray-700" : "bg-white"
      }`}
    >
      <Header
        data={data}
        symbol={symbol}
        darkMode={darkMode}
        selectedExchange={selectedExchange}
        setSelectedExchange={setSelectedExchange}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSymbol={setSymbol}
        isSymbolSelectOpen={isSymbolSelectOpen}
        setIsSymbolSelectOpen={setIsSymbolSelectOpen}
        isExchangeSelectOpen={isExchangeSelectOpen}
        setIsExchangeSelectOpen={setIsExchangeSelectOpen}
        isTypeSelectOpen={isTypeSelectOpen}
        setIsTypeSelectOpen={setIsTypeSelectOpen}
        SUPPORTED_EXCHANGE_CODES={SUPPORTED_EXCHANGE_CODES}
        tickerTypes={tickerTypes}
        symbols={symbols}
        loadingSymbols={loadingSymbols}
        handleExchangeSelect={handleExchangeSelect}
        handleTypeSelect={handleTypeSelect}
        handleSymbolSelect={handleSymbolSelect}
        setQuery={setQuery}
      />

      <NavigationTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        darkMode={darkMode}
        tickerType={selectedType.value}
        data={data}
      />

      <MainContent
        activeTab={activeTab}
        data={data}
        darkMode={darkMode}
        fundamentalsViewSections={fundamentalsViewSections}
        openSections={openSections}
        toggleSection={toggleSection}
        symbol={symbol}
        tickerType={selectedType.value}
      />
    </div>
  );
};

export default FundamentalsDisplay;
