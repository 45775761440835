import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useChat } from "../../hooks/useChat";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuth } from "../../contexts/AuthContext";
import {
  PlusCircle,
  Trash2,
  MessageCircle,
  User,
  ChevronRight,
} from "lucide-react";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";

const ConversationList = () => {
  const token = localStorage.getItem("token");
  const {
    conversations,
    loading,
    error,
    deleteConversation,
    fetchConversations,
  } = useChat(token);
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  const { user: authenticatedUser } = useAuth();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  const handleOpenDeleteDialog = (conversationId) => {
    setSelectedConversationId(conversationId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedConversationId(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedConversationId) {
      await deleteConversation(selectedConversationId);
      handleCloseDeleteDialog();
    }
  };

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const getLatestMessage = (messages) => {
    if (messages && messages.length > 0) {
      const latestMessage = messages[0];
      return {
        content: latestMessage.content,
        date: latestMessage.createdAt,
        hasImage: Boolean(latestMessage.imageUrl),
      };
    }
    return null;
  };

  const getConversationTitle = (conversation) => {
    if (conversation.name) return conversation.name;
    const otherParticipants = conversation.participants
      .filter((p) => p && p.id !== (authenticatedUser && authenticatedUser.id))
      .map((p) => p.username);
    if (otherParticipants.length === 0) return "Conversation with yourself";
    if (otherParticipants.length === 1)
      return `Conversation with ${otherParticipants[0]}`;
    return `Conversation with ${otherParticipants.join(", ")}`;
  };

  const getParticipantAvatars = (participants) => {
    const otherParticipants = participants.filter(
      (p) => p && p.id !== (authenticatedUser && authenticatedUser.id),
    );
    return otherParticipants.slice(0, 3).map((participant, index) => (
      <div
        key={participant.id}
        className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full border-2 border-white overflow-hidden ${
          index !== 0 ? "-ml-2 sm:-ml-3" : ""
        } relative`}
      >
        {participant.profileImageUrl ? (
          <img
            src={participant.profileImageUrl}
            alt={participant.username}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 flex items-center justify-center">
            <span className="text-gray-600 text-xs sm:text-sm font-semibold">
              {participant.username.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
      </div>
    ));
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex items-center justify-center h-64"
      >
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-500"></div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded relative text-sm"
        role="alert"
      >
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className={`p-3 sm:p-4 md:p-6 rounded-lg mt-3 sm:mt-6 ${
        darkMode ? "bg-gray-900" : "bg-gray-50"
      }`}
    >
      <div className="flex flex-col gap-2 justify-between items-center mb-4 sm:mb-6">
        <h2
          className={`text-xl sm:text-2xl md:text-3xl font-bold text-center ${
            darkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Your Conversations
        </h2>
        <button
          onClick={() => navigate("/forum/messages/new")}
          className="bg-green-600 hover:bg-green-700 text-white px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg flex items-center transition duration-300 text-sm sm:text-base"
        >
          <PlusCircle size={16} className="mr-1 sm:mr-2" />
          New Message
        </button>
      </div>

      {!conversations || conversations.length === 0 ? (
        <div
          className={`p-4 sm:p-6 text-center rounded-lg ${
            darkMode ? "bg-gray-800 text-gray-200" : "bg-white text-gray-600"
          }`}
        >
          <MessageCircle size={32} className="mx-auto mb-2 text-gray-400" />
          <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">
            No conversations yet
          </h3>
          <p className="text-xs sm:text-sm">
            Start a new conversation to get the ball rolling!
          </p>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="space-y-3 sm:space-y-4"
        >
          {conversations.map((conversation, index) => {
            if (!conversation || !conversation.id) return null;
            const latestMessage = getLatestMessage(conversation.messages);
            return (
              <motion.div
                key={conversation.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * index, duration: 0.5 }}
              >
                <div
                  className={`rounded-lg overflow-hidden shadow-md ${
                    darkMode
                      ? "bg-gray-800 hover:bg-gray-700"
                      : "bg-white hover:bg-gray-50"
                  } transition duration-300`}
                >
                  <Link to={`${conversation.id}`} className="block p-3 sm:p-4">
                    <div className="flex justify-between items-start mb-1 sm:mb-2">
                      <div className="flex items-center space-x-2 sm:space-x-4">
                        <div className="flex -space-x-2 sm:-space-x-3">
                          {getParticipantAvatars(conversation.participants)}
                        </div>
                        <div>
                          <h3
                            className={`text-base sm:text-lg font-semibold ${
                              darkMode ? "text-white" : "text-gray-900"
                            }`}
                          >
                            {getConversationTitle(conversation)}
                          </h3>
                          <p
                            className={`text-xs sm:text-sm ${
                              darkMode ? "text-gray-400" : "text-gray-600"
                            }`}
                          >
                            {conversation.participants
                              .filter(
                                (p) =>
                                  p &&
                                  p.id !==
                                    (authenticatedUser && authenticatedUser.id),
                              )
                              .map((p) => p.username)
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                      <ChevronRight
                        className={`${
                          darkMode ? "text-gray-400" : "text-gray-600"
                        }`}
                        size={16}
                      />
                    </div>
                    {latestMessage && (
                      <p
                        className={`text-xs sm:text-sm ${
                          darkMode ? "text-gray-300" : "text-gray-700"
                        } truncate mb-1 sm:mb-2`}
                      >
                        {latestMessage.hasImage
                          ? "Image shared"
                          : latestMessage.content}
                      </p>
                    )}
                    <div className="flex justify-between items-center">
                      <p
                        className={`text-xs ${
                          darkMode ? "text-gray-500" : "text-gray-500"
                        }`}
                      >
                        {latestMessage
                          ? `Last: ${formatDate(latestMessage.date)}`
                          : "No messages yet"}
                      </p>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpenDeleteDialog(conversation.id);
                        }}
                        className="text-red-500 hover:text-red-600 p-1 rounded transition duration-300"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </Link>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      )}

      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        darkMode={darkMode}
        title="Delete Conversation"
        description="Are you sure you want to delete this conversation? This action cannot be undone."
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
      />
    </motion.div>
  );
};

export default ConversationList;
