import React from "react";
import { motion } from "framer-motion";
import { AlertTriangle } from "lucide-react";
import { Link } from "react-router-dom";

const AuthRequiredAlert = ({ darkMode, title, message, buttonText }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`max-w-md w-full mx-auto mt-8 p-8 rounded-xl shadow-2xl ${
        darkMode ? "bg-gray-800 text-gray-100" : "bg-white text-gray-800"
      } transition-colors duration-300`}
    >
      <div className="flex flex-col items-center text-center space-y-6">
        <AlertTriangle
          className={`h-12 w-12 ${darkMode ? "text-red-400" : "text-red-500"}`}
        />
        <h2
          className={`text-3xl font-bold ${darkMode ? "text-red-400" : "text-red-600"}`}
        >
          {title}
        </h2>
        <p
          className={`text-lg ${darkMode ? "text-gray-300" : "text-gray-600"}`}
        >
          {message}
        </p>
        <Link
          to="/forum/login"
          className={`py-3 px-6 rounded-full text-white font-semibold transition-all duration-300 ease-in-out flex items-center justify-center space-x-2
            ${darkMode ? "bg-green-600 hover:bg-green-700" : "bg-green-500 hover:bg-green-600"}
            hover:shadow-lg transform hover:-translate-y-1`}
        >
          <span>{buttonText}</span>
        </Link>
      </div>
    </motion.div>
  );
};

export default AuthRequiredAlert;
