import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FaEdit,
  FaPhone,
  FaMapMarkerAlt,
  FaInfoCircle,
  FaUser,
} from "react-icons/fa";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuth } from "../../contexts/AuthContext";
import { useProfile } from "../../contexts/ProfileContext";
import ProfileImageUploader from "./ProfileImageUploader";
import SocialMediaActions from "./SocialMediaActions";
import ProfileUpdateMessage from "./ProfileUpdateMessage";

const ProfileForm = () => {
  const { profile, updateProfile } = useProfile();
  const { darkMode } = useTheme();
  const { user: authenticatedUser } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [imageFile, setImageFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState({
    text: "",
    isError: false,
  });

  useEffect(() => {
    if (profile) {
      setValue("username", profile.username);
      setValue("phone", profile.phone || "");
      setValue("bio", profile.bio || "");
      setValue("location", profile.location || "");
    }
  }, [profile, setValue]);

  const onSubmit = async (data) => {
    try {
      await updateProfile(authenticatedUser.id, data, imageFile);
      setStatusMessage({
        text: "Profile updated successfully!",
        isError: false,
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      setStatusMessage({
        text: "Failed to update profile. Please try again.",
        isError: true,
      });
    }

    setTimeout(() => setStatusMessage({ text: "", isError: false }), 3000);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const textClass = darkMode ? "text-white" : "text-gray-800";
  const accentClass = darkMode ? "text-green-400" : "text-green-600";
  const inputBgClass = darkMode ? "bg-gray-700" : "bg-white";
  const buttonClass = darkMode
    ? "bg-green-600 hover:bg-green-700"
    : "bg-green-500 hover:bg-green-600";

  if (!profile) {
    return null;
  }

  const FormField = ({
    icon: Icon,
    label,
    name,
    type = "text",
    textarea = false,
    disabled = false,
  }) => (
    <div className={`p-4 space-y-2`}>
      <label
        className={`flex items-center space-x-2 ${textClass} font-medium text-sm sm:text-base`}
      >
        <Icon className={`${accentClass} text-lg sm:text-xl`} />
        <span>{label}</span>
      </label>
      {textarea ? (
        <textarea
          {...register(name)}
          className={`w-full p-2 rounded ${inputBgClass} ${textClass} text-sm sm:text-base focus:ring-2 focus:ring-green-500 focus:outline-none transition duration-200 ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
          rows="4"
          disabled={disabled}
        />
      ) : (
        <input
          type={type}
          {...register(name)}
          className={`w-full p-2 rounded ${inputBgClass} ${textClass} text-sm sm:text-base focus:ring-2 focus:ring-green-500 focus:outline-none transition duration-200`}
          disabled={disabled}
        />
      )}
      {errors[name] && (
        <span className="text-red-500 text-sm">{errors[name].message}</span>
      )}
    </div>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="relative space-y-6 px-4 sm:px-6 py-6"
    >
      <div className="absolute top-0 right-0 mt-4 mr-4 sm:mt-6 sm:mr-6">
        <div className="flex flex-col items-end">
          <button
            type="submit"
            className={`px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base font-medium rounded-full ${buttonClass} text-white transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-lg`}
          >
            <FaEdit className="mr-2 inline" /> Save Changes
          </button>
          {statusMessage.text && (
            <ProfileUpdateMessage
              message={statusMessage.text}
              isError={statusMessage.isError}
            />
          )}
        </div>
      </div>

      <div className="text-center pt-12 sm:pt-16">
        <ProfileImageUploader
          profileImageUrl={profile.profileImageUrl}
          darkMode={darkMode}
          accentClass={accentClass}
          handleImageChange={handleImageChange}
        />
        <h1 className={`text-2xl sm:text-3xl font-bold ${textClass} mt-4`}>
          {profile.username}
        </h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        <FormField
          icon={FaUser}
          label="Username"
          name="username"
          type="text"
          disabled={true}
        />
        <FormField icon={FaPhone} label="Phone" name="phone" type="tel" />
      </div>

      <FormField icon={FaMapMarkerAlt} label="Location" name="location" />

      <FormField icon={FaInfoCircle} label="Bio" name="bio" textarea />

      <SocialMediaActions buttonClass={buttonClass} />
    </form>
  );
};

export default ProfileForm;
