import React from "react";
import {
  Target,
  TrendingUp,
  Scale,
  ChevronUp,
  Minus,
  ChevronDown,
} from "lucide-react";

const AnalystRatings = ({ data, darkMode, formatNumber, formatCurrency }) => {
  if (!data) return null;

  const getRatingIcon = (title) => {
    switch (title) {
      case "Strong Buy":
        return <ChevronUp className="w-6 h-6 text-green-500" />;
      case "Buy":
        return <TrendingUp className="w-6 h-6 text-emerald-400" />;
      case "Hold":
        return <Minus className="w-6 h-6 text-yellow-500" />;
      case "Sell":
        return <ChevronDown className="w-6 h-6 text-red-400" />;
      default:
        return <Target className="w-6 h-6 text-blue-500" />;
    }
  };

  const getBackgroundStyle = (title) => {
    if (darkMode) {
      switch (title) {
        case "Consensus Rating":
        case "Price Target":
          return "bg-slate-800 shadow-lg shadow-slate-900/20";
        case "Strong Buy":
          return "bg-green-900/30 shadow-lg shadow-green-900/10";
        case "Buy":
          return "bg-emerald-900/30 shadow-lg shadow-emerald-900/10";
        case "Hold":
          return "bg-yellow-900/30 shadow-lg shadow-yellow-900/10";
        case "Sell":
          return "bg-red-900/30 shadow-lg shadow-red-900/10";
        default:
          return "bg-slate-800 shadow-lg shadow-slate-900/20";
      }
    }
    switch (title) {
      case "Consensus Rating":
      case "Price Target":
        return "bg-white shadow-lg shadow-slate-200/50";
      case "Strong Buy":
        return "bg-green-50 shadow-lg shadow-green-100/50";
      case "Buy":
        return "bg-emerald-50 shadow-lg shadow-emerald-100/50";
      case "Hold":
        return "bg-yellow-50 shadow-lg shadow-yellow-100/50";
      case "Sell":
        return "bg-red-50 shadow-lg shadow-red-100/50";
      default:
        return "bg-white shadow-lg shadow-slate-200/50";
    }
  };

  const metrics = [
    {
      title: "Consensus Rating",
      value: formatNumber(data.Rating),
      subtitle: `Out of 5 (${data.StrongBuy} Strong Buy)`,
      tooltip: "Average analyst rating from 1 (Strong Sell) to 5 (Strong Buy)",
      className: "md:col-span-2 lg:col-span-1",
    },
    {
      title: "Price Target",
      value: formatCurrency(data.TargetPrice),
      tooltip: "Average analyst price target",
      className: "md:col-span-2 lg:col-span-1",
    },
    {
      title: "Strong Buy",
      value: formatNumber(data.StrongBuy),
      tooltip: "Number of analysts rating as Strong Buy",
    },
    {
      title: "Buy",
      value: formatNumber(data.Buy),
      tooltip: "Number of analysts rating as Buy",
    },
    {
      title: "Hold",
      value: formatNumber(data.Hold),
      tooltip: "Number of analysts rating as Hold",
    },
    {
      title: "Sell",
      value: formatNumber(data.Sell),
      subtitle: `Strong Sell: ${data.StrongSell || 0}`,
      tooltip: "Number of analysts rating as Sell or Strong Sell",
    },
  ];

  return (
    <div className="p-4 sm:p-6">
      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 gap-4 sm:gap-6">
        {metrics.map((metric) => (
          <div
            key={metric.title}
            className={`rounded-xl ${getBackgroundStyle(metric.title)} 
              transition-all duration-300 ease-in-out
              hover:scale-[1.02] hover:shadow-xl
              ${darkMode ? "border border-slate-700" : "border border-slate-100"}
              ${metric.className || ""}`}
          >
            <div className="p-4 sm:p-5">
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  {getRatingIcon(metric.title)}
                  <h3
                    className={`text-sm sm:text-base font-medium tracking-wide
                      ${darkMode ? "text-slate-200" : "text-slate-700"}`}
                  >
                    {metric.title}
                  </h3>
                </div>
                <div className="space-y-2">
                  <p
                    className={`text-2xl sm:text-3xl font-semibold tracking-tight
                      ${darkMode ? "text-white" : "text-slate-900"}`}
                  >
                    {metric.value}
                  </p>
                  {metric.subtitle && (
                    <p
                      className={`text-sm font-medium
                        ${darkMode ? "text-slate-400" : "text-slate-600"}`}
                    >
                      {metric.subtitle}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnalystRatings;
