import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate } from "react-router-dom";

const NavText = ({ to, icon: Icon, children, onClick, className = "" }) => {
  const { darkMode } = useTheme();

  const navigate = useNavigate();

  const isHashLink = to.startsWith("#");

  const baseTextClasses =
    "flex items-center justify-center px-4 py-2 rounded-full text-sm font-semibold uppercase tracking-wider transition-all duration-300 ease-in-out cursor-pointer";

  const colorClasses = darkMode
    ? "text-white hover:text-green-300"
    : "text-green-600 hover:text-green-700";

  const underlineClasses = darkMode ? "bg-green-400" : "bg-green-600";

  const navTextClasses = `${baseTextClasses} ${colorClasses} ${className}`;

  const containerVariants = {
    hover: { scale: 1.05, transition: { duration: 0.3 } },
    tap: { scale: 0.95, transition: { duration: 0.1 } },
  };

  const handleClick = (e) => {
    if (isHashLink) {
      e.preventDefault();
      const element = document.querySelector(to);
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else {
      navigate(to);
    }
    onClick?.();
  };

  const Content = () => (
    <span className="flex items-center group">
      {Icon && (
        <Icon
          className={`w-5 h-5 mr-2 ${darkMode ? "text-white" : "text-green-600"}`}
        />
      )}
      <span className="relative">
        {children}
        <span
          className={`absolute left-0 right-0 bottom-0 h-0.5 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out ${underlineClasses}`}
        />
      </span>
    </span>
  );

  const NavContent = (
    <motion.div variants={containerVariants} whileHover="hover" whileTap="tap">
      <Content />
    </motion.div>
  );

  return isHashLink ? (
    <span className={navTextClasses} onClick={handleClick}>
      {NavContent}
    </span>
  ) : (
    <Link to={to} className={navTextClasses} onClick={handleClick}>
      {NavContent}
    </Link>
  );
};

export default NavText;
