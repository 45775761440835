import React from "react";
import {
  Table as TableIcon,
  ArrowUpRight,
  ArrowDownRight,
  Minus,
  TrendingUp,
  Info,
} from "lucide-react";
import { formatCurrency } from "./FormatHelpers";

const metrics = [
  { label: "Cash and Equivalents", key: "cash", category: "liquidity" },
  { label: "Receivables", key: "netReceivables", category: "liquidity" },
  { label: "Inventory", key: "inventory", category: "operations" },
  {
    label: "Property, Plant & Equipment",
    key: "propertyPlantEquipment",
    category: "assets",
  },
  {
    label: "Long Term Investments",
    key: "longTermInvestments",
    category: "investments",
  },
  { label: "Short Term Debt", key: "shortTermDebt", category: "liabilities" },
  { label: "Long Term Debt", key: "longTermDebt", category: "liabilities" },
  { label: "Total Assets", key: "totalAssets", category: "summary" },
  { label: "Total Liabilities", key: "totalLiab", category: "summary" },
  { label: "Total Equity", key: "totalStockholderEquity", category: "summary" },
];

const DetailedBreakdown = ({ currentData, previousData, darkMode }) => {
  const getChangeIcon = (change) => {
    if (change > 0)
      return <ArrowUpRight className="w-4 h-4 text-emerald-500" />;
    if (change < 0) return <ArrowDownRight className="w-4 h-4 text-red-500" />;
    return <Minus className="w-4 h-4 text-gray-400" />;
  };

  const getChangeClass = (change) => {
    if (change > 0) return "text-emerald-500";
    if (change < 0) return "text-red-500";
    return darkMode ? "text-gray-400" : "text-gray-600";
  };

  return (
    <div
      className={`rounded-2xl overflow-hidden border ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-100"
      } shadow-lg transition-all duration-300`}
    >
      {/* Enhanced Header */}
      <div
        className={`px-6 py-4 border-b ${
          darkMode ? "border-gray-800" : "border-gray-100"
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div
              className={`p-2 rounded-xl ${
                darkMode ? "bg-gray-800" : "bg-gray-50"
              }`}
            >
              <TableIcon className="w-5 h-5 text-blue-500" />
            </div>
            <div>
              <h3
                className={`text-lg font-bold ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Detailed Breakdown
              </h3>
              <p
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                Comprehensive financial metrics analysis
              </p>
            </div>
          </div>

          <div
            className={`hidden sm:flex items-center gap-2 px-4 py-2 rounded-lg text-sm ${
              darkMode ? "bg-gray-800" : "bg-gray-50"
            }`}
          >
            <Info className="w-4 h-4" />
            <span>Period-over-period comparison</span>
          </div>
        </div>
      </div>

      {/* Desktop Table View */}
      <div className="hidden md:block overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className={`${darkMode ? "bg-gray-800/50" : "bg-gray-50"}`}>
              <th className="px-6 py-4 text-left text-sm font-semibold">
                Metric
              </th>
              <th className="px-6 py-4 text-right text-sm font-semibold">
                Current Value
              </th>
              <th className="px-6 py-4 text-right text-sm font-semibold">
                Previous Period
              </th>
              <th className="px-6 py-4 text-right text-sm font-semibold">
                Change
              </th>
            </tr>
          </thead>
          <tbody>
            {metrics.map((item, index) => {
              const current = currentData[item.key];
              const previous = previousData?.[item.key];
              const change = previous
                ? ((current - previous) / previous) * 100
                : 0;

              return (
                <tr
                  key={item.key}
                  className={`
                    group transition-colors duration-200
                    ${
                      index % 2 === 0
                        ? darkMode
                          ? "bg-gray-900"
                          : "bg-white"
                        : darkMode
                          ? "bg-gray-800/30"
                          : "bg-gray-50/50"
                    }
                    ${darkMode ? "hover:bg-gray-800" : "hover:bg-gray-50"}
                  `}
                >
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-2">
                      <span
                        className={`text-sm font-medium ${
                          darkMode ? "text-gray-200" : "text-gray-900"
                        }`}
                      >
                        {item.label}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <span
                      className={`text-sm font-medium ${
                        darkMode ? "text-gray-200" : "text-gray-900"
                      }`}
                    >
                      {formatCurrency(current)}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <span
                      className={`text-sm ${
                        darkMode ? "text-gray-400" : "text-gray-600"
                      }`}
                    >
                      {formatCurrency(previous)}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center justify-end gap-2">
                      {getChangeIcon(change)}
                      <span
                        className={`text-sm font-medium ${getChangeClass(change)}`}
                      >
                        {change.toFixed(2)}%
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Enhanced Mobile Card View */}
      <div className="md:hidden p-4 space-y-4">
        {metrics.map((item) => {
          const current = currentData[item.key];
          const previous = previousData?.[item.key];
          const change = previous ? ((current - previous) / previous) * 100 : 0;

          return (
            <div
              key={item.key}
              className={`p-4 rounded-xl border transition-all duration-200 ${
                darkMode
                  ? "bg-gray-800/50 border-gray-700 hover:bg-gray-800"
                  : "bg-gray-50/50 border-gray-100 hover:bg-gray-50"
              }`}
            >
              <div className="flex items-center justify-between mb-3">
                <span
                  className={`font-medium ${
                    darkMode ? "text-gray-200" : "text-gray-900"
                  }`}
                >
                  {item.label}
                </span>
                <div
                  className={`flex items-center gap-1 px-2 py-1 rounded-lg text-sm ${getChangeClass(
                    change,
                  )}`}
                >
                  {getChangeIcon(change)}
                  <span className="font-medium">{change.toFixed(2)}%</span>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div
                    className={`text-xs ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Current
                  </div>
                  <div
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-200" : "text-gray-900"
                    }`}
                  >
                    {formatCurrency(current)}
                  </div>
                </div>

                <div className="space-y-1">
                  <div
                    className={`text-xs ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Previous
                  </div>
                  <div
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {formatCurrency(previous)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailedBreakdown;
