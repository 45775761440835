import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom";
import { ImageIcon, XIcon } from "lucide-react";

const CreateEditPostImageUpload = ({
  darkMode,
  onImagesChange,
  initialImages = [],
}) => {
  const [images, setImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const { id } = useParams();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (initialImages.length > 0 && images.length === 0) {
      setImages(initialImages);
    }
  }, [initialImages]);

  useEffect(() => {
    setImages([]);
  }, [id]);

  const handleFiles = (files) => {
    const newImages = Array.from(files).map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        isExisting: false,
      }),
    );
    setImages((prevImages) => [...prevImages, ...newImages].slice(0, 5));
  };

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const { files } = e.dataTransfer;
    handleFiles(files);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const onClick = () => {
    fileInputRef.current.click();
  };

  const onFileInputChange = (e) => {
    const { files } = e.target;
    handleFiles(files);
  };

  const removeImage = (image) => {
    setImages((prevImages) => prevImages.filter((img) => img !== image));
    if (image.isExisting) {
      setRemovedImages((prev) => [...prev, image.preview]);
    }
  };

  useEffect(() => {
    onImagesChange(images, removedImages);
  }, [images, removedImages, onImagesChange]);

  useEffect(() => {
    return () =>
      images.forEach((image) => {
        if (!image.isExisting && image.preview) {
          URL.revokeObjectURL(image.preview);
        }
      });
  }, [images]);

  return (
    <div className="space-y-2">
      <div
        onClick={onClick}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        className={`cursor-pointer flex items-center justify-center h-40 border-2 border-dashed rounded-lg transition-colors duration-200 
        ${
          isDragActive
            ? "border-green-500 bg-green-50"
            : darkMode
              ? "border-gray-600 bg-gray-700"
              : "border-gray-300 bg-gray-50"
        }`}
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple
          accept="image/*"
          onChange={onFileInputChange}
          style={{ display: "none" }}
        />
        <div className="text-center">
          <ImageIcon className="mx-auto h-16 w-6 text-green-500" />
          <p
            className={`mt-2 text-lg ${
              darkMode ? "text-gray-200" : "text-gray-600"
            }`}
          >
            Drag 'n' drop images
          </p>
        </div>
      </div>
      <motion.div layout className="mt-4 flex flex-wrap gap-4">
        <AnimatePresence>
          {images.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              className="relative"
            >
              <img
                src={image.preview}
                alt={`preview ${index}`}
                className="h-24 w-24 object-cover rounded-lg shadow-md"
              />
              <button
                type="button"
                onClick={() => removeImage(image)}
                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 shadow-lg hover:bg-red-600 transition-colors duration-200"
              >
                <XIcon size={16} />
              </button>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
      {images.length >= 5 && (
        <p
          className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
        >
          Maximum of 5 images allowed
        </p>
      )}
    </div>
  );
};

export default CreateEditPostImageUpload;
