import React from "react";
import {
  VictoryCandlestick,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { format } from "date-fns";

const CandlestickChart = ({ candlestickData, theme, baseColor, darkMode }) => {
  // Safely calculate price statistics with null checks and type conversion
  const getNumericValue = (value) => {
    const num = Number(value);
    return isNaN(num) ? 0 : num;
  };

  const latestCandle = candlestickData[candlestickData.length - 1] || {};
  const firstCandle = candlestickData[0] || {};

  const latestPrice = getNumericValue(latestCandle.close);
  const openPrice = getNumericValue(firstCandle.open);

  const highestPrice = Math.max(
    ...candlestickData.map((d) => getNumericValue(d.high)),
  );
  const lowestPrice = Math.min(
    ...candlestickData.map((d) => getNumericValue(d.low)),
  );

  const priceChange = latestPrice - openPrice;
  const priceChangePercentage =
    openPrice !== 0 ? (priceChange / openPrice) * 100 : 0;
  const isPositive = priceChange >= 0;

  // Format price for display
  const formatPrice = (price) => {
    return price.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Custom tooltip content
  const tooltipFormat = (datum) => {
    const date = new Date(datum.x);
    const formattedDate = format(date, "MMM dd, yyyy HH:mm");
    const open = getNumericValue(datum.open);
    const close = getNumericValue(datum.close);
    const percentChange = open !== 0 ? ((close - open) / open) * 100 : 0;

    return `Date: ${formattedDate}\n
Open: $${formatPrice(getNumericValue(datum.open))}
High: $${formatPrice(getNumericValue(datum.high))}
Low: $${formatPrice(getNumericValue(datum.low))}
Close: $${formatPrice(getNumericValue(datum.close))}\n
Change: ${percentChange.toFixed(2)}%`;
  };

  // Enhanced theme with grid styling
  const enhancedTheme = {
    ...theme,
    chart: {
      ...theme.chart,
      padding: { top: 50, bottom: 50, left: 60, right: 50 },
    },
    axis: {
      ...theme.axis,
      style: {
        ...theme.axis.style,
        grid: {
          stroke: baseColor,
          strokeOpacity: 0.1,
          strokeDasharray: "4, 4",
        },
      },
    },
  };

  return (
    <div className="space-y-6">
      {/* Chart Container */}
      <div
        className={`relative backdrop-blur-lg border ${
          darkMode
            ? "bg-slate-800/80 border-slate-700"
            : "bg-white/90 border-slate-200"
        } rounded-xl overflow-hidden shadow-lg`}
      >
        <div
          className={`absolute inset-0 ${
            darkMode ? "bg-slate-900/20" : "bg-slate-50/20"
          } backdrop-blur-sm`}
        />

        {/* Chart Content */}
        <div className="relative z-10">
          <VictoryChart
            theme={enhancedTheme}
            domainPadding={{ x: 25 }}
            width={800}
            height={400}
            scale={{ x: "time" }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => tooltipFormat(datum)}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={8}
                    flyoutStyle={{
                      stroke: darkMode
                        ? "rgb(51, 65, 85)"
                        : "rgb(226, 232, 240)",
                      strokeWidth: 1,
                      fill: darkMode
                        ? "rgb(30, 41, 59, 0.95)"
                        : "rgb(255, 255, 255, 0.95)",
                      padding: 10,
                    }}
                    style={{
                      fill: darkMode ? "rgb(226, 232, 240)" : "rgb(51, 65, 85)",
                      fontSize: 12,
                    }}
                    flyoutPadding={{
                      top: 10,
                      bottom: 10,
                      left: 15,
                      right: 15,
                    }}
                  />
                }
              />
            }
          >
            <VictoryAxis
              tickFormat={(t) => {
                const date = new Date(t);
                return `${format(date, "HH:mm")}\n${format(date, "MMM dd")}`;
              }}
              style={{
                axis: {
                  stroke: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  strokeWidth: 1,
                },
                ticks: {
                  stroke: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  size: 5,
                },
                tickLabels: {
                  fill: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  fontSize: 12,
                  padding: 8,
                },
                grid: {
                  stroke: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  strokeOpacity: 0.1,
                },
              }}
            />

            <VictoryAxis
              dependentAxis
              tickFormat={(t) => `$${t}`}
              style={{
                axis: {
                  stroke: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  strokeWidth: 1,
                },
                ticks: {
                  stroke: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  size: 5,
                },
                tickLabels: {
                  fill: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  fontSize: 12,
                  padding: 8,
                },
                grid: {
                  stroke: darkMode ? "rgb(148, 163, 184)" : "rgb(71, 85, 105)",
                  strokeOpacity: 0.1,
                },
              }}
            />

            <VictoryCandlestick
              data={candlestickData}
              candleColors={{
                positive: "#10B981", // Emerald-500
                negative: "#EF4444", // Red-500
              }}
              style={{
                data: {
                  stroke: ({ datum }) =>
                    getNumericValue(datum.close) >= getNumericValue(datum.open)
                      ? "#059669" // Emerald-600
                      : "#DC2626", // Red-600
                  strokeWidth: 1,
                },
              }}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  );
};

export default CandlestickChart;
