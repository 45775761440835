import React, { useCallback, useState, useEffect } from "react";
import {
  Bell,
  BellOff,
  Check,
  Trash2,
  ExternalLink,
  RefreshCw,
} from "lucide-react";
import { useAlerts } from "../../contexts/AlertsContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const NotificationsPage = ({ darkMode }) => {
  const {
    alerts,
    markAlertAsRead,
    markAllAlertsAsRead,
    deleteAlert,
    hasUnreadAlerts,
    refreshAlerts,
  } = useAlerts();

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { user } = useAuth();

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    await refreshAlerts();
    setIsRefreshing(false);
  }, [refreshAlerts]);

  useEffect(() => {
    async function loadNotifications() {
      try {
        await refreshAlerts();
      } catch (error) {
        console.error("Error fetching notifications", error);
      } finally {
        setLoading(false);
      }
    }

    loadNotifications();
  }, [refreshAlerts]);

  const handleViewPost = (alert) => {
    markAlertAsRead(alert.id);
    navigate(`/forum/post/${alert.postId}`);
  };

  if (loading) {
    return (
      <div
        className={`text-center py-8 ${darkMode ? "text-gray-300" : "text-gray-700"}`}
      >
        Loading notifications...
      </div>
    );
  }

  return (
    <div
      className={`max-w-4xl mx-auto p-4 mt-8 ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"}`}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 space-y-2 sm:space-y-0">
        <h1 className="text-2xl font-bold flex items-center">
          <AlertsBellIcon darkMode={darkMode} />
          <span className="ml-2">Notifications</span>
        </h1>
        <div className="flex items-center space-x-2 self-end">
          <button
            onClick={handleRefresh}
            className={`p-2 rounded-full ${
              darkMode
                ? "text-green-400 hover:text-green-300 hover:bg-green-900"
                : "text-green-600 hover:text-green-700 hover:bg-green-100"
            } transition duration-200 ease-in-out`}
            disabled={isRefreshing}
          >
            <RefreshCw
              size={20}
              className={isRefreshing ? "animate-spin" : ""}
            />
          </button>
          {hasUnreadAlerts && (
            <button
              onClick={markAllAlertsAsRead}
              className={`text-sm font-medium ${
                darkMode
                  ? "text-green-400 hover:text-green-300"
                  : "text-green-600 hover:text-green-700"
              } transition duration-200 ease-in-out hover:underline`}
            >
              Mark all as read
            </button>
          )}
        </div>
      </div>

      {alerts.length === 0 ? (
        <div
          className={`text-center py-8 ${darkMode ? "text-gray-400" : "text-gray-500"}`}
        >
          <Bell className="mx-auto mb-4" size={48} />
          <p className="text-xl">No notifications</p>
        </div>
      ) : (
        <div className="space-y-3">
          {alerts.map((alert) => (
            <div
              key={alert.id}
              className={`p-3 rounded-lg transition-all duration-150 ease-in-out ${
                alert.isRead
                  ? darkMode
                    ? "bg-gray-700 text-gray-300"
                    : "bg-gray-100 text-gray-600"
                  : darkMode
                    ? "bg-gray-600 shadow-md"
                    : "bg-white shadow-md"
              } ${
                !alert.isRead &&
                (darkMode
                  ? "border-l-4 border-green-400"
                  : "border-l-4 border-green-500")
              }`}
            >
              <div className="flex flex-col space-y-2">
                <div className="flex-grow">
                  <h3
                    className={`font-semibold ${darkMode ? "text-gray-100" : "text-gray-900"}`}
                  >
                    {alert.title}
                  </h3>
                  <p
                    className={`text-sm mt-1 ${darkMode ? "text-gray-300" : "text-gray-600"}`}
                  >
                    {alert.content}
                  </p>
                </div>
                <div
                  className={`flex items-center justify-between text-xs ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                >
                  <span className="flex items-center">
                    <Bell size={12} className="mr-1" />
                    {formatDate(alert.createdAt)}
                  </span>
                  <div className="flex items-center space-x-2">
                    <button
                      className={`flex items-center ${
                        darkMode
                          ? "text-green-400 hover:text-green-300"
                          : "text-green-600 hover:text-green-700"
                      }`}
                      onClick={() => handleViewPost(alert)}
                    >
                      View <ExternalLink size={12} className="ml-1" />
                    </button>
                    {!alert.isRead && (
                      <button
                        onClick={() => markAlertAsRead(alert.id)}
                        className={`${
                          darkMode
                            ? "text-green-400 hover:text-green-300 hover:bg-green-900"
                            : "text-green-600 hover:text-green-700 hover:bg-green-100"
                        } transition duration-200 ease-in-out p-1 rounded-full`}
                        title="Mark as read"
                      >
                        <Check size={16} />
                      </button>
                    )}
                    <button
                      onClick={() => deleteAlert(alert.id)}
                      className={`${
                        darkMode
                          ? "text-red-400 hover:text-red-300 hover:bg-red-900"
                          : "text-red-600 hover:text-red-700 hover:bg-red-100"
                      } transition duration-200 ease-in-out p-1 rounded-full`}
                      title="Delete notification"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AlertsBellIcon = ({ darkMode }) => {
  const { hasUnreadAlerts } = useAlerts();

  return hasUnreadAlerts ? (
    <Bell
      size={28}
      className={`${darkMode ? "text-yellow-400" : "text-yellow-600"} animate-pulse transition-colors duration-300`}
    />
  ) : (
    <BellOff size={28} className="transition-colors duration-300" />
  );
};

export default NotificationsPage;
