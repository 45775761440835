import {
  FaPlay,
  FaStar,
  FaQuoteRight,
  FaRocket,
  FaSignInAlt,
  FaPlusCircle,
  FaSignOutAlt,
  FaArrowLeft,
  FaSearch,
} from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { MdForum } from "react-icons/md";

export const getNavItems = ({
  isAboutRoute,
  isAffiliatesRoute,
  isForumRoute,
  isAuthenticated,
  user,
  onLogout,
}) => {
  if (isAboutRoute || isAffiliatesRoute) {
    return [{ name: "Back to Home", to: "/", icon: FaArrowLeft }];
  }

  if (isForumRoute) {
    const items = [{ name: "Forum", to: "/forum", icon: MdForum }];

    if (isAuthenticated && user) {
      items.push(
        { name: "Search Users", to: "/forum/users/search", icon: FaSearch },
        { name: "Create Post", to: "/forum/create", icon: FaPlusCircle },
        { name: "Messages", to: "/forum/messages", icon: FiMessageSquare },
        {
          name: "Logout",
          to: "/forum",
          icon: FaSignOutAlt,
          onClick: onLogout,
        },
      );
    } else {
      items.push({ name: "Login", to: "/forum/login", icon: FaSignInAlt });
    }

    return items;
  }

  return [
    { name: "Watch", to: "#hero-section", icon: FaPlay },
    { name: "Features", to: "#feature-section", icon: FaStar },
    { name: "Testimonials", to: "#testimonial-section", icon: FaQuoteRight },
    { name: "Forum", to: "/forum", icon: MdForum },
    { name: "Get Started", to: "#cta-section", icon: FaRocket },
  ];
};
