import { useState, useEffect, useCallback } from "react";
import { getApiUrl } from "../config/api";

const usePaginatedComments = (postId, initialPage = 1, commentsPerPage = 5) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [totalComments, setTotalComments] = useState(0);

  const fetchComments = useCallback(
    async (page) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${getApiUrl()}/api/comments/post/${postId}/paginated?page=${page}&limit=${commentsPerPage}`,
        );
        if (!response.ok) {
          throw new Error("Failed to fetch comments");
        }
        const data = await response.json();
        setComments(data.comments);
        setTotalPages(data.totalPages);
        setTotalComments(data.total);
        setCurrentPage(page);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [postId, commentsPerPage],
  );

  useEffect(() => {
    fetchComments(currentPage);
  }, [fetchComments, currentPage]);

  const refreshComments = () => {
    fetchComments(currentPage);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return {
    comments,
    loading,
    error,
    currentPage,
    totalPages,
    totalComments,
    handlePageChange,
    refreshComments,
  };
};

export default usePaginatedComments;
