import React from "react";

const IncomeMetricCard = ({ title, metrics, darkMode }) => {
  const formatValue = (value, isRatio = false) => {
    if (isRatio) {
      return `${value.toFixed(2)}%`;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);
  };

  const MetricRow = ({ metric }) => {
    const value = formatValue(metric.value, metric.isRatio);

    return (
      <div
        className={`group flex justify-between items-center p-2.5 rounded-lg transition-all duration-200 
        hover:bg-opacity-50 cursor-default
        ${darkMode ? "hover:bg-gray-700/40" : "hover:bg-gray-50"}`}
      >
        <div className="flex items-center gap-2">
          <span
            className={`text-sm font-medium tracking-tight
            ${darkMode ? "text-gray-300" : "text-gray-600"}
            group-hover:${darkMode ? "text-gray-200" : "text-gray-700"}`}
          >
            {metric.label}
          </span>
        </div>
        <span
          className={`font-semibold text-sm transition-colors
          ${
            metric.isRatio
              ? metric.value > 0
                ? "text-green-500"
                : "text-red-500"
              : darkMode
                ? "text-gray-200"
                : "text-gray-700"
          }
          ${metric.isRatio && metric.value > 0 ? "group-hover:text-green-400" : ""}
          ${metric.isRatio && metric.value < 0 ? "group-hover:text-red-400" : ""}
          ${!metric.isRatio ? `group-hover:${darkMode ? "text-white" : "text-gray-900"}` : ""}`}
        >
          {value}
        </span>
      </div>
    );
  };

  return (
    <div
      className={`w-full transition-all duration-200 ease-in-out 
      ${darkMode ? "bg-gray-900" : "bg-white"}`}
    >
      <div
        className={`rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm
        ${darkMode ? "bg-gray-800/50" : "bg-white"}
        hover:shadow-xl transition-shadow duration-300`}
      >
        {/* Header */}
        <div
          className={`px-5 py-4 border-b
          ${darkMode ? "border-gray-700/50" : "border-gray-100"}`}
        >
          <h3
            className={`text-base font-semibold tracking-tight
            ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            {title}
          </h3>
        </div>

        {/* Metrics Grid */}
        <div
          className={`p-3 
          ${darkMode ? "bg-gray-800/30" : "bg-gray-50/50"}`}
        >
          <div className="divide-y divide-gray-200/10">
            {metrics.map((metric, index) => (
              <MetricRow key={index} metric={metric} />
            ))}
          </div>
        </div>

        {/* Footer - Optional Summary */}
        {metrics.some((m) => m.isRatio) && (
          <div
            className={`px-5 py-3 border-t
            ${darkMode ? "border-gray-700/50" : "border-gray-100"}`}
          >
            <div
              className={`text-xs font-medium
              ${darkMode ? "text-gray-400" : "text-gray-500"}`}
            >
              Percentage values show relative performance
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IncomeMetricCard;
