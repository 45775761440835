import { useState, useCallback, useEffect } from "react";
import { getApiUrl } from "../config/api";

const usePostDetail = (id, user = null) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const fetchPost = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}/api/posts/${id}`);
      if (!response.ok) throw new Error("Failed to fetch post");
      const data = await response.json();
      setPost(data);
      setIsLiked(data.likes?.some((like) => like.userId === user?.id) || false);
      setLikeCount(data.likes?.length || 0);
    } catch (err) {
      setError(err.message || "Failed to load post. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [id, user?.id]);

  const updateLikeStatus = useCallback(
    (newIsLiked, newLikeCount) => {
      setIsLiked(newIsLiked);
      setLikeCount(newLikeCount);
      setPost((prevPost) => ({
        ...prevPost,
        likes: newIsLiked
          ? [...(prevPost.likes || []), { userId: user?.id }]
          : (prevPost.likes || []).filter((like) => like.userId !== user?.id),
      }));
    },
    [user?.id],
  );

  useEffect(() => {
    if (id) {
      fetchPost();
    }
  }, [id, fetchPost]);

  return {
    post,
    error,
    isLoading,
    isLiked,
    likeCount,
    setPost,
    updateLikeStatus,
  };
};

export default usePostDetail;
