import {
  CheckCircle,
  Lightbulb,
  Diamond,
  Rocket,
  Target,
  DollarSign,
  List,
  Book,
  Heart,
  Music,
  Smile,
  HelpCircle,
  Coffee,
  Unlock,
  Waves,
} from "lucide-react";

const TOPIC_ICONS = {
  allPosts: List,
  quantumMoneyCode: DollarSign,
  manifestation: Diamond,
  meditationAndMindfulness: Waves,
  successStories: CheckCircle,
  quantumNewsAndDevelopments: Lightbulb,
  visualizationTechniques: Target,
  personalGrowth: Rocket,
  wealthAndAbundance: DollarSign,
  energyAndVibration: Waves,
  inspirationAndMotivation: Lightbulb,
  healthAndWellness: Heart,
  bookRecommendations: Book,
  popCulture: Music,
  memes: Smile,
  seekingAdvice: HelpCircle,
  justForFun: Coffee,
  overcomingBlocks: Unlock,
};

export default TOPIC_ICONS;
