import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

const QuantumMoneyCodeLogoText = ({
  qLetter = "Q",
  suffixText = "uantum Money Code",
  qProps = {
    fontFamily: "Quantum",
    fontWeight: "300",
    fontSize: "3rem",
    style: {},
  },
  suffixFontSize = "2rem",
  qSpacingClass = "ml-1",
  verticalAlignment = "center",
}) => {
  const { darkMode } = useTheme();
  const textColor = darkMode ? "text-gray-300" : "text-gray-600";

  return (
    <div className={`flex items-${verticalAlignment}`}>
      <div className={`flex items-center ${qSpacingClass}`}>
        <span
          style={{
            ...qProps,
            fontSize: qProps.fontSize,
            position: "relative",
            ...qProps.style,
          }}
        >
          {qLetter}
        </span>
        <span
          className={`text-md ${textColor}`}
          style={{ fontSize: suffixFontSize }}
        >
          {suffixText}
        </span>
      </div>
    </div>
  );
};

export default QuantumMoneyCodeLogoText;
