// React and React Router imports
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Context imports
import { useAuthContext } from "../../contexts/AuthContext";
import { useAlerts } from "../../contexts/AlertsContext";
import { useTheme } from "../../contexts/ThemeContext";

// Third-party library imports
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Layout and navigation components
import Sidebar from "../navigation/Sidebar";
import ForumLayout from "./ForumLayout";
import { AlertsDropdown } from "../navigation/AlertsDropdown";

// Auth components
import Login from "../auth/Login";
import Register from "../auth/Register";
import RegistrationSuccess from "../auth/RegistrationSuccess";
import EmailVerification from "../auth/EmailVerification";
import AuthenticationGuard from "../auth/AuthenticationGuard";

// Post components
import PostList from "../post/post-list-items/PostList";
import CreateEditPost from "../post/create-post/CreateEditPost";
import PostDetail from "../post/PostDetail";

// Profile and follow components
import ProfilePage from "../profile/ProfilePage";
import FollowersPage from "../follow/follower/FollowersPage";
import FollowingPage from "../follow/following/FollowingPage";

// Common components
import NotificationsPage from "../common/NotificationsPage";
import UserSearch from "../user-search/UserSearch";

// Messaging components
import ConversationsList from "../messaging/ConversationList";
import ConversationDetail from "../messaging/ConversationDetail";
import NewMessage from "../messaging/NewMessage";
import MessageSettings from "../messaging/MessageSettings";
import CallInterface from "../messaging/CallInterface";

// Membership and subscription components
import PricingComponent from "../membership/PricingComponent";
import PlanDetails from "../membership/PlanDetails";
import SubscriptionGuard from "../subscription/guards/SubscriptionGuard";
import SubscriptionSuccess from "../membership/SubscriptionSuccess";
import PremiumFeaturesLayout from "../subscription/common/PremiumFeaturesLayout";

const Forum = () => {
  const { isAuthenticated, user, logout } = useAuthContext();
  const { alerts, markAlertAsRead, markAllAlertsAsRead } = useAlerts();
  const [isAlertsOpen, setIsAlertsOpen] = useState(false);
  const { darkMode, toggleTheme } = useTheme();

  const stripePromise = loadStripe(
    "pk_test_51Q8oW3CBp9O8UqlntcbjFRM1mbbPVNLRjTUl5Nkse6Q4In1PdoAAF4QRIVFmPpNJjMHtY4RqaNVV72FJ4Rs4iPy400niwnaMOa",
  );

  const onToggleAlerts = () => {
    setIsAlertsOpen((prev) => !prev);
  };

  const closeModal = () => {
    setIsAlertsOpen(false);
  };

  return (
    <div className="flex">
      <Sidebar
        isAuthenticated={isAuthenticated}
        onLogout={logout}
        user={user}
        darkMode={darkMode}
        toggleTheme={toggleTheme}
      />
      <div className="flex-1">
        {isAuthenticated && (
          <AlertsDropdown
            isOpen={isAlertsOpen}
            alerts={alerts}
            markAlertAsRead={markAlertAsRead}
            markAllAlertsAsRead={markAllAlertsAsRead}
            closeModal={closeModal}
            darkMode={darkMode}
          />
        )}
        <main className="container mx-auto py-8 md:py-12 lg:py-16">
          <ForumLayout>
            <Routes>
              {/* ======================== ********************************** Public Routes ********************************** ======================== */}
              <Route path="/" element={<PostList />} />
              <Route path="/:topic/*" element={<PostList />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/registration-success"
                element={<RegistrationSuccess />}
              />
              <Route
                path="/verify-email/:token"
                element={<EmailVerification />}
              />
              <Route path="/post/:id" element={<PostDetail />} />

              {/* ======================== ********************************** Protected Routes ********************************** ======================== */}
              <Route
                path="/create"
                element={
                  <AuthenticationGuard>
                    <CreateEditPost user={user} />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/post/:id/edit"
                element={
                  <AuthenticationGuard>
                    <CreateEditPost user={user} />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/profile/:userId"
                element={
                  <AuthenticationGuard>
                    <ProfilePage />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/followers"
                element={
                  <AuthenticationGuard>
                    <FollowersPage />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/following"
                element={
                  <AuthenticationGuard>
                    <FollowingPage />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/notifications"
                element={
                  <AuthenticationGuard>
                    <NotificationsPage darkMode={darkMode} />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/users/search"
                element={
                  <AuthenticationGuard>
                    <UserSearch />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/messages"
                element={
                  <AuthenticationGuard>
                    <ConversationsList />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/messages/:conversationId"
                element={
                  <AuthenticationGuard>
                    <ConversationDetail />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/messages/:conversationId/call"
                element={
                  <AuthenticationGuard>
                    <CallInterface darkMode={darkMode} />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/messages/new"
                element={
                  <AuthenticationGuard>
                    <NewMessage />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/messages/settings"
                element={
                  <AuthenticationGuard>
                    <MessageSettings />
                  </AuthenticationGuard>
                }
              />

              <Route
                path="/pricing"
                element={
                  <AuthenticationGuard>
                    <PricingComponent darkMode={darkMode} />
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/plan/:planType"
                element={
                  <AuthenticationGuard>
                    <Elements stripe={stripePromise}>
                      <PlanDetails darkMode={darkMode} />
                    </Elements>
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/premium-features/*"
                element={
                  <AuthenticationGuard>
                    <SubscriptionGuard
                      requiredPlanTypes={["pro", "business", "quantum"]}
                    >
                      <PremiumFeaturesLayout darkMode={darkMode} />
                    </SubscriptionGuard>
                  </AuthenticationGuard>
                }
              />
              <Route
                path="/subscription-success"
                element={
                  <AuthenticationGuard>
                    <SubscriptionGuard
                      requiredPlanTypes={["pro", "business", "quantum"]}
                    >
                      <SubscriptionSuccess darkMode={darkMode} />
                    </SubscriptionGuard>
                  </AuthenticationGuard>
                }
              />
            </Routes>
          </ForumLayout>
        </main>
      </div>
    </div>
  );
};

export default Forum;
