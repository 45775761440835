import React, { useEffect, useRef, useCallback } from "react";

const SimpleQuantumMoneyAnimation = ({ darkMode, blurStrength = 5 }) => {
  const canvasRef = useRef(null);

  const Particle = useCallback(function Particle(
    x,
    y,
    directionX,
    directionY,
    size,
    color,
  ) {
    this.x = x;
    this.y = y;
    this.directionX = directionX;
    this.directionY = directionY;
    this.size = size;
    this.color = color;
  }, []);

  Particle.prototype.draw = function (ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
    ctx.fillStyle = this.color;
    ctx.fill();
  };

  Particle.prototype.update = function (canvas, mouse) {
    // Move particle
    if (this.x > canvas.width || this.x < 0) {
      this.directionX = -this.directionX;
    }
    if (this.y > canvas.height || this.y < 0) {
      this.directionY = -this.directionY;
    }
    this.x += this.directionX;
    this.y += this.directionY;

    // Check collision with mouse
    if (mouse.x !== null && mouse.y !== null) {
      const dx = mouse.x - this.x;
      const dy = mouse.y - this.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < mouse.radius + this.size) {
        if (mouse.x < this.x && this.x < canvas.width - this.size * 10) {
          this.x += 5;
        }
        if (mouse.x > this.x && this.x > this.size * 10) {
          this.x -= 5;
        }
        if (mouse.y < this.y && this.y < canvas.height - this.size * 10) {
          this.y += 5;
        }
        if (mouse.y > this.y && this.y > this.size * 10) {
          this.y -= 5;
        }
      }
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;
    let particles = [];
    const mouse = {
      x: null,
      y: null,
      radius: (canvas.height / 80) * (canvas.width / 80),
    };

    const createParticles = () => {
      particles = [];
      let numberOfParticles = (canvas.height * canvas.width) / 9000;
      for (let i = 0; i < numberOfParticles; i++) {
        const size = Math.random() * 5 + 1;
        const x = Math.random() * (canvas.width - size * 2) + size * 2;
        const y = Math.random() * (canvas.height - size * 2) + size * 2;
        const directionX = Math.random() * 1 - 0.5;
        const directionY = Math.random() * 1 - 0.5;
        const color = darkMode ? "#00FFFF" : "#3B82F6"; // Cyan for dark mode, blue for light mode

        particles.push(new Particle(x, y, directionX, directionY, size, color));
      }
    };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      mouse.radius = (canvas.height / 80) * (canvas.width / 80);
      createParticles();
    };

    const debounceResize = () => {
      clearTimeout(resizeCanvas.timer);
      resizeCanvas.timer = setTimeout(resizeCanvas, 200);
    };

    const handleMouseMove = (event) => {
      mouse.x = event.x;
      mouse.y = event.y;
    };

    const handleMouseOut = () => {
      mouse.x = null;
      mouse.y = null;
    };

    window.addEventListener("resize", debounceResize);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseout", handleMouseOut);

    resizeCanvas();

    const connectParticles = () => {
      const maxDistance = (canvas.width / 7) * (canvas.height / 7);
      for (let a = 0; a < particles.length; a++) {
        for (let b = a; b < particles.length; b++) {
          const dx = particles[a].x - particles[b].x;
          const dy = particles[a].y - particles[b].y;
          const distance = dx * dx + dy * dy;

          if (distance < maxDistance) {
            const opacity = 1 - distance / 20000;
            ctx.strokeStyle = darkMode
              ? `rgba(230, 232, 230, ${opacity})`
              : `rgba(0, 32, 0, ${opacity})`;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(particles[a].x, particles[a].y);
            ctx.lineTo(particles[b].x, particles[b].y);
            ctx.stroke();
          }
        }
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update(canvas, mouse);
        particle.draw(ctx);
      });
      connectParticles();
      animationFrameId = requestAnimationFrame(animate);
    };

    // Start the animation
    animate();

    return () => {
      window.removeEventListener("resize", debounceResize);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseout", handleMouseOut);
      cancelAnimationFrame(animationFrameId);
    };
  }, [darkMode, Particle]);

  return (
    <div className="fixed top-0 left-0 w-full h-full" style={{ zIndex: -1 }}>
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          backdropFilter: `blur(${blurStrength}px)`,
          WebkitBackdropFilter: `blur(${blurStrength}px)`,
          backgroundColor: darkMode
            ? "rgba(0, 32, 0, 0.7)"
            : "rgba(240, 255, 240, 0.5)",
        }}
      />
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />
    </div>
  );
};

export default SimpleQuantumMoneyAnimation;
