import React, { useState } from "react";
import { useTradeConditionData } from "../../../../hooks/useTradeConditionData";
import {
  Clock,
  DollarSign,
  BarChart2,
  Activity,
  Building2,
  Search,
  TrendingUp,
  ArrowUp,
  ArrowDown,
  Info,
} from "lucide-react";
import UsStockTradeConditionsDisplayComponent from "./UsStockTradeConditionsDisplayComponent";

const UsStockTradeConditionsComponent = ({ tradeData, darkMode }) => {
  const { tradeConditions, updateFilterText } = useTradeConditionData();
  const [searchText, setSearchText] = useState("");

  if (!tradeData || tradeData.length === 0) return null;

  const latestTrade = tradeData[tradeData.length - 1];
  const previousTrade = tradeData[tradeData.length - 2];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  };

  const getConditionDetails = (conditionCode) => {
    const code = Number(conditionCode);
    const condition = tradeConditions[code] || {
      name: "Unknown",
      description: "No description available",
      icon: "HelpCircle",
    };
    return { ...condition, code };
  };

  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    updateFilterText(text);
  };

  const priceChange = latestTrade?.p - previousTrade?.p;
  const priceChangePercent = (priceChange / previousTrade?.p) * 100;
  const isPositiveChange = priceChange >= 0;

  return (
    <div
      className={`w-full rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm ${
        darkMode ? "bg-gray-900/95" : "bg-white/95"
      }`}
    >
      {/* Header */}
      <div className="px-6 py-6 border-b border-gray-200/10">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <div
              className={`p-3 rounded-2xl ${
                darkMode ? "bg-blue-500/20" : "bg-blue-100"
              }`}
            >
              <Building2
                className={`w-7 h-7 ${
                  darkMode ? "text-blue-400" : "text-blue-600"
                }`}
              />
            </div>
            <div>
              <h2
                className={`text-2xl font-bold ${
                  darkMode ? "text-white" : "text-gray-800"
                }`}
              >
                Latest Trade Details
              </h2>
              <div className="flex items-center gap-2 mt-1">
                <span
                  className={`text-lg font-medium ${
                    darkMode ? "text-blue-400" : "text-blue-600"
                  }`}
                >
                  {latestTrade?.s}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm font-medium ${
              darkMode
                ? "bg-gray-800/50 text-gray-300"
                : "bg-gray-100 text-gray-600"
            }`}
          >
            <Clock className="w-4 h-4" />
            <span>{formatTimestamp(latestTrade?.t)}</span>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-2 space-y-6">
        {/* Price Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Price Card */}
          <div
            className={`p-6 rounded-2xl transition-transform duration-200 hover:scale-102 ${
              darkMode ? "bg-blue-900/30" : "bg-blue-50"
            } border-l-4 border-blue-500`}
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <span
                  className={`text-sm font-medium uppercase tracking-wider ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Last Price
                </span>
                <div
                  className={`text-3xl font-bold mt-1 ${
                    darkMode ? "text-white" : "text-gray-800"
                  }`}
                >
                  ${formatNumber(latestTrade?.p)}
                </div>
              </div>
              <div
                className={`p-2 rounded-xl ${
                  darkMode ? "bg-blue-800/50" : "bg-blue-100"
                }`}
              >
                <DollarSign
                  className={`w-6 h-6 ${
                    darkMode ? "text-blue-400" : "text-blue-600"
                  }`}
                />
              </div>
            </div>
            <div
              className={`flex items-center gap-2 text-lg font-semibold ${
                isPositiveChange ? "text-green-500" : "text-red-500"
              }`}
            >
              {isPositiveChange ? (
                <ArrowUp className="w-5 h-5" />
              ) : (
                <ArrowDown className="w-5 h-5" />
              )}
              <span>{formatNumber(Math.abs(priceChange))}</span>
              <span>({Math.abs(priceChangePercent).toFixed(2)}%)</span>
            </div>
          </div>

          {/* Volume Card */}
          <div
            className={`p-6 rounded-2xl transition-transform duration-200 hover:scale-102 ${
              darkMode ? "bg-purple-900/30" : "bg-purple-50"
            } border-l-4 border-purple-500`}
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <span
                  className={`text-sm font-medium uppercase tracking-wider ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Share Volume
                </span>
                <div
                  className={`text-3xl font-bold mt-1 ${
                    darkMode ? "text-white" : "text-gray-800"
                  }`}
                >
                  {latestTrade?.v?.toLocaleString()}
                </div>
              </div>
              <div
                className={`p-2 rounded-xl ${
                  darkMode ? "bg-purple-800/50" : "bg-purple-100"
                }`}
              >
                <BarChart2
                  className={`w-6 h-6 ${
                    darkMode ? "text-purple-400" : "text-purple-600"
                  }`}
                />
              </div>
            </div>
            <div
              className={`text-sm font-medium uppercase tracking-wider ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Last traded size
            </div>
          </div>

          {/* Status Card */}
          <div
            className={`p-6 rounded-2xl transition-transform duration-200 hover:scale-102 ${
              darkMode ? "bg-green-900/30" : "bg-green-50"
            } border-l-4 border-green-500`}
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <span
                  className={`text-sm font-medium uppercase tracking-wider ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Market Status
                </span>
                <div
                  className={`text-3xl font-bold mt-1 ${
                    darkMode ? "text-white" : "text-gray-800"
                  }`}
                >
                  {latestTrade?.ms}
                </div>
              </div>
              <div
                className={`p-2 rounded-xl ${
                  darkMode ? "bg-green-800/50" : "bg-green-100"
                }`}
              >
                <Activity
                  className={`w-6 h-6 ${
                    darkMode ? "text-green-400" : "text-green-600"
                  }`}
                />
              </div>
            </div>
            <div
              className={`flex items-center gap-2 text-sm font-medium ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></div>
              Regular Trading Hours
            </div>
          </div>
        </div>

        {/* Trade Statistics */}
        <div
          className={`p-6 rounded-2xl ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <h3
            className={`text-xl font-bold mb-6 ${
              darkMode ? "text-white" : "text-gray-800"
            }`}
          >
            Trade Statistics
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {[
              {
                label: "Trade Time",
                value: formatTimestamp(latestTrade?.t),
                icon: Clock,
                color: "text-blue-500",
                bgColor: darkMode ? "bg-blue-900/30" : "bg-blue-50",
              },
              {
                label: "Price Level",
                value: `$${formatNumber(latestTrade?.p)}`,
                icon: DollarSign,
                color: "text-indigo-500",
                bgColor: darkMode ? "bg-indigo-900/30" : "bg-indigo-50",
              },
              {
                label: "Share Volume",
                value: latestTrade?.v?.toLocaleString(),
                icon: BarChart2,
                color: "text-purple-500",
                bgColor: darkMode ? "bg-purple-900/30" : "bg-purple-50",
              },
              {
                label: "Today's Change",
                value: `${isPositiveChange ? "+" : "-"}${Math.abs(priceChangePercent).toFixed(2)}%`,
                icon: TrendingUp,
                color: isPositiveChange ? "text-green-500" : "text-red-500",
                bgColor: darkMode
                  ? isPositiveChange
                    ? "bg-green-900/30"
                    : "bg-red-900/30"
                  : isPositiveChange
                    ? "bg-green-50"
                    : "bg-red-50",
              },
            ].map((stat, index) => (
              <div
                key={index}
                className={`flex items-center p-4 rounded-xl transition-transform duration-200 hover:scale-102 ${stat.bgColor}`}
              >
                <div className={`p-2 rounded-xl ${stat.color} bg-opacity-20`}>
                  <stat.icon className={`w-5 h-5 ${stat.color}`} />
                </div>
                <div className="ml-4">
                  <p
                    className={`text-sm font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {stat.label}
                  </p>
                  <p className={`text-lg font-bold mt-1 ${stat.color}`}>
                    {stat.value}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Trade Conditions Display */}
        <div
          className={`p-6 rounded-2xl ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
            <h3
              className={`text-xl font-bold ${
                darkMode ? "text-white" : "text-gray-800"
              }`}
            >
              Trade Conditions
            </h3>
            <div className="relative">
              <Search
                className={`absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                }`}
              />
              <input
                type="text"
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search conditions..."
                className={`w-full sm:w-64 pl-10 pr-4 py-2 rounded-xl text-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  darkMode
                    ? "bg-gray-900/50 text-white placeholder-gray-400"
                    : "bg-white text-gray-900 placeholder-gray-500"
                }`}
              />
            </div>
          </div>

          <UsStockTradeConditionsDisplayComponent
            latestTrade={latestTrade}
            darkMode={darkMode}
            searchText={searchText}
            handleSearchChange={handleSearchChange}
            getConditionDetails={getConditionDetails}
            formatTimestamp={formatTimestamp}
          />
        </div>

        {/* Information Footer */}
        <div
          className={`flex items-start gap-4 p-6 rounded-2xl ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <div
            className={`p-2 rounded-xl ${
              darkMode ? "bg-gray-700" : "bg-gray-200"
            }`}
          >
            <Info
              className={`w-5 h-5 ${
                darkMode ? "text-gray-300" : "text-gray-600"
              }`}
            />
          </div>
          <p
            className={`text-sm leading-relaxed ${
              darkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Trade conditions indicate special circumstances or qualifying
            conditions that apply to this trade. The conditions help market
            participants understand the context and validity of the trade.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UsStockTradeConditionsComponent;
