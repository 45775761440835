import React from "react";
import * as LucideIcons from "lucide-react";

const UsStockTradeConditionsDisplayComponent = ({
  latestTrade,
  darkMode,
  searchText,
  handleSearchChange,
  getConditionDetails,
  formatTimestamp,
}) => {
  if (!latestTrade?.c || latestTrade.c.length === 0) return null;

  return (
    <div className="w-full max-w-6xl mx-auto space-y-8">
      {/* Header with modern styling */}
      <div
        className={`p-6 rounded-xl backdrop-blur-sm border-b transition-all duration-300 ${
          darkMode
            ? "bg-gray-900/95 border-gray-800"
            : "bg-white/95 border-gray-200"
        }`}
      >
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-6">
          <div className="flex items-center gap-4">
            <div
              className={`p-3 rounded-xl transform transition-all duration-300 hover:scale-105 ${
                darkMode ? "bg-blue-600/20" : "bg-blue-100"
              }`}
            >
              <LucideIcons.BarChart2
                className={`w-8 h-8 transition-colors duration-300 ${
                  darkMode ? "text-blue-400" : "text-blue-600"
                }`}
              />
            </div>
            <h3
              className={`text-3xl font-bold tracking-tight transition-colors duration-300 ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Trade Conditions
            </h3>
          </div>
        </div>
      </div>

      {/* Conditions List with enhanced styling */}
      <div className="space-y-4">
        {latestTrade.c
          .filter((condition) => {
            const { code, name, description } = getConditionDetails(condition);
            const searchLower = searchText.toLowerCase();
            return (
              code.toString().includes(searchLower) ||
              name.toLowerCase().includes(searchLower) ||
              description.toLowerCase().includes(searchLower)
            );
          })
          .map((condition, index) => {
            const { name, description, icon, code } =
              getConditionDetails(condition);
            const IconComponent = LucideIcons[icon] || LucideIcons.HelpCircle;

            return (
              <div
                key={index}
                className={`p-6 rounded-xl transition-all duration-300 transform hover:scale-102 hover:shadow-lg
                  ${darkMode ? "bg-gray-800/50" : "bg-gray-50"}`}
              >
                <div className="flex items-start gap-4">
                  <div
                    className={`p-3 rounded-xl transition-all duration-300 
                      ${
                        darkMode
                          ? "bg-blue-900/30 text-blue-400"
                          : "bg-blue-100 text-blue-600"
                      }`}
                  >
                    <IconComponent className="w-6 h-6" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex flex-wrap items-center gap-3">
                      <h4
                        className={`text-lg font-bold transition-colors duration-300 ${
                          darkMode ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {name}
                      </h4>
                      <span
                        className={`px-3 py-1 text-xs font-medium rounded-full transition-colors duration-300
                          ${
                            darkMode
                              ? "bg-gray-700/70 text-gray-300"
                              : "bg-gray-200 text-gray-700"
                          }`}
                      >
                        Code: {code}
                      </span>
                    </div>
                    <p
                      className={`mt-2 text-base leading-relaxed transition-colors duration-300 ${
                        darkMode ? "text-gray-400" : "text-gray-600"
                      }`}
                    >
                      {description}
                    </p>
                    <div className="mt-3 flex items-center gap-2">
                      <LucideIcons.Clock
                        className={`w-4 h-4 transition-colors duration-300 ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      />
                      <p
                        className={`text-sm transition-colors duration-300 ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      >
                        {formatTimestamp(latestTrade?.t)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {/* Empty state when no results found */}
        {latestTrade.c.filter((condition) => {
          const { code, name, description } = getConditionDetails(condition);
          const searchLower = searchText.toLowerCase();
          return (
            code.toString().includes(searchLower) ||
            name.toLowerCase().includes(searchLower) ||
            description.toLowerCase().includes(searchLower)
          );
        }).length === 0 && (
          <div
            className={`p-8 rounded-xl text-center transition-all duration-300 ${
              darkMode ? "bg-gray-800/50" : "bg-gray-50"
            }`}
          >
            <LucideIcons.SearchX
              className={`w-12 h-12 mx-auto mb-4 transition-colors duration-300 ${
                darkMode ? "text-gray-600" : "text-gray-400"
              }`}
            />
            <h4
              className={`text-lg font-semibold mb-2 transition-colors duration-300 ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              No conditions found
            </h4>
            <p
              className={`text-base transition-colors duration-300 ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Try adjusting your search terms
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsStockTradeConditionsDisplayComponent;
