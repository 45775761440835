import { AlertCircle } from "lucide-react";

const ErrorDisplay = ({ error }) => {
  return (
    <div className="w-full max-w-4xl mx-auto p-4 rounded-lg bg-red-50 border border-red-200">
      <div className="flex items-center space-x-2">
        <AlertCircle className="w-5 h-5 text-red-500" />
        <h3 className="text-lg font-semibold text-red-800">Error</h3>
      </div>
      <p className="mt-2 text-red-700">{error}</p>
    </div>
  );
};

export default ErrorDisplay;
