import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import useResponsive from "../../hooks/useResponsive";
import useScrollToSection from "../../hooks/useScrollToSection";
import ThemeToggleText from "../menu/ThemeToggleText";
import MobileMenuToggle from "../menu/MobileMenuToggle";
import QuantumMoneyCodeLogoText from "../menu/QuantumMoneyCodeLogoText";
import { getNavItems } from "./navigationConstants";
import NavigationItems from "./NavigationItems";
import { AlertsBellIcon } from "./AlertsDropdown";
import ProfileLink from "../profile/ProfileLink";

const Header = ({ isAuthenticated, onLogout, user, onToggleAlerts }) => {
  const { darkMode, toggleTheme } = useTheme();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const canvasRef = useRef(null);

  useScrollToSection(location);

  const toggleMobileMenu = useCallback(
    () => setIsMobileMenuOpen((prev) => !prev),
    [],
  );

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const particleCount = 120;
    const particleSpeedFactor = 1;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = 200;
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    const particles = [];

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 0.5;
        this.speedX = (Math.random() - 0.5) * 2 * particleSpeedFactor;
        this.speedY = (Math.random() - 0.5) * 2 * particleSpeedFactor;
        // Updated colors to be more subtle
        this.color =
          Math.random() < 0.5
            ? "rgba(218, 165, 52, 0.25)"
            : "rgba(218, 165, 32, 0.25)";
        this.alpha = Math.random() * 0.5 + 0.5;
        this.angle = Math.random() * Math.PI * 2;
        this.spin = (Math.random() - 0.5) * 0.1;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        this.angle += this.spin;

        if (this.x < 0 || this.x > canvas.width) this.speedX *= -1;
        if (this.y < 0 || this.y > canvas.height) this.speedY *= -1;

        this.alpha = Math.sin(this.angle) * 0.25 + 0.75;
      }

      draw() {
        ctx.save();
        ctx.globalAlpha = this.alpha;
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();

        // Draw "quantum" trails
        ctx.strokeStyle = this.color;
        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(this.x, this.y);
        ctx.lineTo(this.x - this.speedX * 5, this.y - this.speedY * 5);
        ctx.stroke();

        ctx.restore();
      }
    }

    const init = () => {
      particles.length = 0; // Clear existing particles
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle());
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      animationFrameId = requestAnimationFrame(animate);
    };

    init();
    animate();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const logoProps = useMemo(
    () => ({
      qProps: {
        fontFamily: "Quantum",
        fontWeight: "300",
        fontSize: isSmallScreen ? "1rem" : isMediumScreen ? "1.5rem" : "2rem",
        style: {
          color: darkMode ? "#ffd700" : "#b8860b",
          textShadow: darkMode
            ? "0 0 5px rgba(255, 223, 0, 0.5)"
            : "0 0 5px rgba(184, 134, 11, 0.5)",
        },
      },
      suffixFontSize: isSmallScreen
        ? "0.5rem"
        : isMediumScreen
          ? "0.8rem"
          : "1rem",
    }),
    [isSmallScreen, isMediumScreen, darkMode],
  );

  const navItems = useMemo(
    () =>
      getNavItems({
        isAboutRoute: location.pathname === "/about",
        isAffiliatesRoute: location.pathname === "/affiliates",
        isForumRoute: location.pathname.includes("/forum"),
        isAuthenticated,
        user,
        onLogout,
      }),
    [location.pathname, isAuthenticated, user, onLogout],
  );

  const headerClasses = useMemo(() => {
    const baseClasses = `body-font ${
      darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-100 text-gray-900"
    } shadow-sm fixed w-full z-10 overflow-hidden`;
    return isSmallScreen
      ? baseClasses
      : `${baseClasses} transition-all duration-300`;
  }, [darkMode, isSmallScreen]);

  return (
    <header className={headerClasses}>
      <canvas
        ref={canvasRef}
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
      />
      <div className="relative z-10 container max-w-full mx-auto py-2 px-4">
        <div className="flex items-center justify-between h-15">
          <Link to="/" className="flex items-center logo-glow">
            <QuantumMoneyCodeLogoText {...logoProps} />
          </Link>

          <div className="flex items-center space-x-4">
            <nav className="hidden xl:flex items-center space-x-4">
              <NavigationItems
                navItems={navItems}
                user={user}
                onLogout={onLogout}
              />
              {isAuthenticated && (
                <button
                  onClick={onToggleAlerts}
                  className="focus:outline-none relative z-60 group"
                  aria-label="Notifications"
                >
                  <AlertsBellIcon darkMode={darkMode} />
                </button>
              )}
              <ThemeToggleText darkMode={darkMode} toggleTheme={toggleTheme} />
            </nav>
            <div className="xl:hidden">
              <MobileMenuToggle
                isMobileMenuOpen={isMobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}
                darkMode={darkMode}
              />
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`xl:hidden overflow-hidden transition-max-height duration-500 ease-in-out ${
            isMobileMenuOpen ? "max-h-[400px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <nav className="grid grid-cols-2 gap-2 py-2">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                className={`flex flex-col items-center justify-center p-2 ${
                  darkMode ? "hover:bg-gray-800" : "hover:bg-gray-200"
                } rounded text-center`}
                onClick={item.name === "Logout" ? onLogout : item.onClick}
              >
                <span className="text-xl mb-1">
                  {typeof item.icon === "function" ? item.icon() : item.icon}
                </span>
                <span className="text-xs">{item.name}</span>
              </Link>
            ))}
            {isAuthenticated && (
              <button
                onClick={onToggleAlerts}
                className={`flex flex-col items-center justify-center p-2 ${
                  darkMode ? "hover:bg-gray-800" : "hover:bg-gray-200"
                } rounded text-center`}
                aria-label="Notifications"
              >
                <AlertsBellIcon darkMode={darkMode} className="text-xl mb-1" />
                <span className="text-xs">Notifications</span>
              </button>
            )}
            <div
              className={`flex flex-col items-center justify-center p-2 ${
                darkMode ? "hover:bg-gray-800" : "hover:bg-gray-200"
              } rounded text-center`}
            >
              <ThemeToggleText darkMode={darkMode} toggleTheme={toggleTheme} />
            </div>
            <div
              className={`flex flex-col items-center justify-center p-2 ${
                darkMode ? "hover:bg-gray-800" : "hover:bg-gray-200"
              } rounded text-center`}
            >
              <ProfileLink />
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
