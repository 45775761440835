import React from "react";
import {
  VictoryPie,
  VictoryChart,
  VictoryStack,
  VictoryArea,
  VictoryAxis,
  VictoryLegend,
  VictoryTooltip,
  VictoryTheme,
  VictoryContainer,
} from "victory";

const CashFlowCompositionChart = ({ data, darkMode }) => {
  const colors = {
    operating: "#10B981",
    investing: "#3B82F6",
    financing: "#F59E0B",
    operatingNeg: "#DC2626",
    investingNeg: "#7C3AED",
    financingNeg: "#EC4899",
  };

  const baseTheme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        grid: {
          stroke: darkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
        },
        tickLabels: {
          fill: darkMode ? "#fff" : "#000",
          fontSize: 12,
        },
      },
    },
  };

  const formatCurrency = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const formatPercentage = (value, total) => {
    return `${((value / total) * 100).toFixed(1)}%`;
  };

  const latestData = data[data.length - 1];
  const totalValue =
    Math.abs(latestData.operatingCashFlow) +
    Math.abs(latestData.investingCashFlow) +
    Math.abs(latestData.financingCashFlow);

  const pieChartData = [
    {
      x: "Operating",
      y: Math.abs(latestData.operatingCashFlow),
      value: latestData.operatingCashFlow,
      percentage: formatPercentage(
        Math.abs(latestData.operatingCashFlow),
        totalValue,
      ),
    },
    {
      x: "Investing",
      y: Math.abs(latestData.investingCashFlow),
      value: latestData.investingCashFlow,
      percentage: formatPercentage(
        Math.abs(latestData.investingCashFlow),
        totalValue,
      ),
    },
    {
      x: "Financing",
      y: Math.abs(latestData.financingCashFlow),
      value: latestData.financingCashFlow,
      percentage: formatPercentage(
        Math.abs(latestData.financingCashFlow),
        totalValue,
      ),
    },
  ];

  const stackedData = data.map((item) => ({
    date: new Date(item.date),
    operating: Math.max(0, item.operatingCashFlow),
    investing: Math.max(0, item.investingCashFlow),
    financing: Math.max(0, item.financingCashFlow),
    operatingNeg: Math.min(0, item.operatingCashFlow),
    investingNeg: Math.min(0, item.investingCashFlow),
    financingNeg: Math.min(0, item.financingCashFlow),
  }));

  return (
    <div
      className={`w-full rounded-2xl ${
        darkMode ? "bg-gray-900" : "bg-white"
      } shadow-xl overflow-hidden border ${
        darkMode ? "border-gray-800" : "border-gray-100"
      }`}
    >
      {/* Header with modern gradient background */}
      <div
        className={`px-6 py-6 ${
          darkMode
            ? "bg-gradient-to-r from-gray-900 to-gray-800"
            : "bg-gradient-to-r from-gray-50 to-white"
        }`}
      >
        <h2
          className={`text-2xl font-semibold tracking-tight ${
            darkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Cash Flow Composition
        </h2>
        <p
          className={`mt-2 text-sm ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          Analysis of operating, investing, and financing cash flows
        </p>
      </div>

      <div className="p-6 space-y-8">
        {/* Charts Container with improved grid layout */}
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
          {/* Pie Chart Section with glass morphism effect */}
          <div
            className={`relative rounded-2xl p-6 ${
              darkMode
                ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg"
                : "bg-gradient-to-br from-gray-50/50 to-white/50 backdrop-blur-lg"
            } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
          >
            <div className="h-[400px] relative">
              <VictoryPie
                theme={baseTheme}
                containerComponent={<VictoryContainer responsive={true} />}
                height={400}
                width={400}
                padding={{ top: 40, bottom: 40, left: 40, right: 40 }}
                data={pieChartData}
                colorScale={[
                  colors.operating,
                  colors.investing,
                  colors.financing,
                ]}
                innerRadius={100}
                padAngle={2}
                labels={({ datum }) =>
                  `${datum.x}\n${formatCurrency(datum.value)}\n${datum.percentage}`
                }
                labelComponent={
                  <VictoryTooltip
                    flyoutStyle={{
                      fill: darkMode
                        ? "rgba(17, 24, 39, 0.95)"
                        : "rgba(255, 255, 255, 0.95)",
                      stroke: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                      strokeWidth: 1,
                      filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.1))",
                    }}
                    style={{
                      fontSize: 14,
                      fill: darkMode ? "white" : "black",
                      fontFamily: "system-ui",
                    }}
                    cornerRadius={8}
                    pointerLength={10}
                  />
                }
                style={{
                  data: {
                    fillOpacity: 0.9,
                    stroke: darkMode ? "#1F2937" : "#fff",
                    strokeWidth: 2,
                  },
                }}
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onMouseOver: () => ({
                        target: "data",
                        mutation: (props) => ({
                          radius: props.radius + 10,
                          fillOpacity: 1,
                        }),
                      }),
                      onMouseOut: () => ({
                        target: "data",
                        mutation: () => null,
                      }),
                    },
                  },
                ]}
                animate={{ duration: 500, easing: "cubic" }}
              />

              {/* Center Content with improved glass morphism */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div
                  className={`text-center p-6 rounded-xl ${
                    darkMode
                      ? "bg-gray-800/90 backdrop-blur-md border border-gray-700"
                      : "bg-white/90 backdrop-blur-md border border-gray-100"
                  } shadow-lg transform transition-transform duration-300 hover:scale-105`}
                >
                  <p
                    className={`text-lg font-medium ${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    Total Flow
                  </p>
                  <p
                    className={`text-3xl font-bold mt-1 ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {formatCurrency(totalValue)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Area Chart Section with matching design */}
          <div
            className={`rounded-2xl p-6 ${
              darkMode
                ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg"
                : "bg-gradient-to-br from-gray-50/50 to-white/50 backdrop-blur-lg"
            } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
          >
            <div className="h-[400px]">
              <VictoryChart
                theme={baseTheme}
                height={400}
                width={500}
                padding={{ top: 60, right: 50, bottom: 50, left: 70 }}
                scale={{ x: "time" }}
                containerComponent={<VictoryContainer responsive={true} />}
              >
                <VictoryLegend
                  x={50}
                  y={0}
                  orientation="horizontal"
                  gutter={20}
                  style={{
                    labels: {
                      fontSize: 12,
                      fontFamily: "system-ui",
                    },
                    data: { opacity: 0.8 },
                  }}
                  data={[
                    {
                      name: "Operating (+)",
                      symbol: { fill: colors.operating },
                    },
                    {
                      name: "Investing (+)",
                      symbol: { fill: colors.investing },
                    },
                    {
                      name: "Financing (+)",
                      symbol: { fill: colors.financing },
                    },
                  ]}
                />

                <VictoryStack>
                  {[
                    { key: "operating", color: colors.operating },
                    { key: "investing", color: colors.investing },
                    { key: "financing", color: colors.financing },
                  ].map(({ key, color }) => (
                    <VictoryArea
                      key={key}
                      data={stackedData}
                      x="date"
                      y={key}
                      style={{
                        data: {
                          fill: color,
                          fillOpacity: 0.7,
                        },
                      }}
                    />
                  ))}
                </VictoryStack>

                <VictoryStack>
                  {[
                    { key: "operatingNeg", color: colors.operatingNeg },
                    { key: "investingNeg", color: colors.investingNeg },
                    { key: "financingNeg", color: colors.financingNeg },
                  ].map(({ key, color }) => (
                    <VictoryArea
                      key={key}
                      data={stackedData}
                      x="date"
                      y={key}
                      style={{
                        data: {
                          fill: color,
                          fillOpacity: 0.7,
                        },
                      }}
                    />
                  ))}
                </VictoryStack>

                <VictoryAxis
                  tickFormat={(date) => new Date(date).getFullYear()}
                  tickCount={4}
                  style={{
                    tickLabels: {
                      fontSize: 12,
                      padding: 8,
                      fontFamily: "system-ui",
                    },
                    grid: { stroke: "none" },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={formatCurrency}
                  style={{
                    tickLabels: {
                      fontSize: 12,
                      padding: 8,
                      fontFamily: "system-ui",
                    },
                    grid: { strokeWidth: 0.5, strokeDasharray: "4, 4" },
                  }}
                />
              </VictoryChart>
            </div>
          </div>
        </div>

        {/* Enhanced Metrics Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              label: "Operating",
              value: pieChartData[0].percentage,
              color: colors.operating,
            },
            {
              label: "Investing",
              value: pieChartData[1].percentage,
              color: colors.investing,
            },
            {
              label: "Financing",
              value: pieChartData[2].percentage,
              color: colors.financing,
            },
          ].map((metric, index) => (
            <div
              key={index}
              className={`p-6 rounded-2xl transition-all duration-300 transform hover:scale-102 ${
                darkMode
                  ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50"
                  : "bg-gradient-to-br from-gray-50/50 to-white/50"
              } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
              style={{
                borderLeft: `4px solid ${metric.color}`,
              }}
            >
              <div className="flex flex-col">
                <p
                  className={`text-sm font-medium ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {metric.label} Ratio
                </p>
                <p
                  className={`text-3xl font-bold mt-2`}
                  style={{ color: metric.color }}
                >
                  {metric.value}
                </p>
                <div className="mt-2 w-full h-1 rounded-full bg-gray-200 dark:bg-gray-700">
                  <div
                    className="h-1 rounded-full transition-all duration-300"
                    style={{
                      width: metric.value,
                      backgroundColor: metric.color,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CashFlowCompositionChart;
