import React, { useState, useEffect } from "react";
import { FaCamera } from "react-icons/fa";

const ProfileImageUploader = ({
  profileImageUrl,
  darkMode,
  accentClass,
  handleImageChange,
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      handleImageChange(e);
    }
  };

  return (
    <div className="text-center">
      <label htmlFor="profileImage" className="cursor-pointer">
        <div
          className={`w-32 h-32 sm:w-40 sm:h-40 mx-auto rounded-full overflow-hidden border-4 ${accentClass} transition-transform duration-300 transform hover:scale-105`}
        >
          {previewUrl || profileImageUrl ? (
            <img
              src={previewUrl || profileImageUrl}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          ) : (
            <div
              className={`w-full h-full flex items-center justify-center ${
                darkMode ? "bg-gray-800" : "bg-gray-200"
              }`}
            >
              <FaCamera className={`text-4xl sm:text-5xl ${accentClass}`} />
            </div>
          )}
        </div>
        <p className={`mt-2 sm:mt-3 text-xs sm:text-sm ${accentClass}`}>
          {previewUrl ? "Change Preview" : "Change Profile Picture"}
        </p>
      </label>
      <input
        id="profileImage"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};

export default ProfileImageUploader;
