// src/hooks/useFacebookSDK.js

import { useState, useEffect } from "react";

function useFacebookSDK() {
  const [isFbSdkLoaded, setIsFbSdkLoaded] = useState(false);

  useEffect(() => {
    if (window.FB) {
      setIsFbSdkLoaded(true);
      return;
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
      setIsFbSdkLoaded(true);
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.async = true;
      js.defer = true;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return isFbSdkLoaded;
}

export default useFacebookSDK;
