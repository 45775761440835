import React, { useState } from "react";
import { FaQuoteLeft, FaChevronDown, FaChevronUp } from "react-icons/fa";

const BioSection = ({ bio, darkMode }) => {
  const [bioExpanded, setBioExpanded] = useState(false);
  const truncatedBio = bio.slice(0, 100) + (bio.length > 100 ? "..." : "");

  return (
    <div
      className={`${
        darkMode ? "bg-gray-800" : "bg-white"
      } rounded-lg p-3 sm:p-4 mt-3 shadow-md max-w-2xl mx-auto`}
    >
      <div className="flex justify-between items-start mb-2">
        <FaQuoteLeft
          className={`${
            darkMode ? "text-green-400" : "text-green-600"
          } text-lg flex-shrink-0`}
        />
        <button
          onClick={() => setBioExpanded(!bioExpanded)}
          className={`${
            darkMode ? "text-green-400" : "text-green-600"
          } hover:text-green-500 transition-colors duration-200`}
        >
          {bioExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <div className={bioExpanded ? "max-h-48 overflow-y-auto" : ""}>
        <p
          className={`${
            darkMode ? "text-white" : "text-green-800"
          } text-sm sm:text-base italic`}
        >
          {bioExpanded ? bio : truncatedBio}
        </p>
      </div>
      {!bioExpanded && bio.length > 100 && (
        <button
          onClick={() => setBioExpanded(true)}
          className={`${
            darkMode ? "text-green-400" : "text-green-600"
          } text-sm mt-2 hover:underline`}
        >
          Read more
        </button>
      )}
    </div>
  );
};

export default BioSection;
