import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import io from "socket.io-client";
import { getApiUrl } from "../config/api";

export const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasUnreadAlerts, setHasUnreadAlerts] = useState(false);
  const socketRef = useRef(null);

  const fetchAlerts = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(`${getApiUrl()}/api/alerts/latest?limit=5`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch alerts");
      }

      const fetchedAlerts = await response.json();
      setAlerts(fetchedAlerts);
      setHasUnreadAlerts(fetchedAlerts.some((alert) => !alert.isRead));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      socketRef.current = io(`${getApiUrl()}/alerts`, {
        auth: { token },
      });

      socketRef.current.on("connect", () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.id) {
          socketRef.current.emit("join alerts", user.id);
        }
      });

      socketRef.current.on("new alert", (alert) => {
        setAlerts((prevAlerts) => [alert, ...prevAlerts]);
        setHasUnreadAlerts(true);
        fetchAlerts();
      });

      socketRef.current.on("alert updated", (updatedAlert) => {
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) =>
            alert.id === updatedAlert.id ? updatedAlert : alert,
          ),
        );
      });

      socketRef.current.on("alert deleted", ({ alertId }) => {
        setAlerts((prevAlerts) =>
          prevAlerts.filter((alert) => alert.id !== alertId),
        );
      });

      fetchAlerts();

      // Set up periodic refresh (every 3 minutes)
      const intervalId = setInterval(fetchAlerts, 3 * 60 * 1000);

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
        clearInterval(intervalId); // Clean up the interval on unmount
      };
    }
  }, [fetchAlerts]);

  const markAlertAsRead = useCallback(
    async (alertId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token found");

        const response = await fetch(
          `${getApiUrl()}/api/alerts/${alertId}/read`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) throw new Error("Failed to mark alert as read");

        const updatedAlert = await response.json();
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) =>
            alert.id === alertId ? updatedAlert : alert,
          ),
        );

        if (socketRef.current) {
          socketRef.current.emit("mark alert as read", { alertId });
        }

        setHasUnreadAlerts(
          alerts.some((alert) => alert.id !== alertId && !alert.isRead),
        );
      } catch (err) {
        setError(err.message);
      }
    },
    [alerts],
  );

  const markAllAlertsAsRead = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const unreadAlertIds = alerts
        .filter((alert) => !alert.isRead)
        .map((alert) => alert.id);
      if (unreadAlertIds.length === 0) return;

      const response = await fetch(`${getApiUrl()}/api/alerts/read`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ alertIds: unreadAlertIds }),
      });

      if (!response.ok) throw new Error("Failed to mark all alerts as read");

      setAlerts((prevAlerts) =>
        prevAlerts.map((alert) => ({ ...alert, isRead: true })),
      );
      setHasUnreadAlerts(false);

      if (socketRef.current) {
        socketRef.current.emit("mark all alerts as read", {
          alertIds: unreadAlertIds,
        });
      }
    } catch (err) {
      setError(err.message);
    }
  }, [alerts]);

  const deleteAlert = useCallback(
    async (alertId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token found");

        const response = await fetch(`${getApiUrl()}/api/alerts/${alertId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to delete alert");

        setAlerts((prevAlerts) =>
          prevAlerts.filter((alert) => alert.id !== alertId),
        );

        if (socketRef.current) {
          socketRef.current.emit("delete alert", { alertId });
        }

        setHasUnreadAlerts(
          alerts.some((alert) => alert.id !== alertId && !alert.isRead),
        );
      } catch (err) {
        setError(err.message);
      }
    },
    [alerts],
  );

  const refreshAlerts = useCallback(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  const resetAlerts = useCallback(() => {
    setAlerts([]);
    setHasUnreadAlerts(false);
  }, []);

  const value = {
    alerts,
    loading,
    error,
    hasUnreadAlerts,
    markAlertAsRead,
    markAllAlertsAsRead,
    deleteAlert,
    refreshAlerts,
    resetAlerts,
  };

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
};

export const useAlerts = () => {
  const context = useContext(AlertsContext);
  if (!context) {
    throw new Error("useAlerts must be used within an AlertsProvider");
  }
  return context;
};
