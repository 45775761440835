// src/AuthContext.js

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAlerts } from "./AlertsContext";
import {
  getGoogleToken,
  getFacebookToken,
  getMicrosoftToken,
} from "../config/socialAuth";
import { getApiUrl } from "../config/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token"),
  );

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);

  const { refreshAlerts, resetAlerts } = useAlerts();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const savedUser = localStorage.getItem("user");

    if (token && savedUser) {
      setIsAuthenticated(true);
      setUser(JSON.parse(savedUser));
      refreshAlerts();
    }
  }, [refreshAlerts]);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setUser(null);
    resetAlerts();
    if (window.resetProfile) {
      window.resetProfile();
    }
    navigate("/forum");
    window.dispatchEvent(new Event("userLoggedOut"));
  }, [navigate, resetAlerts]);

  const handleAuthResponse = async (response) => {
    setLoading(true);
    try {
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        setIsAuthenticated(true);
        setUser(data.user);

        refreshAlerts();

        return data.user;
      } else {
        throw new Error(data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const register = async (username, email, password) => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}/api/users/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, email, password }),
      });
      const data = await response.json();
      if (response.ok) {
        navigate("forum/registration-success", {
          state: { email: email, message: data.message },
        });
      } else {
        throw new Error(data.message || "Registration failed");
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}/api/users/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });
      const authenticatedUser = await handleAuthResponse(response);
      navigate("/forum");
      window.dispatchEvent(new Event("userLoggedIn"));
      return authenticatedUser;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const loginWithSocial = async (provider) => {
    setLoading(true);
    try {
      let token;
      if (provider === "google") {
        token = await getGoogleToken();
      } else if (provider === "facebook") {
        token = await getFacebookToken();
      } else if (provider === "microsoft") {
        token = await getMicrosoftToken();
      }

      const response = await fetch(
        `${getApiUrl()}/api/users/login/${provider}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ accessToken: token }),
        },
      );
      const authenticatedUser = await handleAuthResponse(response);
      navigate("/forum");
      window.dispatchEvent(new Event("userLoggedIn"));
      return authenticatedUser;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const verifyEmail = async (token) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/verify-email/${token}`,
      );
      const data = await response.json();
      if (response.ok) {
        setVerificationStatus("success");
      } else {
        setVerificationStatus("error");
      }
      return data;
    } catch (error) {
      console.error("Verification error:", error);
      setVerificationStatus("error");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    isAuthenticated,
    user,
    loading,
    verificationStatus,
    register,
    login,
    logout,
    verifyEmail,
    loginWithSocial,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const useAuthContext = useAuth;
