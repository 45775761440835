import { useState, useEffect } from "react";
import { getApiUrl } from "../config/api";

const useCommentLike = (initialComment) => {
  const [comment, setComment] = useState(initialComment);
  const [isLiked, setIsLiked] = useState(initialComment.isLiked || false);
  const [likeCount, setLikeCount] = useState(initialComment.likes?.length || 0);

  useEffect(() => {
    setIsLiked(initialComment.isLiked || false);
    setLikeCount(initialComment.likes?.length || 0);
  }, [initialComment]);

  const toggleLike = async () => {
    try {
      const url = `${getApiUrl()}/api/comments/${comment.id}/toggle-like`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to toggle comment like");
      }

      const result = await response.json();
      setIsLiked(result.isLiked);
      setLikeCount(result.likeCount);
      setComment((prevComment) => ({
        ...prevComment,
        isLiked: result.isLiked,
        likes: { length: result.likeCount },
      }));

      return result;
    } catch (error) {
      console.error("Error toggling comment like:", error);
      return null;
    }
  };

  return { comment, isLiked, likeCount, toggleLike };
};

export default useCommentLike;
