import React from "react";
import { Link } from "react-router-dom";
import { FaImage, FaShareAlt } from "react-icons/fa";

const ActivityCard = ({ type, item, darkMode }) => {
  const textClass = darkMode ? "text-gray-200" : "text-green-800";
  const accentClass = darkMode ? "text-green-400" : "text-green-600";
  const cardBgClass = darkMode ? "bg-gray-800" : "bg-white";
  const hoverClass = darkMode ? "hover:bg-gray-700" : "hover:bg-green-50";

  let title, date, content, link, imageUrls, topic, shareCount;

  switch (type) {
    case "post":
      title = item.title;
      date = item.createdAt;
      content = item.content;
      link = `/forum/post/${item.id}`;
      imageUrls = item.imageUrls;
      topic = item.topic;
      shareCount = item.shareCount;
      break;
    case "comment":
      title = `Comment on "${item.post.title}"`;
      date = item.createdAt;
      content = item.content;
      link = `/forum/post/${item.post.id}`;
      break;
    case "like":
      date = item.createdAt;
      if (item.post) {
        title = `Liked post`;
        content = item.post.title;
        link = `/forum/post/${item.post.id}`;
        imageUrls = item.post.imageUrls;
        topic = item.post.topic;
        shareCount = item.post.shareCount;
      } else if (item.comment) {
        title = `Liked comment`;
        content = item.comment.content;
        link = `/forum/post/${item.comment.post.id}`;
      }
      break;
  }

  return (
    <Link
      to={link}
      className={`block p-2 sm:p-3 rounded-lg ${cardBgClass} ${hoverClass} shadow-md transition-all duration-200`}
    >
      <h3 className={`font-semibold ${textClass} text-sm sm:text-base`}>
        {title}
      </h3>
      <p className={`text-xs mt-1 ${accentClass}`}>
        {new Date(date).toLocaleDateString()}
      </p>
      <p className={`${textClass} mt-1 text-xs sm:text-sm line-clamp-2`}>
        {content}
      </p>
      {(type === "post" || (type === "like" && item.post)) && (
        <div className="mt-2 flex flex-wrap items-center gap-2 text-xs">
          {imageUrls && imageUrls.length > 0 && (
            <span className="flex items-center">
              <FaImage className={`${accentClass} mr-1`} />
              {imageUrls.length}
            </span>
          )}
          <span className={`${accentClass}`}>{topic}</span>
          <span className="flex items-center">
            <FaShareAlt className={`${accentClass} mr-1`} />
            {shareCount}
          </span>
        </div>
      )}
    </Link>
  );
};

export default ActivityCard;
