import React, { useState, useEffect } from "react";
import CommentItem from "./CommentItem";

const CommentList = ({
  paginatedComments,
  darkMode,
  currentlyLoggedInUserId,
  onCommentDeleted,
}) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (Array.isArray(paginatedComments)) {
      setComments(paginatedComments);
    } else {
      console.error("paginatedComments is not an array:", paginatedComments);
      setComments([]);
    }
  }, [paginatedComments]);

  const handleLike = (commentId, isLiked, newLikeCount) => {
    setComments(
      comments.map((comment) =>
        comment.id === commentId
          ? { ...comment, isLiked, likes: { length: newLikeCount } }
          : comment,
      ),
    );
  };

  const handleCommentDeleted = (commentId) => {
    setComments(comments.filter((comment) => comment.id !== commentId));
    onCommentDeleted(commentId);
  };

  return (
    <div className="space-y-4">
      {comments?.map((comment) => (
        <CommentItem
          key={comment.id}
          comment={comment}
          darkMode={darkMode}
          onLike={handleLike}
          onCommentDeleted={handleCommentDeleted}
          currentlyLoggedInUserId={currentlyLoggedInUserId}
        />
      ))}
    </div>
  );
};

export default CommentList;
