import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUserSubscription } from "../../../contexts/UserSubscriptionContext";

const SubscriptionGuard = ({ requiredPlanTypes = [], children }) => {
  const { fetchCurrentUserSubscription, currentSubscription, loading, error } =
    useUserSubscription();

  useEffect(() => {
    fetchCurrentUserSubscription();
  }, [fetchCurrentUserSubscription]);

  if (loading) {
    return <div>Loading subscription information...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const isValidSubscription = (subscription) => {
    if (!subscription || subscription.status !== "active") return false;

    const userPlanType = subscription.planType?.toLowerCase();
    const requiredPlanTypesLower = requiredPlanTypes.map((type) =>
      type?.toLowerCase(),
    );
    return requiredPlanTypesLower.includes(userPlanType);
  };

  if (!isValidSubscription(currentSubscription)) {
    const redirectPath = currentSubscription ? "/forum/pricing" : "/forum";
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default SubscriptionGuard;
