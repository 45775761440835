// TechnicalIndicators.jsx
import React from "react";

const TechnicalIndicators = ({
  darkMode,
  symbol,
  showEMA,
  setShowEMA,
  showSMA,
  setShowSMA,
  showBollingerBands,
  setShowBollingerBands,
  showMACD,
  setShowMACD,
  showRSI,
  setShowRSI,
}) => {
  const isSymbolSelected = !!symbol;

  return (
    <div className="space-y-3 mt-6">
      <label
        className={`text-sm font-medium ${
          darkMode ? "text-gray-300" : "text-gray-700"
        }`}
      >
        Technical Indicators
      </label>
      <div className="flex gap-4 flex-wrap">
        <button
          onClick={() => setShowEMA(!showEMA)}
          disabled={!isSymbolSelected}
          className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
            showEMA
              ? "bg-emerald-500 text-white shadow-lg"
              : darkMode
                ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          } ${!isSymbolSelected ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          EMA
        </button>
        <button
          onClick={() => setShowSMA(!showSMA)}
          disabled={!isSymbolSelected}
          className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
            showSMA
              ? "bg-emerald-500 text-white shadow-lg"
              : darkMode
                ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          } ${!isSymbolSelected ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          SMA
        </button>
        <button
          onClick={() => setShowBollingerBands(!showBollingerBands)}
          disabled={!isSymbolSelected}
          className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
            showBollingerBands
              ? "bg-emerald-500 text-white shadow-lg"
              : darkMode
                ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          } ${!isSymbolSelected ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          Bollinger Bands
        </button>
        <button
          onClick={() => setShowMACD(!showMACD)}
          disabled={!isSymbolSelected}
          className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
            showMACD
              ? "bg-emerald-500 text-white shadow-lg"
              : darkMode
                ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          } ${!isSymbolSelected ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          MACD
        </button>
        <button
          onClick={() => setShowRSI(!showRSI)}
          disabled={!isSymbolSelected}
          className={`flex items-center gap-2 p-3 rounded-xl transition-all duration-200 ${
            showRSI
              ? "bg-emerald-500 text-white shadow-lg"
              : darkMode
                ? "bg-gray-700/50 text-gray-400 hover:bg-gray-700"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          } ${!isSymbolSelected ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          RSI
        </button>
      </div>
    </div>
  );
};

export default TechnicalIndicators;
