import { useCallback } from "react";
import { getApiUrl } from "../config/api";

const usePostLikeToggle = (postId, isAuthenticated, user, setPost) => {
  const toggleLike = useCallback(async () => {
    if (!isAuthenticated) return null;

    try {
      const response = await fetch(
        `${getApiUrl()}/api/posts/${postId}/toggle-like`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.ok) {
        const result = await response.json();

        setPost((prev) => ({
          ...prev,
          isLiked: result.isLiked,
          likeCount: result.likeCount,
          likes: result.isLiked
            ? [...prev.likes, { userId: user?.id }]
            : prev.likes.filter((like) => like.userId !== user?.id),
        }));

        return result;
      }
    } catch (err) {
      console.error("Failed to toggle post like", err);
    }

    return null;
  }, [postId, isAuthenticated, user, setPost]);

  return { toggleLike };
};

export default usePostLikeToggle;
