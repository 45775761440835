import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaSearch } from "react-icons/fa";
import { useTheme } from "../../../contexts/ThemeContext";
import NoPosts from "../errorMessages/NoPosts";
import LoadingPosts from "../LoadingPosts";
import PostsErrorMessage from "../errorMessages/PostsErrorMessage";
import NoSearchResults from "../errorMessages/NoSearchResults";
import Paginator from "../../common/Paginator";
import usePosts from "../../../hooks/usePosts";
import PostDetail from "../../post/PostDetail";
import PostListTopicSelector from "./PostListTopicSelector";

const PostList = () => {
  const { darkMode } = useTheme();
  const { topic } = useParams();
  const navigate = useNavigate();
  const {
    posts,
    totalPages,
    currentPage,
    loading,
    error,
    handlePageChange,
    handleSearch,
    searchQuery,
    selectedTopic,
    handleTopicChange,
  } = usePosts(topic);

  const [currentPosts, setCurrentPosts] = useState(posts);

  useEffect(() => {
    setCurrentPosts(posts);
  }, [posts]);

  useEffect(() => {
    if (selectedTopic && selectedTopic !== topic) {
      navigate(`/forum/${selectedTopic}`, { replace: true });
    }
  }, [selectedTopic, topic, navigate]);

  const handleInputChange = useCallback(
    (e) => {
      handleSearch(e.target.value);
    },
    [handleSearch],
  );

  const handleSearchClick = useCallback(() => {
    handleSearch(searchQuery);
  }, [handleSearch, searchQuery]);

  const handlePostDeleted = (postId) => {
    setCurrentPosts((prevPosts) =>
      prevPosts.filter((post) => post.id !== postId),
    );
  };

  return (
    <section className="container mx-auto max-w-3xl px-1 mt-6">
      <PostListTopicSelector
        selectedTopic={selectedTopic}
        onTopicChange={handleTopicChange}
      />

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="my-4 relative"
      >
        <input
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          placeholder="Search posts..."
          className={`w-full p-4 pl-12 text-lg border rounded-lg ${
            darkMode
              ? "bg-gray-800 text-white border-gray-700"
              : "bg-white text-gray-800 border-gray-300"
          } focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300`}
        />
        <FaSearch
          onClick={handleSearchClick}
          className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
            darkMode ? "text-gray-400" : "text-gray-500"
          } cursor-pointer text-xl`}
        />
      </motion.div>

      {loading && <LoadingPosts darkMode={darkMode} />}
      {error && <PostsErrorMessage darkMode={darkMode} error={error} />}
      {!loading &&
        !error &&
        currentPosts.length === 0 &&
        (searchQuery ? (
          <NoSearchResults darkMode={darkMode} searchQuery={searchQuery} />
        ) : (
          <NoPosts darkMode={darkMode} topic={selectedTopic} />
        ))}

      {!loading && !error && currentPosts.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          {currentPosts.map((post) => (
            <PostDetail
              key={post.id}
              postId={post.id}
              onPostDeleted={handlePostDeleted}
            />
          ))}
        </motion.div>
      )}

      {!loading && !error && totalPages > 1 && (
        <div className="mt-8">
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            darkMode={darkMode}
          />
        </div>
      )}
    </section>
  );
};

export default PostList;
