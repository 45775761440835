import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTheme } from "../../../contexts/ThemeContext";
import { ChevronDown } from "lucide-react";
import TOPIC_ICONS from "../../../constants/TopicIcons";
import TOPIC_MAPPING from "../../../constants/TopicMapping";

const PostListTopicSelector = ({ selectedTopic, onTopicChange }) => {
  const { darkMode } = useTheme();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const newTopic = e.target.value;
    onTopicChange(newTopic);
    navigate(`/forum/${newTopic}`, { replace: true });
  };

  const SelectedIcon = TOPIC_ICONS[selectedTopic] || TOPIC_ICONS.allPosts;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
      className="my-4 relative"
    >
      <select
        id="topic-select"
        value={selectedTopic || "allPosts"}
        onChange={handleChange}
        className={`w-full p-4 pl-12 text-lg border rounded-lg appearance-none ${
          darkMode
            ? "bg-gray-800 text-white border-gray-700"
            : "bg-white text-gray-800 border-gray-300"
        } focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300`}
      >
        {Object.entries(TOPIC_MAPPING).map(([value, displayName]) => (
          <option key={value} value={value}>
            {displayName}
          </option>
        ))}
      </select>
      <SelectedIcon
        className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
          darkMode ? "text-gray-400" : "text-gray-500"
        } text-xl`}
      />
      <ChevronDown
        className={`absolute right-4 top-1/2 transform -translate-y-1/2 ${
          darkMode ? "text-gray-400" : "text-gray-500"
        } text-xl`}
      />
    </motion.div>
  );
};

export default PostListTopicSelector;
