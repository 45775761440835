import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { motion, AnimatePresence } from "framer-motion";
import { ArrowRight } from "lucide-react";
import { useUserSubscription } from "../../contexts/UserSubscriptionContext";
import SimpleQuantumMoneyAnimation from "./SimpleQuantumMoneyAnimation";
import { useNavigate } from "react-router-dom";

const SubscriptionPurchase = ({ plan, darkMode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { createSubscription, error, loading, currentSubscription } =
    useUserSubscription();
  const [couponId, setCouponId] = useState("");
  const [status, setStatus] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      currentSubscription &&
      currentSubscription.planType?.toLowerCase() ===
        plan.name?.toLowerCase() &&
      currentSubscription.status
    ) {
      setStatus("success");
      navigate("/forum/subscription-success");
    }
  }, [currentSubscription, plan.name, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus(null);
    setLocalLoading(true);

    if (!stripe || !elements || !plan) {
      setLocalLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error: stripeError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

    setLocalLoading(false);

    if (stripeError) {
      console.error(stripeError);
      setStatus("failure");
      return;
    }

    try {
      await createSubscription(
        paymentMethod.id,
        plan.priceId,
        plan.name.toUpperCase(),
        couponId || undefined,
      );
      // The subscription update will be handled via socket events
    } catch (err) {
      console.error(err);
      setStatus("failure");
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {loading || localLoading ? (
        <motion.div
          key="loading-animation"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={containerVariants}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <SimpleQuantumMoneyAnimation darkMode={darkMode} />
          <p
            className={`
              ${darkMode ? "text-green-300" : "text-green-600"}
              font-bold text-lg md:text-xl lg:text-2xl mt-4
              absolute left-1/2 transform -translate-x-1/2
              text-center whitespace-nowrap
              ${darkMode ? "text-shadow-light" : "text-shadow-dark"}
            `}
          >
            QUANTUM ENTANGLEMENT OCCURRING...
          </p>
        </motion.div>
      ) : (
        <motion.div
          key="purchase-form"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={containerVariants}
          className={`rounded-2xl shadow-xl overflow-hidden ${
            darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
          } mt-6 transition-colors duration-300`}
        >
          <div className="p-6 sm:p-8">
            <h2 className="text-2xl font-extrabold mb-6">
              Complete Your Purchase
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="rounded-md shadow-sm">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: darkMode ? "#ffffff" : "#424770",
                        "::placeholder": {
                          color: darkMode ? "#9ca3af" : "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#f87171",
                      },
                    },
                  }}
                  className="p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <div>
                <label
                  htmlFor="couponCode"
                  className="block text-sm font-medium mb-2"
                >
                  Coupon Code (optional)
                </label>
                <input
                  type="text"
                  id="couponCode"
                  value={couponId}
                  onChange={(e) => setCouponId(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 ${
                    darkMode
                      ? "bg-gray-700 text-white"
                      : "bg-white text-gray-800"
                  }`}
                  placeholder="Enter coupon code"
                />
              </div>
              {error && (
                <div className="text-red-500 text-sm font-medium">{error}</div>
              )}
              {status === "failure" && (
                <div className="text-red-500 text-sm font-medium">
                  Subscription failed. Please try again.
                </div>
              )}
              <motion.button
                type="submit"
                disabled={!stripe || loading || localLoading}
                className={`w-full py-3 px-4 rounded-full font-semibold text-white transition-all duration-200 flex items-center justify-center ${
                  plan.popular
                    ? "bg-green-500 hover:bg-green-600"
                    : "bg-gray-600 hover:bg-gray-700"
                } ${
                  !stripe || loading || localLoading
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Subscribe to {plan.name}
                <ArrowRight className="ml-2 h-5 w-5" />
              </motion.button>
            </form>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SubscriptionPurchase;
