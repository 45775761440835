import React from "react";
import NavText from "../menu/NavText";
import ProfileLink from "../profile/ProfileLink";

const NavigationItems = ({ navItems, onLogout }) => {
  return (
    <>
      {navItems.map((item) => (
        <NavText
          key={item.name}
          to={item.to}
          icon={item.icon}
          onClick={item.name === "Logout" ? onLogout : item.onClick}
        >
          {item.name}
        </NavText>
      ))}
      <ProfileLink />
    </>
  );
};

export default NavigationItems;
