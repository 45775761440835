import React from "react";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import TOPIC_ICONS from "../../../constants/TopicIcons";
import TOPIC_MAPPING from "../../../constants/TopicMapping";
import { DollarSign } from "lucide-react";

const CreateEditPostTopicSelect = ({ topic, onChange, darkMode }) => {
  const SelectedIcon = TOPIC_ICONS[topic] || DollarSign;

  const filteredTopics = Object.entries(TOPIC_MAPPING).filter(
    ([key]) => key !== "allPosts",
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
      className="my-4 relative"
    >
      <div className="relative">
        <select
          id="topic"
          name="topic"
          value={topic}
          onChange={onChange}
          className={`w-full p-2 sm:p-4 pl-8 sm:pl-12 text-xs sm:text-lg border rounded-lg appearance-none ${
            darkMode
              ? "bg-gray-800 text-white border-gray-700"
              : "bg-white text-gray-800 border-gray-300"
          } focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300`}
        >
          {filteredTopics.map(([value, displayName]) => (
            <option key={value} value={value}>
              {displayName}
            </option>
          ))}
        </select>
        {SelectedIcon && (
          <SelectedIcon
            className={`absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 ${
              darkMode ? "text-gray-400" : "text-gray-500"
            } text-sm sm:text-xl`}
          />
        )}
        <ChevronDown
          className={`absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 ${
            darkMode ? "text-gray-400" : "text-gray-500"
          } text-sm sm:text-xl`}
        />
      </div>
    </motion.div>
  );
};

export default CreateEditPostTopicSelect;
