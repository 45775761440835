import React, { useState, useEffect } from "react";
import { useChat } from "../../hooks/useChat";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { Send, Search, X, User } from "lucide-react";
import { useAuth } from "../../contexts/AuthContext";
import { motion } from "framer-motion";
import useUserSearch from "../../hooks/useUserSearch";

const NewMessage = () => {
  const [message, setMessage] = useState("");
  const [participants, setParticipants] = useState([]);
  const [groupName, setGroupName] = useState("");
  const token = localStorage.getItem("token");
  const { createOrGetConversation, sendMessage } = useChat(token);
  const navigate = useNavigate();
  const { darkMode } = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { users, loading, error, query, handleSearch } = useUserSearch();

  const singleParticipant = searchParams.get("singleParticipant");

  useEffect(() => {
    if (singleParticipant) {
      setParticipants([{ id: Number(singleParticipant) }]);
    } else {
      const lastSegment = location.pathname.split("/").pop();
      if (lastSegment && !isNaN(Number(lastSegment))) {
        setParticipants([{ id: Number(lastSegment) }]);
      }
    }
  }, [location, singleParticipant]);

  const handleCreateConversation = async () => {
    if (participants.length === 0) {
      return alert("At least one participant is required.");
    }
    if (!message.trim()) {
      return alert("Please enter a message.");
    }

    try {
      const participantIds = participants.map((p) => p.id);
      const conversation = await createOrGetConversation(
        participantIds,
        groupName,
      );
      await sendMessage(conversation.id, message);
      navigate(`/forum/messages/${conversation.id}`);
    } catch (error) {
      console.error("Error creating conversation", error);
      alert("Failed to create conversation. Please try again.");
    }
  };

  const handleAddParticipant = (user) => {
    if (!participants.some((p) => p.id === user.id)) {
      setParticipants([...participants, user]);
    }
  };

  const handleRemoveParticipant = (userId) => {
    setParticipants(participants.filter((p) => p.id !== userId));
  };

  const inputClasses = `w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-green-100 focus:border-green-300 ${
    darkMode
      ? "bg-gray-700 text-white border-gray-600 focus:ring-gray-900 focus:border-gray-500"
      : "bg-white text-green-900 border-gray-300"
  }`;

  const labelClasses = `block text-sm font-medium ${
    darkMode ? "text-green-300" : "text-green-700"
  } mb-1`;

  return (
    <div className="mt-16 px-4 sm:px-6 lg:px-8">
      <div
        className={`max-w-2xl mx-auto ${
          darkMode ? "bg-gray-800" : "bg-white"
        } shadow-md rounded-lg overflow-hidden`}
      >
        <div className="p-6 space-y-4">
          <div className="relative">
            <label htmlFor="message" className={labelClasses}>
              Message
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Write a message..."
              rows={4}
              className={inputClasses}
              required
            />
            <button
              onClick={handleCreateConversation}
              className="absolute bottom-2 right-2 m-1 p-2 rounded-full bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
              disabled={!message.trim() || participants.length === 0}
            >
              <Send size={20} color="white" />
            </button>
          </div>

          {!singleParticipant && (
            <>
              <div>
                <label htmlFor="groupName" className={labelClasses}>
                  Group Name (optional)
                </label>
                <input
                  id="groupName"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  placeholder="Enter group name"
                  className={inputClasses}
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="participantSearch" className={labelClasses}>
                  Search Participants
                </label>
                <div className="relative">
                  <input
                    id="participantSearch"
                    type="text"
                    value={query}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search for users..."
                    className={`${inputClasses} pl-10`}
                  />
                  <Search
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={20}
                  />
                </div>
              </div>

              {loading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}

              {!loading && !error && users.length > 0 && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="space-y-2 max-h-60 overflow-y-auto"
                >
                  {users.map((user) => (
                    <motion.div
                      key={user.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className={`flex items-center justify-between p-2 rounded-lg ${
                        darkMode
                          ? "bg-gray-700 hover:bg-gray-600"
                          : "bg-gray-100 hover:bg-gray-200"
                      } cursor-pointer`}
                      onClick={() => handleAddParticipant(user)}
                    >
                      <div className="flex items-center">
                        {user.profileImageUrl ? (
                          <img
                            src={user.profileImageUrl}
                            alt={`${user.username}'s avatar`}
                            className="w-8 h-8 rounded-full mr-2"
                          />
                        ) : (
                          <div className="w-8 h-8 rounded-full mr-2 bg-gray-300 flex items-center justify-center">
                            <User size={20} className="text-gray-600" />
                          </div>
                        )}
                        <span>{user.username}</span>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              )}

              <div className="mt-4">
                <label className={labelClasses}>Selected Participants</label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {participants.map((participant) => (
                    <div
                      key={participant.id}
                      className={`flex items-center px-3 py-1 rounded-full ${
                        darkMode
                          ? "bg-gray-700 text-white"
                          : "bg-gray-200 text-gray-800"
                      }`}
                    >
                      <span>{participant.username}</span>
                      <button
                        onClick={() => handleRemoveParticipant(participant.id)}
                        className="ml-2 focus:outline-none"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewMessage;
