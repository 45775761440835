import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { motion } from "framer-motion";
import { useAuth } from "../../contexts/AuthContext";
import LoadingPosts from "./LoadingPosts";
import PostHeader from "./PostHeader";
import PostActions from "./PostActions";
import PostsErrorMessage from "./errorMessages/PostsErrorMessage";
import CommentForm from "../comments/CommentForm";
import CommentList from "../comments/CommentList";
import usePostDetail from "../../hooks/usePostDetail";
import useHandleComment from "../../hooks/useHandleComment";
import usePostLikeToggle from "../../hooks/usePostLikeToggle";
import usePaginatedComments from "../../hooks/usePaginatedComments";
import Paginator from "../common/Paginator";
import PostImageGallery from "./post-item/PostImageGallery";
import { useProfile } from "../../contexts/ProfileContext";

const PostDetail = ({ postId: propPostId, onPostDeleted }) => {
  const { id: urlPostId } = useParams();
  const { darkMode } = useTheme();
  const { isAuthenticated, user } = useAuth();

  const postId = propPostId || urlPostId;

  const {
    post,
    error,
    isLoading,
    isLiked,
    likeCount,
    setPost,
    updateLikeStatus,
  } = usePostDetail(postId);

  const [localCommentCount, setLocalCommentCount] = useState(
    post?.comments?.length,
  );

  useEffect(() => {
    if (post) {
      setLocalCommentCount(post.comments.length || 0);
    }
  }, [post]);

  const {
    comments,
    currentPage,
    totalPages,
    handlePageChange,
    refreshComments,
  } = usePaginatedComments(postId);

  const { toggleLike } = usePostLikeToggle(
    postId,
    isAuthenticated,
    user,
    setPost,
  );

  const { currentlyLoggedInUserProfile } = useProfile();

  const handleLike = useCallback(async () => {
    const result = await toggleLike();
    if (result) {
      updateLikeStatus(result.isLiked, result.likeCount);
    }
  }, [toggleLike, updateLikeStatus]);

  const { commentContent, setCommentContent, handleComment } = useHandleComment(
    postId,
    isAuthenticated,
    setPost,
    refreshComments,
  );

  const handleCommentDeleted = async () => {
    setLocalCommentCount((prevCount) => Math.max(0, prevCount - 1));
    await refreshComments();
  };

  const handleCommentAdded = () => {
    setLocalCommentCount((prevCount) => prevCount + 1);
  };

  if (isLoading) return <LoadingPosts darkMode={darkMode} />;
  if (error) return <PostsErrorMessage error={error} darkMode={darkMode} />;
  if (!post) return <PostsErrorMessage error={error} darkMode={darkMode} />;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`max-w-3xl mx-auto mt-4 p-4 rounded-lg shadow-lg ${
        darkMode ? "text-white" : "text-gray-900"
      }`}
    >
      <PostHeader
        post={post}
        darkMode={darkMode}
        onDeletePost={onPostDeleted}
      />
      {post.imageUrls && post.imageUrls.length > 0 && (
        <PostImageGallery images={post.imageUrls} darkMode={darkMode} />
      )}
      <PostActions
        post={post}
        commentCount={localCommentCount}
        isLiked={isLiked}
        handleLike={handleLike}
        darkMode={darkMode}
        likeCount={likeCount}
      />
      <CommentForm
        commentContent={commentContent}
        setCommentContent={setCommentContent}
        handleComment={async (...args) => {
          await handleComment(...args);
          handleCommentAdded();
        }}
        darkMode={darkMode}
      />
      <CommentList
        paginatedComments={comments}
        darkMode={darkMode}
        currentlyLoggedInUserId={currentlyLoggedInUserProfile?.id}
        onCommentDeleted={handleCommentDeleted}
      />
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        darkMode={darkMode}
      />
    </motion.div>
  );
};

export default PostDetail;
