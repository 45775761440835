import React, { useState, useRef, useEffect } from "react";
import { Send, Paperclip, X } from "lucide-react";

const MessageInput = ({ onSendMessage, onTyping, darkMode }) => {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  }, [file]);

  const handleTyping = (e) => {
    setMessage(e.target.value);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    onTyping(true);

    typingTimeoutRef.current = setTimeout(() => {
      onTyping(false);
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() || file) {
      onSendMessage(message, file);
      setMessage("");
      setFile(null);
      setPreview(null);

      onTyping(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const removeFile = () => {
    setFile(null);
    setPreview(null);
  };

  const inputBgColor = darkMode ? "bg-gray-800" : "bg-white";
  const textColor = darkMode ? "text-gray-200" : "text-gray-900";
  const placeholderColor = darkMode
    ? "placeholder-gray-500"
    : "placeholder-gray-400";

  return (
    <form onSubmit={handleSubmit} className="p-2">
      {preview && (
        <div className="relative mb-2 inline-block">
          <img
            src={preview}
            alt="Preview"
            className="h-20 w-auto rounded-md object-cover"
          />
          <button
            type="button"
            onClick={removeFile}
            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
            aria-label="Remove file"
          >
            <X size={16} />
          </button>
        </div>
      )}
      <div className="flex items-center space-x-2">
        <div className="relative flex-grow">
          <input
            type="text"
            value={message}
            onChange={handleTyping}
            placeholder="Type a message..."
            className={`w-full py-2 px-4 pr-12 rounded-full text-sm ${inputBgColor} ${textColor} ${placeholderColor} focus:outline-none focus:ring-2 focus:ring-green-500 transition-shadow duration-200`}
          />
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
            aria-label="Attach file"
          >
            <Paperclip size={20} />
          </button>
        </div>
        <button
          type="submit"
          className="p-2 rounded-full bg-green-500 text-white focus:outline-none focus:ring-2 focus:ring-green-500 transition-colors duration-200 flex items-center justify-center"
          aria-label="Send message"
        >
          <Send size={20} />
        </button>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        className="hidden"
      />
    </form>
  );
};

export default MessageInput;
