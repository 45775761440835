import React, { useMemo } from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLegend,
  VictoryArea,
  VictoryTheme,
} from "victory";
import { TrendingUp, TrendingDown, Activity } from "lucide-react";

const CashFlowTrendsChart = ({ data, darkMode }) => {
  const colors = {
    operating: "#10B981",
    investing: "#3B82F6",
    financing: "#F59E0B",
    free: "#8B5CF6",
    netChange: "#EC4899",
    workingCapital: "#60A5FA",
  };

  const baseTheme = {
    ...VictoryTheme.material,
    axis: {
      ...VictoryTheme.material.axis,
      style: {
        ...VictoryTheme.material.axis.style,
        grid: {
          stroke: darkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
        },
        tickLabels: {
          fill: darkMode ? "#fff" : "#000",
          fontSize: 12,
          fontFamily: "system-ui",
        },
      },
    },
  };

  const formatYAxis = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);

  const tooltipFormat = (datum) => {
    const metrics = {
      operating: "Operating Cash Flow",
      investing: "Investing Cash Flow",
      financing: "Financing Cash Flow",
      free: "Free Cash Flow",
      netChange: "Net Change in Cash",
    };

    const date = new Date(datum.x).toLocaleDateString();
    return `${metrics[datum.childName] || datum.childName}:
${formatYAxis(datum.y)}
Date: ${date}`;
  };

  const chartData = useMemo(() => {
    return data.map((item) => ({
      date: new Date(item.date),
      operating: item.operatingCashFlow,
      investing: Math.abs(item.investingCashFlow),
      financing: item.financingCashFlow,
      free: item.freeCashFlow,
      netChange: item.changeInCash,
      cashBurnRate: item.cashBurnRate,
      workingCapitalChange: item.changeInWorkingCapital,
    }));
  }, [data]);

  const getChangeIndicator = (value) => {
    if (value > 0) {
      return {
        icon: TrendingUp,
        color: darkMode ? "text-green-400" : "text-green-600",
        bg: darkMode ? "bg-green-400/10" : "bg-green-50",
      };
    }
    return {
      icon: TrendingDown,
      color: darkMode ? "text-red-400" : "text-red-600",
      bg: darkMode ? "bg-red-400/10" : "bg-red-50",
    };
  };

  return (
    <div
      className={`rounded-2xl overflow-hidden shadow-xl ${
        darkMode
          ? "bg-gray-900 border border-gray-800"
          : "bg-white border border-gray-100"
      }`}
    >
      {/* Enhanced Header */}
      <div
        className={`px-6 py-6 ${
          darkMode
            ? "bg-gradient-to-r from-gray-900 to-gray-800"
            : "bg-gradient-to-r from-gray-50 to-white"
        }`}
      >
        <div className="flex items-center gap-3">
          <Activity
            className={`w-8 h-8 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          />
          <div>
            <h2
              className={`text-2xl font-semibold tracking-tight ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Cash Flow Trends
            </h2>
            <p
              className={`mt-2 text-sm ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Comprehensive analysis of cash flow metrics over time
            </p>
          </div>
        </div>
      </div>

      <div className="p-6 space-y-8">
        {/* Main Chart Section */}
        <div
          className={`rounded-2xl p-6 ${
            darkMode
              ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg"
              : "bg-gradient-to-br from-gray-50/50 to-white/50 backdrop-blur-lg"
          } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
        >
          <div className="h-[600px]">
            <VictoryChart
              theme={baseTheme}
              padding={{ top: 50, right: 50, bottom: 50, left: 70 }}
              scale={{ x: "time" }}
              height={600}
              width={800}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ datum }) => tooltipFormat(datum)}
                  labelComponent={
                    <VictoryTooltip
                      style={{
                        fontSize: 12,
                        fill: darkMode ? "white" : "black",
                        fontFamily: "system-ui",
                      }}
                      flyoutStyle={{
                        fill: darkMode
                          ? "rgba(17, 24, 39, 0.95)"
                          : "rgba(255, 255, 255, 0.95)",
                        stroke: darkMode
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                        strokeWidth: 1,
                        padding: 15,
                        filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.1))",
                      }}
                      cornerRadius={8}
                    />
                  }
                />
              }
            >
              <VictoryLegend
                x={50}
                y={0}
                orientation="horizontal"
                gutter={20}
                style={{
                  labels: {
                    fontSize: 12,
                    fontFamily: "system-ui",
                  },
                  data: { opacity: 0.8 },
                }}
                data={[
                  { name: "Operating", symbol: { fill: colors.operating } },
                  { name: "Investing", symbol: { fill: colors.investing } },
                  { name: "Financing", symbol: { fill: colors.financing } },
                  { name: "Free Cash Flow", symbol: { fill: colors.free } },
                  { name: "Net Change", symbol: { fill: colors.netChange } },
                ]}
              />

              <VictoryAxis
                tickFormat={(date) => new Date(date).getFullYear()}
                tickCount={4}
                style={{
                  tickLabels: {
                    fontSize: 12,
                    padding: 8,
                    fontFamily: "system-ui",
                  },
                  grid: { stroke: "none" },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={formatYAxis}
                style={{
                  tickLabels: {
                    fontSize: 12,
                    padding: 8,
                    fontFamily: "system-ui",
                  },
                  grid: { strokeWidth: 0.5, strokeDasharray: "4, 4" },
                }}
              />

              {[
                { key: "operating", color: colors.operating },
                { key: "investing", color: colors.investing },
                { key: "financing", color: colors.financing },
                { key: "free", color: colors.free },
                { key: "netChange", color: colors.netChange },
              ].map(({ key, color }) => (
                <VictoryLine
                  key={key}
                  name={key}
                  data={chartData}
                  x="date"
                  y={key}
                  style={{
                    data: {
                      stroke: color,
                      strokeWidth: 2.5,
                      filter: "drop-shadow(0 2px 2px rgba(0,0,0,0.1))",
                    },
                  }}
                />
              ))}
            </VictoryChart>
          </div>
        </div>

        {/* Working Capital Chart */}
        <div
          className={`rounded-2xl p-6 ${
            darkMode
              ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-lg"
              : "bg-gradient-to-br from-gray-50/50 to-white/50 backdrop-blur-lg"
          } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
        >
          <div className="flex items-center gap-3 mb-6">
            <div
              className="w-1 h-8 rounded-full"
              style={{ backgroundColor: colors.workingCapital }}
            />
            <h3
              className={`text-lg font-semibold ${
                darkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Working Capital Changes
            </h3>
          </div>

          <div className="h-[300px]">
            <VictoryChart
              theme={baseTheme}
              padding={{ top: 30, right: 50, bottom: 50, left: 70 }}
              scale={{ x: "time" }}
              height={300}
              width={800}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ datum }) =>
                    `Working Capital Change: ${formatYAxis(datum.workingCapitalChange)}\nDate: ${new Date(datum.date).toLocaleDateString()}`
                  }
                  labelComponent={
                    <VictoryTooltip
                      style={{
                        fontSize: 12,
                        fill: darkMode ? "white" : "black",
                        fontFamily: "system-ui",
                      }}
                      flyoutStyle={{
                        fill: darkMode
                          ? "rgba(17, 24, 39, 0.95)"
                          : "rgba(255, 255, 255, 0.95)",
                        stroke: darkMode
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                        strokeWidth: 1,
                        filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.1))",
                      }}
                      cornerRadius={8}
                    />
                  }
                />
              }
            >
              <VictoryArea
                data={chartData}
                x="date"
                y="workingCapitalChange"
                style={{
                  data: {
                    fill: darkMode
                      ? `${colors.workingCapital}30`
                      : `${colors.workingCapital}20`,
                    stroke: colors.workingCapital,
                    strokeWidth: 2,
                  },
                }}
              />
              <VictoryAxis
                tickFormat={(date) => new Date(date).getFullYear()}
                tickCount={4}
                style={{
                  tickLabels: {
                    fontSize: 12,
                    padding: 8,
                    fontFamily: "system-ui",
                  },
                  grid: { stroke: "none" },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={formatYAxis}
                style={{
                  tickLabels: {
                    fontSize: 12,
                    padding: 8,
                    fontFamily: "system-ui",
                  },
                  grid: { strokeWidth: 0.5, strokeDasharray: "4, 4" },
                }}
              />
            </VictoryChart>
          </div>
        </div>

        {/* Enhanced Metrics Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            {
              label: "Average Operating Cash Flow",
              value:
                chartData.reduce((acc, curr) => acc + curr.operating, 0) /
                chartData.length,
              color: colors.operating,
            },
            {
              label: "Average Free Cash Flow",
              value:
                chartData.reduce((acc, curr) => acc + curr.free, 0) /
                chartData.length,
              color: colors.free,
            },
            {
              label: "Total Net Change",
              value: chartData.reduce((acc, curr) => acc + curr.netChange, 0),
              color: colors.netChange,
            },
            {
              label: "Average Cash Burn Rate",
              value:
                chartData.reduce((acc, curr) => acc + curr.cashBurnRate, 0) /
                chartData.length,
              color: colors.investing,
            },
          ].map((metric, index) => {
            const indicator = getChangeIndicator(metric.value);
            return (
              <div
                key={index}
                className={`rounded-2xl p-6 transition-all duration-300 ${
                  darkMode
                    ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50"
                    : "bg-gradient-to-br from-gray-50/50 to-white/50"
                } border ${darkMode ? "border-gray-800" : "border-gray-100"}`}
              >
                <div className="flex flex-col">
                  <span
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {metric.label}
                  </span>
                  <div className="flex items-center gap-2 mt-2">
                    <div className={`p-1 rounded-lg ${indicator.bg}`}>
                      <indicator.icon
                        className={`w-5 h-5 ${indicator.color}`}
                      />
                    </div>
                    <span
                      className={`text-2xl font-bold`}
                      style={{ color: metric.color }}
                    >
                      {formatYAxis(metric.value)}
                    </span>
                  </div>
                  <div className="mt-3 w-full h-1 rounded-full bg-gray-200 dark:bg-gray-700">
                    <div
                      className="h-1 rounded-full transition-all duration-300"
                      style={{
                        width: `${Math.min(Math.abs((metric.value / chartData[0][metric.label.toLowerCase()]) * 100), 100)}%`,
                        backgroundColor: metric.color,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CashFlowTrendsChart;
