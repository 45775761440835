import { useState, useCallback, useEffect } from "react";
import { getApiUrl } from "../config/api";

const usePosts = (initialTopic) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(
    initialTopic || "allPosts",
  );
  const [posts, setPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPosts = useCallback(async () => {
    setLoading(true);
    setError(null);

    const url = `${getApiUrl()}/api/posts/topic/${encodeURIComponent(selectedTopic)}?page=${currentPage}&limit=5`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch posts");
      }

      const data = await response.json();
      setPosts(data.posts);
      setTotalPages(data.pagination.totalPages);
    } catch (err) {
      setError("Failed to fetch posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [currentPage, selectedTopic]);

  const searchPosts = useCallback(async () => {
    if (!searchQuery) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${getApiUrl()}/api/posts/search?q=${encodeURIComponent(searchQuery)}&page=${currentPage}&limit=5`,
      );

      if (!response.ok) {
        throw new Error("Failed to search posts");
      }

      const data = await response.json();
      setPosts(data.posts);
      setTotalPages(data.pagination.totalPages);
    } catch (err) {
      setError("Failed to search posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [searchQuery, currentPage]);

  useEffect(() => {
    if (searchQuery) {
      searchPosts();
    } else {
      fetchPosts();
    }
  }, [fetchPosts, searchPosts, searchQuery, selectedTopic]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (query) {
      setSelectedTopic("");
    } else {
      setSelectedTopic("allPosts");
    }
    setCurrentPage(1);
  }, []);

  const handleTopicChange = useCallback((topic) => {
    setSelectedTopic(topic);
    setSearchQuery("");
    setCurrentPage(1);
  }, []);

  return {
    posts,
    totalPages,
    currentPage,
    loading,
    error,
    handlePageChange,
    handleSearch,
    searchQuery,
    selectedTopic,
    handleTopicChange,
  };
};

export default usePosts;
