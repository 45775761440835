import React from "react";
import { FaStar } from "react-icons/fa";

function AffiliateTestimonialCard({ testimonial, darkMode }) {
  return (
    <div
      className={`p-8 rounded-xl shadow-2xl transition-all duration-300 hover:shadow-3xl ${darkMode ? "bg-gray-800 hover:bg-gray-750" : "bg-white hover:bg-gray-50"}`}
    >
      <div className="flex items-center mb-4">
        <FaStar className="text-yellow-400 w-6 h-6 mr-2" />
        <FaStar className="text-yellow-400 w-6 h-6 mr-2" />
        <FaStar className="text-yellow-400 w-6 h-6 mr-2" />
        <FaStar className="text-yellow-400 w-6 h-6 mr-2" />
        <FaStar className="text-yellow-400 w-6 h-6" />
      </div>
      <p
        className={`mb-6 text-lg italic leading-relaxed ${darkMode ? "text-gray-300" : "text-gray-700"}`}
      >
        "{testimonial.quote}"
      </p>
      <div className="flex items-center">
        <div
          className={`bg-green-400 w-12 h-10 rounded-full flex items-center justify-center text-white font-bold text-2xl mr-4 shadow-md`}
        >
          {testimonial.author.charAt(0)}
        </div>
        <div>
          <p
            className={`font-semibold text-lg ${darkMode ? "text-white" : "text-gray-900"}`}
          >
            {testimonial.author}
          </p>
          <p
            className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          >
            {testimonial.earnings} earned as affiliate
          </p>
        </div>
      </div>
    </div>
  );
}

export default AffiliateTestimonialCard;
