import React from "react";
import { motion } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";

const MobileMenuToggle = ({ isMobileMenuOpen, toggleMobileMenu, darkMode }) => (
  <motion.span
    onClick={toggleMobileMenu}
    className="cursor-pointer"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    {isMobileMenuOpen ? (
      <FaTimes
        className={`w-5 h-5 ${darkMode ? "text-white" : "text-gray-900"}`}
      />
    ) : (
      <FaBars
        className={`w-5 h-5 ${darkMode ? "text-white" : "text-gray-900"}`}
      />
    )}
  </motion.span>
);

export default MobileMenuToggle;
