import React, { useState } from "react";
import ETFOverview from "./ETFOverview";
import PerformanceChart from "./PerformanceChart";
import AssetAllocationChart from "./AssetAllocationChart";
import TopHoldingsTable from "./TopHoldingsTable";
import TechnicalIndicators from "./TechnicalIndicators";
import {
  LayoutDashboard,
  TrendingUp,
  PieChart,
  BarChart,
  ExternalLink,
  Search,
} from "lucide-react";

const ETFVisualization = ({ data, darkMode }) => {
  const [view, setView] = useState("overview");

  const navigationTabs = [
    { id: "overview", label: "Overview", icon: LayoutDashboard },
    { id: "performance", label: "Performance", icon: TrendingUp },
    { id: "holdings", label: "Holdings", icon: PieChart },
    { id: "technicals", label: "Technicals", icon: BarChart },
  ];

  if (!data) {
    return (
      <div
        className={`min-h-screen flex items-center justify-center ${
          darkMode ? "bg-gray-900" : "bg-gray-50"
        }`}
      >
        <div
          className={`max-w-md mx-auto p-8 rounded-xl border shadow-sm transition-all ${
            darkMode
              ? "bg-gray-800 border-gray-700 text-gray-300"
              : "bg-white border-gray-200 text-gray-600"
          }`}
        >
          <div className="flex flex-col items-center gap-4">
            <Search className="w-12 h-12 text-gray-400" />
            <div className="text-center">
              <h2
                className={`text-xl font-semibold mb-2 ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                No ETF Data Available
              </h2>
              <p className="text-sm">
                Unable to display ETF information at this time.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen transition-colors duration-200 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-900"
      }`}
    >
      <div className="max-w-7xl mx-auto p-2 sm:p-4 lg:p-6 space-y-4">
        {/* Enhanced Header */}
        <div
          className={`rounded-xl border shadow-sm overflow-hidden ${
            darkMode
              ? "bg-gray-900 border-gray-800"
              : "bg-white border-gray-200"
          }`}
        >
          <div className="p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div className="space-y-2">
                <div className="flex items-center gap-3">
                  <h1
                    className={`text-xl sm:text-2xl font-bold tracking-tight ${
                      darkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {data.General?.Name}
                  </h1>
                </div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {data.General?.Description}
                </p>
              </div>
            </div>
          </div>

          {/* Modern Navigation Tabs */}
          <div
            className={`border-t ${
              darkMode ? "border-gray-800" : "border-gray-200"
            }`}
          >
            <div className="flex flex-wrap gap-1 p-1">
              {navigationTabs.map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  onClick={() => setView(id)}
                  className={`
                    flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                    ${
                      view === id
                        ? darkMode
                          ? "bg-green-500/20 text-green-400"
                          : "bg-green-50 text-green-600"
                        : darkMode
                          ? "text-gray-400 hover:text-gray-300 hover:bg-gray-800"
                          : "text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                    }
                  `}
                >
                  <Icon className="w-4 h-4" />
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Dynamic Content with Smooth Transitions */}
        <div className="space-y-4 transition-all duration-200">
          {view === "overview" && (
            <ETFOverview data={data} darkMode={darkMode} />
          )}
          {view === "performance" && (
            <PerformanceChart data={data} darkMode={darkMode} />
          )}
          {view === "holdings" && (
            <div className="space-y-4">
              <AssetAllocationChart data={data} darkMode={darkMode} />
              <TopHoldingsTable data={data} darkMode={darkMode} />
            </div>
          )}
          {view === "technicals" && (
            <TechnicalIndicators data={data} darkMode={darkMode} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ETFVisualization;
