import React from "react";
import { formatCurrency } from "./FormatHelpers";
import { ArrowUpRight, ArrowDownRight, Minus } from "lucide-react";

const MetricCard = ({
  title,
  metrics,
  darkMode,
  icon: Icon,
  color = "blue",
}) => {
  const getChangeIndicator = (value, previousValue) => {
    if (!previousValue) return null;
    const change = ((value - previousValue) / previousValue) * 100;

    if (Math.abs(change) < 0.01)
      return {
        icon: Minus,
        color: darkMode ? "text-gray-400" : "text-gray-500",
        value: "0.00%",
      };

    return {
      icon: change > 0 ? ArrowUpRight : ArrowDownRight,
      color: change > 0 ? "text-emerald-500" : "text-red-500",
      value: `${Math.abs(change).toFixed(2)}%`,
    };
  };

  const colorVariants = {
    blue: {
      icon: darkMode
        ? "text-blue-400 bg-blue-500/10"
        : "text-blue-500 bg-blue-50",
      border: darkMode ? "border-blue-500/20" : "border-blue-100",
      hover: darkMode ? "hover:bg-blue-500/5" : "hover:bg-blue-50/50",
    },
    emerald: {
      icon: darkMode
        ? "text-emerald-400 bg-emerald-500/10"
        : "text-emerald-500 bg-emerald-50",
      border: darkMode ? "border-emerald-500/20" : "border-emerald-100",
      hover: darkMode ? "hover:bg-emerald-500/5" : "hover:bg-emerald-50/50",
    },
    purple: {
      icon: darkMode
        ? "text-purple-400 bg-purple-500/10"
        : "text-purple-500 bg-purple-50",
      border: darkMode ? "border-purple-500/20" : "border-purple-100",
      hover: darkMode ? "hover:bg-purple-500/5" : "hover:bg-purple-50/50",
    },
  };

  return (
    <div
      className={`rounded-2xl border transition-all duration-200 ${
        darkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-100"
      } shadow-sm hover:shadow-md`}
    >
      {/* Header */}
      <div
        className={`p-4 border-b ${
          darkMode ? "border-gray-800" : "border-gray-100"
        }`}
      >
        <div className="flex items-center gap-3">
          {Icon && (
            <div className={`p-2 rounded-xl ${colorVariants[color].icon}`}>
              <Icon className="w-5 h-5" />
            </div>
          )}
          <h3
            className={`text-lg font-bold ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            {title}
          </h3>
        </div>
      </div>

      {/* Metrics */}
      <div className="p-4 space-y-3">
        {metrics.map((metric, index) => {
          const changeIndicator = getChangeIndicator(
            metric.value,
            metric.previousValue,
          );

          return (
            <div
              key={index}
              className={`group p-2 rounded-lg transition-all duration-200 ${
                darkMode ? "hover:bg-gray-800" : "hover:bg-gray-50"
              }`}
            >
              <div className="flex items-center justify-between">
                <span
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {metric.label}
                </span>
                {changeIndicator && (
                  <div
                    className={`flex items-center gap-1 text-xs font-medium ${changeIndicator.color}`}
                  >
                    <changeIndicator.icon className="w-3 h-3" />
                    {changeIndicator.value}
                  </div>
                )}
              </div>

              <div className="flex items-baseline justify-between mt-1">
                <span
                  className={`text-base font-bold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {typeof metric.value === "string"
                    ? metric.value
                    : formatCurrency(metric.value)}
                </span>

                {metric.additionalInfo && (
                  <span
                    className={`text-xs ${
                      darkMode ? "text-gray-500" : "text-gray-400"
                    }`}
                  >
                    {metric.additionalInfo}
                  </span>
                )}
              </div>

              {/* Progress Bar */}
              {metric.progress !== undefined && (
                <div className="mt-2 h-1 w-full bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className={`h-full rounded-full transition-all duration-300 ${
                      darkMode ? "bg-blue-500" : "bg-blue-600"
                    }`}
                    style={{
                      width: `${Math.min(100, Math.max(0, metric.progress))}%`,
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Optional Footer */}
      {metrics.some((m) => m.footnote) && (
        <div
          className={`px-4 py-3 border-t ${
            darkMode ? "border-gray-800" : "border-gray-100"
          }`}
        >
          <div
            className={`text-xs ${
              darkMode ? "text-gray-500" : "text-gray-400"
            }`}
          >
            {metrics.find((m) => m.footnote)?.footnote}
          </div>
        </div>
      )}
    </div>
  );
};

export default MetricCard;
