import { useState, useEffect } from "react";
import { getApiUrl } from "../config/api";

export const useFollowers = (page, pageSize) => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${getApiUrl()}/api/users/followers/paginated?page=${page}&limit=${pageSize}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch followers");
        }
        const data = await response.json();
        setFollowers(data.followers);
        setTotalPages(data.pages);
        setError(null);
      } catch (err) {
        setError("Failed to fetch followers");
      } finally {
        setLoading(false);
      }
    };

    fetchFollowers();
  }, [page, pageSize]);

  const removeFollower = async (followerId) => {
    try {
      const response = await fetch(
        `${getApiUrl()}/api/users/remove-follower/${followerId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Failed to remove follower");
      }
      setFollowers(followers.filter((follower) => follower.id !== followerId));
    } catch (err) {
      setError("Failed to remove follower");
    }
  };

  return { followers, loading, error, totalPages, removeFollower };
};
