import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useChat } from "../../hooks/useChat";
import { useTheme } from "../../contexts/ThemeContext";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import { useAuth } from "../../contexts/AuthContext";
import TypingIndicator from "./TypingIndicator";
import LoadingMessage from "./LoadingMessage";
import ErrorMessage from "./ErrorMessage";
import { Phone, Video, PhoneCall } from "lucide-react";

const ConversationDetail = () => {
  const { conversationId } = useParams();
  const { user } = useAuth();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { darkMode } = useTheme();
  const [localMessages, setLocalMessages] = useState([]);
  const [typingUsernames, setTypingUsernames] = useState([]);
  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  const {
    messages,
    typingUsers,
    loading,
    error,
    fetchMessages,
    sendMessage,
    joinConversation,
    leaveConversation,
    setTypingStatus,
  } = useChat(token, conversationId);

  useEffect(() => {
    if (conversationId) {
      joinConversation(conversationId);
      fetchMessages(conversationId);

      return () => {
        leaveConversation(conversationId);
      };
    }
  }, [conversationId, joinConversation, fetchMessages, leaveConversation]);

  useEffect(() => {
    setLocalMessages((prevMessages) => {
      const newMessages = messages.filter(
        (message) =>
          !prevMessages.some((prevMessage) => prevMessage.id === message.id),
      );
      return [...prevMessages, ...newMessages];
    });
  }, [messages]);

  useEffect(() => {
    if (user && user.id) {
      const usernames = typingUsers
        .filter((typingUser) => typingUser.userId !== user.id)
        .map((typingUser) => typingUser.username);
      setTypingUsernames(usernames);
    }
  }, [typingUsers, user]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [localMessages]);

  const bgColor = darkMode ? "bg-gray-900" : "bg-gray-50";

  const handleSendMessage = useCallback(
    (content, file) => {
      if (conversationId) {
        sendMessage(conversationId, content, file);
      }
    },
    [conversationId, sendMessage],
  );

  const handleTyping = useCallback(
    (isTyping) => {
      if (conversationId) {
        setTypingStatus(conversationId, isTyping);
      }
    },
    [conversationId, setTypingStatus],
  );

  const handleStartCall = useCallback(
    async (isVideo) => {
      try {
        navigate(`/forum/messages/${conversationId}/call`, {
          state: { isVideo },
        });
      } catch (err) {
        console.error("Error starting call:", err);
      }
    },
    [conversationId, navigate, user?.id],
  );

  if (loading) {
    return <LoadingMessage darkMode={darkMode} />;
  }

  if (error) {
    return <ErrorMessage darkMode={darkMode} error={error} />;
  }

  return (
    <div className={`${bgColor} p-4 rounded-lg flex flex-col h-full mt-3`}>
      <div className="flex justify-end space-x-2 mb-2">
        <button
          onClick={() => handleStartCall(false)}
          className="p-2 rounded-full bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 shadow-md"
          aria-label="Start audio call"
        >
          <Phone size={20} />
        </button>
        <button
          onClick={() => handleStartCall(true)}
          className="p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 shadow-md"
          aria-label="Start video call"
        >
          <Video size={20} />
        </button>
      </div>

      <div className="flex-1 overflow-y-auto" ref={messageListRef}>
        <MessageList
          messages={localMessages}
          darkMode={darkMode}
          currentUserId={user?.id}
        />
        {typingUsernames.length > 0 && (
          <TypingIndicator usernames={typingUsernames} darkMode={darkMode} />
        )}
      </div>
      <div ref={inputRef}>
        <MessageInput
          onSendMessage={handleSendMessage}
          onTyping={handleTyping}
          darkMode={darkMode}
        />
      </div>
    </div>
  );
};

export default ConversationDetail;
