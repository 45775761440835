import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { motion } from "framer-motion";
import {
  FaShieldAlt,
  FaHandshake,
  FaUserLock,
  FaFileContract,
} from "react-icons/fa";

function TermsOfService() {
  const { darkMode } = useTheme();

  const sections = [
    {
      icon: FaHandshake,
      title: "Agreement to Terms",
      content: `By accessing and using Quantum Money Code's services, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access our services.`,
    },
    {
      icon: FaFileContract,
      title: "Service Description",
      content: `Quantum Money Code provides educational content, financial strategies, and community support. While we strive to provide accurate information, all content is for educational purposes only and does not constitute financial advice.`,
    },
    {
      icon: FaUserLock,
      title: "User Responsibilities",
      content: `You are responsible for maintaining the confidentiality of your account information and for all activities under your account. You must be at least 18 years old to use our services.`,
    },
    {
      icon: FaShieldAlt,
      title: "Intellectual Property",
      content: `All content, features, and functionality of Quantum Money Code are owned by us and are protected by international copyright, trademark, and other intellectual property laws.`,
    },
  ];

  return (
    <section
      className={`min-h-screen py-20 px-4 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-800"
      } transition-colors duration-500`}
    >
      <div className="container mx-auto max-w-6xl">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={`text-5xl font-extrabold text-center mb-10 ${
            darkMode ? "text-green-400" : "text-green-600"
          } transition-colors duration-500`}
        >
          Terms of Service
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className={`text-xl text-center leading-relaxed max-w-3xl mx-auto mb-20 ${
            darkMode ? "text-gray-300" : "text-gray-700"
          } transition-colors duration-500`}
        >
          Please read these terms carefully before using Quantum Money Code's
          services. These terms outline your rights and responsibilities as a
          user of our platform.
        </motion.p>

        <div className="space-y-8">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`${
                darkMode ? "bg-green-800" : "bg-green-100"
              } bg-opacity-10 rounded-lg p-6`}
            >
              <div className="flex items-center mb-4">
                <section.icon className="text-green-500 w-8 h-8 mr-3" />
                <h2 className="text-2xl font-semibold">{section.title}</h2>
              </div>
              <p
                className={`${
                  darkMode ? "text-gray-300" : "text-gray-700"
                } text-lg leading-relaxed`}
              >
                {section.content}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className={`text-center mt-12 ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          Last updated: November 2024
        </motion.p>
      </div>
    </section>
  );
}

export default TermsOfService;
