import React from "react";
import {
  DollarSign,
  TrendingUp,
  Scale,
  Building2,
  BarChart2,
  BookOpen,
} from "lucide-react";

const Valuation = ({ data, darkMode, formatNumber, formatCurrency }) => {
  if (!data) return null;

  // Utility functions for styling
  const getBackgroundStyle = () => {
    return darkMode
      ? "bg-slate-800 shadow-lg shadow-slate-900/20"
      : "bg-white shadow-lg shadow-slate-200/50";
  };

  const getTextColor = () => {
    return darkMode ? "text-slate-200" : "text-slate-700";
  };

  const ValuationCard = ({ title, value, subtitle, icon: Icon }) => (
    <div
      className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
        darkMode ? "border border-slate-700" : "border border-slate-100"
      }`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <Icon className="w-6 h-6 text-blue-500" />
            <h3
              className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
            >
              {title}
            </h3>
          </div>
          <div className="space-y-2">
            <p
              className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                darkMode ? "text-white" : "text-slate-900"
              }`}
            >
              {value}
            </p>
            {subtitle && (
              <p
                className={`text-sm font-medium ${
                  darkMode ? "text-slate-400" : "text-slate-600"
                }`}
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const PriceMultipleBar = ({ value, title }) => {
    const maxWidth = 100;
    const width = Math.min((value / 60) * 100, maxWidth);

    const getBarColor = (value) => {
      if (value < 20) return "bg-green-500";
      if (value < 40) return "bg-blue-500";
      return "bg-purple-500";
    };

    return (
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span
            className={`text-sm font-medium ${
              darkMode ? "text-slate-200" : "text-slate-700"
            }`}
          >
            {title}
          </span>
          <span
            className={`text-sm font-bold px-2 py-1 rounded-lg ${
              darkMode
                ? "bg-slate-700 text-white"
                : "bg-slate-100 text-slate-900"
            }`}
          >
            {formatNumber(value)}x
          </span>
        </div>
        <div
          className={`h-2.5 w-full rounded-full overflow-hidden ${
            darkMode ? "bg-slate-700" : "bg-slate-200"
          }`}
        >
          <div
            className={`h-full rounded-full ${getBarColor(value)}`}
            style={{ width: `${width}%` }}
          />
        </div>
      </div>
    );
  };

  const SectionWrapper = ({ title, icon: Icon, children }) => (
    <div
      className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:shadow-xl ${
        darkMode ? "border border-slate-700" : "border border-slate-100"
      }`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-6">
          <div className="flex items-center space-x-3">
            <Icon className="w-6 h-6 text-blue-500" />
            <h3 className={`text-lg font-semibold ${getTextColor()}`}>
              {title}
            </h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );

  const MetricBox = ({ title, value }) => (
    <div
      className={`rounded-xl ${
        darkMode ? "bg-slate-700" : "bg-slate-50"
      } transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
    >
      <div className="p-4 sm:p-5">
        <div className="space-y-2">
          <div
            className={`text-sm font-medium ${
              darkMode ? "text-slate-400" : "text-slate-600"
            }`}
          >
            {title}
          </div>
          <div
            className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
              darkMode ? "text-white" : "text-slate-900"
            }`}
          >
            {value}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Primary Valuation Metrics */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        <ValuationCard
          title="P/E Ratio (TTM)"
          value={`${formatNumber(data.TrailingPE)}x`}
          subtitle="Historical Earnings"
          icon={BarChart2}
        />
        <ValuationCard
          title="Forward P/E"
          value={`${formatNumber(data.ForwardPE)}x`}
          subtitle="Expected Earnings"
          icon={TrendingUp}
        />
        <ValuationCard
          title="P/S Ratio"
          value={`${formatNumber(data.PriceSalesTTM)}x`}
          subtitle="Price to Sales"
          icon={DollarSign}
        />
        <ValuationCard
          title="P/B Ratio"
          value={`${formatNumber(data.PriceBookMRQ)}x`}
          subtitle="Price to Book"
          icon={BookOpen}
        />
      </div>

      {/* Enterprise Value Metrics */}
      <SectionWrapper title="Enterprise Value Metrics" icon={Building2}>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <MetricBox
            title="Enterprise Value"
            value={formatCurrency(data.EnterpriseValue)}
          />
          <MetricBox
            title="EV/Revenue"
            value={`${formatNumber(data.EnterpriseValueRevenue)}x`}
          />
          <MetricBox
            title="EV/EBITDA"
            value={`${formatNumber(data.EnterpriseValueEbitda)}x`}
          />
        </div>
      </SectionWrapper>

      {/* Valuation Comparison */}
      <SectionWrapper title="Valuation Multiples" icon={Scale}>
        <div className="space-y-5">
          <PriceMultipleBar value={data.TrailingPE} title="P/E (TTM)" />
          <PriceMultipleBar value={data.ForwardPE} title="Forward P/E" />
          <PriceMultipleBar value={data.PriceSalesTTM} title="P/S Ratio" />
          <PriceMultipleBar
            value={data.EnterpriseValueEbitda}
            title="EV/EBITDA"
          />
        </div>
        <div
          className={`mt-5 px-4 py-3 rounded-lg text-sm font-medium ${
            darkMode
              ? "bg-slate-700 text-slate-300"
              : "bg-slate-100 text-slate-600"
          }`}
        >
          Bars show relative scale of valuation multiples
        </div>
      </SectionWrapper>
    </div>
  );
};

export default Valuation;
