import { useState, useEffect, useCallback, useRef } from "react";
import io from "socket.io-client";
import { getApiUrl } from "../config/api";

export const useChat = (token, conversationId) => {
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [typingUsers, setTypingUsers] = useState([]);
  const socketRef = useRef(null);

  useEffect(() => {
    socketRef.current = io(`${getApiUrl()}/conversations`, {
      auth: { token },
    });

    socketRef.current.on("new message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socketRef.current.on("user typing", ({ userId, username, isTyping }) => {
      setTypingUsers((prevUsers) => {
        if (isTyping) {
          if (!prevUsers.some((user) => user.userId === userId)) {
            return [...prevUsers, { userId, username }];
          }
        } else {
          return prevUsers.filter((user) => user.userId !== userId);
        }
        return prevUsers;
      });
    });

    socketRef.current.on("message read", ({ messageId, userId }) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, isRead: true } : msg,
        ),
      );
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [token]);

  // Fetch messages and join conversation when conversationId changes
  useEffect(() => {
    if (conversationId) {
      joinConversation(conversationId);
      fetchMessages(conversationId);
      return () => {
        leaveConversation(conversationId);
      };
    }
  }, [conversationId]);

  const fetchConversations = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${getApiUrl()}/api/conversations`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch conversations");
      }
      const data = await response.json();
      setConversations(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch conversations");
      console.error("Error fetching conversations:", err);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const createOrGetConversation = useCallback(
    async (participantIds, name = null) => {
      try {
        setLoading(true);
        const response = await fetch(`${getApiUrl()}/api/conversations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ participantIds, name }),
        });
        if (!response.ok) {
          throw new Error("Failed to create or get conversation");
        }
        const newConversation = await response.json();
        setConversations((prevConversations) => [
          ...prevConversations,
          newConversation,
        ]);
        setCurrentConversation(newConversation);
        setError(null);
        return newConversation;
      } catch (err) {
        setError("Failed to create or get conversation");
        console.error("Error creating or getting conversation:", err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token],
  );

  const fetchPaginatedMessages = useCallback(
    async (conversationId, page = 1, limit = 20) => {
      if (!conversationId) {
        console.error("Conversation ID is required to fetch messages");
        return;
      }
      try {
        setLoading(true);
        const response = await fetch(
          `${getApiUrl()}/api/conversations/${conversationId}/messages?page=${page}&limit=${limit}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch paginated messages");
        }
        const data = await response.json();
        setMessages((prevMessages) => [...prevMessages, ...data.messages]);
        setError(null);
        return data;
      } catch (err) {
        setError("Failed to fetch paginated messages");
        console.error("Error fetching paginated messages:", err);
      } finally {
        setLoading(false);
      }
    },
    [token],
  );

  const fetchMessages = useCallback(
    async (conversationId) => {
      if (!conversationId) {
        console.error("Conversation ID is required to fetch messages");
        return;
      }
      try {
        setLoading(true);
        const response = await fetch(
          `${getApiUrl()}/api/conversations/${conversationId}/all-messages`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch all messages");
        }
        const data = await response.json();
        setMessages(data.messages);
        setError(null);
      } catch (err) {
        setError("Failed to fetch all messages");
        console.error("Error fetching all messages:", err);
      } finally {
        setLoading(false);
      }
    },
    [token],
  );

  const sendMessage = useCallback(
    async (conversationId, content, file) => {
      let fileUrl = null;

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await fetch(
            `${getApiUrl()}/api/conversations/${conversationId}/upload`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            },
          );

          if (!response.ok) {
            throw new Error("Failed to upload image");
          }

          const data = await response.json();
          fileUrl = data.fileUrl;
        } catch (error) {
          console.error("Error uploading file:", error);
          return;
        }
      }

      socketRef.current.emit("send message", {
        conversationId,
        content,
        fileUrl,
      });
    },
    [token],
  );

  const markMessageAsRead = useCallback((messageId) => {
    if (socketRef.current) {
      socketRef.current.emit("read message", messageId);
    }
  }, []);

  const joinConversation = useCallback((conversationId) => {
    if (socketRef.current) {
      socketRef.current.emit("join conversation", conversationId);
    }
  }, []);

  const leaveConversation = useCallback((conversationId) => {
    if (socketRef.current) {
      socketRef.current.emit("leave conversation", conversationId);
    }
  }, []);

  const setTypingStatus = useCallback((conversationId, isTyping) => {
    if (socketRef.current) {
      socketRef.current.emit("typing", { conversationId, isTyping });
    }
  }, []);

  const deleteConversation = useCallback(
    async (conversationId) => {
      try {
        setLoading(true);
        const response = await fetch(
          `${getApiUrl()}/api/conversations/${conversationId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Failed to delete conversation");
        }

        setConversations((prevConversations) =>
          prevConversations.filter((conv) => conv.id !== conversationId),
        );
        setError(null);
      } catch (err) {
        setError("Failed to delete conversation");
        console.error("Error deleting conversation:", err);
      } finally {
        setLoading(false);
      }
    },
    [token],
  );

  return {
    conversations,
    currentConversation,
    messages,
    typingUsers,
    loading,
    error,
    fetchConversations,
    createOrGetConversation,
    sendMessage,
    markMessageAsRead,
    joinConversation,
    leaveConversation,
    setTypingStatus,
    fetchPaginatedMessages,
    fetchMessages,
    deleteConversation,
  };
};
