import React, { useState } from "react";
import {
  Users,
  TrendingDown,
  TrendingUp,
  ChartBar,
  ArrowUpRight,
  ArrowDownRight,
  CalendarDays,
} from "lucide-react";

const OutstandingShares = ({ data, darkMode, formatNumber, formatDate }) => {
  const [view, setView] = useState("annual");

  if (!data) return null;

  const annualData = Object.values(data.annual || {}).sort(
    (a, b) => new Date(b.dateFormatted) - new Date(a.dateFormatted),
  );

  const quarterlyData = Object.values(data.quarterly || {}).sort(
    (a, b) => new Date(b.dateFormatted) - new Date(a.dateFormatted),
  );

  const calculateChange = (current, previous) => {
    if (!previous || !current) return 0;
    return ((current.shares - previous.shares) / previous.shares) * 100;
  };

  const calculateTotalChange = (data) => {
    const newest = data[0];
    const oldest = data[data.length - 1];
    if (!newest || !oldest) return 0;
    return ((newest.shares - oldest.shares) / oldest.shares) * 100;
  };

  const formatTimeframe = (date, isQuarterly) => {
    if (isQuarterly) {
      const [year, quarter] = date.split("-");
      return `${year} Q${quarter?.replace("Q", "")}`;
    }
    return date;
  };

  const ViewToggle = () => (
    <div className="flex rounded-lg overflow-hidden border divide-x">
      <button
        onClick={() => setView("annual")}
        className={`px-3 py-1.5 text-sm font-medium ${
          view === "annual"
            ? darkMode
              ? "bg-blue-600 text-white"
              : "bg-blue-500 text-white"
            : darkMode
              ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
              : "bg-slate-50 text-slate-700 hover:bg-slate-100"
        }`}
      >
        Annual
      </button>
      <button
        onClick={() => setView("quarterly")}
        className={`px-3 py-1.5 text-sm font-medium ${
          view === "quarterly"
            ? darkMode
              ? "bg-blue-600 text-white"
              : "bg-blue-500 text-white"
            : darkMode
              ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
              : "bg-slate-50 text-slate-700 hover:bg-slate-100"
        }`}
      >
        Quarterly
      </button>
    </div>
  );

  const displayData = view === "annual" ? annualData : quarterlyData;
  const totalChange = calculateTotalChange(displayData);

  // Utility functions for styling
  const getBackgroundStyle = () => {
    return darkMode
      ? "bg-slate-800 shadow-lg shadow-slate-900/20"
      : "bg-white shadow-lg shadow-slate-200/50";
  };

  const getTextColor = () => {
    return darkMode ? "text-slate-200" : "text-slate-700";
  };

  // Mobile card view for each data row
  const DataCard = ({ period, change, changePercent }) => (
    <div
      className={`rounded-xl p-4 transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg ${
        darkMode ? "bg-slate-700" : "bg-slate-50"
      }`}
    >
      <div className="flex justify-between items-start mb-2">
        <span
          className={`font-medium ${darkMode ? "text-slate-200" : "text-slate-900"}`}
        >
          {formatTimeframe(period.date, view === "quarterly")}
        </span>
        <div
          className={`flex items-center gap-1 ${
            changePercent > 0
              ? "text-green-500"
              : changePercent < 0
                ? "text-red-500"
                : darkMode
                  ? "text-slate-400"
                  : "text-slate-600"
          }`}
        >
          {changePercent !== 0 && (
            <>
              {changePercent > 0 ? (
                <ArrowUpRight size={16} />
              ) : (
                <ArrowDownRight size={16} />
              )}
              <span>
                {changePercent > 0 && "+"}
                {changePercent.toFixed(2)}%
              </span>
            </>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 text-sm">
        <div>
          <div className={`${darkMode ? "text-slate-400" : "text-slate-600"}`}>
            Shares
          </div>
          <div className={`${darkMode ? "text-slate-200" : "text-slate-900"}`}>
            {formatNumber(period.shares)}
          </div>
        </div>
        <div>
          <div className={`${darkMode ? "text-slate-400" : "text-slate-600"}`}>
            Change
          </div>
          <div
            className={`${
              change > 0
                ? "text-green-500"
                : change < 0
                  ? "text-red-500"
                  : darkMode
                    ? "text-slate-400"
                    : "text-slate-600"
            }`}
          >
            {change !== 0 && (change > 0 ? "+" : "")}
            {formatNumber(change)}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        {[
          {
            title: "Current Shares",
            value: formatNumber(displayData[0]?.shares),
            subtitle: `As of ${formatDate(displayData[0]?.dateFormatted)}`,
            icon: <Users className="w-6 h-6 text-blue-500" />,
          },
          {
            title: "Historical Change",
            value: `${totalChange > 0 ? "+" : ""}${totalChange.toFixed(2)}%`,
            subtitle: `Since ${formatDate(displayData[displayData.length - 1]?.dateFormatted)}`,
            icon:
              totalChange > 0 ? (
                <TrendingUp className="w-6 h-6 text-green-500" />
              ) : (
                <TrendingDown className="w-6 h-6 text-red-500" />
              ),
            valueColor: totalChange > 0 ? "text-green-500" : "text-red-500",
          },
          {
            title: "Peak Shares",
            value: formatNumber(Math.max(...displayData.map((d) => d.shares))),
            subtitle: "Historical Maximum",
            icon: <ChartBar className="w-6 h-6 text-blue-500" />,
          },
          {
            title: "Average Shares",
            value: formatNumber(
              displayData.reduce((sum, d) => sum + d.shares, 0) /
                displayData.length,
            ),
            subtitle: "Historical Average",
            icon: <ChartBar className="w-6 h-6 text-blue-500" />,
          },
        ].map((item, index) => (
          <div
            key={index}
            className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl ${
              darkMode ? "border border-slate-700" : "border border-slate-100"
            }`}
          >
            <div className="p-4 sm:p-5">
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  {item.icon}
                  <h3
                    className={`text-sm sm:text-base font-medium tracking-wide ${getTextColor()}`}
                  >
                    {item.title}
                  </h3>
                </div>
                <div className="space-y-2">
                  <p
                    className={`text-2xl sm:text-3xl font-semibold tracking-tight ${
                      item.valueColor
                        ? item.valueColor
                        : darkMode
                          ? "text-white"
                          : "text-slate-900"
                    }`}
                  >
                    {item.value}
                  </p>
                  {item.subtitle && (
                    <p
                      className={`text-sm font-medium ${
                        darkMode ? "text-slate-400" : "text-slate-600"
                      }`}
                    >
                      {item.subtitle}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Data Table Section */}
      <div
        className={`rounded-xl ${getBackgroundStyle()} transition-all duration-300 ease-in-out hover:shadow-xl ${
          darkMode ? "border border-slate-700" : "border border-slate-100"
        }`}
      >
        <div className="p-4 sm:p-5">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center space-x-3">
              <CalendarDays className="w-6 h-6 text-blue-500" />
              <h3 className={`text-lg font-semibold ${getTextColor()}`}>
                Historical Shares Outstanding
              </h3>
            </div>
            <ViewToggle />
          </div>

          {/* Desktop Table View */}
          <div className="hidden sm:block overflow-x-auto mt-6">
            <table className="w-full">
              <thead>
                <tr
                  className={`border-b ${darkMode ? "border-slate-700" : "border-slate-200"}`}
                >
                  <th
                    className={`py-3 px-4 text-left ${
                      darkMode ? "text-slate-400" : "text-slate-600"
                    }`}
                  >
                    Period
                  </th>
                  <th
                    className={`py-3 px-4 text-right ${
                      darkMode ? "text-slate-400" : "text-slate-600"
                    }`}
                  >
                    Shares Outstanding
                  </th>
                  <th
                    className={`py-3 px-4 text-right ${
                      darkMode ? "text-slate-400" : "text-slate-600"
                    }`}
                  >
                    Change
                  </th>
                  <th
                    className={`py-3 px-4 text-right ${
                      darkMode ? "text-slate-400" : "text-slate-600"
                    }`}
                  >
                    % Change
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayData.map((period, index) => {
                  const previousPeriod = displayData[index + 1];
                  const change = previousPeriod
                    ? period.shares - previousPeriod.shares
                    : 0;
                  const changePercent = calculateChange(period, previousPeriod);

                  return (
                    <tr
                      key={period.dateFormatted}
                      className={`border-b last:border-b-0 ${
                        darkMode
                          ? "border-slate-700 hover:bg-slate-700"
                          : "border-slate-200 hover:bg-slate-50"
                      }`}
                    >
                      <td
                        className={`py-3 px-4 ${
                          darkMode ? "text-slate-200" : "text-slate-900"
                        }`}
                      >
                        {formatTimeframe(period.date, view === "quarterly")}
                      </td>
                      <td
                        className={`py-3 px-4 text-right ${
                          darkMode ? "text-slate-200" : "text-slate-900"
                        }`}
                      >
                        {formatNumber(period.shares)}
                      </td>
                      <td
                        className={`py-3 px-4 text-right ${
                          change > 0
                            ? "text-green-500"
                            : change < 0
                              ? "text-red-500"
                              : darkMode
                                ? "text-slate-400"
                                : "text-slate-600"
                        }`}
                      >
                        {change !== 0 && (change > 0 ? "+" : "")}
                        {formatNumber(change)}
                      </td>
                      <td
                        className={`py-3 px-4 text-right ${
                          changePercent > 0
                            ? "text-green-500"
                            : changePercent < 0
                              ? "text-red-500"
                              : darkMode
                                ? "text-slate-400"
                                : "text-slate-600"
                        }`}
                      >
                        {changePercent !== 0 && (
                          <div className="flex items-center justify-end gap-1">
                            {changePercent > 0 ? (
                              <ArrowUpRight size={16} />
                            ) : (
                              <ArrowDownRight size={16} />
                            )}
                            {changePercent > 0 && "+"}
                            {changePercent.toFixed(2)}%
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Mobile Card View */}
          <div className="sm:hidden mt-6 space-y-4">
            {displayData.map((period, index) => {
              const previousPeriod = displayData[index + 1];
              const change = previousPeriod
                ? period.shares - previousPeriod.shares
                : 0;
              const changePercent = calculateChange(period, previousPeriod);

              return (
                <DataCard
                  key={period.dateFormatted}
                  period={period}
                  change={change}
                  changePercent={changePercent}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutstandingShares;
