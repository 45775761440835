import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  FaChartLine,
  FaDollarSign,
  FaGlobe,
  FaRocket,
  FaUsers,
  FaLaptop,
  FaQuestion,
} from "react-icons/fa";
import NavButton from "../menu/NavButton";

function HeroSection() {
  const { darkMode } = useTheme();

  return (
    <section
      className={`pt-32 pb-16 px-4 ${darkMode ? "bg-gray-900" : "bg-blue-50"} 
      md:pt-40 md:pb-20
      lg:pt-48 lg:pb-24
      transition-all duration-500`}
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 text-center lg:text-left mb-12 lg:mb-0">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-6 leading-tight">
              Unlock Your
              <br />
              Ultimate Potential
            </h1>
            <p
              className={`text-xl mb-8 max-w-2xl mx-auto lg:mx-0 ${
                darkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Join our thriving community of affiliates and tap into a world of
              opportunities. Our platform provides you with the tools,
              resources, and support you need to succeed in the dynamic world of
              affiliate marketing.
            </p>
            <p
              className={`text-lg mb-8 max-w-2xl mx-auto lg:mx-0 ${
                darkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Whether you're just starting out or looking to scale your existing
              affiliate business, we offer a range of high-converting products,
              comprehensive training materials, and a dedicated support team to
              help you achieve your goals.
            </p>
            <div className="flex flex-col sm:flex-row justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
              <NavButton
                to="https://www.clickbank.com/"
                icon={FaRocket}
                isExternal={true}
              >
                Start Your Journey
              </NavButton>
              <NavButton to="how-it-works" icon={FaQuestion}>
                Learn More
              </NavButton>
            </div>
          </div>

          <div className="lg:w-1/2 flex justify-center">
            <div
              className={`w-full max-w-md p-8 rounded-3xl shadow-xl transition-transform duration-300 transform hover:scale-105 ${
                darkMode
                  ? "bg-gray-800 text-gray-100"
                  : "bg-white text-gray-700"
              }`}
            >
              <h3 className="text-3xl font-bold mb-6 text-center text-green-500">
                Why Choose Us?
              </h3>
              <ul className="space-y-6">
                <li className="flex items-center">
                  <FaChartLine
                    className={`w-8 h-8 mr-4 ${
                      darkMode ? "text-green-400" : "text-green-500"
                    }`}
                  />
                  <span className="text-lg font-medium">
                    High-converting products and landing pages
                  </span>
                </li>
                <li className="flex items-center">
                  <FaDollarSign
                    className={`w-8 h-8 mr-4 ${
                      darkMode ? "text-green-400" : "text-green-500"
                    }`}
                  />
                  <span className="text-lg font-medium">
                    Competitive commission structure
                  </span>
                </li>
                <li className="flex items-center">
                  <FaGlobe
                    className={`w-8 h-8 mr-4 ${
                      darkMode ? "text-green-400" : "text-green-500"
                    }`}
                  />
                  <span className="text-lg font-medium">
                    Access to a global customer base
                  </span>
                </li>
                <li className="flex items-center">
                  <FaUsers
                    className={`w-8 h-8 mr-4 ${
                      darkMode ? "text-green-400" : "text-green-500"
                    }`}
                  />
                  <span className="text-lg font-medium">
                    Supportive affiliate community
                  </span>
                </li>
                <li className="flex items-center">
                  <FaLaptop
                    className={`w-8 h-8 mr-4 ${
                      darkMode ? "text-green-400" : "text-green-500"
                    }`}
                  />
                  <span className="text-lg font-medium">
                    Advanced tracking and reporting tools
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
